import React, { FC } from "react"
import {
  HasSidebarLayout,
  ListHeader,
  TabGroupFilter,
  UnstableSensorsContent,
  TimeseriesGapChart,
  TimeseriesHandlingGapChart,
} from "src/components"
import { DiagnosticsAttachmentsTab } from "src/components/sections/diagnostics/attachments"
import { SearchParamsKeys } from "src/enums"

const getUserListTabs = () => [
  {
    status: "Unstable sensors",
    content: <UnstableSensorsContent />,
  },
  {
    status: "Timeseries GAP",
    content: <TimeseriesGapChart />,
  },
  {
    status: "Timeseries Handling GAP",
    content: <TimeseriesHandlingGapChart />,
  },
  {
    status: "Background Sync",
    content: <DiagnosticsAttachmentsTab />,
  }
]

const DiagnosticsTabs: FC = () => {
  const tabs = getUserListTabs()
  return <TabGroupFilter name={SearchParamsKeys.Tab} statusList={tabs} />
}

const Diagnostics: FC = () => {
  document.title = 'Floorcloud Admin Panel - Diagnostics'
  return (
    <HasSidebarLayout>
      <ListHeader title="Diagnostics" />
      <DiagnosticsTabs />
    </HasSidebarLayout>
  )
}

export default Diagnostics
