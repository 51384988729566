import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Attachment } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseQueryOptions<unknown, Error, Attachment>,
    'queryKey' | 'queryFn'
  >;
}

export const useAttachmentRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useQuery<unknown, Error, Attachment>(
    [Entities.Attachments, id],
    async () => {
      const { data } = await api.v1AdminAttachmentsAttachmentIdGet(id)
      return data
    },
    { ...options },
  )
}
