import React, { FC } from 'react'
import { ApexOptions } from 'apexcharts'
import format from 'date-fns/format'
import { Box, CircularProgress } from '@mui/material'
import { merge } from 'lodash'
import { Chart } from './chart'

interface SensorChartProps {
  data: ApexOptions['series'];
  options?: Partial<Omit<ApexOptions, 'series'>>;
  isLoading?: boolean;
}

export const getCustomTooltip = ({ series, seriesIndex, dataPointIndex, w }: any): string => {
  const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]
  const label = w.globals.initialSeries[seriesIndex].name
  const timestamp = w.globals.ttKeyFormatter(data.x)
  const readingValue = data.y
  const latitude: string = data.meta.latitude ? [
    '<div class="apexcharts-tooltip-goals-group">',
    '<span class="apexcharts-tooltip-text-goals-label">',
    'Latitude: ',
    '</span>',
    '<span class="apexcharts-tooltip-text-goals-value">',
    data.meta.latitude || '-',
    '</span>',
    '</div>',
  ].join('') : ''
  const longitude: string = data.meta.longitude ? [
    '<div class="apexcharts-tooltip-goals-group">',
    '<span class="apexcharts-tooltip-text-goals-label">',
    'Longitude: ',
    '</span>',
    '<span class="apexcharts-tooltip-text-goals-value">',
    data.meta.longitude || '-',
    '</span>',
    '</div>',
  ].join('') : ''
  const mainReading: string = !data.meta.isLocation ? [
    '<div class="apexcharts-tooltip-y-group">',
    '<span class="apexcharts-tooltip-text-y-label">',
    `${label}: `,
    '</span>',
    '<span class="apexcharts-tooltip-text-y-value">',
    readingValue,
    '</span>',
    '</div>',
  ].join('') : [latitude, longitude].join('')
  const contractor: string = [
    '<div class="apexcharts-tooltip-goals-group">',
    '<span class="apexcharts-tooltip-text-goals-label">',
    'Contractor: ',
    '</span>',
    '<span class="apexcharts-tooltip-text-goals-value">',
    data.meta.contractor || '-',
    '</span>',
    '</div>',
  ].join('')
  const project: string = [
    '<div class="apexcharts-tooltip-goals-group">',
    '<span class="apexcharts-tooltip-text-goals-label">',
    'Project: ',
    '</span>',
    '<span class="apexcharts-tooltip-text-goals-value">',
    data.meta.project || '-',
    '</span>',
    '</div>',
  ].join('')
  const section: string = [
    '<div class="apexcharts-tooltip-goals-group">',
    '<span class="apexcharts-tooltip-text-goals-label">',
    'Section: ',
    '</span>',
    '<span class="apexcharts-tooltip-text-goals-value">',
    data.meta.projectSection || '-',
    '</span>',
    '</div>',
  ].join('')
  const gapInMinutes: string = data.meta.gapInMinutes ? [
    '<div class="apexcharts-tooltip-goals-group">',
    '<span class="apexcharts-tooltip-text-goals-label">',
    'Gap in minutes: ',
    '</span>',
    '<span class="apexcharts-tooltip-text-goals-value">',
    data.meta.gapInMinutes,
    '</span>',
    '</div>',
  ].join('') : ''
  const rapidRhProbes: string = data.meta.rapidRhProbes?.length > 0 ? [
    '<div class="apexcharts-tooltip-goals-group">',
    '<span class="apexcharts-tooltip-text-goals-label">',
    'Rapid RH probes: ',
    '</span>',
    '<span class="apexcharts-tooltip-text-goals-value">',
    data.meta.rapidRhProbes.join(','),
    '</span>',
    '</div>',
  ].join('') : ''
  const backgroundColor: string = [
    `<span class="apexcharts-tooltip-marker" style="background-color: ${data.fillColor};">`,
    '</span>'
  ].join('')
  const timestampBlock: string = [
    '<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">',
    timestamp,
    '</div>',
  ].join('')

  return (
    timestampBlock +
    '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">' +
    backgroundColor +
    '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
    mainReading +
    contractor +
    project +
    section +
    gapInMinutes +
    rapidRhProbes +
    '</div>' +
    '</div>' +
    '</div>'
  )
}

export const SensorChart: FC<SensorChartProps> = ({ data, options = {}, isLoading = false }) => {
  const chartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            headerCategory: 'Time',
          },
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      labels: {
        offsetX: 0,
        formatter: (timestamp) => {
          return format(Number(timestamp), 'MMM dd - h a')
        },
      },
    },
    tooltip: {
      x: {
        formatter: (timestamp) => {
          return format(timestamp, 'MMM dd - h a')
        },
      },
    },
  }

  const resultOptions = merge(chartOptions, options)
  return (
    <Box height="400px" ml={-2} width="calc(100% + 16px)" position="relative">
      {
        isLoading && (
          <>
            <Box
              position="absolute"
              width="100%"
              height="100%"
              zIndex={1}
              sx={{
                background: "grey",
                opacity: 0.1,
              }}
            />
            <Box
              position="absolute"
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress color="primary" size={26} />
            </Box>
          </>
        )
      }
      <Chart options={resultOptions} data={data} />
    </Box>
  )
}
