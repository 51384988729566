import React, { FC, PropsWithChildren, useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Button, Grid, Typography } from "@mui/material"
import {
  HasSidebarLayout,
  DataLoad,
  EntityHeader,
  InnerLink,
  UserInfo,
  PropsInjector,
  ProjectsRequestableList,
  TabGroupFilter,
  UserActiveSessionList,
  DeleteUserModal,
  ArchiveUserModal,
  UnarchiveUserModal,
  ClosedEye,
  UserEventList,
  UserGlobalNotificationList,
  UserProjectNotificationList,
  UserAdminNotificationList,
} from "src/components"
import { useUserRequest, useProjectListRequest, useDeleteUserRequest, useArchiveUserRequest, useUnarchiveUserRequest, useUserMessageChannelListRequest } from "src/hooks/api"
import { User, Project, UserMessageChannel } from "src/api"
import { useAddPopupMessage, useDialog, useQueryParamsState } from "src/hooks/ui"
import { DialogNames, SearchParamsKeys } from "src/enums"

const getTabs = ({
  user,
  updateUser,
}: {
  user: User;
  updateUser: () => void;
}) => {
  const tabs = [
    {
      status: "Projects",
      content: (
        <ProjectsRequestableList
          userId={user.id}
          noResultText="Current user no belongs to any project"
        />
      ),
    },
    {
      status: "Active sessions",
      content: (
        <UserActiveSessionList
          user={user}
        />
      ),
    },
    {
      status: "Events",
      content: <UserEventList userId={user.id} />
    },
    {
      status: "Global Notifications",
      content: (
        <UserGlobalNotificationList userId={user.id} />
      ),
    },
    {
      status: "Project Notifications",
      content: (
        <UserProjectNotificationList userId={user.id} />
      )
    }
  ]
  if (user.role?.isAdmin) {
    tabs.push({
      status: "Admin Notifications",
      content: (
        <UserAdminNotificationList
          user={user}
          notifications={user?.adminNotifications || []}
          updateUser={updateUser}
          showFooter={false}
        />
      )
    })
  }
  return tabs
}

interface UserShowContentProps {
  user?: User;
  projects?: Project[];
  projectsCount?: number;
  projectsLoading?: boolean;
  updateUser?: () => void;
}

const UserShowContent: FC<UserShowContentProps> = ({
  user = {},
  updateUser,
}) => {
  const { id, name, contractor, contractorId, lastActive, email, phone, role, lastVersionApp, lastAction, isArchived, messageChannel } =
    user as User
  const { openDialog: openDeleteDialog, closeDialog: closeDeleteDialog } = useDialog(DialogNames.DeleteUser)
  const { openDialog: openArchiveDialog, closeDialog: closeArchiveDialog } = useDialog(DialogNames.ArchiveUser)
  const { openDialog: openUnarchiveDialog, closeDialog: closeUnarchiveDialog } = useDialog(DialogNames.UnarchiveUser)
  const deleteUserRequest = useDeleteUserRequest()
  const archiveUserRequest = useArchiveUserRequest()
  const unarchiveUserRequest = useUnarchiveUserRequest()
  const userMessageChannelRequest = useUserMessageChannelListRequest({
    params: { limit: 100 }
  })
  const addMessage = useAddPopupMessage()
  const navigate = useNavigate()
  const selectedUserChannel: UserMessageChannel = useCallback(() => {
    const channel = (userMessageChannelRequest.data?.rows || []).find((item) => item.name === messageChannel)
    return channel as UserMessageChannel
  }, [userMessageChannelRequest, messageChannel])()

  const deleteUser = () => {
    deleteUserRequest.mutate({
      id,
    }, {
      onSuccess: () => {
        closeDeleteDialog()
        addMessage({ text: 'The user was successfully deleted', type: 'success' })
        navigate(-1)
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
      }
    })
  }

  const archiveUser = () => {
    archiveUserRequest.mutate({
      userId: id,
    }, {
      onSuccess: () => {
        closeArchiveDialog()
        addMessage({ text: 'The user was successfully archived', type: 'success' })
        if (typeof updateUser === 'function') updateUser()
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
      }
    })
  }

  const unarchiveUser = () => {
    unarchiveUserRequest.mutate({
      userId: id,
    }, {
      onSuccess: (result) => {
        const { hasClone, user: anarchivedUser } = result
        closeUnarchiveDialog()
        if (hasClone) {
          addMessage({
            text: <Typography>
              Active user with the same credentials exists
              <InnerLink to={`/users/${anarchivedUser.id}`}>{` - ${anarchivedUser.name}`}</InnerLink>
            </Typography>,
            type: 'warning',
          })
        } else {
          addMessage({ text: 'The user was successfully unarchived', type: 'success' })
          if (typeof updateUser === 'function') updateUser()
        }
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
      }
    })
  }

  const actionBlock = () => {
    if (isArchived) {
      return (
        <Button
          variant="contained"
          color="error"
          onClick={openUnarchiveDialog}
        >
          Unarchive
        </Button>
      )
    }

    return (
      <>
        <Grid item marginRight={2}>
          <InnerLink to={`/users/${id}/edit`} underline="none">
            <Button variant="contained">Edit Info</Button>
          </InnerLink>
        </Grid>
        <Grid item marginRight={2}>
          <Button
            variant="contained"
            color="error"
            onClick={openArchiveDialog}
          >
            Archive
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="error"
            onClick={openDeleteDialog}
          >
            Delete
          </Button>
        </Grid>
      </>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <EntityHeader
          title={name}
          rightIcon={
            isArchived
              ? (
                <Grid container item title="Archived User">
                  <ClosedEye />
                </Grid>
              )
              : null
          }
          actionBlockOnTheRight={actionBlock()}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" sx={{ mb: 1, textTransform: "capitalize" }}>
          {role?.displayName}
        </Typography>
        <UserInfo
          contractorId={contractorId}
          contractorName={contractor?.name}
          email={email}
          phone={phone}
          lastActive={lastActive ? new Date(lastActive) : void 0}
          lastAppVersion={lastVersionApp}
          lastAction={lastAction}
          messageChannel={selectedUserChannel}
        />
      </Grid>
      {
        isArchived
          ? null
          : (
            <Grid item xs={12}>
              <TabGroupFilter
                name={SearchParamsKeys.Tab}
                statusList={getTabs({
                  user: user as User,
                  updateUser: updateUser as () => void,
                })}
                paramsToRemove={[
                  SearchParamsKeys.Page,
                  SearchParamsKeys.RowsPerPage,
                ]}
              />
            </Grid>
          )
      }
      <DeleteUserModal
        userName={name}
        onCancel={closeDeleteDialog}
        onDelete={deleteUser}
        isLoading={deleteUserRequest.isLoading}
      />
      <ArchiveUserModal
        userName={name}
        onCancel={closeArchiveDialog}
        onArchive={archiveUser}
        isLoading={archiveUserRequest.isLoading}
      />
      <UnarchiveUserModal
        userName={name}
        onCancel={closeUnarchiveDialog}
        onUnarchive={unarchiveUser}
        isLoading={unarchiveUserRequest.isLoading}
      />
    </Grid>
  )
}

interface UserResourceLoaderProps {
  userId: number;
}

const UserResourceLoader: FC<PropsWithChildren<UserResourceLoaderProps>> = ({
  userId: id,
  children,
}) => {
  const queryParams = useQueryParamsState()
  const userRequest = useUserRequest({ id })
  document.title = `Floorcloud Admin Panel - User ${userRequest.data?.name || ''}`

  const projectsRequest = useProjectListRequest({
    params: {
      userId: id,
      ...queryParams,
    },
  })

  const isError = userRequest.isError || projectsRequest.isError
  const error = userRequest.error || projectsRequest.error

  const props = {
    user: userRequest?.data || {},
    projects: projectsRequest?.data?.rows || [],
    projectsCount: projectsRequest?.data?.count || 0,
    projectsLoading: projectsRequest.isInitialLoading,
    updateUser: userRequest.refetch,
  }

  return (
    <DataLoad
      isLoading={userRequest.isInitialLoading}
      isError={isError}
      errorMessage={error?.message}
    >
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

const UserShow: FC = () => {
  const params = useParams()
  const { id } = params as { id: string }

  return (
    <HasSidebarLayout>
      <UserResourceLoader userId={parseInt(id, 10)}>
        <UserShowContent />
      </UserResourceLoader>
    </HasSidebarLayout>
  )
}

export default UserShow
