import { TableCell, Typography } from "@mui/material"
import {
  ManufacturerAnnouncementDashboardStatistics,
  PhotoDashboardStatistics,
  ProductDashboardStatistics,
  ProjectDashboardStatistics,
  RapidRHSensorDashboardStatistics,
  SensorDashboardStatistics,
  UsageDashboardStatistics,
  UsageDashboardStatisticsSharedDetails,
  UsageDashboardStatisticsTask,
} from "src/api"
import { DynamicColumn } from "../ui"

export namespace ProjectDashboardColumns {
  export const StatesNumber: DynamicColumn<ProjectDashboardStatistics> = {
    field: "statesNumber",
    headerName: "States",
    type: "digit",
  }

  export const ContractorsNumber: DynamicColumn<ProjectDashboardStatistics> = {
    field: "contractorsNumber",
    headerName: "Contractors (active/deactivated)",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Typography variant="body1">All: {row.contractorsNumber} ({row.activatedContractorsNumber}/{row.deactivatedContractorsNumber})</Typography>
          <Typography variant="body1">Paid: {row.paidContractorsNumber} ({row.paidActivatedContractorsNumber}/{row.paidDeactivatedContractorsNumber})</Typography>
        </TableCell>
      )
    },
  }

  export const UsersNumber: DynamicColumn<ProjectDashboardStatistics> = {
    field: "usersNumber",
    headerName: "Users",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Typography variant="body1">All: {row.usersNumber}</Typography>
          <Typography variant="body1">Active: {row.activeUsersNumber}</Typography>
          <Typography variant="body1">Archived: {row.archivedUsersNumber}</Typography>
        </TableCell>
      )
    },
  }

  export const ProjectsNumber: DynamicColumn<ProjectDashboardStatistics> = {
    field: "projectsNumber",
    headerName: "Projects",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Typography variant="body1">All: {row.projectsNumber}</Typography>
          <Typography variant="body1">Active: {row.activeProjectsNumber}</Typography>
          <Typography variant="body1">Archived: {row.archivedProjectsNumber}</Typography>
        </TableCell>
      )
    },
  }

  export const SectionsNumber: DynamicColumn<ProjectDashboardStatistics> = {
    field: "sectionsNumber",
    headerName: "Sections",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Typography variant="body1">All: {row.sectionsNumber}</Typography>
          <Typography variant="body1">Active: {row.activeSectionsNumber}</Typography>
          <Typography variant="body1">Archived: {row.archivedSectionsNumber}</Typography>
        </TableCell>
      )
    },
  }
}

export namespace SensorDashboardColumns {
  export const SensorsNumber: DynamicColumn<SensorDashboardStatistics> = {
    field: "sensorsNumber",
    headerName: "Total/Online",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Typography variant="body1">{row.sensorsNumber}/{row.onlineSensorsNumber}</Typography>
        </TableCell>
      )
    },
  }

  export const FreeSensorsNumber: DynamicColumn<SensorDashboardStatistics> = {
    field: "freeSensorsNumber",
    headerName: "Inventory",
    type: "digit",
  }

  export const AssignedSensorsNumber: DynamicColumn<SensorDashboardStatistics> = {
    field: "assignedSensorsNumber",
    headerName: "Assigned to contractors",
    type: "digit",
  }

  export const InUseSensorsNumber: DynamicColumn<SensorDashboardStatistics> = {
    field: "inUseSensorsNumber",
    headerName: "In use/Active",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Typography variant="body1">{row.inUseSensorsNumber}/{row.activeInUseSensorsNumber}</Typography>
        </TableCell>
      )
    },
  }

  export const ArchivedSensorsNumber: DynamicColumn<SensorDashboardStatistics> = {
    field: "archivedSensorsNumber",
    headerName: "Archived",
    type: "digit",
  }
}

export namespace RapidRHSensorDashboardColumns {
  export const SensorsNumber: DynamicColumn<RapidRHSensorDashboardStatistics> = {
    field: "sensorsNumber",
    headerName: "Detected",
    type: "digit",
  }

  export const InUseSensorsNumber: DynamicColumn<RapidRHSensorDashboardStatistics> = {
    field: "inUseSensorsNumber",
    headerName: "In use",
    type: "digit",
  }
}

export namespace ProductDashboardColumns {
  export const ManfacturersNumber: DynamicColumn<ProductDashboardStatistics> = {
    field: "manfacturersNumber",
    headerName: "Manufacturers",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Typography variant="body1">All: {row.manufacturersNumber}</Typography>
          <Typography variant="body1">Verified: {row.verifiedManufacturersNumber}</Typography>
        </TableCell>
      )
    },
  }

  export const ProductsNumber: DynamicColumn<ProductDashboardStatistics> = {
    field: "productsNumber",
    headerName: "Total products",
    type: "digit",
  }

  export const PublishedProductsNumber: DynamicColumn<ProductDashboardStatistics> = {
    field: "publishedProductsNumber",
    headerName: "Published products",
    type: "digit",
  }

  export const InUseProductsNumber: DynamicColumn<ProductDashboardStatistics> = {
    field: "inUseProductsNumber",
    headerName: "In use products",
    type: "digit",
  }
}

export namespace UsageStatisticsDashboardColumns {
  export const MonitoredSectionsNumber: DynamicColumn<UsageDashboardStatistics> = {
    field: "monitoredSectionsNumber",
    headerName: "Sections monitored",
    type: "digit",
  }

  export const MonitoredHoursNumber: DynamicColumn<UsageDashboardStatistics> = {
    field: "monitoredHoursNumber",
    headerName: "Hours monitored",
    type: "custom",
    component: ({ row }) => {
      const hours = row.monitoredHoursNumber
      const days = Math.ceil(row.monitoredHoursNumber / 24)
      const months = Math.ceil(days / 30)
      const years = (months / 12).toFixed(1)
      return (
        <TableCell>
          <Typography variant="body1">Hours: {hours}</Typography>
          <Typography variant="body1">Days: {days}</Typography>
          <Typography variant="body1">Months ~ {months}</Typography>
          <Typography variant="body1">Years ~ {years}</Typography>
        </TableCell>
      )
    },
  }

  export const AlertsNumber: DynamicColumn<UsageDashboardStatistics> = {
    field: "alert.alertsNumber",
    headerName: "Alerts generated",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Typography variant="body1">Total: {row.alert.alertsNumber}</Typography>
          {row.alert.types.map((alertType) => {
            if (alertType.name.toLocaleLowerCase() === 'dewpoint') {
              const percent = Math.round((alertType.alertsNumber * 100) / row.alert.alertsNumber)
              return (
                <Typography variant="body1">{alertType.name}: {percent}%</Typography>
              )
            }
            const percentMin = Math.round((alertType.alertsNumberMin * 100) / row.alert.alertsNumber)
            const percentMax = Math.round((alertType.alertsNumberMax * 100) / row.alert.alertsNumber)
            return (
              <>
                <Typography variant="body1">Low {alertType.name}: {percentMin}%</Typography>
                <Typography variant="body1">High {alertType.name}: {percentMax}%</Typography>
              </>
            )
          })}
        </TableCell>
      )
    },
  }

  export const SharedData: DynamicColumn<UsageDashboardStatistics> = {
    field: "sharedDetails.sharedAlertsNumber",
    headerName: "Data Shared",
    type: "custom",
    component: ({ row }) => {
      const map: Record<string, string> = {
        sharedAlertsNumber: 'Alert',
        sharedAmbientConditionsNumber: 'Floorcloud sensors chart',
        sharedRapidRhDetailsNumber: 'RapidRH probe details',
        sharedRapidRhListNumber: 'RapidRH probe list',
      }
      return (
        <TableCell>
          {Object.keys(row.sharedDetails).map((key) => {
            const name = map[key]
            const value = row.sharedDetails[key as keyof UsageDashboardStatisticsSharedDetails]
            if (!name) return null
            return (
              <Typography variant="body1">{name}: {value}</Typography>
            )
          })}
        </TableCell>
      )
    },
  }

  export const ReportsNumber: DynamicColumn<UsageDashboardStatistics> = {
    field: "report.reportsNumber",
    headerName: "Reports generated",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Typography variant="body1">Total: {row.report.reportsNumber}</Typography>
          {row.report.types.map((reportType) => {
            const percent = Math.round((reportType.reportsNumber * 100) / row.report.reportsNumber)
            return (
              <Typography variant="body1">{reportType.name}: {percent}%</Typography>
            )
          })}
        </TableCell>
      )
    },
  }

  export const NotesNumber: DynamicColumn<UsageDashboardStatistics> = {
    field: "note.notesNumber",
    headerName: "Notes created",
    type: "digit",
  }

  export const PhotosNumber: DynamicColumn<UsageDashboardStatistics> = {
    field: "photo.photosNumber",
    headerName: "Photos taken",
    type: "custom",
    component: ({ row }) => {
      const map: Record<string, string> = {
        floorcloudSensorConnectionPhotosNumber: 'Floorcloud sensor connection',
        notePhotosNumber: 'Notes',
        productAdditionPhotosNumber: 'Product addition',
        rapidRhProbeConnectionPhotosNumber: 'RapidRH probe connection',
        taskPhotosNumber: 'Tasks',
      }
      return (
        <TableCell>
          <Typography variant="body1">Total: {row.photo.photosNumber}</Typography>
          {Object.keys(row.photo).map((key) => {
            const name = map[key]
            const value = row.photo[key as keyof PhotoDashboardStatistics]
            if (!name) return null
            return (
              <Typography variant="body1">{name}: {value}</Typography>
            )
          })}
        </TableCell>
      )
    },
  }

  export const TasksNumber: DynamicColumn<UsageDashboardStatistics> = {
    field: "tasksNumber",
    headerName: "Tasks",
    type: "custom",
    component: ({ row }) => {
      const map: Record<string, string> = {
        assignedTasksNumber: 'Assigned',
        unassignedTasksNumber: 'Unassigned',
        activeTasksNumber: 'Active',
        completedTasksNumber: 'Completed',
        archivedTasksNumber: 'Archived',
      }
      return (
        <TableCell>
          <Typography variant="body1">Total: {row.task.tasksNumber}</Typography>
          {Object.keys(row.task).map((key) => {
            const name = map[key]
            const value = row.task[key as keyof UsageDashboardStatisticsTask]
            if (!name) return null
            return (
              <Typography variant="body1">{name}: {value}</Typography>
            )
          })}
        </TableCell>
      )
    },
  }

  export const InstalledProductsNumber: DynamicColumn<UsageDashboardStatistics> = {
    field: "installedProductsNumber",
    headerName: "Products installed",
    type: "digit",
  }
}

export namespace ProjectTypeDashboardColumns {
  export const ProjectType: DynamicColumn<{ name: string, percent: number }> = {
    field: "name",
    headerName: "Type",
    type: "string",
  }

  export const ProjectPercent: DynamicColumn<{ name: string, percent: number }> = {
    field: "percent",
    headerName: "%",
    type: "custom",
    cellProps: { align: "right" },
    component: ({ row, cellProps }) => {
      return (
        <TableCell align={cellProps?.align}>
          <Typography variant="body1">{row.percent}%</Typography>
        </TableCell>
      )
    },
  }
}

export namespace ProjectBuildingTypeDashboardColumns {
  export const ProjectBuildingType: DynamicColumn<{ name: string, percent: number }> = {
    field: "name",
    headerName: "Type",
    type: "string",
  }

  export const ProjectPercent: DynamicColumn<{ name: string, percent: number }> = {
    field: "percent",
    headerName: "%",
    type: "custom",
    cellProps: { align: "right" },
    component: ({ row, cellProps }) => {
      return (
        <TableCell align={cellProps?.align}>
          <Typography variant="body1">{row.percent}%</Typography>
        </TableCell>
      )
    },
  }
}

export namespace ManufacturerAnnouncementDashboardColumns {
  export const Posts: DynamicColumn<ManufacturerAnnouncementDashboardStatistics> = {
    field: "allNumber",
    headerName: "Posts",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Typography variant="body1">All: {row.allNumber}</Typography>
          {row.categories.map((category) => (
            <Typography key={category.type} variant="body1">{category.displayName}: {category.announcementsNumber}</Typography>
          ))}
        </TableCell>
      )
    },
  }

  export const UniqueManufacturers: DynamicColumn<ManufacturerAnnouncementDashboardStatistics> = {
    field: "uniqueManufacturersNumber",
    headerName: "Amount of unique manufacturers on all posts",
    type: "digit",
  }
}

export namespace EventTypeDashboardColumns {
  export const EventName: DynamicColumn<{ name: string, eventsNumber: number }> = {
    field: "name",
    headerName: "Type",
    type: "string",
  }

  export const EventsNumber: DynamicColumn<{ name: string, eventsNumber: number }> = {
    field: "eventsNumber",
    headerName: "Amount",
    type: "digit",
    cellProps: { align: "right" },
  }
}
