import { FC, useMemo } from 'react'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from '@mui/material'
import { DialogNames } from 'src/enums'
import { StatefulDialog } from 'src/components/ui'

export type SettingsItem = {
  name: string
  oldValue?: string
  newValue?: string
}

interface Props {
  onEditSettings: () => void
  onResetSettings?: () => void
  onCloseDialog: () => void
  settings?: SettingsItem[]
  title?: string
  isLoading?: boolean
}

interface SettingsItemTextProps {
  name: string
  oldValue?: string
  newValue?: string
}

const SettingsItemText: FC<SettingsItemTextProps> = ({ name, oldValue, newValue }) => {
  const changesBlock = useMemo(() => {
    if (!oldValue || !newValue) {
      return null
    }
    if (String(oldValue) === String(newValue)) {
      return (
        <Typography ml={1} component="span" fontWeight={500}>
          No changes
        </Typography>
      )
    }
    return (
      <Typography ml={1} component="span" fontWeight={600} color="red">
        {`"${oldValue}" -> "${newValue}"`}
      </Typography>
    )
  }, [oldValue, newValue])

  return (
    <Typography variant="body1" component="div">
      <Typography variant="body1" component="span" fontWeight={400}>
        {name}
      </Typography>
      {changesBlock}
    </Typography>
  )
}

export const UpdateConfigurationSettingsModal: FC<Props> = ({
  onEditSettings,
  onResetSettings,
  onCloseDialog,
  settings,
  title,
  isLoading
}) => {
  return (
    <StatefulDialog
      name={DialogNames.ConfigurationSettings}
      onClose={() => onCloseDialog()}
      actions={
        <DialogActions>
          <Button onClick={() => onCloseDialog()}>
            Close
          </Button>
          {onResetSettings && (
            <Button onClick={() => onResetSettings()}>
              Reset
            </Button>
          )}
          <Button
            onClick={onEditSettings}
            color='error'
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress color="info" size={26}/> : 'Save changes'}
          </Button>
        </DialogActions>
      }
    >
      <DialogTitle id="alert-dialog-title">
        { title ? title : 'Update configuration settings' }
      </DialogTitle>
      <DialogContent>
        <DialogContentText component='div' sx={{ mb: 2 }}>
          <Typography variant="body1" component="span">Are you sure you want to update configuration settings?</Typography>
          {settings && settings.length && (
            <>
              <Typography variant="body1" fontWeight={600}>
                The following settings will be updated:
              </Typography>
              <List sx={{ listStyleType: "disc", paddingLeft: '15px' }}>
                {settings.map((item, index) => (
                  <ListItem key={index} sx={{ display: "list-item" }}>
                    <ListItemText primary={<SettingsItemText {...item} />} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </DialogContentText>
      </DialogContent>
    </StatefulDialog>
  )
}
