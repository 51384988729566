import { FC, useCallback } from 'react'
import { GridColDef } from '@mui/x-data-grid-premium'
import Grid from '@mui/material/Grid'
import { Event } from "src/api"
import { useEventListRequest } from 'src/hooks/api'
import { useImagesViewer, useQueryParams } from 'src/hooks/ui'
import { ImagesViewerStateProvider } from 'src/components/providers'
import { EventColumns, EventList } from '../event'

const defaultPerPage = 50
const defaultPage = 1
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

type Props = {
  rapidRhProbeId: number
}

const RapidRhProbeEvents: FC<Props> = ({ rapidRhProbeId }) => {
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const { isInitialLoading, data } = useEventListRequest({
    params: {
      rapidRhProbeId,
      ...queryParams,
    },
  })
  const { openGallery } = useImagesViewer()
  const exportedFileName = `${document.title}_events`
  const columns = useCallback((): GridColDef<Event>[] => ([
    EventColumns.Name,
    EventColumns.Type,
    EventColumns.Project,
    EventColumns.Section,
    EventColumns.Author,
    EventColumns.CreatedAt,
    EventColumns.Distance,
    EventColumns.Device,
    EventColumns.AppVersion,
    EventColumns.Photo(openGallery),
  ]), [openGallery])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <EventList
          eventCount={data?.count || 0}
          events={data?.rows || []}
          eventColumns={columns()}
          isLoadingEvents={isInitialLoading}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
          height='700px'
          exportedFileName={exportedFileName}
        />
      </Grid>
    </Grid>
  )
}

const RapidRhProbeEventsWrapper: FC<Props> = (props) => {
  return (
    <ImagesViewerStateProvider>
      <RapidRhProbeEvents {...props} />
    </ImagesViewerStateProvider>
  )
}

export {
  RapidRhProbeEventsWrapper as RapidRhProbeEvents
}
