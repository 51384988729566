import { FC, useState } from 'react'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import { useQueryParams } from 'src/hooks/ui'
import { AttachmentFilters, SearchParamsKeys } from 'src/enums'

type Props = {
  activeFilters?: AttachmentFilters[]
}

export const AttachmentListFilters: FC<Props> = ({
  activeFilters = [],
}) => {
  const [hasStatusFilter] = useState<boolean>(activeFilters.includes(AttachmentFilters.Status))
  const [queryParams, setQueryParams, removeQueryParams] = useQueryParams()
  const statusOptions = [
    { name: 'All', value: 'all' },
    { name: 'Pending', value: 'pending' },
    { name: 'Processing', value: 'processing' },
    { name: 'Processed', value: 'processed' },
    { name: 'Failed', value: 'failed' },
    { name: 'Cancelled', value: 'cancelled' },
  ]
  const handleAttachmentStatusChanges = async (event: SelectChangeEvent<typeof queryParams['attachmentStatus']>) => {
    const { target: { value } } = event
    let _value = value
    if (value === 'all') {
      await removeQueryParams([SearchParamsKeys.AttachmentStatus])
    } else {
      setQueryParams({ [SearchParamsKeys.AttachmentStatus]: _value })
    }
  }

  return (
    <Grid container alignItems="stretch" spacing={2}>
      {hasStatusFilter && (
        <Grid item>
          <FormControl sx={{ width: 400 }}>
            <InputLabel id="attachment-status-chip-label">Status</InputLabel>
            <Select
              labelId="attachment-status-chip-label"
              id="attachment-status-chip"
              value={queryParams[SearchParamsKeys.AttachmentStatus] || 'all'}
              onChange={handleAttachmentStatusChanges}
              input={<OutlinedInput id="attachment-status-select-chip" label="Status" />}
              renderValue={(selected) => {
                const statusItem = statusOptions.find((item) => `${item.value}` === selected)
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }} key={`${statusItem?.value}`}>
                    <Typography variant='body2'>{statusItem?.name}</Typography>
                  </Box>
                )
              }}
            >
              {
                statusOptions.map((statusItem) => (
                  <MenuItem
                    key={`${statusItem?.value}`}
                    value={`${statusItem?.value}`}
                  >
                    {statusItem?.name}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  )
}
