import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ConfigurationParamUpdateBulkResponseItem, ConfigurationParamUpdateBulkItem } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<ConfigurationParamUpdateBulkResponseItem[], Error, ConfigurationParamUpdateBulkItem[], ConfigurationParamUpdateBulkResponseItem[]>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditConfigurationParamBulkRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<ConfigurationParamUpdateBulkResponseItem[], Error, ConfigurationParamUpdateBulkItem[], ConfigurationParamUpdateBulkResponseItem[]>(
    [Entities.Configuration, 'edit-bulk'],
    async (payload: ConfigurationParamUpdateBulkItem[]) => {
      const { data } = await api.v1AdminConfigurationPut(payload)
      return data as ConfigurationParamUpdateBulkResponseItem[]
    },
    {
      ...options,
    },
  )
}
