import React, { FC, Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import { ScrollToTop, SignInRedirect } from "src/components"
import { useAuthorizedState } from "src/hooks/ui"
import Root from "src/pages/Root"
import ErrorPage from "src/pages/Error"
import SignIn from "src/pages/SignIn"
import SensorList from "src/pages/Sensor.list"
import SensorShow from "src/pages/Sensor.show"
import GatewayList from "src/pages/Gateway.list"
import ContractorList from "src/pages/Contractor.list"
import ContractorNew from "src/pages/Contractor.new"
import ContractorShow from "src/pages/Contractor.show"
import ContractorEdit from "src/pages/Contractor.edit"
import ManufactureList from "src/pages/Manufacturer.list"
import ManufactureNew from "src/pages/Manufacturer.new"
import ManufactureEdit from "src/pages/Manufacturer.edit"
import ManufactureShow from "src/pages/Manufacturer.show"
import ProjectList from "src/pages/Project.list"
import ProjectShow from "src/pages/Project.show"
import ProductList from "src/pages/Product.list"
import ProductShow from "src/pages/Product.show"
import ProductUpload from "src/pages/Product.upload"
import UserList from "src/pages/User.list"
import UserNew from "src/pages/User.new"
import UserShow from "src/pages/User.show"
import UserEdit from "src/pages/User.edit"
import SettingsListList from "src/pages/Settings.list"
import NewMessage from 'src/pages/Message.new'
import WagnerProbeList from 'src/pages/WagnerProbe.list'
import Profile from "./pages/Profile.list"
import ProductDraftList from "./pages/ProductDraft.list"
import EditSensorConfiguration from "./pages/SensorConfiguration.edit"
import ReleaseList from "./pages/Release.list"
import ReleaseNew from "./pages/Release.new"
import ReleaseEdit from './pages/Release.edit'
import WagnerProbeShow from './pages/WagnerProbe.show'
import Dashboard from './pages/Dashboard'
import SectionShow from './pages/Section.show'
import ProductRequestList from './pages/ProductRequest.list'
import EventShow from "./pages/Event.show"
import Diagnostics from "./pages/Diagnostics"
import ReportList from "./pages/Report.list"
import TaskList from "./pages/Task.list"
import EventList from "./pages/Event.list"
import SensorRawUplink from "./pages/SensorRawUplink"
import ManufacturerAnnouncementList from "./pages/ManufacturerAnnouncement.list"
import ManufacturerAnnouncementNew from "./pages/ManufacturerAnnouncement.new"
import ManufacturerAnnouncementEdit from "./pages/ManufacturerAnnouncement.edit"
import SensorDiagnostics from './pages/SensorExtendedDiagnostics'
import ProductImportList from './pages/ProductImport.list'
import ProductImportShow from './pages/ProductImport.show'
import AdvertisementPacketDecoder from './pages/AdvertisementPacketDecoder'
import ContractorActivationHistory from "./pages/ContractorActivationHistory"
import ContractorTokensHistory from "./pages/ContractorTokensHistory"
import MessageHistory from "./pages/MessageHistory"
import ManufacturerAnnouncementSettings from './pages/ManufacturerAnnouncementSettings.list'
import WagnerDeviceFirmwareList from "./pages/WagnerDeviceFirmware.list"
import WagnerDeviceFirmwareNew from "./pages/WagnerDeviceFirmware.new"
import WagnerDeviceFirmwareEdit from "./pages/WagnerDeviceFirmware.edit"
import DocumentList from "./pages/Document.list"
import AlertList from "./pages/Alert.list"
import AttachmentShow from "./pages/Attachment.show"

const unauthorizedRoutes = [
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    path: "/*",
    element: <SignInRedirect />,
  }
]

const authorizedRoutes = [
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    path: "/sensors/configuration/edit/:uniqueId",
    element: <EditSensorConfiguration />
  },
  {
    path: "/sensors/:uniqueId",
    element: <SensorShow />,
  },
  {
    path: "/sensors/:uniqueId/extended-diagnostics",
    element: <SensorDiagnostics />,
  },
  {
    path: "/sensors",
    element: <SensorList />,
  },
  {
    path: "/sensors/advertisement-packet-decoder",
    element: <AdvertisementPacketDecoder />
  },
  {
    path: "/gateways",
    element: <GatewayList />,
  },
  {
    path: "/contractors/new",
    element: <ContractorNew />,
  },
  {
    path: "/contractors/:id/edit",
    element: <ContractorEdit />,
  },
  {
    path: "/contractors/:id/activation-history",
    element: <ContractorActivationHistory />,
  },
  {
    path: "/contractors/:id/tokens-history",
    element: <ContractorTokensHistory />,
  },
  {
    path: "/contractors/:id",
    element: <ContractorShow />,
  },
  {
    path: "/contractors",
    element: <ContractorList />,
  },
  {
    path: "/manufacturers/new",
    element: <ManufactureNew />,
  },
  {
    path: "/manufacturers/:id/edit",
    element: <ManufactureEdit />,
  },
  {
    path: "/manufacturers/:id",
    element: <ManufactureShow />,
  },
  {
    path: "/manufacturers",
    element: <ManufactureList />,
  },
  {
    path: "/projects/:id",
    element: <ProjectShow />,
  },
  {
    path: "/projects",
    element: <ProjectList />,
  },
  {
    path: "/db-management/upload",
    element: <ProductUpload />,
  },
  {
    path: "/products/:id",
    element: <ProductShow />,
  },
  {
    path: "/products",
    element: <ProductList />,
  },
  {
    path: "/db-management",
    element: <ProductDraftList />,
  },
  {
    path: "/users/new",
    element: <UserNew />,
  },
  {
    path: "/users/:id/edit",
    element: <UserEdit />,
  },
  {
    path: "/users/:id",
    element: <UserShow />,
  },
  {
    path: "/users",
    element: <UserList />,
  },
  {
    path: "/settings",
    element: <SettingsListList />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/messages/new",
    element: <NewMessage />,
  },
  {
    path: "/messages/history",
    element: <MessageHistory />,
  },
  {
    path: "/wagner-probes",
    element: <WagnerProbeList />
  },
  {
    path: "/wagner-probes/firmwares",
    element: <WagnerDeviceFirmwareList />
  },
  {
    path: "/wagner-probes/firmwares/new",
    element: <WagnerDeviceFirmwareNew />
  },
  {
    path: "/wagner-probes/firmwares/:id/edit",
    element: <WagnerDeviceFirmwareEdit />
  },
  {
    path: "/wagner-probes/:probeId",
    element: <WagnerProbeShow />
  },
  {
    path: "/releases/new",
    element: <ReleaseNew />
  },
  {
    path: "/releases/:id/edit",
    element: <ReleaseEdit />,
  },
  {
    path: "/releases",
    element: <ReleaseList />
  },
  {
    path: "/dashboard",
    element: <Dashboard />
  },
  {
    path: "/diagnostics",
    element: <Diagnostics />
  },
  {
    path: "/product-requests",
    element: <ProductRequestList />
  },
  {
    path: "/sections/:id",
    element: <SectionShow />,
  },
  {
    path: "/events",
    element: <EventList />
  },
  {
    path: "/events/:id",
    element: <EventShow />
  },
  {
    path: "/reports",
    element: <ReportList />
  },
  {
    path: "/tasks",
    element: <TaskList />
  },
  {
    path: "/manufacturer-announcements",
    element: <ManufacturerAnnouncementList />
  },
  {
    path: "/manufacturer-announcements/new",
    element: <ManufacturerAnnouncementNew />
  },
  {
    path: "/manufacturer-announcements/settings",
    element: <ManufacturerAnnouncementSettings />
  },
  {
    path: "/manufacturer-announcements/:id/edit",
    element: <ManufacturerAnnouncementEdit />
  },
  {
    path: "/sensor-raw-uplink",
    element: <SensorRawUplink />
  },
  {
    path: "/product-imports",
    element: <ProductImportList />
  },
  {
    path: "/product-imports/:id",
    element: <ProductImportShow />
  },
  {
    path: "/documents",
    element: <DocumentList />
  },
  {
    path: "/alerts",
    element: <AlertList />
  },
  {
    path: "/attachments/:id",
    element: <AttachmentShow />
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]

const AppRoutes: FC = () => {
  const authorized = useAuthorizedState()
  const routes = authorized ? authorizedRoutes : unauthorizedRoutes
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ScrollToTop />
      <Routes>
        {routes.map(({ path, element }, idx) => (
          <Route key={idx} path={path} element={element} />
        ))}
      </Routes>
    </Suspense>
  )
}

export default AppRoutes
