import React, { FC, useState } from "react"
import * as Yup from "yup"
import { Button, Grid, Box, Typography, CircularProgress } from "@mui/material"
import { Formik, Form } from "formik"
import { TextField, PhoneInput, RadioGroup, FormCheckbox } from "src/components/ui"
import { RootContractorSelector } from "./rootContractorSelector"
import {
  phoneNullableValidation,
  phoneRequiredValidation,
} from "../../../utils/validators"
import { formatPhone } from "../../../utils/formatters"
import { ContractorCreationV2 } from "../../../api"
import { ImageUploadCard } from "../manufacturer/imageUploadCard"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  address: Yup.string().nullable(),
  phone: phoneNullableValidation,
  email: Yup.string().nullable().email("Invalid email"),
  type: Yup.string().required(),
  rootContractorId: Yup.number()
    .when('type', {
      is: 'branch',
      then: (schema) => schema.required('HQ contractor is required'),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError('HQ contractor is required'),
  ownerName: Yup.string().required("Name is required"),
  ownerPhone: phoneRequiredValidation,
  ownerEmail: Yup.string().required().email("Invalid email"),
  isEnabledWagnerDeviceFirmwareUpdate: Yup.boolean(),
  isEnabledAppBranding: Yup.boolean(),
})

export interface ContractorNewState {
  name: string;
  address1?: string
  phone?: string
  email?: string
  type: 'standalone' | 'root' | 'branch'
  rootContractorId?: number
  ownerName: string
  ownerPhone: string
  ownerEmail: string
  isActiveDashboard?: boolean
  isEnabledAppBranding?: boolean
  isEnabledWagnerDeviceFirmwareUpdate?: boolean
}

const defaultInitial: ContractorNewState = {
  name: "",
  address1: void 0,
  phone: void 0,
  email: void 0,
  type: 'standalone',
  rootContractorId: void 0,
  ownerName: "",
  ownerPhone: "",
  ownerEmail: "",
  isActiveDashboard: false,
  isEnabledAppBranding: false,
  isEnabledWagnerDeviceFirmwareUpdate: false,
}

interface ContractorNewFormProps {
  initialValues?: ContractorNewState | null;
  isLoading?: boolean;
  onSubmit: (formState: ContractorCreationV2, logo: File | null) => void;
}

export const ContractorNewForm: FC<ContractorNewFormProps> = ({
  initialValues,
  isLoading = false,
  onSubmit,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const updateFile = (value: File | null) => {
    setSelectedFile(value)
  }

  const renderDashboardBlock = (values: ContractorNewState) => {
    if (values.type === 'root') {
      return (
        <>
          <Grid item xs={12} my={1}>
            <FormCheckbox
              name="isActiveDashboard"
              label="Activate dashboard"
              view="switch"
            />
          </Grid>
        </>
      )
    }
    return null
  }

  return (
    <Formik
      initialValues={{ ...defaultInitial, ...initialValues }}
      onSubmit={(data: ContractorNewState) => {
        const result: ContractorCreationV2 = {
          name: data.name,
          phone: formatPhone(data.phone),
          address1: data.address1,
          email: data.email,
          isRoot: data.type === 'root',
          rootContractorId: data.rootContractorId,
          isActiveDashboard: data.type !== 'root' ? false : data.isActiveDashboard,
          isEnabledAppBranding: data.isEnabledAppBranding,
          isEnabledWagnerDeviceFirmwareUpdate: data.isEnabledWagnerDeviceFirmwareUpdate,
          user: {
            name: data.ownerName,
            phone: formatPhone(data.ownerPhone) || "",
            email: data.ownerEmail,
          },
        }
        onSubmit(result, selectedFile)
      }}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form>
          <Typography variant="h4">Create new contractor</Typography>
          <Box width="900px">
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Grid container spacing={1} mt={2} mb={5}>
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      label="Name"
                      required
                      TextFieldProps={{ placeholder: "Name of organization" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="address1"
                      label="Address"
                      TextFieldProps={{ placeholder: "Address" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PhoneInput
                      name="phone"
                      label="Phone number"
                      placeholder="Contact phone"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      label="Email"
                      TextFieldProps={{ placeholder: "Contact email" }}
                    />
                  </Grid>
                  <Grid item xs={12} my={1}>
                    <RadioGroup
                      name="type"
                      label="HQ/Branch"
                      options={[
                        { label: 'Standalone contractor', value: 'standalone' },
                        { label: 'HQ contractor', value: 'root' },
                        { label: 'Branch contractor', value: 'branch' },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} my={1}>
                    <RootContractorSelector
                      label="HQ contractor"
                      name="rootContractorId"
                      nameContractorTypeField="type"
                    />
                  </Grid>
                  {renderDashboardBlock(values)}
                </Grid>
                <Typography variant="h4">Contractor owner user</Typography>
                <Grid container spacing={1} mt={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="ownerName"
                      label="Name"
                      required
                      TextFieldProps={{ placeholder: "Name" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PhoneInput name="ownerPhone" label="Phone number" required />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="ownerEmail"
                      label="Email"
                      required
                      TextFieldProps={{ placeholder: "Email" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      Owner will receive an invitation. Other users can not login,
                      unless owner signed the agreement upon login
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box pt={2}>
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isLoading}
                      >
                        {
                          isLoading ? <CircularProgress color="info" size={26} /> : 'Create contractor and invite owner'
                        }
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={7} mt={3} mb={5} flexDirection="column" spacing={3}>
                <Grid item width="100%">
                  <ImageUploadCard updateFile={updateFile} />
                </Grid>
                <Grid item>
                  <FormCheckbox
                    name="isEnabledAppBranding"
                    label="App branding: display contractor logo in the app"
                    view="switch"
                  />
                </Grid>
                <Grid item>
                  <FormCheckbox
                    name="isEnabledWagnerDeviceFirmwareUpdate"
                    label="Wagner device firmware update"
                    view="switch"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
