import React, { FC, useMemo } from "react"
import Box from "@mui/system/Box"
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Skeleton from '@mui/material/Skeleton'
import { Grid, Typography } from "@mui/material"
import format from "date-fns/format"
import { CustomSingleEvent, EventTag } from "src/api"
import { capitalizeFirstLetter } from "src/utils/formatters"
import { CircularProgressWithLabel, InnerLink, OuterLink } from "src/components/ui"
import { AttachmentStatus } from "src/interfaces"
import { SourceInformation } from "./sourceInfo"

interface EventInfoProps {
  event: CustomSingleEvent
}
type EventPhoto = {
  link: string
  isLoading?: boolean
  isFailed?: boolean
  progress?: number
  status?: AttachmentStatus
}

export const EventInfo: FC<EventInfoProps> = ({ event }) => {
  const {
    eventCardRenderingData: { eventDatetime, eventDetails, tags, note, author },
    appDevice,
    appVersion,
    distance,
    type,
    projectSectionId,
    productId,
    sensorUniqueId,
    wagnerProbeDeviceId,
    authorId,
  } = event

  const generalInformation = [
    { name: 'Type', value: type?.displayName || '-' },
    { name: 'Created at', value: eventDatetime ? format(new Date(eventDatetime), 'PPpp') : '-' },
    { name: 'Description', value: eventDetails || '-' },
    { name: 'Note', value: note || '-' },
  ]
  const metaInformation = [
    { name: 'Author', value: author ? <InnerLink to={`/users/${authorId}`}>{author}</InnerLink> : '-' },
    { name: 'Device', value: appDevice || '-' },
    { name: 'App Version', value: appVersion || '-' },
    { name: 'Distance', value: distance ? `${distance} miles` : '-' },
  ]
  
  const getTagValue = (tag: EventTag): React.ReactNode | string => {
    const dictionary: Record<string, { url: string, id: number | string | undefined }> = {
      product: { url: 'products', id: productId },
      sensor: { url: 'sensors', id: sensorUniqueId },
      section: { url: 'sections', id: projectSectionId },
      wagnerProbe: { url: 'wagner-probes', id: wagnerProbeDeviceId }
    }
    const entity = dictionary[tag.tagType]
    if (entity !== undefined && entity.id) {
      return (
        <InnerLink to={`/${entity.url}/${entity.id}`}>{tag.tagValue}</InnerLink>
      )
    }
    return tag.tagValue
  }

  const eventPhotos: EventPhoto[] = useMemo(() => {
    if (event.attachments) {
      return event.attachments.filter((attachment) => attachment.type === 'photo').map((attachment) => ({
        link: attachment.link || '',
        isLoading: ['pending', 'processing'].includes(attachment.status as string),
        isFailed: ['failed', 'cancelled'].includes(attachment.status as string),
        progress: attachment.progress,
        status: attachment.status as AttachmentStatus,
      }))
    }
    if (event.eventCardRenderingData.photos) {
      return event.eventCardRenderingData.photos.map((photo) => ({
        link: photo.originalLink || '',
        isLoading: false,
        isFailed: false,
        progress: 100,
        status: 'processed',
      }))
    }
    return []
  }, [event])

  const eventFiles: { link: string, name: string }[] = useMemo(() => {
    if (event.attachments) {
      return event.attachments.filter((attachment) => attachment.type === 'file').map((attachment) => ({
        link: attachment.link || '',
        name: attachment.originalFileName || '',
      }))
    }
    return []
  }, [event])

  const hasLoadedPhotos = useMemo(() => (
    eventPhotos.some((photo) => photo.status === 'processed')
  ), [eventPhotos])

  const renderPhotoItem = (photo: EventPhoto) => {
    if (photo.isLoading === true) {
      return (
        <Box position="relative" width={hasLoadedPhotos ? "100%" : "200px"} height={hasLoadedPhotos ? "100%" : "400px"}>
          <Skeleton variant="rectangular" animation="wave" width="100%" height="calc(100% - 3px)" />
          {
            typeof photo.progress === 'number'
              ? (
                <Box position="absolute" left="calc(50% - 20px)" top="calc(50% - 20px)">
                  <CircularProgressWithLabel value={photo.progress} />
                </Box>
              )
              : null 
          }
        </Box>
      )
    }

    if (photo.isFailed === true) {
      return (
        <Box position="relative" width={hasLoadedPhotos ? "100%" : "200px"} height={hasLoadedPhotos ? "100%" : "400px"}>
          <Skeleton variant="rectangular" width="100%" animation={false} height="calc(100% - 3px)" />
          <Box position="absolute" left="calc(50% - 40px)" top="calc(50% - 20px)">
            <Typography variant="caption" color="error">{ photo.status === 'failed' ? 'Failed to load' : 'Cancelled' }</Typography>
          </Box>
        </Box>
      )
    }
    
    return (
      <img
        style={{
          objectFit: "scale-down",
          width: "100%",
        }}
        src={photo.link}
        alt="event media"
      />
    )
  }

  return (
    <Box width="100%" pt={2}>
      <Grid container spacing={4}>
        <Grid container xs={12} md={6} item spacing={2}>
          <Box width="100%" pl="16px">
            <Grid item xs={12} sx={{ py: 1 }}>
              <Typography variant="h6" fontWeight={500}>General information</Typography>
            </Grid>
            {generalInformation.map((item, index) => (
              <Grid key={index} item xs={12} sx={{ py: 0.5 }}>
                <Typography variant="body1" component="div">
                  <Typography variant="body1" fontWeight={500} display="inline">{item.name}:</Typography> {item.value}
                </Typography>
              </Grid>
            ))}
            {
              tags?.length
                ? (
                  <Grid item xs={12} sx={{ py: 0.5 }}>
                    <Typography variant="body1" fontWeight={500}>Tags:</Typography>
                    <List sx={{ py: 0 }}>
                      {tags?.map((tag, index) => (
                        <ListItem key={index} divider sx={{ pl: 2 }}>
                          <ListItemText
                            primary={capitalizeFirstLetter(tag.tagType)}
                            secondary={getTagValue(tag)}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                )
                : null
            }
          </Box>
        </Grid>
        <Grid container xs={12} md={6} item spacing={2}>
          <Box width="100%" pl="16px">
            <Grid item xs={12} sx={{ py: 1 }}>
              <Typography variant="h6" fontWeight={500}>Meta information</Typography>
            </Grid>
            {metaInformation.map((item, index) => (
              <Grid key={index} item xs={12} sx={{ py: 0.5 }}>
                <Typography variant="body1" component="div">
                  <Typography variant="body1" fontWeight={500} display="inline">{item.name}:</Typography> {item.value}
                </Typography>
              </Grid>
            ))}
          </Box>
        </Grid>
        <Grid container xs={12} item spacing={2}>
          <Box width="100%" pl="16px">
            <SourceInformation event={event} />
          </Box>
        </Grid>
        {
          eventFiles?.length
            ? (
              <Grid container xs={12} item spacing={1} direction="column" pl="16px">
                <Grid item>
                  <Typography variant="h6" fontWeight={500}>Files</Typography>
                </Grid>
                <Grid container item>
                  {eventFiles.map((file, index) => (
                    <Grid item key={index}>
                      <OuterLink
                        to={file.link}
                      >
                        {file.name}
                      </OuterLink>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )
            : null
        }
        {
          eventPhotos?.length
            ? (
              <Grid container xs={12} item direction="column" pl="16px" spacing={1}>
                <Grid item>
                  <Typography variant="h6" fontWeight={500}>Photos</Typography>
                </Grid>
                <Grid container item spacing={2} overflow="auto">
                  {eventPhotos.map((photo, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4} xl={3}>
                      {renderPhotoItem(photo)}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )
            : null
        }
      </Grid>
    </Box>
  )
}
