import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ContractorTokenHistory } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseQueryOptions<unknown, Error, ContractorTokenHistory[]>,
    'queryKey' | 'queryFn'
  >;
}

export const useContractorTokensHistoryRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useQuery<unknown, Error, ContractorTokenHistory[]>(
    [Entities.Contractors, id, 'tokens-history'],
    async () => {
      const { data } = await api.v1AdminContractorsContractorIdTokensHistoryGet(id)
      return data
    },
    { ...options },
  )
}
