import { FC, useMemo } from 'react'
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material'
import { DialogNames, WagnerDeviceFirmwareDeviceTypeEnum } from 'src/enums'
import { WagnerDeviceFirmware } from 'src/api'
import { StatefulDialog } from 'src/components/ui'

interface Props {
  firmware: WagnerDeviceFirmware
  onSubmit: () => void
  onCancel: () => void
  isLoading?: boolean
}

export const WagnerDeviceFirmwareRemovalDialog: FC<Props> = ({
  firmware,
  onSubmit,
  onCancel,
  isLoading = false,
}) => {
  const deviceType: string = useMemo(() => {
    const map: Record<string, string> = {
      [WagnerDeviceFirmwareDeviceTypeEnum.DataGrabber]: 'Data Grabber',
      [WagnerDeviceFirmwareDeviceTypeEnum.RangeExtender]: 'Range Extender',
    }
    return map[firmware?.deviceType]
  }, [firmware])

  return (
    <StatefulDialog
      name={DialogNames.DeleteWagnerDeviceFirmware}
      actions={
        <DialogActions>
          <Button variant="outlined" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={isLoading}
            onClick={onSubmit}
          >
            { isLoading ? <CircularProgress color="info" size={26} /> : 'Delete'}
          </Button>
        </DialogActions>
      }
    >
      <DialogTitle>Wagner device firmware removal</DialogTitle>
      <DialogContent>
        <Box width="400px">
          <Typography
            variant="body1"
            component="p"
          >
            <Typography variant="body1" component="span">
              Are you sure you want to delete the following firmware?
            </Typography>
            <br />
            <Typography variant="body1" component="span" fontWeight={450}>
              Version: {firmware.version}
            </Typography>
            <br />
            <Typography variant="body1" component="span" fontWeight={450}>
              Device type: {deviceType}
            </Typography>
          </Typography>
        </Box>
      </DialogContent>
    </StatefulDialog>
  )
}
