import { FC, useMemo } from "react"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import { Link, useSearchParams } from "react-router-dom"
import {
  HasSidebarLayout,
  ListHeader,
  TabGroupFilter,
  WagnerDeviceFirmwareList,
  WagnerDeviceFirmwareSettings,
} from "src/components"
import { SearchParamsKeys, WagnerDeviceFirmwareDeviceTypeEnum } from "src/enums"

const WagnerDeviceFirmware: FC = () => {
  document.title = 'Floorcloud Admin Panel - Wagner Deivce Firmware'
  const [queryParams] = useSearchParams()
  const deviceType = useMemo(() => queryParams.get(SearchParamsKeys.WagnerDeviceFirmwareTab), [queryParams])
  const creationUrl = useMemo(() => {
    const allowedTabs = ['DataGrabber', 'RangeExtender']
    if (!deviceType || !allowedTabs.includes(deviceType)) {
      return '/wagner-probes/firmwares/new'
    }
    return `/wagner-probes/firmwares/new?${SearchParamsKeys.WagnerDeviceType}=${deviceType}`
  }, [deviceType])

  return (
    <HasSidebarLayout>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <ListHeader title="Wagner Device Firmware">
            <Link to={creationUrl} style={{ textDecoration: "none" }}>
              <Button role="link" variant="contained">
                Add New Firmware
              </Button>
            </Link>
          </ListHeader>
        </Grid>
        <Grid item>
          <TabGroupFilter
            statusList={[
              {
                status: "Data Grabber",
                content: <WagnerDeviceFirmwareList deviceType={WagnerDeviceFirmwareDeviceTypeEnum.DataGrabber} />,
              },
              {
                status: "Range Extender",
                content: <WagnerDeviceFirmwareList deviceType={WagnerDeviceFirmwareDeviceTypeEnum.RangeExtender} />,
              },
              {
                status: "Settings",
                content: <WagnerDeviceFirmwareSettings />,
              }
            ]}
            name={SearchParamsKeys.WagnerDeviceFirmwareTab}
            paramsToRemove={[]}
          />
        </Grid>
      </Grid>
    </HasSidebarLayout>
  )
}

export default WagnerDeviceFirmware
