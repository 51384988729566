import React, { FC, useState } from "react"
import { Form, useFormik, FormikProvider } from "formik"
import * as Yup from "yup"
import { Box, Button, CircularProgress, Divider, Grid } from "@mui/material"
import { TextField } from "src/components/ui"
import { useNewReleaseRequest, useUploadPhotoRequest } from "src/hooks/api"
import { FeatureCreationParams, ReleaseCreation, UploadPhotoResult } from "src/api"
import { useAddPopupMessage } from "src/hooks/ui"
import { useNavigate } from "react-router-dom"
import { ReleaseFeatureNewForm } from "./releaseFeatureNewForm"
import { hasDuplicates } from "./helpers"

export interface ReleaseFeaturePayload {
  id: string | number;
  header: string;
  description?: string;
  image?: File | null;
  link?: string;
}

interface NewReleasePayload {
  description: string;
  number: string;
  featureName: string;
}

const validationSchema = Yup.object().shape({
  description: Yup.string().required("Release name is required"),
  number: Yup.string().required("Release version is required"),
  featureName: Yup.string(),
})

const initialValues = {
  number: "",
  description: "",
  featureName: ""
}

export const ReleaseNewForm: FC = () => {
  const [features, setFeatures] = useState<ReleaseFeaturePayload[]>([])
  const photoUploadRequest = useUploadPhotoRequest()
  const newReleaseRequest = useNewReleaseRequest()
  const addMessage = useAddPopupMessage()
  const navigate = useNavigate()

  const saveRelease = (data: ReleaseCreation) => {
    newReleaseRequest.mutate(data, {
      onSuccess: () => {
        addMessage({ text: 'Release was successfully created', type: 'success' })
        navigate(-1)
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
      }
    })
  }
  const onSubmit = (data: NewReleasePayload) => {
    if (hasDuplicates(features)) {
      addMessage({ text: "There are features with the same names in the release", type: 'error' })
      return
    }

    let preparedFeatures: FeatureCreationParams[] = []
    const images = features
      .filter((feature) => Boolean(feature.image))
      .map((feature) => feature.image as File)
    
    if (images.length > 0) {
      photoUploadRequest.mutate({
        photos: images,
      }, {
        onSuccess: (result) => {
          preparedFeatures = features.map((feature) => {
            const image = (result as UploadPhotoResult[]).find((item) => item.originalFileName === feature.image?.name)
            return {
              header: feature.header,
              description: feature.description,
              image: image?.original,
            }
          })

          saveRelease({
            description: data.description,
            number: data.number,
            features: preparedFeatures
          })
        }
      })
    } else {
      preparedFeatures = features.map((feature) => ({
        header: feature.header,
        description: feature.description,
      }))
      saveRelease({
        description: data.description,
        number: data.number,
        features: preparedFeatures
      })
    }
  }
  const formik = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validationSchema,
  })
  const { values, setValues, submitForm } = formik

  const addFeature = () => {
    if (!values.featureName) return

    const id = values.featureName.toLowerCase().replace(' ', '_')
    const isDuplicate = features.some((feature) => {
      const preparedFeatureName = feature.header.toLowerCase().replace(' ', '_')
      return preparedFeatureName === id
    })
    if (isDuplicate) {
      addMessage({ text: "The feature with this name already exists", type: 'warning' })
      return
    }
    setFeatures([
      ...features,
      { id, header: values.featureName }
    ])
    setValues({
      ...values,
      featureName: ""
    })
  }
  const updateFeature = (feature: Partial<ReleaseFeaturePayload>) => {
    const index = features.findIndex((item) => item.id === feature.id)
    if (index === -1) return

    const isDuplicate = features.filter((item) => item.id !== feature.id).some((item) => {
      const preparedFeatureName = (item.header || '').toLowerCase().replace(' ', '_')
      const preparedUpdatedFeatureName = (feature.header || '').toLowerCase().replace(' ', '_')
      return preparedFeatureName === preparedUpdatedFeatureName
    })
    if (isDuplicate) {
      addMessage({ text: "The feature with this name already exists", type: 'warning' })
    }
    const copy = features.slice()
    copy[index] = {
      ...copy[index],
      ...feature,
    }
    setFeatures(copy)
  }
  const deleteFeature = (id: string | number) => {
    const filteredFeatures = features.filter((feature) => feature.id !== id)
    setFeatures(filteredFeatures)
  }

  return (
    <FormikProvider
      value={formik}
    >
      <Form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              name="description"
              label="Release name"
              required
              TextFieldProps={{ placeholder: "Release name" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="number"
              label="Release version"
              required
              TextFieldProps={{ placeholder: "Release version" }}
            />
          </Grid>
        </Grid>
      </Form>
      <Grid container>
        <Grid item xs={12}>
          {features.map((feature) => (
            <ReleaseFeatureNewForm
              key={feature.id}
              defaultValues={feature}
              onChangeFeature={updateFeature}
              onDeleteFeature={deleteFeature}
            />
          ))}
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          xs={12}
          position="relative"
          mt={10}
        >
          <Grid item xs={12}>
            <TextField
              name="featureName"
              label="Add feature"
              TextFieldProps={{ placeholder: "Feature name" }}
            />
          </Grid>
          <Grid
            item
            xs={2}
            position="absolute"
            right={-100}
          >
            <Button
              size="large"
              variant="outlined"
              color="primary"
              onClick={addFeature}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={5}>
          <Divider />
        </Grid>
        <Grid item container xs={6}>
          <Grid item xs={6}>
            <Box pt={2}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="button"
                onClick={submitForm}
                disabled={photoUploadRequest.isLoading || newReleaseRequest.isLoading}
              >
                {
                  photoUploadRequest.isLoading || newReleaseRequest.isLoading ? <CircularProgress color="info" size={26} /> : 'Save'
                }
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box pt={2}>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                type="button"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </FormikProvider>
  )
}
