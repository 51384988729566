import React, { FC } from "react"
import { SensorListFilters, SensorsMapView } from "src/components"
import { SensorColumnsDataGrid } from "src/components/columns/sensor"
import { Sensor } from "src/api"
import { useQueryParamsState } from "src/hooks/ui"
import { SearchParamsKeys, ViewTypes, SensorFilters, SensorButtons } from "src/enums"
import { SensorMapFilters } from "src/components/forms/sensor/sensorMapFilters"
import { CommonSensorList } from "./commonSensorsList"
import { ContractorsDataLoader } from "./contractorsDataLoader"

const columns = [
  SensorColumnsDataGrid.Id,
  SensorColumnsDataGrid.Model,
  SensorColumnsDataGrid.IMEI,
  SensorColumnsDataGrid.DevEUI,
  SensorColumnsDataGrid.Label,
  SensorColumnsDataGrid.Contractor,
  SensorColumnsDataGrid.LastPing,
  SensorColumnsDataGrid.BatteryLevel,
  SensorColumnsDataGrid.DateConnected,
  SensorColumnsDataGrid.TimeOnProject,
  SensorColumnsDataGrid.UsedAt,
  SensorColumnsDataGrid.City,
  SensorColumnsDataGrid.Project,
]

interface InUseSensorListProps {
  isLoading: boolean;
  count: number;
  data: Sensor[];
}

export const InUseSensorList: FC<InUseSensorListProps> = ({
  data,
  isLoading,
  count,
}) => {
  const queryParams = useQueryParamsState()
  const viewType = queryParams[SearchParamsKeys.ViewType] || ViewTypes.List
  return viewType === ViewTypes.List ? (
    <CommonSensorList
      columns={columns}
      count={count}
      data={data}
      isLoading={isLoading}
      filters={
        <ContractorsDataLoader>
          <SensorListFilters
            activeFilters={[
              SensorFilters.LowBattery,
              SensorFilters.WeakSignal,
              SensorFilters.LastPingMoreThanHour,
              SensorFilters.SyncPeriod,
              SensorFilters.ReadingsAge,
              SensorFilters.CurrentSensorConfiguration,
              SensorFilters.BleStatus,
              SensorFilters.Contractor,
            ]}
          />
        </ContractorsDataLoader>
      }
      buttons={[SensorButtons.GetHistoricalData, SensorButtons.ManageSensorBleState]}
      checkboxSelection
    />
  ) : (
    <SensorsMapView
      data={data}
      isLoading={isLoading}
      filters={
        <ContractorsDataLoader>
          <SensorMapFilters
            activeFilters={[
              SensorFilters.Contractor,
              SensorFilters.LowBattery,
              SensorFilters.WeakSignal,
              SensorFilters.LastPingMoreThanHour,
              SensorFilters.SyncPeriod,
              SensorFilters.ReadingsAge,
              SensorFilters.LocationType,
            ]}
          />
        </ContractorsDataLoader>
      }
    />
  )
}
