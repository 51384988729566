import { FC, useState } from 'react'
import Typography from '@mui/material/Typography'
import { WagnerDeviceFirmware } from 'src/api'
import { Centralize, DataLoad } from 'src/components/ui'
import { useDeleteWagnerDeviceFirmwareRequest, useWagnerDeviceFirwmareListRequest } from 'src/hooks/api'
import { useAddPopupMessage, useDialog } from 'src/hooks/ui'
import { DialogNames, WagnerDeviceFirmwareDeviceTypeEnum } from 'src/enums'
import { WagnerDeviceFirmwareItem } from './wagnerDeviceFirmwareItem'
import { WagnerDeviceFirmwareRemovalDialog } from './wagnerDeviceFirmwareRemovalPopup'

type Props = {
  deviceType: WagnerDeviceFirmwareDeviceTypeEnum
}

export const WagnerDeviceFirmwareList: FC<Props> = ({ deviceType }) => {
  const [selectedFirmware, setSelectedFirmware] = useState<WagnerDeviceFirmware | null>(null)
  const addMessage = useAddPopupMessage()
  const { openDialog, closeDialog } = useDialog(DialogNames.DeleteWagnerDeviceFirmware)
  const { isInitialLoading, data, isError, error, refetch: refetchList } = useWagnerDeviceFirwmareListRequest({
    params: {
      deviceType,
      limit: 100,
    }
  })
  const firmwareRemovalRequest = useDeleteWagnerDeviceFirmwareRequest({
    options: {
      onSuccess: () => {
        refetchList()
        addMessage({ type: 'success', text: 'Firmware removed successfully' })
        closeDialog()
      },
      onError: () => {
        addMessage({ type: 'error', text: 'Failed to remove firmware' })
      }
    }
  })
  const handleFirmwareRemoval = () => {
    if (!selectedFirmware) {
      addMessage({ type: 'error', text: 'Firmware not found' })
      return
    }
    firmwareRemovalRequest.mutate({ id: selectedFirmware.id })
  }
  const onDeleteFirmware = (firmware: WagnerDeviceFirmware) => {
    setSelectedFirmware(firmware)
    openDialog()
  }
  const firmwares = data?.rows || []
  return (
    <DataLoad
      isLoading={isInitialLoading}
      isError={isError}
      errorMessage={error?.message}
    >
      {
        firmwares.length === 0 ? (
          <Centralize>
            <Typography variant="body1">No firmwares</Typography>
          </Centralize>
        ) : null
      }
      {firmwares.map((firmware) => (
        <WagnerDeviceFirmwareItem
          key={firmware.id}
          firmware={firmware}
          onDeleteFirmware={onDeleteFirmware}
        />
      ))}
      {
        selectedFirmware ? (
          <WagnerDeviceFirmwareRemovalDialog
            firmware={selectedFirmware as WagnerDeviceFirmware}
            onSubmit={() => handleFirmwareRemoval()}
            onCancel={() => closeDialog()}
            isLoading={firmwareRemovalRequest.isLoading}
          />
        ) : null
      }
    </DataLoad>
  )
}
