import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import {
  DataGridPremium,
  GridColDef,
  useGridApiRef,
  gridFilterModelSelector,
  gridFilteredTopLevelRowCountSelector,
  GridPaginationModel,
  GridPinnedColumns,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium'
import ImagesViewer from 'react-images-viewer'
import { TableToolbar } from 'src/components/ui'
import { Attachment } from 'src/api'
import { AttachmentFilters, SearchParamsKeys } from 'src/enums'
import { defaultRowsValuePerPage } from 'src/config'
import { useImagesViewer } from 'src/hooks/ui'
import Grid from '@mui/material/Grid'
import { AttachmentColumns } from './attachmentColumns'
import { AttachmentListFilters } from './attachmentFilters'

const defaultRowsPerPageOptions = defaultRowsValuePerPage

interface AttachmentListProps {
  attachments: Attachment[]
  attachmentCount: number
  isLoadingAttachments: boolean
  queryParams: Record<string, string>
  setQueryParams: (params: Record<string, string>, paramsToRemove?: string[]) => void
  autoHeight?: boolean
  height?: string
  alertColumns?: GridColDef<Attachment>[]
  exportedFileName?: string
  activeFilters?: AttachmentFilters[]
}

export const AttachmentList: FC<AttachmentListProps> = ({
  attachmentCount,
  attachments,
  isLoadingAttachments,
  setQueryParams,
  queryParams,
  autoHeight = false,
  height = '100%',
  activeFilters = [],
  alertColumns,
  exportedFileName,
}) => {
  const apiRef = useGridApiRef()
  const {
    isOpen: isOpenedGallery,
    images,
    currentImageIndex,
    openGallery,
    closeGallery,
    goToNext,
    goToPrev,
  } = useImagesViewer()
  const defaultColumns = useMemo((): GridColDef<Attachment>[] => ([
    AttachmentColumns.FileName,
    AttachmentColumns.Type,
    AttachmentColumns.Status,
    AttachmentColumns.RelatedTo,
    AttachmentColumns.Contractor,
    AttachmentColumns.Project,
    AttachmentColumns.ProjectSection,
    AttachmentColumns.Author,
    AttachmentColumns.CreatedAt,
    AttachmentColumns.File(openGallery),
  ]), [openGallery])
  const columns = alertColumns?.length ? alertColumns : defaultColumns
  const [rowCountState, setRowCountState] = useState(attachmentCount)
  const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumns>({
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, ""],
  })

  useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      attachmentCount !== undefined ? attachmentCount : prevRowCountState
    )
  }, [attachmentCount, setRowCountState])

  const handlePinnedColumnsChange = useCallback(
    (updatedPinnedColumns: GridPinnedColumns) => {
      setPinnedColumns(updatedPinnedColumns)
    },
    []
  )

  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    const { page, pageSize } = pagination
    const newPage = page + 1
    if (setQueryParams) {
      setQueryParams({
        [SearchParamsKeys.Page]: `${newPage}`,
        [SearchParamsKeys.RowsPerPage]: `${pageSize}`,
      }, [
        SearchParamsKeys.Page,
        SearchParamsKeys.RowsPerPage,
      ])
    }
  }

  const handleChangeState = async () => {
    const filterModel = gridFilterModelSelector(apiRef.current.state)
    if (filterModel.items.length > 0 && filterModel.items[0].value) {
      const visibleRowCount = gridFilteredTopLevelRowCountSelector(apiRef.current.state)
      if (visibleRowCount) setRowCountState(visibleRowCount)
    } else {
      setRowCountState(attachmentCount)
    }
  }

  const page = queryParams["page"] ? parseInt(queryParams["page"], 10) - 1 : undefined
  const pageSize = queryParams["rowsPerPage"]
    ? parseInt(queryParams["rowsPerPage"], 10)
    : undefined

  return (
    <Box height='100%'>
      {
        activeFilters.length > 0 && (
          <Grid container flexDirection="column" justifyContent="space-between" mb={2}>
            <AttachmentListFilters
              activeFilters={activeFilters}
            />
          </Grid>
        )
      }
      <Paper sx={{ flexGrow: 1, minHeight: '200px', height, maxHeight: '800px', width: '100%' }}>
        <DataGridPremium<Attachment>
          apiRef={apiRef}
          pagination
          autoHeight={autoHeight}
          pinnedColumns={pinnedColumns}
          disableRowSelectionOnClick
          rows={attachments}
          columns={columns}
          rowCount={rowCountState}
          loading={isLoadingAttachments}
          paginationModel={{
            page: page as number,
            pageSize: pageSize as number
          }}
          rowThreshold={2}
          columnThreshold={2}
          paginationMode="server"
          pageSizeOptions={defaultRowsPerPageOptions}
          onPaginationModelChange={handleChangePaginationModel}
          onStateChange={handleChangeState}
          localeText={{
            columnMenuSortAsc: "Sort A-Z",
            columnMenuSortDesc: "Sort Z-A",
          }}
          slots={{
            toolbar: () => <TableToolbar exportedFileName={exportedFileName} />
          }}
          onPinnedColumnsChange={handlePinnedColumnsChange}
          sx={(theme) => ({
            border: "none",
            "& .MuiDataGrid-columnHeaderTitleContainerContent": {
              paddingLeft: "5px",
              overflow: "visible",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: 1,
              fontSize: "13px",
              fontWeight: 400,
              color: "rgba(0, 0, 0, 0.6)",
              overflow: "visible",
            },
          })}
        />
        <ImagesViewer
          imgs={images}
          isOpen={isOpenedGallery}
          onClickPrev={goToPrev}
          onClickNext={goToNext}
          onClose={closeGallery}
          currImg={currentImageIndex}
        />
      </Paper>
    </Box>
  )
}
