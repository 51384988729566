import { FC } from 'react'
import { Link } from "react-router-dom"
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import { WagnerDeviceFirmware } from 'src/api'
import { OuterLink } from 'src/components/ui'
import { FileIcon } from 'src/components/icons'

type Props = {
  firmware: WagnerDeviceFirmware
  onDeleteFirmware: (firmware: WagnerDeviceFirmware) => void
}

export const WagnerDeviceFirmwareItem: FC<Props> = ({ firmware, onDeleteFirmware }) => {
  return (
    <Grid container p={4} border={1} borderRadius={3} borderColor="lightgray" mb={2}>
      <Grid item xs={12} lg={6}>
        <Typography variant="h6" fontWeight={500}>Version: {firmware.version}</Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body1" sx={{ whiteSpace: 'pre-line'}}>{firmware.description || 'No description'}</Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body1">Files:</Typography>
        <List dense>
          {firmware.attachments?.map((attachment) => (
            <ListItem key={attachment.id}>
              <ListItemIcon>
                <FileIcon size={36} />
              </ListItemIcon>
              <ListItemText primary={<OuterLink to={attachment.link || ''}>
                {attachment.fileName}
              </OuterLink>} />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item container xs={12} lg={6} justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Link to={`/wagner-probes/firmwares/${firmware.id}/edit`} style={{ textDecoration: "none" }}>
            <Button role="link" variant="outlined">
              Edit
            </Button>
          </Link>
        </Grid>
        <Grid item>
          <Button role="link" variant="outlined" color="error" onClick={() => onDeleteFirmware(firmware)}>
            Delete
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
