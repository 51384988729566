import React, { FC, useState, useCallback, useEffect } from 'react'
import { ApexOptions } from 'apexcharts'
import { format } from 'date-fns'
import formatDuration from "date-fns/formatDuration"
import secondsToMinutes from "date-fns/secondsToMinutes"
import secondsToHours from "date-fns/secondsToHours"
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded'
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardRounded'
import CloseIcon from '@mui/icons-material/CloseRounded'
import { useAggregatedTimeseriesHandlingGapRequest } from 'src/hooks/api'
import { Chart } from 'src/components/ui'
import { AggregatedTimeseriesHandlingGap } from 'src/api'

type ApexChartDataObj = {
  x: any;
  y: any;
  fillColor?: string;
  strokeColor?: string;
  meta?: any;
  goals?: any;
};

const STEP = 7

export const TimeseriesHandlingGapChart: FC = () => {
  const [dateFrom, setDateFrom] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - STEP)))
  const [dateTo, setDateTo] = useState<Date>(new Date())
  const [timeseriesGapList, setTimeseriesGapList] = useState<AggregatedTimeseriesHandlingGap[]>([])

  const {
    data: timeseriesGapData,
    isInitialLoading,
    isRefetching,
    refetch: refetchTimeseriesGap,
  } = useAggregatedTimeseriesHandlingGapRequest({
    params: {
      dateFrom: new Date(new Date(dateFrom).setUTCMinutes(0, 0)).toISOString(),
      dateTo: new Date(new Date(dateTo).setUTCMinutes(59, 59)).toISOString(),
    },
    options: { enabled: false },
  })

  useEffect(() => {
    setTimeseriesGapList(timeseriesGapData || [])
  }, [timeseriesGapData])

  useEffect(() => {
    if (dateFrom && dateTo) refetchTimeseriesGap()
  }, [dateFrom, dateTo, refetchTimeseriesGap])

  const isLastPeriod = useCallback(() => {
    return dateTo.getDate() === new Date().getDate()
      && dateTo.getMonth() === new Date().getMonth()
      && dateTo.getFullYear() === new Date().getFullYear()
  }, [dateTo])()

  const handleGoBack = () => {
    if (isRefetching) return

    const newTimeFrom = new Date(new Date(dateFrom).setDate(new Date(dateFrom).getDate() - STEP))
    const newTimeTo = new Date(new Date(dateTo).setDate(new Date(dateTo).getDate() - STEP))
    setDateFrom(newTimeFrom)
    setDateTo(newTimeTo)
  }

  const handleGoForward = () => {
    if (isLastPeriod || isRefetching) return

    const newTimeFrom = new Date(new Date(dateFrom).setDate(new Date(dateFrom).getDate() + STEP))
    const newTimeTo = new Date(new Date(dateTo).setDate(new Date(dateTo).getDate() + STEP))
    setDateFrom(newTimeFrom)
    setDateTo(newTimeTo)
  }

  const resetRange = () => {
    const newTimeFrom = new Date(new Date(new Date().setDate(new Date().getDate() - STEP)))
    const newTimeTo = new Date()
    setDateFrom(newTimeFrom)
    setDateTo(newTimeTo)
  }

  const getDuration = (value: number) => {
    const seconds = value > 60
      ? value - (Math.floor(value / 60) * 60)
      : value
    const minutes = secondsToMinutes(value) > 60
      ? secondsToMinutes(value) - (Math.floor(secondsToMinutes(value) / 60) * 60)
      : secondsToMinutes(value)
    const hours = secondsToHours(value) > 24
      ? secondsToHours(value) - (Math.floor(secondsToHours(value) / 24) * 24)
      : secondsToHours(value)
    const days = Math.floor(value / 60 / 60 / 24)
    return {
      seconds,
      minutes,
      hours,
      days,
    }
  }

  const getSensorBarChartData = useCallback(
    (): ApexOptions['series'] => {
      const avgGapData: ApexChartDataObj[] = []
      for (let i = 0; i < STEP * 24; i++) {
        const shiftedTime = new Date(new Date(dateTo).setUTCHours(new Date(dateTo).getUTCHours() - i))
        const timeseriesGap = timeseriesGapList.find((item) => (
          new Date(item.bucket).getUTCFullYear() === shiftedTime.getUTCFullYear()
          && new Date(item.bucket).getUTCMonth() === shiftedTime.getUTCMonth()
          && new Date(item.bucket).getUTCDate() === shiftedTime.getUTCDate()
          && new Date(item.bucket).getUTCHours() === shiftedTime.getUTCHours()
        ))
        avgGapData.unshift({
          x: shiftedTime.getTime(),
          y: timeseriesGap?.avgGap ? Math.round(timeseriesGap.avgGap) : 0,
        })
      }
      return [
        {
          name: 'Average gap',
          data: avgGapData,
        },
      ]
    },
    [timeseriesGapList, dateTo]
  )

  const chartOptions: ApexOptions = {
    chart: {
      type: 'line',
      toolbar: {
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            headerCategory: 'Time',
          },
        },
      },
    },
    colors: ['#13d501', '#f4cf01', '#ff0000', '#1060fe'],
    stroke: {
      curve: 'smooth',
      width: [3, 3, 3, 0],
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      labels: {
        offsetX: 0,
        formatter: (timestamp) => {
          return format(Number(timestamp), 'MMM dd - h a')
        },
      },
    },
    tooltip: {
      x: {
        formatter: (timestamp) => {
          return format(timestamp, 'MMM dd - h a')
        },
      },
      y: {
        formatter: (value, { seriesIndex }) => {
          if (seriesIndex === 3) return String(value)

          return formatDuration(
            getDuration(value),
            {
              format: ['days', 'hours', 'minutes', 'seconds'],
              zero: false
            }
          )
        }
      }
    },
  }

  return (
    <Grid container spacing={2} direction='column'>
      <Grid item>
        <Typography variant='h6'>TimeseriesDB handling GAP</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          The chart below shows the difference between the time when a reading was saved to database and the time when the reading was handled by the system.
        </Typography>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item>
          <IconButton aria-label="go-back" color="primary" onClick={handleGoBack} disabled={isRefetching}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item px={1}>
          <Typography variant="body1">
            {`${format(dateFrom, 'PP')} - ${format(dateTo, 'PP')}`}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton aria-label="go-forward" color="primary" onClick={handleGoForward} disabled={isLastPeriod || isRefetching}>
            <ArrowForwardIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton aria-label="reset" color="primary" onClick={resetRange} disabled={isLastPeriod || isRefetching}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item container>
        <Box height="400px" ml={-2} width="calc(100% + 16px)" position="relative">
          {
            isInitialLoading && (
              <>
                <Box
                  position="absolute"
                  width="100%"
                  height="100%"
                  zIndex={1}
                  sx={{
                    background: "grey",
                    opacity: 0.1,
                  }}
                />
                <Box
                  position="absolute"
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress color="primary" size={26} />
                </Box>
              </>
            )
          }
          <Chart options={chartOptions} data={getSensorBarChartData()} />
        </Box>
      </Grid>
    </Grid>
  )
}
