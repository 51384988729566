import React, { FC, PropsWithChildren } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Button, Grid } from "@mui/material"
import {
  HasSidebarLayout,
  DataLoad,
  EntityHeader,
  TabGroupFilter,
  InnerLink,
  PropsInjector,
  ManufacturerInfo,
  ProductsOfManufacturer,
  DeleteManufacturerModal,
} from "src/components"
import { useDeleteManufacturerRequest, useManufacturerRequest } from "src/hooks/api"
import { Manufacturer } from "src/api"
import { DialogNames, SearchParamsKeys } from "src/enums"
import { useAddPopupMessage, useDialog } from "src/hooks/ui"

const getStatusList = ({ manufacturerId }: { manufacturerId: number }) => {
  return [
    {
      status: "Products",
      content: <ProductsOfManufacturer manufacturerId={manufacturerId} />,
    },
  ]
}

interface ContractorShowContentProps {
  manufacturer?: Manufacturer;
  onDeleteManufacturer?: (id: number) => void;
}

const ManufacturerShowContent: FC<ContractorShowContentProps> = ({
  manufacturer,
  onDeleteManufacturer = () => {},
}) => {
  const { openDialog, closeDialog } = useDialog(DialogNames.DeleteManufacturer)
  if (!manufacturer) return null
  const { id, name, email, phone, fullAddress, photo } = manufacturer

  const onDelete = () => {
    onDeleteManufacturer(id)
    closeDialog()
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <EntityHeader
          title={name}
          actionBlockOnTheRight={<><Button
            variant="outlined"
            color="error"
            sx={{ marginRight: 2 }}
            onClick={openDialog}
          >
            Delete
          </Button>
          <InnerLink
            to={`/manufacturers/${id}/edit`}
            style={{ textDecoration: "none" }}
          >
            <Button variant="contained">Edit Info</Button>
          </InnerLink></>}
        />
      </Grid>
      <Grid item xs={12}>
        <ManufacturerInfo address={fullAddress} phone={phone} email={email} photo={photo} />
      </Grid>
      <Grid item xs={12}>
        <TabGroupFilter
          name={SearchParamsKeys.Tab}
          statusList={getStatusList({ manufacturerId: id })}
          paramsToRemove={[SearchParamsKeys.Page, SearchParamsKeys.RowsPerPage]}
        />
      </Grid>
      <DeleteManufacturerModal
        manufacturerName={name}
        onCancel={() => closeDialog()}
        onDelete={onDelete}
      />
    </Grid>
  )
}

interface ManufacturerResourceLoaderProps {
  id: number;
}

const ManufacturerResourceLoader: FC<PropsWithChildren<ManufacturerResourceLoaderProps>> = ({
  id,
  children,
}) => {
  const { isInitialLoading, isError, data, error } = useManufacturerRequest({ id })
  document.title = `Floorcloud Admin Panel - Manufacturer ${data?.name || ''}`
  const navigate = useNavigate()
  const addMessage = useAddPopupMessage()
  const deleteManufacturerRequest = useDeleteManufacturerRequest({
    options: {
      onError: ({ message: text }) => {
        addMessage({ type: "error", text })
      },
      onSuccess: () => {
        navigate(-1)
        addMessage({ type: "success", text: "Manufacturer successfully deleted" })
      },
    },
  })

  const deleteManufacturer = async (manufacturerId: number): Promise<void> => {
    deleteManufacturerRequest.mutate(manufacturerId)
  }

  const props = {
    manufacturer: data,
    onDeleteManufacturer: deleteManufacturer,
  }

  return (
    <DataLoad
      isLoading={isInitialLoading}
      isError={isError}
      errorMessage={error?.message}
    >
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

const ContractorShow: FC = () => {
  const params = useParams()
  const { id } = params as { id: string }

  return (
    <HasSidebarLayout>
      <ManufacturerResourceLoader id={parseInt(id, 10)}>
        <ManufacturerShowContent />
      </ManufacturerResourceLoader>
    </HasSidebarLayout>
  )
}

export default ContractorShow
