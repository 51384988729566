import { FC, useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'
import { useSensorRelatedRapidRhProbesRequest } from 'src/hooks/api'
import { WagnerProbe } from 'src/api'
import { TableToolbar } from 'src/components/ui'
import { RapidRHColumnsDataGrid } from '../wagnerProbes'

type Props = {
  sensorUniqueId: string
}

export const SensorRelatedRapidRhProbes: FC<Props> = ({ sensorUniqueId }) => {
  const { data, isInitialLoading } = useSensorRelatedRapidRhProbesRequest({ uniqueId: sensorUniqueId })
  const rapidRhProbes: WagnerProbe[] = data || []
  const columns = useMemo((): GridColDef<WagnerProbe>[] => ([
    RapidRHColumnsDataGrid.ProbeId,
    RapidRHColumnsDataGrid.IsOnline,
    RapidRHColumnsDataGrid.ReadingTime,
    RapidRHColumnsDataGrid.Label,
    RapidRHColumnsDataGrid.LastDeviceType,
    RapidRHColumnsDataGrid.LastBatteryLevel,
    RapidRHColumnsDataGrid.LastSignalStrength,
    RapidRHColumnsDataGrid.ReceivedFromDataGrabber,
    RapidRHColumnsDataGrid.ReceivedFromRepeater,
    RapidRHColumnsDataGrid.ReceivedViaGAT,
  ]), [])
  
  return (
    <>
      <Grid container spacing={2} alignItems="center" mb={1}>
        <Grid item>
          <Typography variant="h6">
            Related Rapid RH Probes
          </Typography>
        </Grid>
      </Grid>
      <Paper sx={{ height: '500px' }}>
        <DataGridPremium<WagnerProbe>
          pagination={false}
          autoHeight={false}
          disableRowSelectionOnClick
          rows={rapidRhProbes}
          columns={columns}
          rowCount={rapidRhProbes.length}
          loading={isInitialLoading}
          rowThreshold={2}
          columnThreshold={2}
          hideFooterPagination
          localeText={{
            columnMenuSortAsc: "Sort A-Z",
            columnMenuSortDesc: "Sort Z-A",
          }}
          slots={{
            toolbar: () => <TableToolbar exportedFileName={`${document.title}_related_rapid_rh_probes`} />
          }}
          sx={(theme) => ({
            border: "none",
            "& .MuiDataGrid-columnHeaderTitleContainerContent": {
              paddingLeft: "5px",
              overflow: "visible",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: 1,
              fontSize: "13px",
              fontWeight: 400,
              color: "rgba(0, 0, 0, 0.6)",
              overflow: "visible",
            },
          })}
        />
      </Paper>
    </>
  )
}
