import React, { FC, useMemo, useState } from "react"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { GridActionsCellItem } from "@mui/x-data-grid-premium"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { SensorColumnsDataGrid } from "src/components/columns/sensor"
import { Sensor, SensorState } from "src/api"
import { SensorsMapView } from "src/components/ui"
import { useQueryParamsState, useSensorStateUpdate } from "src/hooks/ui"
import { SearchParamsKeys, SensorButtons, SensorFilters, SensorStateList, ViewTypes } from "src/enums"
import { SensorListFilters } from "src/components/forms/sensor"
import { SensorMapFilters } from "src/components/forms/sensor/sensorMapFilters"
import { CommonSensorList } from "./commonSensorsList"
import { ContractorsDataLoader } from "./contractorsDataLoader"
import { UnarchiveSensorModal } from "../unarchiveSensorModal"
import { ArchiveSensorModal } from "../archiveSensorModal"

const getColumns = ({
  handleOpenMenu,
}: {
  handleOpenMenu: (e: any, row: any) => void
}) => [
  SensorColumnsDataGrid.Id,
  SensorColumnsDataGrid.Model,
  SensorColumnsDataGrid.IMEI,
  SensorColumnsDataGrid.Label,
  SensorColumnsDataGrid.FirmwareVersion,
  SensorColumnsDataGrid.AvailableInThingspace,
  SensorColumnsDataGrid.LastPing,
  SensorColumnsDataGrid.SignalStrength,
  SensorColumnsDataGrid.BatteryLevel,
  SensorColumnsDataGrid.DateAdded,
  SensorColumnsDataGrid.Contractor,
  SensorColumnsDataGrid.Project,
  SensorColumnsDataGrid.State,
  SensorColumnsDataGrid.ServicePlan,
  SensorColumnsDataGrid.EnabledBluetooth,
  {
    field: "actions",
    headerName: "",
    type: "actions",
    width: 30,
    getActions: ({ row }: { row: any }) => [
      <GridActionsCellItem
        icon={<MoreVertIcon />}
        label="Actions"
        className="textPrimary"
        onClick={(e: any) => handleOpenMenu(e, row)}
        color="inherit"
      />,
    ],
  },
]

interface AllSensorListProps {
  isLoading: boolean;
  count: number;
  data: Sensor[];
  states: SensorState[];
  refetchSensorList: () => void;
}

export const AllSensorList: FC<AllSensorListProps> = ({
  data,
  isLoading,
  count,
  states,
  refetchSensorList,
}) => {
  const [currentRow, setCurrentRow] = useState<Sensor | null>(null)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const queryParams = useQueryParamsState()
  const viewType = queryParams[SearchParamsKeys.ViewType] || ViewTypes.List
  const {
    openSensorUnarchivingDialog,
    closeSensorUnarchivingDialog,
    openSensorArchivingDialog,
    closeSensorArchivingDialog,
    updateSensorState,
    isLoadingChangingSensorState,
  } = useSensorStateUpdate()

  const dataWithState = useMemo(
    () =>
      data.map((row) => ({
        ...row,
        state: states.find((e) => e.id === row.stateId),
      })),
    [data, states]
  )

  const archivedState = useMemo(() => states.find(
    (state) => state.name === SensorStateList.Archived
  ), [states])
  const newState = useMemo(() => states.find(
    (state) => state.name === SensorStateList.New
  ), [states])
  const isArchived = useMemo(() => (row: Sensor) => row && row.stateId === archivedState?.id, [archivedState])

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    row: Sensor
  ) => {
    setCurrentRow(row)
    setMenuAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setCurrentRow(null)
    setMenuAnchorEl(null)
  }

  const getMenuItems = () => {
    if (!isArchived(currentRow as Sensor)) {
      return (
        <MenuItem
          disabled={isLoadingChangingSensorState}
          onClick={openSensorArchivingDialog}
        >
          Archive
        </MenuItem>
      )
    }

    return (
      <MenuItem
        disabled={isLoadingChangingSensorState}
        onClick={openSensorUnarchivingDialog}
      >
        Unarchive
      </MenuItem>
    )
  }

  return viewType === ViewTypes.List ? (
    <>
      <CommonSensorList
        columns={getColumns({ handleOpenMenu })}
        count={count}
        data={dataWithState}
        isLoading={isLoading}
        filters={
          <SensorListFilters
            activeFilters={[
              SensorFilters.LowBattery,
              SensorFilters.WeakSignal,
              SensorFilters.LastPingMoreThanHour,
              SensorFilters.SyncPeriod,
              SensorFilters.ReadingsAge,
              SensorFilters.CurrentSensorConfiguration,
              SensorFilters.BleStatus,
              SensorFilters.FirmwareVersion,
            ]}
          />
        }
        buttons={[SensorButtons.GetHistoricalData]}
        checkboxSelection
      />
      <Menu
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={handleCloseMenu}
      >
        {getMenuItems()}
      </Menu>
      <UnarchiveSensorModal
        sensorDeviceId={currentRow?.deviceId as string}
        isLoading={isLoadingChangingSensorState}
        onCancel={() => {
          closeSensorUnarchivingDialog()
          handleCloseMenu()
        }}
        onUnarchive={() => {
          updateSensorState({
            uniqueId: currentRow?.uniqueId as string,
            state: newState as SensorState,
            onSuccess: () => {
              refetchSensorList()
              handleCloseMenu()
            },
          })
        }}
      />
      <ArchiveSensorModal
        sensorDeviceId={currentRow?.deviceId as string}
        isLoading={isLoadingChangingSensorState}
        onCancel={() => {
          closeSensorArchivingDialog()
          handleCloseMenu()
        }}
        onArchive={() => {
          updateSensorState({
            uniqueId: currentRow?.uniqueId as string,
            state: archivedState as SensorState,
            onSuccess: () => {
              refetchSensorList()
              handleCloseMenu()
            },
          })
        }}
      />
    </>
  ) : (
    <SensorsMapView
      data={dataWithState}
      isLoading={isLoading}
      filters={
        <ContractorsDataLoader>
          <SensorMapFilters
            activeFilters={[
              SensorFilters.Contractor,
              SensorFilters.LowBattery,
              SensorFilters.WeakSignal,
              SensorFilters.LastPingMoreThanHour,
              SensorFilters.SyncPeriod,
              SensorFilters.ReadingsAge,
              SensorFilters.LocationType,
            ]}
          />
        </ContractorsDataLoader>
      }
    />
  )
}
