import { FC, useCallback } from 'react'
import { GridColDef } from '@mui/x-data-grid-premium'
import { Event } from "src/api"
import { ImagesViewerStateProvider } from 'src/components'
import { useEventListRequest } from 'src/hooks/api'
import { useImagesViewer, useQueryParams } from 'src/hooks/ui'
import Grid from '@mui/material/Grid'
import { EventColumns, EventList, EventListFilters } from '../event'

interface Props {
  contractorId: number;
}

const defaultPerPage = 50
const defaultPage = 1
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

const ContractorEventList: FC<Props> = ({
  contractorId,
}) => {
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const { isInitialLoading, data } = useEventListRequest({
    params: {
      contractorId,
      ...queryParams,
    },
  })
  const { openGallery } = useImagesViewer()
  const exportedFileName = `${document.title}_events`
  const columns = useCallback((): GridColDef<Event>[] => ([
    EventColumns.Name,
    EventColumns.Type,
    EventColumns.Project,
    EventColumns.Section,
    EventColumns.Author,
    EventColumns.CreatedAt,
    EventColumns.Distance,
    EventColumns.Device,
    EventColumns.AppVersion,
    EventColumns.Photo(openGallery),
  ]), [openGallery])

  return (
    <Grid container flexDirection="column" spacing={2}>
      <Grid item xs={12}>
        <EventListFilters />
      </Grid>
      <Grid item xs={12}>
        <EventList
          eventCount={data?.count || 0}
          events={data?.rows || []}
          eventColumns={columns()}
          isLoadingEvents={isInitialLoading}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
          height='700px'
          exportedFileName={exportedFileName}
        />
      </Grid>
    </Grid>
  )
}

const ContractorEventListWrapper: FC<Props> = ({ contractorId }) => {
  return (
    <ImagesViewerStateProvider>
      <ContractorEventList contractorId={contractorId} />
    </ImagesViewerStateProvider>
  )
}

export {
  ContractorEventListWrapper as ContractorEventList,
}
