import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseQueryOptions<unknown, Error, string[]>,
    'queryKey' | 'queryFn'
  >;
}

export const useSensorFirmwareVersions = ({
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  return useQuery<unknown, Error, string[]>(
    [Entities.Sensors, `get-firmware-versions-list`],
    async () => {
      const { data } = await api.v1AdminSensorsFirmwareVersionsGet()
      return data as string[]
    },
    options,
  )
}
