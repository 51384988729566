import React, { FC, useCallback, useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {
  DataGridPremium,
} from '@mui/x-data-grid-premium'
import { Attachment } from 'src/api'
import { OuterLink } from 'src/components/ui'
import { AttachmentColumns } from './attachmentColumns'

interface Props {
  attachment: Attachment
}

export const AttachmentInfo: FC<Props> = ({ attachment }) => {
  const statusTableColumns = [
    AttachmentColumns.Type,
    AttachmentColumns.Status,
    AttachmentColumns.AmountOfChunks,
    AttachmentColumns.AmountOfUploadedChunks,
    AttachmentColumns.Size,
  ]
  const generalTableColumns = [
    AttachmentColumns.RelatedTo,
    AttachmentColumns.Contractor,
    AttachmentColumns.Project,
    AttachmentColumns.ProjectSection,
    AttachmentColumns.Author,
    AttachmentColumns.CreatedAt,
  ]
  const fileName = useMemo(() => attachment.fileName || attachment.originalFileName || '', [attachment])
  const isPhoto = useMemo(() => attachment.type === 'photo', [attachment.type])
  const isProcessed = useMemo(() => attachment.status === 'processed', [attachment.status])

  const renderFileSection = useCallback(() => {
    if (isPhoto && isProcessed) {
      return (
        <img
          style={{
            objectFit: "scale-down",
            width: 400,
            height: 'auto',
          }}
          alt={fileName}
          src={attachment.link}
        />
      )
    }

    if (isProcessed && attachment.link) {
      return (
        <OuterLink to={attachment.link} target="_blank">
          Download
        </OuterLink>
      )
    }

    return '-'
  }, [attachment.link, fileName, isPhoto, isProcessed])

  return (
    <Grid container spacing={4}>
      <Grid item container direction="column" spacing={1} sx={{ width: '100%', maxWidth: '700px' }}>
        <Grid item>
          <Typography variant="h6">Status information</Typography>
        </Grid>
        <Grid item>
          <DataGridPremium<Attachment>
            rows={[attachment]}
            columns={statusTableColumns}
            hideFooter
            disableRowSelectionOnClick
          />
        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h6">General information</Typography>
        </Grid>
        <Grid item>
          <DataGridPremium<Attachment>
            rows={[attachment]}
            columns={generalTableColumns}
            hideFooter
            disableRowSelectionOnClick
          />
        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h6">File</Typography>
        </Grid>
        <Grid item>
          {renderFileSection()}
        </Grid>
      </Grid>
    </Grid>
  )
}
