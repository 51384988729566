import { FC } from 'react'
import { Box, Typography } from "@mui/material"
import { DataLoad } from 'src/components/ui'
import { useMobileSettingsRequest } from 'src/hooks/api/useMobileSettings'
import { MobileSettingsEdit } from 'src/components/forms/mobileSettings/mobileSettingsEdit'

export const MobileSettingsParams: FC = () => {
  const {
    data: mobileSettings,
    isInitialLoading,
    isError,
    refetch: refetchMobileSettings
  } = useMobileSettingsRequest()

  if (!mobileSettings) return null

  return (
    <DataLoad isLoading={isInitialLoading} isError={isError}>
      <Typography variant="h4">Mobile Settings</Typography>
      <Box width="500px" mt={2}>
        <MobileSettingsEdit
          mobileSettings={mobileSettings}
          refetchMobileSettings={refetchMobileSettings}
        />
      </Box>
    </DataLoad>
  )
}
