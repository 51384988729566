import { Box, Typography } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid-premium"
import format from "date-fns/format"
import formatDistance from "date-fns/formatDistance"
import { EventTypeName, Report } from "src/api"
import { InnerLink, OuterLink } from "src/components/ui"
import { ReportTypeNameEnum } from "src/enums"

export namespace ReportColumnsDataGrid {
  export const Name: GridColDef<Report> = {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    minWidth: 200,
    renderCell: ({ row }) => {
      const name = row.name || row.type?.displayName
      if (row.status !== 'ready') {
        return name
      }

      const { expiredAt, downloadLink } = row
      if (expiredAt && new Date(expiredAt) <= new Date()) {
        return name
      } else if (!downloadLink) {
        return name
      }

      return (
        <OuterLink to={downloadLink} download>{name}</OuterLink>
      )
    }
  }

  export const Type: GridColDef<Report> = {
    field: 'type.displayName',
    headerName: 'Report type',
    type: 'string',
    minWidth: 200,
    valueGetter: ({row}) => row.type?.displayName || null,
  }

  export const ReportProject: GridColDef<Report> = {
    field: 'projects',
    headerName: 'Project',
    type: 'string',
    minWidth: 200,
    valueGetter: ({ row }) => {
      const projects = row?.projects || []
      return projects.map((project) => project.name).join(',')
    },
    renderCell: ({ row }) => {
      const projects = row?.projects || []
      if (projects.length !== 0) {
        return (
          <Box>
            {projects.map((project, index) => (
              <Box key={project.id}> 
                <InnerLink to={`/projects/${project.id}`}>{project.name}</InnerLink>
                {index < (projects || []).length - 1 ? (
                  <Typography variant="body2" component='span'>, </Typography>
                ) : null}
              </Box>
            ))}
          </Box>
        )
      }
      return '-'
    },
  }

  export const ReportSection: GridColDef<Report> = {
    field: 'projectSections',
    headerName: 'Section',
    type: 'string',
    minWidth: 200,
    valueGetter: ({ row }) => {
      const projectSections = row?.projectSections || []
      return projectSections.map((projectSection) => projectSection.name).join(',')
    },
    renderCell: ({ row }) => {
      const projectSections = row?.projectSections || []
      if (projectSections.length !== 0) {
        return (
          <Box>
            {projectSections.map((projectSection, index) => (
              <Box key={projectSection.id} component='span'> 
                <InnerLink key={projectSection.id} to={`/sections/${projectSection.id}`}>{projectSection.name}</InnerLink>
                {index < (projectSections || []).length - 1 ? (
                  <Typography variant="body2" component='span'>, </Typography>
                ) : null}
              </Box>
            ))}
          </Box>
        )
      }
      return '-'
    },
  }

  export const DateFrom: GridColDef<Report> = {
    field: 'settings.dateFrom',
    headerName: 'Date from',
    type: 'string',
    minWidth: 130,
    valueGetter: ({row}) => row.settings?.dateFrom || null,
    valueFormatter: ({ value }) => value ? format(new Date(value), 'PP') : '-'
  }

  export const DateTo: GridColDef<Report> = {
    field: 'settings.dateTo',
    headerName: 'Date to',
    type: 'string',
    minWidth: 130,
    valueGetter: ({row}) => row.settings?.dateTo || null,
    valueFormatter: ({ value }) => value ? format(new Date(value), 'PP') : '-'
  }

  export const Duration: GridColDef<Report> = {
    field: 'settings',
    headerName: 'Duration in days',
    type: 'string',
    minWidth: 130,
    valueGetter: ({row}) => row.settings?.dateTo && row.settings?.dateFrom
      ? formatDistance(new Date(row.settings.dateTo), new Date(row.settings.dateFrom))
      : '-',
  }

  export const DateOfGeneration: GridColDef<Report> = {
    field: 'createdAtDate',
    headerName: 'Date of generation',
    type: 'string',
    minWidth: 130,
    valueGetter: ({row}) => row.createdAt || null,
    valueFormatter: ({ value }) => value ? format(new Date(value), 'PP') : '-'
  }

  export const TimeOfGeneration: GridColDef<Report> = {
    field: 'createdAtTime',
    headerName: 'Time of generation',
    type: 'string',
    minWidth: 130,
    valueGetter: ({row}) => row.createdAt || null,
    valueFormatter: ({ value }) => value ? format(new Date(value), 'pp') : '-'
  }

  export const DayOfWeek: GridColDef<Report> = {
    field: 'createdAtDayOfWeek',
    headerName: 'Day of week',
    type: 'string',
    minWidth: 130,
    valueGetter: ({row}) => row.createdAt || null,
    valueFormatter: ({ value }) => value ? format(new Date(value), 'cccc') : '-'
  }

  export const Contractor: GridColDef<Report> = {
    field: 'contractor.name',
    headerName: 'Contractor',
    type: 'string',
    minWidth: 200,
    valueGetter: ({row}) => row.contractor?.name || null,
    renderCell: ({ row }) => {
      if (row?.contractor) {
        const contractorId = row?.contractor.id
        const contractorName = row?.contractor.name
        return <InnerLink to={`/contractors/${contractorId}`}>{contractorName}</InnerLink>
      }
      return '-'
    },
  }

  export const Author: GridColDef<Report> = {
    field: 'user.name',
    headerName: 'Author',
    type: 'string',
    minWidth: 170,
    valueGetter: ({row}) => row.author?.name || null,
    renderCell: ({ row }) => {
      if (row?.author) {
        const authorId = row?.author.id
        const authorName = row?.author.name
        return <InnerLink to={`/users/${authorId}`}>{authorName}</InnerLink>
      }
      return '-'
    },
  }

  export const ProjectArchivationDate: GridColDef<Report> = {
    field: 'projectArchivationDate',
    headerName: 'Project archivation date',
    type: 'string',
    minWidth: 200,
    valueGetter: ({row}) => {
      if (row.projects?.length !== 1) return null

      const project = row.projects[0]
      if (project.isActiveProject) return null

      const projectArchivedEvent = (project.events || []).find((event) => event.type?.name === 'project_archived')
      if (!projectArchivedEvent) return null

      return projectArchivedEvent.createdAt
    },
    valueFormatter: ({ value }) => value ? format(new Date(value), 'PP') : '-'
  }

  export const AvailabilitySummary: GridColDef<Report> = {
    field: 'summary',
    headerName: 'Summary',
    type: 'string',
    minWidth: 100,
    valueFormatter: ({ value }) => Boolean(value) ? 'Yes' : 'No'
  }

  export const NameHadChanged: GridColDef<Report> = {
    field: 'type.name',
    headerName: 'Name changed',
    type: 'string',
    minWidth: 100,
    valueGetter: ({ row }) => row.type?.displayName === row.name,
    valueFormatter: ({ value }) => typeof value === 'boolean' && !value ? 'Yes' : 'No'
  }

  export const EventsIncluded: GridColDef<Report> = {
    field: 'reportsEvents',
    headerName: 'Events included',
    type: 'string',
    minWidth: 100,
    valueGetter: ({ row }) => {
      if (row.type?.name !== ReportTypeNameEnum.JobSiteConditions) {
        return null
      }
      return row.reportsEvents?.length || 0
    },
    valueFormatter: ({ value }) => {
      if (value === null) {
        return '-'
      }
      return value > 0 ? 'Yes' : 'No'
    }
  }

  export const EventsAmount: GridColDef<Report> = {
    field: 'reportsEvents.id',
    headerName: 'Events amount',
    type: 'string',
    minWidth: 100,
    valueGetter: ({ row }) => {
      if (row.type?.name !== ReportTypeNameEnum.JobSiteConditions) {
        return null
      }
      return row.reportsEvents?.length || 0
    },
    valueFormatter: ({ value }) => {
      if (value === null) {
        return '-'
      }
      return value
    }
  }

  export const EventsWithPhoto: GridColDef<Report> = {
    field: 'reportsEvents.attachments',
    headerName: 'Events include photos',
    type: 'string',
    minWidth: 130,
    valueGetter: ({ row }) => {
      if (row.type?.name !== ReportTypeNameEnum.JobSiteConditions) {
        return null
      }

      const exludedEventTypes: EventTypeName[] = [
        EventTypeName.AlertShared,
        EventTypeName.SlabConditionsListShared,
        EventTypeName.SlabConditionsShared,
        EventTypeName.ClimateConditionsShared,
      ]
      const eventsWithPhoto = (row.reportsEvents || []).filter((re) => re.attachments?.length && !exludedEventTypes.includes(re.event?.type?.name as EventTypeName))
      return eventsWithPhoto.length
    },
    valueFormatter: ({ value }) => {
      if (value === null) {
        return '-'
      }
      return value > 0 ? 'Yes' : 'No'
    }
  }

  export const EventsWithNotes: GridColDef<Report> = {
    field: 'reportsEvents.event',
    headerName: 'Events include notes',
    type: 'string',
    minWidth: 130,
    valueGetter: ({ row }) => {
      if (row.type?.name !== ReportTypeNameEnum.JobSiteConditions) {
        return null
      }

      const eventsWithNotes = (row.reportsEvents || []).filter((re) => re.event?.type?.name === EventTypeName.NewNoteAdded)
      return eventsWithNotes.length
    },
    valueFormatter: ({ value }) => {
      if (value === null) {
        return '-'
      }
      return value > 0 ? 'Yes' : 'No'
    }
  }

  export const Components: GridColDef<Report> = {
    field: 'components',
    headerName: 'Components',
    type: 'string',
    minWidth: 170,
    valueGetter: ({ row }) => (row.components || []).map((item) => item.displayName).join(', '),
    valueFormatter: ({ value }) => value || '-',
  }
}