/* tslint:disable */
/* eslint-disable */
/**
 * Floor Cloud API
 * API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdminAlert
 */
export interface AdminAlert {
    /**
     * 
     * @type {number}
     * @memberof AdminAlert
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AdminAlert
     */
    'typeId': number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminAlert
     */
    'isActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminAlert
     */
    'isWatched': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminAlert
     */
    'endTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminAlert
     */
    'lastValue': number;
    /**
     * 
     * @type {number}
     * @memberof AdminAlert
     */
    'minNormalValue': number;
    /**
     * 
     * @type {number}
     * @memberof AdminAlert
     */
    'maxNormalValue': number;
    /**
     * 
     * @type {number}
     * @memberof AdminAlert
     */
    'projectSectionId': number;
    /**
     * 
     * @type {number}
     * @memberof AdminAlert
     */
    'projectId': number;
    /**
     * 
     * @type {number}
     * @memberof AdminAlert
     */
    'affectedProductCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminAlert
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminAlert
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminAlert
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminAlert
     */
    'defaultTextForSharing'?: string;
    /**
     * 
     * @type {ProjectSection}
     * @memberof AdminAlert
     */
    'projectSection'?: ProjectSection;
    /**
     * 
     * @type {Project}
     * @memberof AdminAlert
     */
    'project'?: Project;
    /**
     * 
     * @type {Array<ProductProjectSection>}
     * @memberof AdminAlert
     */
    'productsProjectSections'?: Array<ProductProjectSection>;
    /**
     * 
     * @type {Array<ProjectSectionSensor>}
     * @memberof AdminAlert
     */
    'projectSectionsSensors'?: Array<ProjectSectionSensor>;
    /**
     * 
     * @type {AlertType}
     * @memberof AdminAlert
     */
    'type'?: AlertType;
    /**
     * 
     * @type {Array<AlertProjectSectionSensor>}
     * @memberof AdminAlert
     */
    'alertsProjectSectionsSensors'?: Array<AlertProjectSectionSensor>;
    /**
     * 
     * @type {Array<AlertProductProjectSection>}
     * @memberof AdminAlert
     */
    'alertsProductsProjectSections'?: Array<AlertProductProjectSection>;
}
/**
 * 
 * @export
 * @interface AdminBackgroundSyncSettings
 */
export interface AdminBackgroundSyncSettings {
    /**
     * 
     * @type {number}
     * @memberof AdminBackgroundSyncSettings
     */
    'chunkSize': number;
}
/**
 * 
 * @export
 * @interface AdminMobileSettings
 */
export interface AdminMobileSettings {
    /**
     * 
     * @type {AdminWagnerProbeSettings}
     * @memberof AdminMobileSettings
     */
    'wagnerProbeSettings': AdminWagnerProbeSettings;
    /**
     * 
     * @type {AdminBackgroundSyncSettings}
     * @memberof AdminMobileSettings
     */
    'backgroundSyncSettings'?: AdminBackgroundSyncSettings;
}
/**
 * 
 * @export
 * @interface AdminNotification
 */
export interface AdminNotification {
    /**
     * 
     * @type {number}
     * @memberof AdminNotification
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AdminNotification
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof AdminNotification
     */
    'notificationType': string;
    /**
     * 
     * @type {string}
     * @memberof AdminNotification
     */
    'notificationTypeDisplayName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminNotification
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminNotification
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminNotification
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AdminProjectTypeList
 */
export interface AdminProjectTypeList {
    /**
     * 
     * @type {number}
     * @memberof AdminProjectTypeList
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AdminProjectTypeList
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProjectTypeList
     */
    'displayName'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminProjectTypeList
     */
    'projectsCount': number;
    /**
     * 
     * @type {string}
     * @memberof AdminProjectTypeList
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminProjectTypeList
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AdminUserListBody
 */
export interface AdminUserListBody {
    /**
     * 
     * @type {Array<number>}
     * @memberof AdminUserListBody
     */
    'roleIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdminUserListBody
     */
    'contractorIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdminUserListBody
     */
    'manufacturerIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface AdminWagnerProbeSettings
 */
export interface AdminWagnerProbeSettings {
    /**
     * 
     * @type {number}
     * @memberof AdminWagnerProbeSettings
     */
    'signalStrengthThreshold': number;
    /**
     * 
     * @type {number}
     * @memberof AdminWagnerProbeSettings
     */
    'connectivitySignalLevel': number;
    /**
     * 
     * @type {number}
     * @memberof AdminWagnerProbeSettings
     */
    'connectivityCheckPacketFreshnessThreshold': number;
}
/**
 * 
 * @export
 * @interface AggregatedTimeseriesGap
 */
export interface AggregatedTimeseriesGap {
    /**
     * 
     * @type {string}
     * @memberof AggregatedTimeseriesGap
     */
    'bucket': string;
    /**
     * 
     * @type {number}
     * @memberof AggregatedTimeseriesGap
     */
    'maxGap': number;
    /**
     * 
     * @type {number}
     * @memberof AggregatedTimeseriesGap
     */
    'avgGap': number;
    /**
     * 
     * @type {number}
     * @memberof AggregatedTimeseriesGap
     */
    'minGap': number;
    /**
     * 
     * @type {number}
     * @memberof AggregatedTimeseriesGap
     */
    'numberOfDevices': number;
}
/**
 * 
 * @export
 * @interface AggregatedTimeseriesHandlingGap
 */
export interface AggregatedTimeseriesHandlingGap {
    /**
     * 
     * @type {string}
     * @memberof AggregatedTimeseriesHandlingGap
     */
    'bucket': string;
    /**
     * 
     * @type {number}
     * @memberof AggregatedTimeseriesHandlingGap
     */
    'avgGap': number;
}
/**
 * 
 * @export
 * @interface AggregatedUnstableSensor
 */
export interface AggregatedUnstableSensor {
    /**
     * 
     * @type {string}
     * @memberof AggregatedUnstableSensor
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof AggregatedUnstableSensor
     */
    'numberOfUnstableSensors': number;
    /**
     * 
     * @type {number}
     * @memberof AggregatedUnstableSensor
     */
    'numberOfDeadSensors': number;
}
/**
 * 
 * @export
 * @interface Alert
 */
export interface Alert {
    /**
     * 
     * @type {number}
     * @memberof Alert
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Alert
     */
    'typeId': number;
    /**
     * 
     * @type {boolean}
     * @memberof Alert
     */
    'isActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Alert
     */
    'isWatched': boolean;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'endTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof Alert
     */
    'lastValue': number;
    /**
     * 
     * @type {number}
     * @memberof Alert
     */
    'minNormalValue': number;
    /**
     * 
     * @type {number}
     * @memberof Alert
     */
    'maxNormalValue': number;
    /**
     * 
     * @type {number}
     * @memberof Alert
     */
    'projectSectionId': number;
    /**
     * 
     * @type {number}
     * @memberof Alert
     */
    'projectId': number;
    /**
     * 
     * @type {number}
     * @memberof Alert
     */
    'affectedProductCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'defaultTextForSharing'?: string;
    /**
     * 
     * @type {ProjectSection}
     * @memberof Alert
     */
    'projectSection'?: ProjectSection;
    /**
     * 
     * @type {Project}
     * @memberof Alert
     */
    'project'?: Project;
    /**
     * 
     * @type {Array<ProductProjectSection>}
     * @memberof Alert
     */
    'productsProjectSections'?: Array<ProductProjectSection>;
    /**
     * 
     * @type {Array<ProjectSectionSensor>}
     * @memberof Alert
     */
    'projectSectionsSensors'?: Array<ProjectSectionSensor>;
    /**
     * 
     * @type {AlertType}
     * @memberof Alert
     */
    'type'?: AlertType;
    /**
     * 
     * @type {Array<AlertProjectSectionSensor>}
     * @memberof Alert
     */
    'alertsProjectSectionsSensors'?: Array<AlertProjectSectionSensor>;
}
/**
 * 
 * @export
 * @interface AlertProductProjectSection
 */
export interface AlertProductProjectSection {
    /**
     * 
     * @type {number}
     * @memberof AlertProductProjectSection
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AlertProductProjectSection
     */
    'alertId': number;
    /**
     * 
     * @type {number}
     * @memberof AlertProductProjectSection
     */
    'productProjectSectionId': number;
    /**
     * 
     * @type {boolean}
     * @memberof AlertProductProjectSection
     */
    'isActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof AlertProductProjectSection
     */
    'maxDeviation': number;
    /**
     * 
     * @type {number}
     * @memberof AlertProductProjectSection
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertProductProjectSection
     */
    'lastCreated': string;
    /**
     * 
     * @type {string}
     * @memberof AlertProductProjectSection
     */
    'endTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertProductProjectSection
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AlertProductProjectSection
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AlertProductProjectSection
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<AlertProductProjectSectionHistory>}
     * @memberof AlertProductProjectSection
     */
    'history'?: Array<AlertProductProjectSectionHistory>;
    /**
     * 
     * @type {Alert}
     * @memberof AlertProductProjectSection
     */
    'alert'?: Alert;
    /**
     * 
     * @type {ProductProjectSection}
     * @memberof AlertProductProjectSection
     */
    'productProjectSection'?: ProductProjectSection;
}
/**
 * 
 * @export
 * @interface AlertProductProjectSectionHistory
 */
export interface AlertProductProjectSectionHistory {
    /**
     * 
     * @type {number}
     * @memberof AlertProductProjectSectionHistory
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AlertProductProjectSectionHistory
     */
    'alertProductProjectSectionId': number;
    /**
     * 
     * @type {number}
     * @memberof AlertProductProjectSectionHistory
     */
    'maxDeviation': number;
    /**
     * 
     * @type {string}
     * @memberof AlertProductProjectSectionHistory
     */
    'endTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertProductProjectSectionHistory
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AlertProductProjectSectionHistory
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AlertProductProjectSectionHistoryV2
 */
export interface AlertProductProjectSectionHistoryV2 {
    /**
     * 
     * @type {number}
     * @memberof AlertProductProjectSectionHistoryV2
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AlertProductProjectSectionHistoryV2
     */
    'alertProductProjectSectionId': number;
    /**
     * 
     * @type {number}
     * @memberof AlertProductProjectSectionHistoryV2
     */
    'maxDeviation': number;
    /**
     * 
     * @type {string}
     * @memberof AlertProductProjectSectionHistoryV2
     */
    'endTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertProductProjectSectionHistoryV2
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AlertProductProjectSectionHistoryV2
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AlertProductProjectSectionV2
 */
export interface AlertProductProjectSectionV2 {
    /**
     * 
     * @type {number}
     * @memberof AlertProductProjectSectionV2
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AlertProductProjectSectionV2
     */
    'alertId': number;
    /**
     * 
     * @type {number}
     * @memberof AlertProductProjectSectionV2
     */
    'productProjectSectionId': number;
    /**
     * 
     * @type {boolean}
     * @memberof AlertProductProjectSectionV2
     */
    'isActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof AlertProductProjectSectionV2
     */
    'maxDeviation': number;
    /**
     * 
     * @type {number}
     * @memberof AlertProductProjectSectionV2
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertProductProjectSectionV2
     */
    'lastCreated': string;
    /**
     * 
     * @type {string}
     * @memberof AlertProductProjectSectionV2
     */
    'endTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertProductProjectSectionV2
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof AlertProductProjectSectionV2
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AlertProductProjectSectionV2
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<AlertProductProjectSectionHistoryV2>}
     * @memberof AlertProductProjectSectionV2
     */
    'history'?: Array<AlertProductProjectSectionHistoryV2>;
    /**
     * 
     * @type {Alert}
     * @memberof AlertProductProjectSectionV2
     */
    'alert'?: Alert;
    /**
     * 
     * @type {ProductProjectSection}
     * @memberof AlertProductProjectSectionV2
     */
    'productProjectSection'?: ProductProjectSection;
}
/**
 * 
 * @export
 * @interface AlertProjectSectionSensor
 */
export interface AlertProjectSectionSensor {
    /**
     * 
     * @type {number}
     * @memberof AlertProjectSectionSensor
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AlertProjectSectionSensor
     */
    'alertId': number;
    /**
     * 
     * @type {number}
     * @memberof AlertProjectSectionSensor
     */
    'projectSectionSensorId': number;
    /**
     * 
     * @type {string}
     * @memberof AlertProjectSectionSensor
     */
    'endTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertProjectSectionSensor
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AlertProjectSectionSensor
     */
    'updatedAt': string;
    /**
     * 
     * @type {ProjectSectionSensor}
     * @memberof AlertProjectSectionSensor
     */
    'projectSectionSensor'?: ProjectSectionSensor;
}
/**
 * 
 * @export
 * @interface AlertSharing
 */
export interface AlertSharing {
    /**
     * 
     * @type {string}
     * @memberof AlertSharing
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AlertSharing
     */
    'recipients': Array<string>;
    /**
     * 
     * @type {Array<ProjectCreationV2Photo>}
     * @memberof AlertSharing
     */
    'photo'?: Array<ProjectCreationV2Photo>;
    /**
     * 
     * @type {ProjectCreationV2Photo}
     * @memberof AlertSharing
     */
    'graphics': ProjectCreationV2Photo;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof AlertSharing
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface AlertType
 */
export interface AlertType {
    /**
     * 
     * @type {number}
     * @memberof AlertType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AlertType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AlertType
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AlertType
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AlertTypeItemDashboardStatistics
 */
export interface AlertTypeItemDashboardStatistics {
    /**
     * 
     * @type {string}
     * @memberof AlertTypeItemDashboardStatistics
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AlertTypeItemDashboardStatistics
     */
    'alertsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof AlertTypeItemDashboardStatistics
     */
    'alertsNumberMin': number;
    /**
     * 
     * @type {number}
     * @memberof AlertTypeItemDashboardStatistics
     */
    'alertsNumberMax': number;
}
/**
 * 
 * @export
 * @interface AlertsCountByProject
 */
export interface AlertsCountByProject {
    /**
     * 
     * @type {number}
     * @memberof AlertsCountByProject
     */
    'projectId': number;
    /**
     * 
     * @type {number}
     * @memberof AlertsCountByProject
     */
    'activeAlertsCount': number;
}
/**
 * 
 * @export
 * @interface Architect
 */
export interface Architect {
    /**
     * 
     * @type {number}
     * @memberof Architect
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Architect
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof Architect
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof Architect
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Architect
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Architect
     */
    'contactPerson'?: string;
    /**
     * 
     * @type {string}
     * @memberof Architect
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Architect
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Architect
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AssignForeman
 */
export interface AssignForeman {
    /**
     * 
     * @type {number}
     * @memberof AssignForeman
     */
    'projectId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof AssignForeman
     */
    'userIds': Array<number>;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof AssignForeman
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface AssignForemanResponse
 */
export interface AssignForemanResponse {
    /**
     * 
     * @type {number}
     * @memberof AssignForemanResponse
     */
    'projectId': number;
    /**
     * 
     * @type {number}
     * @memberof AssignForemanResponse
     */
    'userId': number;
    /**
     * 
     * @type {boolean}
     * @memberof AssignForemanResponse
     */
    'result': boolean;
    /**
     * 
     * @type {string}
     * @memberof AssignForemanResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface AssignProductToTask
 */
export interface AssignProductToTask {
    /**
     * 
     * @type {number}
     * @memberof AssignProductToTask
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssignProductToTask
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssignProductToTask
     */
    'amount'?: string;
    /**
     * 
     * @type {Array<ProjectCreationV2Photo>}
     * @memberof AssignProductToTask
     */
    'photo'?: Array<ProjectCreationV2Photo>;
    /**
     * 
     * @type {Array<ProjectCreationV2Photo>}
     * @memberof AssignProductToTask
     */
    'video'?: Array<ProjectCreationV2Photo>;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof AssignProductToTask
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface AssignUserToProjects
 */
export interface AssignUserToProjects {
    /**
     * 
     * @type {Array<number>}
     * @memberof AssignUserToProjects
     */
    'projectIds': Array<number>;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof AssignUserToProjects
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface AssignWagnerProbePayload
 */
export interface AssignWagnerProbePayload {
    /**
     * 
     * @type {string}
     * @memberof AssignWagnerProbePayload
     */
    'probeId': string;
    /**
     * 
     * @type {string}
     * @memberof AssignWagnerProbePayload
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssignWagnerProbePayload
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof AssignWagnerProbePayload
     */
    'slabThickness': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssignWagnerProbePayload
     */
    'holeDepth': number | null;
    /**
     * 
     * @type {string}
     * @memberof AssignWagnerProbePayload
     */
    'readerId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssignWagnerProbePayload
     */
    'slabTemperatureF': number;
    /**
     * 
     * @type {number}
     * @memberof AssignWagnerProbePayload
     */
    'slabHumidity': number;
    /**
     * 
     * @type {number}
     * @memberof AssignWagnerProbePayload
     */
    'signalStrength'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignWagnerProbePayload
     */
    'batteryLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignWagnerProbePayload
     */
    'repeaterBatteryLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssignWagnerProbePayload
     */
    'deviceType'?: AssignWagnerProbePayloadDeviceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AssignWagnerProbePayload
     */
    'readingTime': string;
    /**
     * 
     * @type {string}
     * @memberof AssignWagnerProbePayload
     */
    'note'?: string;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof AssignWagnerProbePayload
     */
    'coordinates'?: EventCoordinates;
    /**
     * 
     * @type {Array<AssignWagnerProbePayloadPhoto>}
     * @memberof AssignWagnerProbePayload
     */
    'photo'?: Array<AssignWagnerProbePayloadPhoto>;
    /**
     * 
     * @type {string}
     * @memberof AssignWagnerProbePayload
     */
    'advPacket'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssignWagnerProbePayload
     */
    'rapidRHConfigurationTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AssignWagnerProbePayload
     */
    'dataGrabberSignalStrength'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AssignWagnerProbePayload
     */
    'repeaterSignalStrength'?: string | null;
}

export const AssignWagnerProbePayloadDeviceTypeEnum = {
    MiniGrabber: 'mini_grabber',
    DataGrabber: 'data_grabber',
    Repeater: 'repeater',
    TotalReader: 'total_reader'
} as const;

export type AssignWagnerProbePayloadDeviceTypeEnum = typeof AssignWagnerProbePayloadDeviceTypeEnum[keyof typeof AssignWagnerProbePayloadDeviceTypeEnum];

/**
 * 
 * @export
 * @interface AssignWagnerProbePayloadPhoto
 */
export interface AssignWagnerProbePayloadPhoto {
    /**
     * 
     * @type {AttachmentParams}
     * @memberof AssignWagnerProbePayloadPhoto
     */
    'thumbnail'?: AttachmentParams;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof AssignWagnerProbePayloadPhoto
     */
    'original'?: AttachmentParams;
}
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'link'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'bucket': string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'fileName': string;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'productProjectSectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'taskId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'sensorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'slabConditionHistoryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'eventId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'alertId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'manufacturerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'contractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'manufacturerAnnouncementPostId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'productRequestId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'projectDocumentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'wagnerDeviceFirmwareId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'mimeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'originalFileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'authorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'hashId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'progress'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'totalParts'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Attachment
     */
    'uploadedParts'?: Array<number>;
    /**
     * 
     * @type {Task}
     * @memberof Attachment
     */
    'task'?: Task;
    /**
     * 
     * @type {Event}
     * @memberof Attachment
     */
    'event'?: Event;
    /**
     * 
     * @type {Document}
     * @memberof Attachment
     */
    'projectDocument'?: Document;
    /**
     * 
     * @type {Project}
     * @memberof Attachment
     */
    'project'?: Project;
    /**
     * 
     * @type {User}
     * @memberof Attachment
     */
    'author'?: User;
}
/**
 * 
 * @export
 * @interface AttachmentParams
 */
export interface AttachmentParams {
    /**
     * 
     * @type {string}
     * @memberof AttachmentParams
     */
    'bucket': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentParams
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface Battery
 */
export interface Battery {
    /**
     * 
     * @type {string}
     * @memberof Battery
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Battery
     */
    'uniqueId': string;
    /**
     * 
     * @type {string}
     * @memberof Battery
     */
    'sensorType': string;
    /**
     * 
     * @type {string}
     * @memberof Battery
     */
    'time': string;
    /**
     * 
     * @type {number}
     * @memberof Battery
     */
    'battery': number;
    /**
     * 
     * @type {number}
     * @memberof Battery
     */
    'contractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Battery
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Battery
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Battery
     */
    'isInRange'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Battery
     */
    'isDelayed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Battery
     */
    'isCharging'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Battery
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Battery
     */
    'updatedAt': string;
    /**
     * 
     * @type {Contractor}
     * @memberof Battery
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Project}
     * @memberof Battery
     */
    'project'?: Project;
    /**
     * 
     * @type {ProjectSection}
     * @memberof Battery
     */
    'projectSection'?: ProjectSection;
}
/**
 * 
 * @export
 * @interface BuildingType
 */
export interface BuildingType {
    /**
     * 
     * @type {number}
     * @memberof BuildingType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BuildingType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BuildingType
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingType
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingType
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface BuildingTypeList
 */
export interface BuildingTypeList {
    /**
     * 
     * @type {number}
     * @memberof BuildingTypeList
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BuildingTypeList
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BuildingTypeList
     */
    'displayName'?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingTypeList
     */
    'projectsCount': number;
    /**
     * 
     * @type {string}
     * @memberof BuildingTypeList
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BuildingTypeList
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface BulkAssigningProductResponse
 */
export interface BulkAssigningProductResponse {
    /**
     * 
     * @type {number}
     * @memberof BulkAssigningProductResponse
     */
    'projectId': number;
    /**
     * 
     * @type {number}
     * @memberof BulkAssigningProductResponse
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkAssigningProductResponse
     */
    'productId': number;
    /**
     * 
     * @type {boolean}
     * @memberof BulkAssigningProductResponse
     */
    'result': boolean;
    /**
     * 
     * @type {string}
     * @memberof BulkAssigningProductResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface BulkProductAssignPayload
 */
export interface BulkProductAssignPayload {
    /**
     * 
     * @type {Array<number>}
     * @memberof BulkProductAssignPayload
     */
    'productIds': Array<number>;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof BulkProductAssignPayload
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface CheckNWFARegionResponse
 */
export interface CheckNWFARegionResponse {
    /**
     * 
     * @type {number}
     * @memberof CheckNWFARegionResponse
     */
    'nwfaMoistureMapRegionId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CheckNWFARegionResponse
     */
    'displayNWFARegionsDialog': boolean;
    /**
     * 
     * @type {Array<NWFAMoistureMapRegion>}
     * @memberof CheckNWFARegionResponse
     */
    'nwfaRegions': Array<NWFAMoistureMapRegion>;
    /**
     * 
     * @type {string}
     * @memberof CheckNWFARegionResponse
     */
    'state'?: string;
}
/**
 * 
 * @export
 * @interface CombinedReportComponentUpdatePayload
 */
export interface CombinedReportComponentUpdatePayload {
    /**
     * 
     * @type {string}
     * @memberof CombinedReportComponentUpdatePayload
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedReportComponentUpdatePayload
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CombinedReportComponentUpdatePayload
     */
    'isVisible'?: boolean;
}
/**
 * 
 * @export
 * @interface CombinedReportIncludedEvent
 */
export interface CombinedReportIncludedEvent {
    /**
     * 
     * @type {number}
     * @memberof CombinedReportIncludedEvent
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CombinedReportIncludedEvent
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedReportIncludedEvent
     */
    'displayName'?: string;
    /**
     * 
     * @type {number}
     * @memberof CombinedReportIncludedEvent
     */
    'eventTypeId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CombinedReportIncludedEvent
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CombinedReportIncludedEvent
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedReportIncludedEvent
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface CombinedReportIncludedEventUpdatePayload
 */
export interface CombinedReportIncludedEventUpdatePayload {
    /**
     * 
     * @type {Array<CombinedReportIncludedEventUpdatePayloadIncludedEvents>}
     * @memberof CombinedReportIncludedEventUpdatePayload
     */
    'includedEvents': Array<CombinedReportIncludedEventUpdatePayloadIncludedEvents>;
}
/**
 * 
 * @export
 * @interface CombinedReportIncludedEventUpdatePayloadIncludedEvents
 */
export interface CombinedReportIncludedEventUpdatePayloadIncludedEvents {
    /**
     * 
     * @type {string}
     * @memberof CombinedReportIncludedEventUpdatePayloadIncludedEvents
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CombinedReportIncludedEventUpdatePayloadIncludedEvents
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface ConfigurationParamUpdate
 */
export interface ConfigurationParamUpdate {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationParamUpdate
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface ConfigurationParamUpdateBulkItem
 */
export interface ConfigurationParamUpdateBulkItem {
    /**
     * 
     * @type {number}
     * @memberof ConfigurationParamUpdateBulkItem
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationParamUpdateBulkItem
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface ConfigurationParamUpdateBulkResponseItem
 */
export interface ConfigurationParamUpdateBulkResponseItem {
    /**
     * 
     * @type {ConfigurationParameter}
     * @memberof ConfigurationParamUpdateBulkResponseItem
     */
    'result': ConfigurationParameter;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationParamUpdateBulkResponseItem
     */
    'error': string | null;
}
/**
 * 
 * @export
 * @interface ConfigurationParameter
 */
export interface ConfigurationParameter {
    /**
     * 
     * @type {number}
     * @memberof ConfigurationParameter
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationParameter
     */
    'type': ConfigurationParameterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationParameter
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationParameter
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationParameter
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationParameter
     */
    'updatedAt'?: string;
}

export const ConfigurationParameterTypeEnum = {
    TermsOfUseLink: 'terms_of_use_link',
    PrivacyPolicyLink: 'privacy_policy_link',
    FloorcloudPhone: 'floorcloud_phone',
    FloorcloudEmail: 'floorcloud_email',
    MobileSessionLimit: 'mobile_session_limit',
    FloorcloudHowToLink: 'floorcloud_how_to_link',
    NwfaPhone: 'nwfa_phone',
    NwfaEmail: 'nwfa_email',
    ProductRequestedEmail: 'product_requested_email',
    RapidRhTimeValidationThresholdHours: 'rapid_rh_time_validation_threshold_hours',
    GangboxEmail: 'gangbox_email',
    LatestAvailableRelease: 'latest_available_release',
    WagnerDeviceFirmwareUpdateListeningTimeout: 'wagner_device_firmware_update_listening_timeout',
    WagnerDeviceFirmwareUpdateSignalStrengthThreshold: 'wagner_device_firmware_update_signal_strength_threshold',
    PrefreezingValueLimit: 'prefreezing_value_limit',
    FreezingValueLimit: 'freezing_value_limit',
    FreezingThreshold: 'freezing_threshold',
    DocumentUploadSizeLimit: 'document_upload_size_limit'
} as const;

export type ConfigurationParameterTypeEnum = typeof ConfigurationParameterTypeEnum[keyof typeof ConfigurationParameterTypeEnum];

/**
 * 
 * @export
 * @interface ConnectAttachmentsToEntityPayload
 */
export interface ConnectAttachmentsToEntityPayload {
    /**
     * 
     * @type {string}
     * @memberof ConnectAttachmentsToEntityPayload
     */
    'entity': ConnectAttachmentsToEntityPayloadEntityEnum;
    /**
     * 
     * @type {number}
     * @memberof ConnectAttachmentsToEntityPayload
     */
    'id': number;
    /**
     * 
     * @type {Array<ConnectAttachmentsToEntityPayloadAttachments>}
     * @memberof ConnectAttachmentsToEntityPayload
     */
    'attachments': Array<ConnectAttachmentsToEntityPayloadAttachments>;
}

export const ConnectAttachmentsToEntityPayloadEntityEnum = {
    Event: 'event',
    Task: 'task',
    Document: 'document'
} as const;

export type ConnectAttachmentsToEntityPayloadEntityEnum = typeof ConnectAttachmentsToEntityPayloadEntityEnum[keyof typeof ConnectAttachmentsToEntityPayloadEntityEnum];

/**
 * 
 * @export
 * @interface ConnectAttachmentsToEntityPayloadAttachments
 */
export interface ConnectAttachmentsToEntityPayloadAttachments {
    /**
     * 
     * @type {string}
     * @memberof ConnectAttachmentsToEntityPayloadAttachments
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectAttachmentsToEntityPayloadAttachments
     */
    'checksum': string;
}
/**
 * 
 * @export
 * @interface Contractor
 */
export interface Contractor {
    /**
     * 
     * @type {number}
     * @memberof Contractor
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'address2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'phone'?: string;
    /**
     * 
     * @type {number}
     * @memberof Contractor
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Contractor
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Contractor
     */
    'allSensorsAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Contractor
     */
    'usingSensorsAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Contractor
     */
    'allProjectsAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Contractor
     */
    'activeProjectsAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Contractor
     */
    'usersAmount'?: number;
    /**
     * 
     * @type {Array<User>}
     * @memberof Contractor
     */
    'users'?: Array<User>;
    /**
     * 
     * @type {Array<Label>}
     * @memberof Contractor
     */
    'labels'?: Array<Label>;
    /**
     * 
     * @type {Array<ContractorSensor>}
     * @memberof Contractor
     */
    'contractorsSensors'?: Array<ContractorSensor>;
    /**
     * 
     * @type {boolean}
     * @memberof Contractor
     */
    'isAgreementAccepted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'activationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'activatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'deactivationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'deactivatedBy'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Contractor
     */
    'isActiveTrial': boolean;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'trialStartedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'trialEndedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Contractor
     */
    'isActivePaidPlan': boolean;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'paidPlanStartedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'paidPlanEndedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'photo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'photoUpdatedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Contractor
     */
    'isEnabledAppBranding'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contractor
     */
    'isEnabledWagnerDeviceFirmwareUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contractor
     */
    'isActiveDashboard'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contractor
     */
    'isRoot': boolean;
    /**
     * 
     * @type {number}
     * @memberof Contractor
     */
    'rootContractorId'?: number;
    /**
     * 
     * @type {Contractor}
     * @memberof Contractor
     */
    'rootContractor'?: Contractor;
    /**
     * 
     * @type {Array<Contractor>}
     * @memberof Contractor
     */
    'branches'?: Array<Contractor>;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Contractor
     */
    'attachments'?: Array<Attachment>;
    /**
     * 
     * @type {Array<ContractorActivationHistory>}
     * @memberof Contractor
     */
    'activationHistory'?: Array<ContractorActivationHistory>;
    /**
     * 
     * @type {Array<ContractorToken>}
     * @memberof Contractor
     */
    'tokens'?: Array<ContractorToken>;
}
/**
 * 
 * @export
 * @interface ContractorActivationHistory
 */
export interface ContractorActivationHistory {
    /**
     * 
     * @type {number}
     * @memberof ContractorActivationHistory
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorActivationHistory
     */
    'contractorId': number;
    /**
     * 
     * @type {string}
     * @memberof ContractorActivationHistory
     */
    'status': ContractorActivationHistoryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ContractorActivationHistory
     */
    'authorName': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorActivationHistory
     */
    'authorPhone': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorActivationHistory
     */
    'author': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorActivationHistory
     */
    'datetime': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorActivationHistory
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractorActivationHistory
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {Contractor}
     * @memberof ContractorActivationHistory
     */
    'contractor'?: Contractor;
}

export const ContractorActivationHistoryStatusEnum = {
    Activated: 'activated',
    Deactivated: 'deactivated'
} as const;

export type ContractorActivationHistoryStatusEnum = typeof ContractorActivationHistoryStatusEnum[keyof typeof ContractorActivationHistoryStatusEnum];

/**
 * 
 * @export
 * @interface ContractorBranchStatsResponse
 */
export interface ContractorBranchStatsResponse {
    /**
     * 
     * @type {number}
     * @memberof ContractorBranchStatsResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ContractorBranchStatsResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorBranchStatsResponse
     */
    'photo': string | null;
    /**
     * 
     * @type {number}
     * @memberof ContractorBranchStatsResponse
     */
    'activeUsersCount': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorBranchStatsResponse
     */
    'activeProjectsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorBranchStatsResponse
     */
    'activeProjectsWithAlertsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorBranchStatsResponse
     */
    'activeAlertsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorBranchStatsResponse
     */
    'connectedToProjectsSensorsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorBranchStatsResponse
     */
    'assignedToContractorSensorsCount': number;
}
/**
 * 
 * @export
 * @interface ContractorCreation
 */
export interface ContractorCreation {
    /**
     * 
     * @type {string}
     * @memberof ContractorCreation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorCreation
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractorCreation
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractorCreation
     */
    'address1'?: string;
    /**
     * 
     * @type {ContractorCreationUser}
     * @memberof ContractorCreation
     */
    'user': ContractorCreationUser;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ContractorCreation
     */
    'photo'?: AttachmentParams;
}
/**
 * 
 * @export
 * @interface ContractorCreationUser
 */
export interface ContractorCreationUser {
    /**
     * 
     * @type {string}
     * @memberof ContractorCreationUser
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorCreationUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorCreationUser
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface ContractorCreationV2
 */
export interface ContractorCreationV2 {
    /**
     * 
     * @type {string}
     * @memberof ContractorCreationV2
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorCreationV2
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractorCreationV2
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractorCreationV2
     */
    'address1'?: string;
    /**
     * 
     * @type {ContractorCreationUser}
     * @memberof ContractorCreationV2
     */
    'user': ContractorCreationUser;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ContractorCreationV2
     */
    'photo'?: AttachmentParams;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorCreationV2
     */
    'isRoot': boolean;
    /**
     * 
     * @type {number}
     * @memberof ContractorCreationV2
     */
    'rootContractorId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorCreationV2
     */
    'isEnabledAppBranding'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorCreationV2
     */
    'isActiveDashboard'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorCreationV2
     */
    'isEnabledWagnerDeviceFirmwareUpdate'?: boolean;
}
/**
 * 
 * @export
 * @interface ContractorEditing
 */
export interface ContractorEditing {
    /**
     * 
     * @type {string}
     * @memberof ContractorEditing
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractorEditing
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorEditing
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorEditing
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorEditing
     */
    'trialEndedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorEditing
     */
    'isActivePaidPlan'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContractorEditing
     */
    'comment'?: string | null;
    /**
     * 
     * @type {Array<Label>}
     * @memberof ContractorEditing
     */
    'labels'?: Array<Label>;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ContractorEditing
     */
    'photo'?: AttachmentParams;
}
/**
 * 
 * @export
 * @interface ContractorEditingMobile
 */
export interface ContractorEditingMobile {
    /**
     * 
     * @type {string}
     * @memberof ContractorEditingMobile
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractorEditingMobile
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorEditingMobile
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorEditingMobile
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorEditingMobile
     */
    'address2'?: string | null;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ContractorEditingMobile
     */
    'photo'?: AttachmentParams;
}
/**
 * 
 * @export
 * @interface ContractorEditingV2
 */
export interface ContractorEditingV2 {
    /**
     * 
     * @type {string}
     * @memberof ContractorEditingV2
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractorEditingV2
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorEditingV2
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorEditingV2
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorEditingV2
     */
    'trialEndedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorEditingV2
     */
    'isActivePaidPlan'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContractorEditingV2
     */
    'comment'?: string | null;
    /**
     * 
     * @type {Array<Label>}
     * @memberof ContractorEditingV2
     */
    'labels'?: Array<Label>;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ContractorEditingV2
     */
    'photo'?: AttachmentParams;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorEditingV2
     */
    'isRoot'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContractorEditingV2
     */
    'rootContractorId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorEditingV2
     */
    'isEnabledAppBranding'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorEditingV2
     */
    'isActiveDashboard'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorEditingV2
     */
    'isEnabledWagnerDeviceFirmwareUpdate'?: boolean;
}
/**
 * 
 * @export
 * @interface ContractorIntegrationToken
 */
export interface ContractorIntegrationToken {
    /**
     * 
     * @type {number}
     * @memberof ContractorIntegrationToken
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorIntegrationToken
     */
    'contractorId': number;
    /**
     * 
     * @type {string}
     * @memberof ContractorIntegrationToken
     */
    'obfuscatedToken': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorIntegrationToken
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorIntegrationToken
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractorIntegrationToken
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {Contractor}
     * @memberof ContractorIntegrationToken
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Array<ContractorIntegrationTokenHistory>}
     * @memberof ContractorIntegrationToken
     */
    'history'?: Array<ContractorIntegrationTokenHistory>;
}
/**
 * 
 * @export
 * @interface ContractorIntegrationTokenHistory
 */
export interface ContractorIntegrationTokenHistory {
    /**
     * 
     * @type {number}
     * @memberof ContractorIntegrationTokenHistory
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorIntegrationTokenHistory
     */
    'contractorTokenId': number;
    /**
     * 
     * @type {string}
     * @memberof ContractorIntegrationTokenHistory
     */
    'action': string;
    /**
     * 
     * @type {number}
     * @memberof ContractorIntegrationTokenHistory
     */
    'authorId': number;
    /**
     * 
     * @type {User}
     * @memberof ContractorIntegrationTokenHistory
     */
    'author'?: User;
    /**
     * 
     * @type {ContractorIntegrationToken}
     * @memberof ContractorIntegrationTokenHistory
     */
    'contractorToken'?: ContractorIntegrationToken;
    /**
     * 
     * @type {string}
     * @memberof ContractorIntegrationTokenHistory
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractorIntegrationTokenHistory
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ContractorSensor
 */
export interface ContractorSensor {
    /**
     * 
     * @type {number}
     * @memberof ContractorSensor
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorSensor
     */
    'contractorId': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorSensor
     */
    'sensorId': number;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorSensor
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof ContractorSensor
     */
    'disconnectTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractorSensor
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorSensor
     */
    'updatedAt': string;
    /**
     * 
     * @type {Sensor}
     * @memberof ContractorSensor
     */
    'sensor'?: Sensor;
    /**
     * 
     * @type {Contractor}
     * @memberof ContractorSensor
     */
    'contractor'?: Contractor;
}
/**
 * 
 * @export
 * @interface ContractorStatsResponse
 */
export interface ContractorStatsResponse {
    /**
     * 
     * @type {string}
     * @memberof ContractorStatsResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ContractorStatsResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorStatsResponse
     */
    'activeUsersCount': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorStatsResponse
     */
    'activeProjectsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorStatsResponse
     */
    'activeProjectsWithAlertsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorStatsResponse
     */
    'activeAlertsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorStatsResponse
     */
    'connectedToProjectsSensorsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorStatsResponse
     */
    'assignedToContractorSensorsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorStatsResponse
     */
    'connectedRapidRhProbesCount': number;
}
/**
 * 
 * @export
 * @interface ContractorToken
 */
export interface ContractorToken {
    /**
     * 
     * @type {number}
     * @memberof ContractorToken
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorToken
     */
    'contractorId': number;
    /**
     * 
     * @type {string}
     * @memberof ContractorToken
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorToken
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorToken
     */
    'obfuscatedToken': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorToken
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractorToken
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {Contractor}
     * @memberof ContractorToken
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Array<ContractorTokenHistory>}
     * @memberof ContractorToken
     */
    'history'?: Array<ContractorTokenHistory>;
}
/**
 * 
 * @export
 * @interface ContractorTokenHistory
 */
export interface ContractorTokenHistory {
    /**
     * 
     * @type {number}
     * @memberof ContractorTokenHistory
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ContractorTokenHistory
     */
    'contractorTokenId': number;
    /**
     * 
     * @type {string}
     * @memberof ContractorTokenHistory
     */
    'action': string;
    /**
     * 
     * @type {number}
     * @memberof ContractorTokenHistory
     */
    'authorId': number;
    /**
     * 
     * @type {User}
     * @memberof ContractorTokenHistory
     */
    'author'?: User;
    /**
     * 
     * @type {ContractorToken}
     * @memberof ContractorTokenHistory
     */
    'contractorToken'?: ContractorToken;
    /**
     * 
     * @type {string}
     * @memberof ContractorTokenHistory
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractorTokenHistory
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface CreateBuildingTypePayload
 */
export interface CreateBuildingTypePayload {
    /**
     * 
     * @type {string}
     * @memberof CreateBuildingTypePayload
     */
    'displayName': string;
}
/**
 * 
 * @export
 * @interface CreateDocumentPayload
 */
export interface CreateDocumentPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentPayload
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentPayload
     */
    'description'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateDocumentPayload
     */
    'attachmentIds'?: Array<number>;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof CreateDocumentPayload
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface CreateFirmwarePayload
 */
export interface CreateFirmwarePayload {
    /**
     * 
     * @type {string}
     * @memberof CreateFirmwarePayload
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFirmwarePayload
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFirmwarePayload
     */
    'deviceType': CreateFirmwarePayloadDeviceTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateFirmwarePayload
     */
    'attachmentIds': Array<number>;
}

export const CreateFirmwarePayloadDeviceTypeEnum = {
    DataGrabber: 'data_grabber',
    RangeExtender: 'range_extender'
} as const;

export type CreateFirmwarePayloadDeviceTypeEnum = typeof CreateFirmwarePayloadDeviceTypeEnum[keyof typeof CreateFirmwarePayloadDeviceTypeEnum];

/**
 * 
 * @export
 * @interface CreateManufacturerAnnouncementCategoryPayload
 */
export interface CreateManufacturerAnnouncementCategoryPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerAnnouncementCategoryPayload
     */
    'displayName': string;
}
/**
 * 
 * @export
 * @interface CreateManufacturerAnnouncementPayload
 */
export interface CreateManufacturerAnnouncementPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerAnnouncementPayload
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateManufacturerAnnouncementPayload
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerAnnouncementPayload
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateManufacturerAnnouncementPayload
     */
    'manufacturerId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateManufacturerAnnouncementPayload
     */
    'isVisibleContactMeButton'?: boolean;
    /**
     * 
     * @type {Array<CreateManufacturerAnnouncementPostPayload>}
     * @memberof CreateManufacturerAnnouncementPayload
     */
    'posts'?: Array<CreateManufacturerAnnouncementPostPayload>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateManufacturerAnnouncementPayload
     */
    'categoryIds': Array<number>;
}
/**
 * 
 * @export
 * @interface CreateManufacturerAnnouncementPostPayload
 */
export interface CreateManufacturerAnnouncementPostPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerAnnouncementPostPayload
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerAnnouncementPostPayload
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerAnnouncementPostPayload
     */
    'videoLink'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateManufacturerAnnouncementPostPayload
     */
    'manufacturerAnnouncementId'?: number;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof CreateManufacturerAnnouncementPostPayload
     */
    'image'?: AttachmentParams;
}
/**
 * 
 * @export
 * @interface CreateManufacturerAnnouncementTypePayload
 */
export interface CreateManufacturerAnnouncementTypePayload {
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerAnnouncementTypePayload
     */
    'displayName': string;
}
/**
 * 
 * @export
 * @interface CreateProjectTypePayload
 */
export interface CreateProjectTypePayload {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectTypePayload
     */
    'displayName': string;
}
/**
 * 
 * @export
 * @interface CreateUserPayload
 */
export interface CreateUserPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateUserPayload
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserPayload
     */
    'phone': string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserPayload
     */
    'roleId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateUserPayload
     */
    'contractorId': number;
}
/**
 * 
 * @export
 * @interface CreateUserResponse
 */
export interface CreateUserResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserResponse
     */
    'hasClone': boolean;
    /**
     * 
     * @type {User}
     * @memberof CreateUserResponse
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof CreateUserResponse
     */
    'description': string | null;
}
/**
 * 
 * @export
 * @interface CreateUserResponseV2
 */
export interface CreateUserResponseV2 {
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserResponseV2
     */
    'hasClone': boolean;
    /**
     * 
     * @type {User}
     * @memberof CreateUserResponseV2
     */
    'user': User;
}
/**
 * 
 * @export
 * @interface CurrentAlertData
 */
export interface CurrentAlertData {
    /**
     * 
     * @type {string}
     * @memberof CurrentAlertData
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof CurrentAlertData
     */
    'lastValue': number;
    /**
     * 
     * @type {number}
     * @memberof CurrentAlertData
     */
    'minNormalValue': number;
    /**
     * 
     * @type {number}
     * @memberof CurrentAlertData
     */
    'maxNormalValue': number;
    /**
     * 
     * @type {string}
     * @memberof CurrentAlertData
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAlertData
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAlertData
     */
    'lastCreated': string;
    /**
     * 
     * @type {number}
     * @memberof CurrentAlertData
     */
    'alertId': number;
    /**
     * 
     * @type {string}
     * @memberof CurrentAlertData
     */
    'sensorUniqueId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CurrentAlertData
     */
    'sensorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrentAlertData
     */
    'duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrentAlertData
     */
    'actualDuration'?: number;
    /**
     * 
     * @type {string}
     * @memberof CurrentAlertData
     */
    'shortDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof CurrentAlertData
     */
    'projectSectionSensorId'?: number;
}
/**
 * 
 * @export
 * @interface CustomSingleEvent
 */
export interface CustomSingleEvent {
    /**
     * 
     * @type {boolean}
     * @memberof CustomSingleEvent
     */
    'isDocumentRemoved'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEvent
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEvent
     */
    'appDevice'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEvent
     */
    'appVersion'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEvent
     */
    'distance'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomSingleEvent
     */
    'notifyAll'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEvent
     */
    'sensorUniqueId'?: string;
    /**
     * 
     * @type {Array<CustomSingleEventSensorUniqueIds>}
     * @memberof CustomSingleEvent
     */
    'sensorUniqueIds'?: Array<CustomSingleEventSensorUniqueIds>;
    /**
     * 
     * @type {Array<CustomSingleEventDifference>}
     * @memberof CustomSingleEvent
     */
    'difference'?: Array<CustomSingleEventDifference>;
    /**
     * 
     * @type {Array<CustomSingleEventSlabConditionsValues>}
     * @memberof CustomSingleEvent
     */
    'slabConditionsValues'?: Array<CustomSingleEventSlabConditionsValues>;
    /**
     * 
     * @type {EventCardRenderingData}
     * @memberof CustomSingleEvent
     */
    'eventCardRenderingData': EventCardRenderingData;
    /**
     * 
     * @type {EventType}
     * @memberof CustomSingleEvent
     */
    'type'?: EventType;
    /**
     * 
     * @type {Project}
     * @memberof CustomSingleEvent
     */
    'project'?: Project;
    /**
     * 
     * @type {ProjectSection}
     * @memberof CustomSingleEvent
     */
    'projectSection'?: ProjectSection;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CustomSingleEvent
     */
    'eventData'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEvent
     */
    'eventDatetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEvent
     */
    'eventName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEvent
     */
    'eventType'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEvent
     */
    'platform'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEvent
     */
    'nwfaMoistureMapRegionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEvent
     */
    'authorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEvent
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEvent
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEvent
     */
    'projectSectionSensorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEvent
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEvent
     */
    'sensorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEvent
     */
    'taskId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEvent
     */
    'alertId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEvent
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEvent
     */
    'productProjectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEvent
     */
    'productProjectSectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEvent
     */
    'wagnerProbeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEvent
     */
    'wagnerProbeDeviceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEvent
     */
    'projectDocumentId'?: number;
    /**
     * 
     * @type {Array<CustomSingleEventSections>}
     * @memberof CustomSingleEvent
     */
    'sections': Array<CustomSingleEventSections>;
    /**
     * 
     * @type {CustomSingleEventMeasurements}
     * @memberof CustomSingleEvent
     */
    'measurements'?: CustomSingleEventMeasurements;
    /**
     * 
     * @type {CustomSingleEventMessages}
     * @memberof CustomSingleEvent
     */
    'messages'?: CustomSingleEventMessages;
    /**
     * 
     * @type {CustomSingleEventPrecondition}
     * @memberof CustomSingleEvent
     */
    'precondition'?: CustomSingleEventPrecondition;
    /**
     * 
     * @type {CustomSingleEventRequirements}
     * @memberof CustomSingleEvent
     */
    'requirements'?: CustomSingleEventRequirements;
    /**
     * 
     * @type {boolean}
     * @memberof CustomSingleEvent
     */
    'outOfSpecProductUsed'?: boolean;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof CustomSingleEvent
     */
    'attachments'?: Array<Attachment>;
}
/**
 * 
 * @export
 * @interface CustomSingleEventDifference
 */
export interface CustomSingleEventDifference {
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventDifference
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventDifference
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventDifference
     */
    'prevValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventDifference
     */
    'nextValue'?: string;
}
/**
 * 
 * @export
 * @interface CustomSingleEventMeasurements
 */
export interface CustomSingleEventMeasurements {
    /**
     * 
     * @type {CustomSingleEventMeasurementsEquilibriumMc}
     * @memberof CustomSingleEventMeasurements
     */
    'equilibriumMc': CustomSingleEventMeasurementsEquilibriumMc;
    /**
     * 
     * @type {CustomSingleEventMeasurementsMeasuredMc}
     * @memberof CustomSingleEventMeasurements
     */
    'measuredMc': CustomSingleEventMeasurementsMeasuredMc;
    /**
     * 
     * @type {CustomSingleEventMeasurementsMeasuredMc}
     * @memberof CustomSingleEventMeasurements
     */
    'subfloorMc': CustomSingleEventMeasurementsMeasuredMc;
}
/**
 * 
 * @export
 * @interface CustomSingleEventMeasurementsEquilibriumMc
 */
export interface CustomSingleEventMeasurementsEquilibriumMc {
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventMeasurementsEquilibriumMc
     */
    'isOutOfSpec'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventMeasurementsEquilibriumMc
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface CustomSingleEventMeasurementsMeasuredMc
 */
export interface CustomSingleEventMeasurementsMeasuredMc {
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventMeasurementsMeasuredMc
     */
    'isOutOfSpec'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventMeasurementsMeasuredMc
     */
    'value'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CustomSingleEventMeasurementsMeasuredMc
     */
    'userValues'?: Array<number>;
}
/**
 * 
 * @export
 * @interface CustomSingleEventMessages
 */
export interface CustomSingleEventMessages {
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomSingleEventMessages
     */
    'equilibriumMc': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomSingleEventMessages
     */
    'measuredMc': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomSingleEventMessages
     */
    'subfloorMc': Array<string>;
}
/**
 * 
 * @export
 * @interface CustomSingleEventPrecondition
 */
export interface CustomSingleEventPrecondition {
    /**
     * 
     * @type {CustomSingleEventPreconditionAmbientConditionValues}
     * @memberof CustomSingleEventPrecondition
     */
    'ambientConditionValues': CustomSingleEventPreconditionAmbientConditionValues;
    /**
     * 
     * @type {boolean}
     * @memberof CustomSingleEventPrecondition
     */
    'boardWidthLessThan3'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventPrecondition
     */
    'subfloorType'?: string;
}
/**
 * 
 * @export
 * @interface CustomSingleEventPreconditionAmbientConditionValues
 */
export interface CustomSingleEventPreconditionAmbientConditionValues {
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventPreconditionAmbientConditionValues
     */
    'humidity': number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventPreconditionAmbientConditionValues
     */
    'temperature': number;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventPreconditionAmbientConditionValues
     */
    'sensorUniqueId'?: string;
}
/**
 * 
 * @export
 * @interface CustomSingleEventRequirements
 */
export interface CustomSingleEventRequirements {
    /**
     * 
     * @type {CustomSingleEventRequirementsManufacturerSpecs}
     * @memberof CustomSingleEventRequirements
     */
    'manufacturerSpecs': CustomSingleEventRequirementsManufacturerSpecs;
    /**
     * 
     * @type {CustomSingleEventRequirementsManufacturerSpecsHumidityRange}
     * @memberof CustomSingleEventRequirements
     */
    'nwfaTargetMcRange': CustomSingleEventRequirementsManufacturerSpecsHumidityRange;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventRequirements
     */
    'subfloorRequiredMc'?: string;
}
/**
 * 
 * @export
 * @interface CustomSingleEventRequirementsManufacturerSpecs
 */
export interface CustomSingleEventRequirementsManufacturerSpecs {
    /**
     * 
     * @type {CustomSingleEventRequirementsManufacturerSpecsHumidityRange}
     * @memberof CustomSingleEventRequirementsManufacturerSpecs
     */
    'humidityRange'?: CustomSingleEventRequirementsManufacturerSpecsHumidityRange;
    /**
     * 
     * @type {CustomSingleEventRequirementsManufacturerSpecsHumidityRange}
     * @memberof CustomSingleEventRequirementsManufacturerSpecs
     */
    'temperatureRange'?: CustomSingleEventRequirementsManufacturerSpecsHumidityRange;
    /**
     * 
     * @type {CustomSingleEventRequirementsManufacturerSpecsHumidityRange}
     * @memberof CustomSingleEventRequirementsManufacturerSpecs
     */
    'targetMc'?: CustomSingleEventRequirementsManufacturerSpecsHumidityRange;
}
/**
 * 
 * @export
 * @interface CustomSingleEventRequirementsManufacturerSpecsHumidityRange
 */
export interface CustomSingleEventRequirementsManufacturerSpecsHumidityRange {
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventRequirementsManufacturerSpecsHumidityRange
     */
    'max': number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventRequirementsManufacturerSpecsHumidityRange
     */
    'min': number;
}
/**
 * 
 * @export
 * @interface CustomSingleEventSections
 */
export interface CustomSingleEventSections {
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventSections
     */
    'sectionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventSections
     */
    'sectionId'?: number;
    /**
     * 
     * @type {Array<EventJSONDataSpecificLocations>}
     * @memberof CustomSingleEventSections
     */
    'specificLocations'?: Array<EventJSONDataSpecificLocations>;
    /**
     * 
     * @type {boolean}
     * @memberof CustomSingleEventSections
     */
    'current'?: boolean;
    /**
     * 
     * @type {Array<CustomSingleEventSensors>}
     * @memberof CustomSingleEventSections
     */
    'sensors'?: Array<CustomSingleEventSensors>;
}
/**
 * 
 * @export
 * @interface CustomSingleEventSensorUniqueIds
 */
export interface CustomSingleEventSensorUniqueIds {
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventSensorUniqueIds
     */
    'uniqueId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventSensorUniqueIds
     */
    'deviceId'?: string;
}
/**
 * 
 * @export
 * @interface CustomSingleEventSensors
 */
export interface CustomSingleEventSensors {
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventSensors
     */
    'sensorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventSensors
     */
    'sensorUniqueId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventSensors
     */
    'sectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventSensors
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {EventJSONDataCurrentRanges}
     * @memberof CustomSingleEventSensors
     */
    'currentRanges'?: EventJSONDataCurrentRanges;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventSensors
     */
    'temperatureC'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventSensors
     */
    'temperatureF'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventSensors
     */
    'temperatureUpdateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventSensors
     */
    'humidity'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventSensors
     */
    'humidityUpdateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventSensors
     */
    'dewpointC'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventSensors
     */
    'dewpointF'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventSensors
     */
    'dewpointDeviationF'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventSensors
     */
    'dewpointUpdateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventSensors
     */
    'batteryLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventSensors
     */
    'batteryLevelUpdateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventSensors
     */
    'signalStrength'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventSensors
     */
    'signalStrengthUpdateTime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomSingleEventSensors
     */
    'current'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomSingleEventSensors
     */
    'isInRangeHumidity'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomSingleEventSensors
     */
    'isInRangeTemperature'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomSingleEventSensors
     */
    'isInRangeDewPoint'?: boolean;
    /**
     * 
     * @type {Array<EventActiveAlertV2>}
     * @memberof CustomSingleEventSensors
     */
    'alerts'?: Array<EventActiveAlertV2>;
}
/**
 * 
 * @export
 * @interface CustomSingleEventSlabConditionsValues
 */
export interface CustomSingleEventSlabConditionsValues {
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventSlabConditionsValues
     */
    'slabConditionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventSlabConditionsValues
     */
    'sectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomSingleEventSlabConditionsValues
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventSlabConditionsValues
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomSingleEventSlabConditionsValues
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface DashboardStatistics
 */
export interface DashboardStatistics {
    /**
     * 
     * @type {ProjectDashboardStatistics}
     * @memberof DashboardStatistics
     */
    'project': ProjectDashboardStatistics;
    /**
     * 
     * @type {SensorDashboardStatistics}
     * @memberof DashboardStatistics
     */
    'sensor': SensorDashboardStatistics;
    /**
     * 
     * @type {RapidRHSensorDashboardStatistics}
     * @memberof DashboardStatistics
     */
    'rapidRHSensor': RapidRHSensorDashboardStatistics;
    /**
     * 
     * @type {ProductDashboardStatistics}
     * @memberof DashboardStatistics
     */
    'product': ProductDashboardStatistics;
    /**
     * 
     * @type {UsageDashboardStatistics}
     * @memberof DashboardStatistics
     */
    'usageStatistics': UsageDashboardStatistics;
    /**
     * 
     * @type {Array<ProjectTypeItemDashboardStatistics>}
     * @memberof DashboardStatistics
     */
    'projectTypes': Array<ProjectTypeItemDashboardStatistics>;
    /**
     * 
     * @type {Array<ProjectBuildingTypeItemDashboardStatistics>}
     * @memberof DashboardStatistics
     */
    'projectBuildingTypes': Array<ProjectBuildingTypeItemDashboardStatistics>;
    /**
     * 
     * @type {ManufacturerAnnouncementDashboardStatistics}
     * @memberof DashboardStatistics
     */
    'manufacturerAnnouncement': ManufacturerAnnouncementDashboardStatistics;
    /**
     * 
     * @type {EventDashboardStatistics}
     * @memberof DashboardStatistics
     */
    'event': EventDashboardStatistics;
}
/**
 * 
 * @export
 * @interface DefaultResponse
 */
export interface DefaultResponse {
    /**
     * 
     * @type {number}
     * @memberof DefaultResponse
     */
    'statusCode': number;
    /**
     * 
     * @type {string}
     * @memberof DefaultResponse
     */
    'error': string;
    /**
     * 
     * @type {object}
     * @memberof DefaultResponse
     */
    'result': object;
}
/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {DeviceOs}
     * @memberof Device
     */
    'os': DeviceOs;
    /**
     * 
     * @type {DeviceClient}
     * @memberof Device
     */
    'client': DeviceClient;
    /**
     * 
     * @type {DeviceDevice}
     * @memberof Device
     */
    'device': DeviceDevice;
}
/**
 * 
 * @export
 * @interface DeviceClient
 */
export interface DeviceClient {
    /**
     * 
     * @type {string}
     * @memberof DeviceClient
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceClient
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceClient
     */
    'short_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceClient
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceClient
     */
    'engine'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceClient
     */
    'engine_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceClient
     */
    'family'?: string;
}
/**
 * 
 * @export
 * @interface DeviceDevice
 */
export interface DeviceDevice {
    /**
     * 
     * @type {string}
     * @memberof DeviceDevice
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDevice
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDevice
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDevice
     */
    'model'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDevice
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface DeviceOs
 */
export interface DeviceOs {
    /**
     * 
     * @type {string}
     * @memberof DeviceOs
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceOs
     */
    'short_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceOs
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceOs
     */
    'platform'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceOs
     */
    'family'?: string;
}
/**
 * 
 * @export
 * @interface DewPointInformation
 */
export interface DewPointInformation {
    /**
     * 
     * @type {number}
     * @memberof DewPointInformation
     */
    'minDewPoint': number;
    /**
     * 
     * @type {number}
     * @memberof DewPointInformation
     */
    'maxDewPoint': number;
}
/**
 * 
 * @export
 * @interface Dewpoint
 */
export interface Dewpoint {
    /**
     * 
     * @type {string}
     * @memberof Dewpoint
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Dewpoint
     */
    'uniqueId': string;
    /**
     * 
     * @type {string}
     * @memberof Dewpoint
     */
    'sensorType': string;
    /**
     * 
     * @type {string}
     * @memberof Dewpoint
     */
    'time': string;
    /**
     * 
     * @type {number}
     * @memberof Dewpoint
     */
    'dewpointC': number;
    /**
     * 
     * @type {number}
     * @memberof Dewpoint
     */
    'dewpointF': number;
    /**
     * 
     * @type {number}
     * @memberof Dewpoint
     */
    'deviationF'?: number;
    /**
     * 
     * @type {number}
     * @memberof Dewpoint
     */
    'contractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Dewpoint
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Dewpoint
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Dewpoint
     */
    'isInRange'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Dewpoint
     */
    'isDelayed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Dewpoint
     */
    'isCharging'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Dewpoint
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Dewpoint
     */
    'updatedAt': string;
    /**
     * 
     * @type {Contractor}
     * @memberof Dewpoint
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Project}
     * @memberof Dewpoint
     */
    'project'?: Project;
    /**
     * 
     * @type {ProjectSection}
     * @memberof Dewpoint
     */
    'projectSection'?: ProjectSection;
}
/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'fileType'?: string;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'contractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'authorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {User}
     * @memberof Document
     */
    'author'?: User;
    /**
     * 
     * @type {Project}
     * @memberof Document
     */
    'project'?: Project;
    /**
     * 
     * @type {Contractor}
     * @memberof Document
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Document
     */
    'attachments'?: Array<Attachment>;
}
/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'authorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'contractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'sensorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'taskId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'alertId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'productProjectSectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'slabConditionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'noteId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'parentEventId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'sensorRangeHistoryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'projectDocumentId'?: number;
    /**
     * 
     * @type {EventJSONData}
     * @memberof Event
     */
    'data'?: EventJSONData;
    /**
     * 
     * @type {EventCardRenderingData}
     * @memberof Event
     */
    'eventCardRenderingData'?: EventCardRenderingData;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Event
     */
    'products'?: Array<Product>;
    /**
     * 
     * @type {Array<ProductProject>}
     * @memberof Event
     */
    'productsProjects'?: Array<ProductProject>;
    /**
     * 
     * @type {Array<ProductProjectSection>}
     * @memberof Event
     */
    'productsProjectSections'?: Array<ProductProjectSection>;
    /**
     * 
     * @type {boolean}
     * @memberof Event
     */
    'notifyAll'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'wagnerProbeId'?: number;
    /**
     * 
     * @type {User}
     * @memberof Event
     */
    'author'?: User;
    /**
     * 
     * @type {ProjectSection}
     * @memberof Event
     */
    'projectSection'?: ProjectSection;
    /**
     * 
     * @type {EventType}
     * @memberof Event
     */
    'type'?: EventType;
    /**
     * 
     * @type {Project}
     * @memberof Event
     */
    'project'?: Project;
    /**
     * 
     * @type {Contractor}
     * @memberof Event
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Event
     */
    'attachments'?: Array<Attachment>;
}
/**
 * 
 * @export
 * @interface EventActiveAlert
 */
export interface EventActiveAlert {
    /**
     * 
     * @type {number}
     * @memberof EventActiveAlert
     */
    'alertId': number;
    /**
     * 
     * @type {string}
     * @memberof EventActiveAlert
     */
    'alertType': string;
    /**
     * 
     * @type {Array<EventActiveAlertAffectedProduct>}
     * @memberof EventActiveAlert
     */
    'affectedProducts': Array<EventActiveAlertAffectedProduct>;
}
/**
 * 
 * @export
 * @interface EventActiveAlertAffectedProduct
 */
export interface EventActiveAlertAffectedProduct {
    /**
     * 
     * @type {string}
     * @memberof EventActiveAlertAffectedProduct
     */
    'productName': string;
    /**
     * 
     * @type {number}
     * @memberof EventActiveAlertAffectedProduct
     */
    'maxDeviation': number;
    /**
     * 
     * @type {number}
     * @memberof EventActiveAlertAffectedProduct
     */
    'duration': number;
}
/**
 * 
 * @export
 * @interface EventActiveAlertAffectedProductV2
 */
export interface EventActiveAlertAffectedProductV2 {
    /**
     * 
     * @type {string}
     * @memberof EventActiveAlertAffectedProductV2
     */
    'productName': string;
    /**
     * 
     * @type {number}
     * @memberof EventActiveAlertAffectedProductV2
     */
    'maxDeviation': number;
    /**
     * 
     * @type {number}
     * @memberof EventActiveAlertAffectedProductV2
     */
    'duration': number;
}
/**
 * 
 * @export
 * @interface EventActiveAlertV2
 */
export interface EventActiveAlertV2 {
    /**
     * 
     * @type {number}
     * @memberof EventActiveAlertV2
     */
    'alertId': number;
    /**
     * 
     * @type {string}
     * @memberof EventActiveAlertV2
     */
    'alertType': string;
    /**
     * 
     * @type {Array<EventActiveAlertAffectedProductV2>}
     * @memberof EventActiveAlertV2
     */
    'affectedProducts': Array<EventActiveAlertAffectedProductV2>;
}
/**
 * 
 * @export
 * @interface EventCardRenderingData
 */
export interface EventCardRenderingData {
    /**
     * 
     * @type {string}
     * @memberof EventCardRenderingData
     */
    'eventDatetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCardRenderingData
     */
    'eventType'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCardRenderingData
     */
    'eventName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCardRenderingData
     */
    'eventDetails'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCardRenderingData
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCardRenderingData
     */
    'author'?: string;
    /**
     * 
     * @type {Array<EventCardRenderingDataPhotos>}
     * @memberof EventCardRenderingData
     */
    'photos'?: Array<EventCardRenderingDataPhotos>;
    /**
     * 
     * @type {Array<EventCardRenderingDataVideos>}
     * @memberof EventCardRenderingData
     */
    'videos'?: Array<EventCardRenderingDataVideos>;
    /**
     * 
     * @type {Array<EventCardRenderingDataAttachments>}
     * @memberof EventCardRenderingData
     */
    'attachments'?: Array<EventCardRenderingDataAttachments>;
    /**
     * 
     * @type {Array<EventTag>}
     * @memberof EventCardRenderingData
     */
    'tags'?: Array<EventTag>;
}
/**
 * 
 * @export
 * @interface EventCardRenderingDataAttachments
 */
export interface EventCardRenderingDataAttachments {
    /**
     * 
     * @type {string}
     * @memberof EventCardRenderingDataAttachments
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCardRenderingDataAttachments
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCardRenderingDataAttachments
     */
    'originalLink'?: string;
}
/**
 * 
 * @export
 * @interface EventCardRenderingDataPhotos
 */
export interface EventCardRenderingDataPhotos {
    /**
     * 
     * @type {string}
     * @memberof EventCardRenderingDataPhotos
     */
    'thumbnailLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCardRenderingDataPhotos
     */
    'originalLink'?: string;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof EventCardRenderingDataPhotos
     */
    'thumbnail'?: AttachmentParams;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof EventCardRenderingDataPhotos
     */
    'original'?: AttachmentParams;
}
/**
 * 
 * @export
 * @interface EventCardRenderingDataVideos
 */
export interface EventCardRenderingDataVideos {
    /**
     * 
     * @type {string}
     * @memberof EventCardRenderingDataVideos
     */
    'thumbnailLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCardRenderingDataVideos
     */
    'originalLink'?: string;
}
/**
 * 
 * @export
 * @interface EventCoordinates
 */
export interface EventCoordinates {
    /**
     * 
     * @type {number}
     * @memberof EventCoordinates
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof EventCoordinates
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof EventCoordinates
     */
    'accuracy'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventCoordinates
     */
    'altitude'?: number;
}
/**
 * 
 * @export
 * @interface EventCreation
 */
export interface EventCreation {
    /**
     * 
     * @type {string}
     * @memberof EventCreation
     */
    'type': EventCreationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof EventCreation
     */
    'projectId': number;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof EventCreation
     */
    'coordinates'?: EventCoordinates;
    /**
     * 
     * @type {number}
     * @memberof EventCreation
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventCreation
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventCreation
     */
    'sensorId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventCreation
     */
    'sensorIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof EventCreation
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventCreation
     */
    'alertId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventCreation
     */
    'productProjectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventCreation
     */
    'productProjectSectionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventCreation
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventCreation
     */
    'generalContractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventCreation
     */
    'architectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventCreation
     */
    'taskId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventCreation
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventCreation
     */
    'nwfaMoistureMapRegionId'?: number;
    /**
     * 
     * @type {Array<ProjectCreationV2Photo>}
     * @memberof EventCreation
     */
    'photos'?: Array<ProjectCreationV2Photo>;
    /**
     * 
     * @type {Array<ProjectCreationV2Photo>}
     * @memberof EventCreation
     */
    'videos'?: Array<ProjectCreationV2Photo>;
    /**
     * 
     * @type {Array<AttachmentParams>}
     * @memberof EventCreation
     */
    'files'?: Array<AttachmentParams>;
    /**
     * 
     * @type {object}
     * @memberof EventCreation
     */
    'customParams'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof EventCreation
     */
    'notifyAll'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventCreation
     */
    'wagnerProbeId'?: number;
}

export const EventCreationTypeEnum = {
    ProjectUpdated: 'project_updated',
    ProjectCreated: 'project_created',
    ContractorUserRemoved: 'contractor_user_removed',
    ContractorUserAdded: 'contractor_user_added',
    AlertAppears: 'alert_appears',
    AlertIgnored: 'alert_ignored',
    AlertShared: 'alert_shared',
    AlertClosed: 'alert_closed',
    AlertCreated: 'alert_created',
    TaskUpdated: 'task_updated',
    TaskCreated: 'task_created',
    TaskArchived: 'task_archived',
    TaskUnarchived: 'task_unarchived',
    SensorDisconnected: 'sensor_disconnected',
    SensorUpdated: 'sensor_updated',
    SensorConnected: 'sensor_connected',
    ProductOutOfSpecification: 'product_out_of_specification',
    ProductInSpecification: 'product_in_specification',
    AllProductsInRange: 'all_products_in_range',
    SlabReadingUpdated: 'slab_reading_updated',
    SlabReadingAdded: 'slab_reading_added',
    NewNoteAdded: 'new_note_added',
    ProductUsedOnTask: 'product_used_on_task',
    ProductUpdated: 'product_updated',
    ProductAdded: 'product_added',
    SectionCreated: 'section_created',
    SectionUpdated: 'section_updated',
    ProductAdditionRequested: 'product_addition_requested',
    ProductMonitoringTurnedOff: 'product_monitoring_turned_off',
    ProductMonitoringTurnedOn: 'product_monitoring_turned_on',
    ProductArchived: 'product_archived',
    ProductUnarchived: 'product_unarchived',
    WoodEquilibriumMeasured: 'wood_equilibrium_measured',
    CheckConnectivity: 'check_connectivity'
} as const;

export type EventCreationTypeEnum = typeof EventCreationTypeEnum[keyof typeof EventCreationTypeEnum];

/**
 * 
 * @export
 * @interface EventDashboardStatistics
 */
export interface EventDashboardStatistics {
    /**
     * 
     * @type {number}
     * @memberof EventDashboardStatistics
     */
    'eventsNumber': number;
    /**
     * 
     * @type {Array<EventTypeDashboardStatistics>}
     * @memberof EventDashboardStatistics
     */
    'types': Array<EventTypeDashboardStatistics>;
}
/**
 * 
 * @export
 * @interface EventJSONData
 */
export interface EventJSONData {
    /**
     * 
     * @type {string}
     * @memberof EventJSONData
     */
    'source'?: string;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof EventJSONData
     */
    'coordinatesFromMobilePhone'?: EventCoordinates;
    /**
     * 
     * @type {Array<EventActiveAlert>}
     * @memberof EventJSONData
     */
    'activeAlerts'?: Array<EventActiveAlert>;
    /**
     * 
     * @type {Array<EventJSONDataSensorsState>}
     * @memberof EventJSONData
     */
    'sensorsState'?: Array<EventJSONDataSensorsState>;
    /**
     * 
     * @type {Array<EventJSONDataSlabConditionsValues>}
     * @memberof EventJSONData
     */
    'slabConditionsValues'?: Array<EventJSONDataSlabConditionsValues>;
    /**
     * 
     * @type {Array<EventJSONDataSections>}
     * @memberof EventJSONData
     */
    'sections'?: Array<EventJSONDataSections>;
    /**
     * 
     * @type {string}
     * @memberof EventJSONData
     */
    'appDevice'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventJSONData
     */
    'appVersion'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventJSONData
     */
    'distance'?: number;
}
/**
 * 
 * @export
 * @interface EventJSONDataCurrentRanges
 */
export interface EventJSONDataCurrentRanges {
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataCurrentRanges
     */
    'temperatureMinF'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataCurrentRanges
     */
    'temperatureMaxF'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataCurrentRanges
     */
    'humidityMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataCurrentRanges
     */
    'humidityMax'?: number;
}
/**
 * 
 * @export
 * @interface EventJSONDataSections
 */
export interface EventJSONDataSections {
    /**
     * 
     * @type {string}
     * @memberof EventJSONDataSections
     */
    'sectionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataSections
     */
    'sectionId'?: number;
    /**
     * 
     * @type {Array<EventJSONDataSpecificLocations>}
     * @memberof EventJSONDataSections
     */
    'specificLocations'?: Array<EventJSONDataSpecificLocations>;
}
/**
 * 
 * @export
 * @interface EventJSONDataSensorsState
 */
export interface EventJSONDataSensorsState {
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataSensorsState
     */
    'sensorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataSensorsState
     */
    'sectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataSensorsState
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {EventJSONDataCurrentRanges}
     * @memberof EventJSONDataSensorsState
     */
    'currentRanges'?: EventJSONDataCurrentRanges;
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataSensorsState
     */
    'temperatureC'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataSensorsState
     */
    'temperatureF'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventJSONDataSensorsState
     */
    'temperatureUpdateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataSensorsState
     */
    'humidity'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventJSONDataSensorsState
     */
    'humidityUpdateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataSensorsState
     */
    'dewpointC'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataSensorsState
     */
    'dewpointF'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventJSONDataSensorsState
     */
    'dewpointUpdateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataSensorsState
     */
    'batteryLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventJSONDataSensorsState
     */
    'batteryLevelUpdateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataSensorsState
     */
    'signalStrength'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventJSONDataSensorsState
     */
    'signalStrengthUpdateTime'?: string;
}
/**
 * 
 * @export
 * @interface EventJSONDataSlabConditionsValues
 */
export interface EventJSONDataSlabConditionsValues {
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataSlabConditionsValues
     */
    'slabConditionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataSlabConditionsValues
     */
    'sectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataSlabConditionsValues
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventJSONDataSlabConditionsValues
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventJSONDataSlabConditionsValues
     */
    'valie'?: string;
}
/**
 * 
 * @export
 * @interface EventJSONDataSpecificLocations
 */
export interface EventJSONDataSpecificLocations {
    /**
     * 
     * @type {number}
     * @memberof EventJSONDataSpecificLocations
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventJSONDataSpecificLocations
     */
    'specificLocationName'?: string;
}
/**
 * 
 * @export
 * @interface EventJSONDataV2
 */
export interface EventJSONDataV2 {
    /**
     * 
     * @type {string}
     * @memberof EventJSONDataV2
     */
    'source'?: string;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof EventJSONDataV2
     */
    'coordinatesFromMobilePhone'?: EventCoordinates;
    /**
     * 
     * @type {Array<EventActiveAlertV2>}
     * @memberof EventJSONDataV2
     */
    'activeAlerts'?: Array<EventActiveAlertV2>;
    /**
     * 
     * @type {Array<EventJSONDataSensorsState>}
     * @memberof EventJSONDataV2
     */
    'sensorsState'?: Array<EventJSONDataSensorsState>;
    /**
     * 
     * @type {Array<EventJSONDataSlabConditionsValues>}
     * @memberof EventJSONDataV2
     */
    'slabConditionsValues'?: Array<EventJSONDataSlabConditionsValues>;
    /**
     * 
     * @type {Array<EventJSONDataSections>}
     * @memberof EventJSONDataV2
     */
    'sections'?: Array<EventJSONDataSections>;
}
/**
 * 
 * @export
 * @interface EventTag
 */
export interface EventTag {
    /**
     * 
     * @type {string}
     * @memberof EventTag
     */
    'tagType': string;
    /**
     * 
     * @type {string}
     * @memberof EventTag
     */
    'tagValue': string;
}
/**
 * 
 * @export
 * @interface EventType
 */
export interface EventType {
    /**
     * 
     * @type {number}
     * @memberof EventType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EventType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EventType
     */
    'displayName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventType
     */
    'isVisible': boolean;
    /**
     * 
     * @type {string}
     * @memberof EventType
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof EventType
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface EventTypeDashboardStatistics
 */
export interface EventTypeDashboardStatistics {
    /**
     * 
     * @type {string}
     * @memberof EventTypeDashboardStatistics
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof EventTypeDashboardStatistics
     */
    'eventsNumber': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EventTypeName = {
    ProjectUpdated: 'project_updated',
    ProjectCreated: 'project_created',
    ProjectArchived: 'project_archived',
    ProjectUnarchived: 'project_unarchived',
    ContractorUserRemoved: 'contractor_user_removed',
    ContractorUserAdded: 'contractor_user_added',
    AlertAppears: 'alert_appears',
    AlertIgnored: 'alert_ignored',
    AlertShared: 'alert_shared',
    AlertClosed: 'alert_closed',
    AlertCreated: 'alert_created',
    TaskUpdated: 'task_updated',
    TaskCreated: 'task_created',
    TaskArchived: 'task_archived',
    TaskUnarchived: 'task_unarchived',
    SensorDisconnected: 'sensor_disconnected',
    SensorUpdated: 'sensor_updated',
    SensorConnected: 'sensor_connected',
    SensorNewRangeValues: 'sensor_new_range_values',
    ProductOutOfSpecification: 'product_out_of_specification',
    ProductInSpecification: 'product_in_specification',
    AllProductsInRange: 'all_products_in_range',
    SlabReadingUpdated: 'slab_reading_updated',
    SlabReadingAdded: 'slab_reading_added',
    NewNoteAdded: 'new_note_added',
    ProductUsedOnTask: 'product_used_on_task',
    ProductUpdated: 'product_updated',
    ProductAdded: 'product_added',
    ProductAdditionRequested: 'product_addition_requested',
    SectionCreated: 'section_created',
    SectionUpdated: 'section_updated',
    ProductMonitoringTurnedOff: 'product_monitoring_turned_off',
    ProductMonitoringTurnedOn: 'product_monitoring_turned_on',
    ProductArchived: 'product_archived',
    ProductUnarchived: 'product_unarchived',
    UserAssignedToProject: 'user_assigned_to_project',
    UserRemovedFromProject: 'user_removed_from_project',
    ProjectSectionArchived: 'project_section_archived',
    ProjectSectionUnarchived: 'project_section_unarchived',
    WoodEquilibriumMeasured: 'wood_equilibrium_measured',
    WagnerProbeUpdated: 'wagner_probe_updated',
    WagnerProbeMoved: 'wagner_probe_moved',
    WagnerProbeApproved: 'wagner_probe_approved',
    WagnerProbeAssigned: 'wagner_probe_assigned',
    CheckConnectivity: 'check_connectivity',
    ClimateConditionsShared: 'climate_conditions_shared',
    SlabConditionsShared: 'slab_conditions_shared',
    SlabConditionsListShared: 'slab_conditions_list_shared',
    DocumentAdded: 'document_added',
    DocumentUpdated: 'document_updated',
    DocumentRemoved: 'document_removed',
    UserAdded: 'user_added',
    UserUpdated: 'user_updated',
    UserArchived: 'user_archived',
    UserUnarchived: 'user_unarchived'
} as const;

export type EventTypeName = typeof EventTypeName[keyof typeof EventTypeName];


/**
 * 
 * @export
 * @interface EventTypeUpdatePayload
 */
export interface EventTypeUpdatePayload {
    /**
     * 
     * @type {boolean}
     * @memberof EventTypeUpdatePayload
     */
    'isVisible': boolean;
}
/**
 * 
 * @export
 * @interface EventV2
 */
export interface EventV2 {
    /**
     * 
     * @type {number}
     * @memberof EventV2
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventV2
     */
    'authorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventV2
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventV2
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventV2
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventV2
     */
    'sensorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventV2
     */
    'taskId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventV2
     */
    'alertId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventV2
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventV2
     */
    'productProjectSectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventV2
     */
    'slabConditionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventV2
     */
    'noteId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventV2
     */
    'parentEventId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventV2
     */
    'sensorRangeHistoryId'?: number;
    /**
     * 
     * @type {EventJSONDataV2}
     * @memberof EventV2
     */
    'data'?: EventJSONDataV2;
    /**
     * 
     * @type {EventCardRenderingData}
     * @memberof EventV2
     */
    'eventCardRenderingData'?: EventCardRenderingData;
    /**
     * 
     * @type {string}
     * @memberof EventV2
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventV2
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {Array<Product>}
     * @memberof EventV2
     */
    'products'?: Array<Product>;
    /**
     * 
     * @type {Array<ProductProject>}
     * @memberof EventV2
     */
    'productsProjects'?: Array<ProductProject>;
    /**
     * 
     * @type {Array<ProductProjectSection>}
     * @memberof EventV2
     */
    'productsProjectSections'?: Array<ProductProjectSection>;
    /**
     * 
     * @type {boolean}
     * @memberof EventV2
     */
    'notifyAll'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventV2
     */
    'wagnerProbeId'?: number;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof EventV2
     */
    'attachments'?: Array<Attachment>;
}
/**
 * 
 * @export
 * @interface Feature
 */
export interface Feature {
    /**
     * 
     * @type {number}
     * @memberof Feature
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    'header': string;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    'image'?: string;
    /**
     * 
     * @type {number}
     * @memberof Feature
     */
    'releaseId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface FeatureCreationParams
 */
export interface FeatureCreationParams {
    /**
     * 
     * @type {string}
     * @memberof FeatureCreationParams
     */
    'header'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreationParams
     */
    'description'?: string;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof FeatureCreationParams
     */
    'image'?: AttachmentParams;
}
/**
 * 
 * @export
 * @interface FeatureEditingParams
 */
export interface FeatureEditingParams {
    /**
     * 
     * @type {number}
     * @memberof FeatureEditingParams
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeatureEditingParams
     */
    'header'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureEditingParams
     */
    'description'?: string;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof FeatureEditingParams
     */
    'image'?: AttachmentParams;
}
/**
 * 
 * @export
 * @interface FillMissedReadingsBulkPayload
 */
export interface FillMissedReadingsBulkPayload {
    /**
     * 
     * @type {string}
     * @memberof FillMissedReadingsBulkPayload
     */
    'dateFrom': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FillMissedReadingsBulkPayload
     */
    'imeis': Array<string>;
}
/**
 * 
 * @export
 * @interface FillMissedReadingsPayload
 */
export interface FillMissedReadingsPayload {
    /**
     * 
     * @type {string}
     * @memberof FillMissedReadingsPayload
     */
    'dateFrom': string;
}
/**
 * 
 * @export
 * @interface FillMissedReadingsResponse
 */
export interface FillMissedReadingsResponse {
    /**
     * 
     * @type {string}
     * @memberof FillMissedReadingsResponse
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof FillMissedReadingsResponse
     */
    'message': string | null;
}
/**
 * 
 * @export
 * @interface FirmwareHistoryItem
 */
export interface FirmwareHistoryItem {
    /**
     * 
     * @type {number}
     * @memberof FirmwareHistoryItem
     */
    'sensorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FirmwareHistoryItem
     */
    'firmware'?: string;
    /**
     * 
     * @type {string}
     * @memberof FirmwareHistoryItem
     */
    'endAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof FirmwareHistoryItem
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof FirmwareHistoryItem
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface GeneralContractor
 */
export interface GeneralContractor {
    /**
     * 
     * @type {number}
     * @memberof GeneralContractor
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralContractor
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralContractor
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralContractor
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralContractor
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralContractor
     */
    'contactPerson'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralContractor
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralContractor
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralContractor
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface GlobalNotification
 */
export interface GlobalNotification {
    /**
     * 
     * @type {number}
     * @memberof GlobalNotification
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GlobalNotification
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GlobalNotification
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof GlobalNotification
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalNotification
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface GlobalOrProjectTaskCreation
 */
export interface GlobalOrProjectTaskCreation {
    /**
     * 
     * @type {string}
     * @memberof GlobalOrProjectTaskCreation
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof GlobalOrProjectTaskCreation
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalOrProjectTaskCreation
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalOrProjectTaskCreation
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof GlobalOrProjectTaskCreation
     */
    'coordinates'?: EventCoordinates;
    /**
     * 
     * @type {number}
     * @memberof GlobalOrProjectTaskCreation
     */
    'assigneeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GlobalOrProjectTaskCreation
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ProjectCreationV2Photo>}
     * @memberof GlobalOrProjectTaskCreation
     */
    'photos'?: Array<ProjectCreationV2Photo>;
}
/**
 * 
 * @export
 * @interface HasSensorsResponse
 */
export interface HasSensorsResponse {
    /**
     * 
     * @type {number}
     * @memberof HasSensorsResponse
     */
    'numberOfSensors': number;
    /**
     * 
     * @type {boolean}
     * @memberof HasSensorsResponse
     */
    'showSensorsScreen': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HasSensorsResponse
     */
    'isRequiredManualReadings': boolean;
    /**
     * 
     * @type {Array<Sensor>}
     * @memberof HasSensorsResponse
     */
    'sensors': Array<Sensor>;
}
/**
 * 
 * @export
 * @interface Humidity
 */
export interface Humidity {
    /**
     * 
     * @type {string}
     * @memberof Humidity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Humidity
     */
    'uniqueId': string;
    /**
     * 
     * @type {string}
     * @memberof Humidity
     */
    'sensorType': string;
    /**
     * 
     * @type {string}
     * @memberof Humidity
     */
    'time': string;
    /**
     * 
     * @type {number}
     * @memberof Humidity
     */
    'humidity': number;
    /**
     * 
     * @type {number}
     * @memberof Humidity
     */
    'contractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Humidity
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Humidity
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Humidity
     */
    'isInRange'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Humidity
     */
    'isDelayed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Humidity
     */
    'isCharging'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Humidity
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Humidity
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Humidity
     */
    'minRange'?: number;
    /**
     * 
     * @type {number}
     * @memberof Humidity
     */
    'maxRange'?: number;
    /**
     * 
     * @type {Contractor}
     * @memberof Humidity
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Project}
     * @memberof Humidity
     */
    'project'?: Project;
    /**
     * 
     * @type {ProjectSection}
     * @memberof Humidity
     */
    'projectSection'?: ProjectSection;
}
/**
 * 
 * @export
 * @interface ImportedProductItem
 */
export interface ImportedProductItem {
    /**
     * 
     * @type {number}
     * @memberof ImportedProductItem
     */
    'productDraftId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportedProductItem
     */
    'manufacturerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportedProductItem
     */
    'manufacturerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportedProductItem
     */
    'productName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportedProductItem
     */
    'errors'?: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    'error'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse200
     */
    'result'?: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2001
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    'error'?: string;
    /**
     * 
     * @type {User}
     * @memberof InlineResponse2001
     */
    'result'?: User;
}
/**
 * 
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20010
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20010
     */
    'error'?: string;
    /**
     * 
     * @type {Manufacturer}
     * @memberof InlineResponse20010
     */
    'result'?: Manufacturer;
}
/**
 * 
 * @export
 * @interface InlineResponse200100
 */
export interface InlineResponse200100 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200100
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200100
     */
    'error'?: string;
    /**
     * 
     * @type {Array<ReportComponent>}
     * @memberof InlineResponse200100
     */
    'result'?: Array<ReportComponent>;
}
/**
 * 
 * @export
 * @interface InlineResponse200101
 */
export interface InlineResponse200101 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200101
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200101
     */
    'error'?: string;
    /**
     * 
     * @type {ReportComponent}
     * @memberof InlineResponse200101
     */
    'result'?: ReportComponent;
}
/**
 * 
 * @export
 * @interface InlineResponse200102
 */
export interface InlineResponse200102 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200102
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200102
     */
    'error'?: string;
    /**
     * 
     * @type {UploadFileByChunksResult}
     * @memberof InlineResponse200102
     */
    'result'?: UploadFileByChunksResult;
}
/**
 * 
 * @export
 * @interface InlineResponse200103
 */
export interface InlineResponse200103 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200103
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200103
     */
    'error'?: string;
    /**
     * 
     * @type {Attachment}
     * @memberof InlineResponse200103
     */
    'result'?: Attachment;
}
/**
 * 
 * @export
 * @interface InlineResponse200104
 */
export interface InlineResponse200104 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200104
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200104
     */
    'error'?: string;
    /**
     * 
     * @type {Array<ManageSensorBLEStateResponse>}
     * @memberof InlineResponse200104
     */
    'result'?: Array<ManageSensorBLEStateResponse>;
}
/**
 * 
 * @export
 * @interface InlineResponse200105
 */
export interface InlineResponse200105 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200105
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200105
     */
    'error'?: string;
    /**
     * 
     * @type {ManageSensorBLEStateResponse}
     * @memberof InlineResponse200105
     */
    'result'?: ManageSensorBLEStateResponse;
}
/**
 * 
 * @export
 * @interface InlineResponse200106
 */
export interface InlineResponse200106 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200106
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200106
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse200106Result}
     * @memberof InlineResponse200106
     */
    'result'?: InlineResponse200106Result;
}
/**
 * 
 * @export
 * @interface InlineResponse200106Result
 */
export interface InlineResponse200106Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200106Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof InlineResponse200106Result
     */
    'rows'?: Array<Attachment>;
}
/**
 * 
 * @export
 * @interface InlineResponse200107
 */
export interface InlineResponse200107 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200107
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200107
     */
    'error'?: string;
    /**
     * 
     * @type {TimeseriesGapResponse}
     * @memberof InlineResponse200107
     */
    'result'?: TimeseriesGapResponse;
}
/**
 * 
 * @export
 * @interface InlineResponse200108
 */
export interface InlineResponse200108 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200108
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200108
     */
    'error'?: string;
    /**
     * 
     * @type {Array<AggregatedTimeseriesHandlingGap>}
     * @memberof InlineResponse200108
     */
    'result'?: Array<AggregatedTimeseriesHandlingGap>;
}
/**
 * 
 * @export
 * @interface InlineResponse200109
 */
export interface InlineResponse200109 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200109
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200109
     */
    'error'?: string;
    /**
     * 
     * @type {Array<WagnerProbe>}
     * @memberof InlineResponse200109
     */
    'result'?: Array<WagnerProbe>;
}
/**
 * 
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20011
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20011
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20011Result}
     * @memberof InlineResponse20011
     */
    'result'?: InlineResponse20011Result;
}
/**
 * 
 * @export
 * @interface InlineResponse200110
 */
export interface InlineResponse200110 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200110
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200110
     */
    'error'?: string;
    /**
     * 
     * @type {Array<ContractorTokenHistory>}
     * @memberof InlineResponse200110
     */
    'result'?: Array<ContractorTokenHistory>;
}
/**
 * 
 * @export
 * @interface InlineResponse20011Result
 */
export interface InlineResponse20011Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20011Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<Manufacturer>}
     * @memberof InlineResponse20011Result
     */
    'rows'?: Array<Manufacturer>;
}
/**
 * 
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20012
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20012
     */
    'error'?: string;
    /**
     * 
     * @type {Array<Release>}
     * @memberof InlineResponse20012
     */
    'result'?: Array<Release>;
}
/**
 * 
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20013
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20013
     */
    'error'?: string;
    /**
     * 
     * @type {Release}
     * @memberof InlineResponse20013
     */
    'result'?: Release;
}
/**
 * 
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20014
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20014
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20014Result}
     * @memberof InlineResponse20014
     */
    'result'?: InlineResponse20014Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20014Result
 */
export interface InlineResponse20014Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20014Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ProductTipType>}
     * @memberof InlineResponse20014Result
     */
    'rows'?: Array<ProductTipType>;
}
/**
 * 
 * @export
 * @interface InlineResponse20015
 */
export interface InlineResponse20015 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20015
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20015
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20015Result}
     * @memberof InlineResponse20015
     */
    'result'?: InlineResponse20015Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20015Result
 */
export interface InlineResponse20015Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20015Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ProductPackageType>}
     * @memberof InlineResponse20015Result
     */
    'rows'?: Array<ProductPackageType>;
}
/**
 * 
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20016
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20016
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20016Result}
     * @memberof InlineResponse20016
     */
    'result'?: InlineResponse20016Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20016Result
 */
export interface InlineResponse20016Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20016Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ProductType>}
     * @memberof InlineResponse20016Result
     */
    'rows'?: Array<ProductType>;
}
/**
 * 
 * @export
 * @interface InlineResponse20017
 */
export interface InlineResponse20017 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20017
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20017
     */
    'error'?: string;
    /**
     * 
     * @type {ProductType}
     * @memberof InlineResponse20017
     */
    'result'?: ProductType;
}
/**
 * 
 * @export
 * @interface InlineResponse20018
 */
export interface InlineResponse20018 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20018
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20018
     */
    'error'?: string;
    /**
     * 
     * @type {Array<ProductTypeTip>}
     * @memberof InlineResponse20018
     */
    'result'?: Array<ProductTypeTip>;
}
/**
 * 
 * @export
 * @interface InlineResponse20019
 */
export interface InlineResponse20019 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20019
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20019
     */
    'error'?: string;
    /**
     * 
     * @type {Product}
     * @memberof InlineResponse20019
     */
    'result'?: Product;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2002
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    'error'?: string;
    /**
     * 
     * @type {SendCodeResponse}
     * @memberof InlineResponse2002
     */
    'result'?: SendCodeResponse;
}
/**
 * 
 * @export
 * @interface InlineResponse20020
 */
export interface InlineResponse20020 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20020
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20020
     */
    'error'?: string;
    /**
     * 
     * @type {Array<Array<UploadXlsxProduct>>}
     * @memberof InlineResponse20020
     */
    'result'?: Array<Array<UploadXlsxProduct>>;
}
/**
 * 
 * @export
 * @interface InlineResponse20021
 */
export interface InlineResponse20021 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20021
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20021
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20021Result}
     * @memberof InlineResponse20021
     */
    'result'?: InlineResponse20021Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20021Result
 */
export interface InlineResponse20021Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20021Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ProductTypeTip>}
     * @memberof InlineResponse20021Result
     */
    'rows'?: Array<ProductTypeTip>;
}
/**
 * 
 * @export
 * @interface InlineResponse20022
 */
export interface InlineResponse20022 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20022
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20022
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20022Result}
     * @memberof InlineResponse20022
     */
    'result'?: InlineResponse20022Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20022Result
 */
export interface InlineResponse20022Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20022Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ProductTip>}
     * @memberof InlineResponse20022Result
     */
    'rows'?: Array<ProductTip>;
}
/**
 * 
 * @export
 * @interface InlineResponse20023
 */
export interface InlineResponse20023 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20023
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20023
     */
    'error'?: string;
    /**
     * 
     * @type {Array<ProductsDraftsUpdateResponse>}
     * @memberof InlineResponse20023
     */
    'result'?: Array<ProductsDraftsUpdateResponse>;
}
/**
 * 
 * @export
 * @interface InlineResponse20024
 */
export interface InlineResponse20024 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20024
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20024
     */
    'error'?: string;
    /**
     * 
     * @type {ProductDraft}
     * @memberof InlineResponse20024
     */
    'result'?: ProductDraft;
}
/**
 * 
 * @export
 * @interface InlineResponse20025
 */
export interface InlineResponse20025 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20025
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20025
     */
    'error'?: string;
    /**
     * 
     * @type {Array<ProductsDraftsPublishResponse>}
     * @memberof InlineResponse20025
     */
    'result'?: Array<ProductsDraftsPublishResponse>;
}
/**
 * 
 * @export
 * @interface InlineResponse20026
 */
export interface InlineResponse20026 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20026
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20026
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20026Result}
     * @memberof InlineResponse20026
     */
    'result'?: InlineResponse20026Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20026Result
 */
export interface InlineResponse20026Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20026Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<Product>}
     * @memberof InlineResponse20026Result
     */
    'rows'?: Array<Product>;
}
/**
 * 
 * @export
 * @interface InlineResponse20027
 */
export interface InlineResponse20027 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20027
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20027
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20027Result}
     * @memberof InlineResponse20027
     */
    'result'?: InlineResponse20027Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20027Result
 */
export interface InlineResponse20027Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20027Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<SensorState>}
     * @memberof InlineResponse20027Result
     */
    'rows'?: Array<SensorState>;
}
/**
 * 
 * @export
 * @interface InlineResponse20028
 */
export interface InlineResponse20028 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20028
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20028
     */
    'error'?: string;
    /**
     * 
     * @type {Sensor}
     * @memberof InlineResponse20028
     */
    'result'?: Sensor;
}
/**
 * 
 * @export
 * @interface InlineResponse20029
 */
export interface InlineResponse20029 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20029
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20029
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20029Result}
     * @memberof InlineResponse20029
     */
    'result'?: InlineResponse20029Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20029Result
 */
export interface InlineResponse20029Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20029Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<Sensor>}
     * @memberof InlineResponse20029Result
     */
    'rows'?: Array<Sensor>;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2003
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    'error'?: string;
    /**
     * 
     * @type {Contractor}
     * @memberof InlineResponse2003
     */
    'result'?: Contractor;
}
/**
 * 
 * @export
 * @interface InlineResponse20030
 */
export interface InlineResponse20030 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20030
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20030
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20030Result}
     * @memberof InlineResponse20030
     */
    'result'?: InlineResponse20030Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20030Result
 */
export interface InlineResponse20030Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20030Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<Project>}
     * @memberof InlineResponse20030Result
     */
    'rows'?: Array<Project>;
}
/**
 * 
 * @export
 * @interface InlineResponse20031
 */
export interface InlineResponse20031 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20031
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20031
     */
    'error'?: string;
    /**
     * 
     * @type {Project}
     * @memberof InlineResponse20031
     */
    'result'?: Project;
}
/**
 * 
 * @export
 * @interface InlineResponse20032
 */
export interface InlineResponse20032 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20032
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20032
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20032Result}
     * @memberof InlineResponse20032
     */
    'result'?: InlineResponse20032Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20032Result
 */
export interface InlineResponse20032Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20032Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ProjectSection>}
     * @memberof InlineResponse20032Result
     */
    'rows'?: Array<ProjectSection>;
}
/**
 * 
 * @export
 * @interface InlineResponse20033
 */
export interface InlineResponse20033 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20033
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20033
     */
    'error'?: string;
    /**
     * 
     * @type {ProjectSection}
     * @memberof InlineResponse20033
     */
    'result'?: ProjectSection;
}
/**
 * 
 * @export
 * @interface InlineResponse20034
 */
export interface InlineResponse20034 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20034
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20034
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20034Result}
     * @memberof InlineResponse20034
     */
    'result'?: InlineResponse20034Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20034Result
 */
export interface InlineResponse20034Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20034Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<AdminProjectTypeList>}
     * @memberof InlineResponse20034Result
     */
    'rows'?: Array<AdminProjectTypeList>;
}
/**
 * 
 * @export
 * @interface InlineResponse20035
 */
export interface InlineResponse20035 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20035
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20035
     */
    'error'?: string;
    /**
     * 
     * @type {ProjectType}
     * @memberof InlineResponse20035
     */
    'result'?: ProjectType;
}
/**
 * 
 * @export
 * @interface InlineResponse20036
 */
export interface InlineResponse20036 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20036
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20036
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20036Result}
     * @memberof InlineResponse20036
     */
    'result'?: InlineResponse20036Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20036Result
 */
export interface InlineResponse20036Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20036Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<EventType>}
     * @memberof InlineResponse20036Result
     */
    'rows'?: Array<EventType>;
}
/**
 * 
 * @export
 * @interface InlineResponse20037
 */
export interface InlineResponse20037 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20037
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20037
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20037Result}
     * @memberof InlineResponse20037
     */
    'result'?: InlineResponse20037Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20037Result
 */
export interface InlineResponse20037Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20037Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<BuildingTypeList>}
     * @memberof InlineResponse20037Result
     */
    'rows'?: Array<BuildingTypeList>;
}
/**
 * 
 * @export
 * @interface InlineResponse20038
 */
export interface InlineResponse20038 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20038
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20038
     */
    'error'?: string;
    /**
     * 
     * @type {BuildingType}
     * @memberof InlineResponse20038
     */
    'result'?: BuildingType;
}
/**
 * 
 * @export
 * @interface InlineResponse20039
 */
export interface InlineResponse20039 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20039
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20039
     */
    'error'?: string;
    /**
     * 
     * @type {Array<UploadPhotoResult>}
     * @memberof InlineResponse20039
     */
    'result'?: Array<UploadPhotoResult>;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2004
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse2004Result}
     * @memberof InlineResponse2004
     */
    'result'?: InlineResponse2004Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20040
 */
export interface InlineResponse20040 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20040
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20040
     */
    'error'?: string;
    /**
     * 
     * @type {Array<UploadedFileResult>}
     * @memberof InlineResponse20040
     */
    'result'?: Array<UploadedFileResult>;
}
/**
 * 
 * @export
 * @interface InlineResponse20041
 */
export interface InlineResponse20041 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20041
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20041
     */
    'error'?: string;
    /**
     * 
     * @type {Array<ConfigurationParameter>}
     * @memberof InlineResponse20041
     */
    'result'?: Array<ConfigurationParameter>;
}
/**
 * 
 * @export
 * @interface InlineResponse20042
 */
export interface InlineResponse20042 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20042
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20042
     */
    'error'?: string;
    /**
     * 
     * @type {Array<ConfigurationParamUpdateBulkResponseItem>}
     * @memberof InlineResponse20042
     */
    'result'?: Array<ConfigurationParamUpdateBulkResponseItem>;
}
/**
 * 
 * @export
 * @interface InlineResponse20043
 */
export interface InlineResponse20043 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20043
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20043
     */
    'error'?: string;
    /**
     * 
     * @type {ConfigurationParameter}
     * @memberof InlineResponse20043
     */
    'result'?: ConfigurationParameter;
}
/**
 * 
 * @export
 * @interface InlineResponse20044
 */
export interface InlineResponse20044 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20044
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20044
     */
    'error'?: string;
    /**
     * 
     * @type {AdminMobileSettings}
     * @memberof InlineResponse20044
     */
    'result'?: AdminMobileSettings;
}
/**
 * 
 * @export
 * @interface InlineResponse20045
 */
export interface InlineResponse20045 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20045
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20045
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20045Result}
     * @memberof InlineResponse20045
     */
    'result'?: InlineResponse20045Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20045Result
 */
export interface InlineResponse20045Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20045Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ThingspaceSensor>}
     * @memberof InlineResponse20045Result
     */
    'rows'?: Array<ThingspaceSensor>;
}
/**
 * 
 * @export
 * @interface InlineResponse20046
 */
export interface InlineResponse20046 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20046
     */
    'error'?: string;
    /**
     * 
     * @type {any}
     * @memberof InlineResponse20046
     */
    'result'?: any;
}
/**
 * 
 * @export
 * @interface InlineResponse20047
 */
export interface InlineResponse20047 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20047
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20047
     */
    'error'?: string;
    /**
     * 
     * @type {Array<Session>}
     * @memberof InlineResponse20047
     */
    'result'?: Array<Session>;
}
/**
 * 
 * @export
 * @interface InlineResponse20048
 */
export interface InlineResponse20048 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20048
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20048
     */
    'error'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse20048
     */
    'result'?: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse20049
 */
export interface InlineResponse20049 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20049
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20049
     */
    'error'?: string;
    /**
     * 
     * @type {SensorCurrentConfig}
     * @memberof InlineResponse20049
     */
    'result'?: SensorCurrentConfig;
}
/**
 * 
 * @export
 * @interface InlineResponse2004Result
 */
export interface InlineResponse2004Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2004Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<Contractor>}
     * @memberof InlineResponse2004Result
     */
    'rows'?: Array<Contractor>;
}
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2005
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005
     */
    'error'?: string;
    /**
     * 
     * @type {AdminNotification}
     * @memberof InlineResponse2005
     */
    'result'?: AdminNotification;
}
/**
 * 
 * @export
 * @interface InlineResponse20050
 */
export interface InlineResponse20050 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20050
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20050
     */
    'error'?: string;
    /**
     * 
     * @type {SensorDefaultConfiguration}
     * @memberof InlineResponse20050
     */
    'result'?: SensorDefaultConfiguration;
}
/**
 * 
 * @export
 * @interface InlineResponse20051
 */
export interface InlineResponse20051 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20051
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20051
     */
    'error'?: string;
    /**
     * 
     * @type {Array<SystemMessageType>}
     * @memberof InlineResponse20051
     */
    'result'?: Array<SystemMessageType>;
}
/**
 * 
 * @export
 * @interface InlineResponse20052
 */
export interface InlineResponse20052 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20052
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20052
     */
    'error'?: string;
    /**
     * 
     * @type {Array<SystemMessageTransportType>}
     * @memberof InlineResponse20052
     */
    'result'?: Array<SystemMessageTransportType>;
}
/**
 * 
 * @export
 * @interface InlineResponse20053
 */
export interface InlineResponse20053 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20053
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20053
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20053
     */
    'result'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse20054
 */
export interface InlineResponse20054 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20054
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20054
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20054Result}
     * @memberof InlineResponse20054
     */
    'result'?: InlineResponse20054Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20054Result
 */
export interface InlineResponse20054Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20054Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<WagnerProbe>}
     * @memberof InlineResponse20054Result
     */
    'rows'?: Array<WagnerProbe>;
}
/**
 * 
 * @export
 * @interface InlineResponse20055
 */
export interface InlineResponse20055 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20055
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20055
     */
    'error'?: string;
    /**
     * 
     * @type {WagnerProbe}
     * @memberof InlineResponse20055
     */
    'result'?: WagnerProbe;
}
/**
 * 
 * @export
 * @interface InlineResponse20056
 */
export interface InlineResponse20056 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20056
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20056
     */
    'error'?: string;
    /**
     * 
     * @type {UnarchiveResponse}
     * @memberof InlineResponse20056
     */
    'result'?: UnarchiveResponse;
}
/**
 * 
 * @export
 * @interface InlineResponse20057
 */
export interface InlineResponse20057 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20057
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20057
     */
    'error'?: string;
    /**
     * 
     * @type {CreateUserResponseV2}
     * @memberof InlineResponse20057
     */
    'result'?: CreateUserResponseV2;
}
/**
 * 
 * @export
 * @interface InlineResponse20058
 */
export interface InlineResponse20058 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20058
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20058
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20058Result}
     * @memberof InlineResponse20058
     */
    'result'?: InlineResponse20058Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20058Result
 */
export interface InlineResponse20058Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20058Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<Label>}
     * @memberof InlineResponse20058Result
     */
    'rows'?: Array<Label>;
}
/**
 * 
 * @export
 * @interface InlineResponse20059
 */
export interface InlineResponse20059 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20059
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20059
     */
    'error'?: string;
    /**
     * 
     * @type {Label}
     * @memberof InlineResponse20059
     */
    'result'?: Label;
}
/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2006
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2006
     */
    'error'?: string;
    /**
     * 
     * @type {UserProjectNotification}
     * @memberof InlineResponse2006
     */
    'result'?: UserProjectNotification;
}
/**
 * 
 * @export
 * @interface InlineResponse20060
 */
export interface InlineResponse20060 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20060
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20060
     */
    'error'?: string;
    /**
     * 
     * @type {Array<Project>}
     * @memberof InlineResponse20060
     */
    'result'?: Array<Project>;
}
/**
 * 
 * @export
 * @interface InlineResponse20061
 */
export interface InlineResponse20061 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20061
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20061
     */
    'error'?: string;
    /**
     * 
     * @type {Readout}
     * @memberof InlineResponse20061
     */
    'result'?: Readout;
}
/**
 * 
 * @export
 * @interface InlineResponse20062
 */
export interface InlineResponse20062 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20062
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20062
     */
    'error'?: string;
    /**
     * 
     * @type {Array<WagnerProbeReading>}
     * @memberof InlineResponse20062
     */
    'result'?: Array<WagnerProbeReading>;
}
/**
 * 
 * @export
 * @interface InlineResponse20063
 */
export interface InlineResponse20063 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20063
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20063
     */
    'error'?: string;
    /**
     * 
     * @type {DashboardStatistics}
     * @memberof InlineResponse20063
     */
    'result'?: DashboardStatistics;
}
/**
 * 
 * @export
 * @interface InlineResponse20064
 */
export interface InlineResponse20064 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20064
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20064
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20064Result}
     * @memberof InlineResponse20064
     */
    'result'?: InlineResponse20064Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20064Result
 */
export interface InlineResponse20064Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20064Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ProductRequest>}
     * @memberof InlineResponse20064Result
     */
    'rows'?: Array<ProductRequest>;
}
/**
 * 
 * @export
 * @interface InlineResponse20065
 */
export interface InlineResponse20065 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20065
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20065
     */
    'error'?: string;
    /**
     * 
     * @type {Array<ProductRequestStatus>}
     * @memberof InlineResponse20065
     */
    'result'?: Array<ProductRequestStatus>;
}
/**
 * 
 * @export
 * @interface InlineResponse20066
 */
export interface InlineResponse20066 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20066
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20066
     */
    'error'?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20066
     */
    'result'?: number;
}
/**
 * 
 * @export
 * @interface InlineResponse20067
 */
export interface InlineResponse20067 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20067
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20067
     */
    'error'?: string;
    /**
     * 
     * @type {ProductRequest}
     * @memberof InlineResponse20067
     */
    'result'?: ProductRequest;
}
/**
 * 
 * @export
 * @interface InlineResponse20068
 */
export interface InlineResponse20068 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20068
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20068
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20068Result}
     * @memberof InlineResponse20068
     */
    'result'?: InlineResponse20068Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20068Result
 */
export interface InlineResponse20068Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20068Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<Event>}
     * @memberof InlineResponse20068Result
     */
    'rows'?: Array<Event>;
}
/**
 * 
 * @export
 * @interface InlineResponse20069
 */
export interface InlineResponse20069 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20069
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20069
     */
    'error'?: string;
    /**
     * 
     * @type {CustomSingleEvent}
     * @memberof InlineResponse20069
     */
    'result'?: CustomSingleEvent;
}
/**
 * 
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2007
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2007
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse2007Result}
     * @memberof InlineResponse2007
     */
    'result'?: InlineResponse2007Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20070
 */
export interface InlineResponse20070 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20070
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20070
     */
    'error'?: string;
    /**
     * 
     * @type {Array<ReportType>}
     * @memberof InlineResponse20070
     */
    'result'?: Array<ReportType>;
}
/**
 * 
 * @export
 * @interface InlineResponse20071
 */
export interface InlineResponse20071 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20071
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20071
     */
    'error'?: string;
    /**
     * 
     * @type {ReportType}
     * @memberof InlineResponse20071
     */
    'result'?: ReportType;
}
/**
 * 
 * @export
 * @interface InlineResponse20072
 */
export interface InlineResponse20072 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20072
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20072
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineResponse20072
     */
    'result'?: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineResponse20073
 */
export interface InlineResponse20073 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20073
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20073
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20073Result}
     * @memberof InlineResponse20073
     */
    'result'?: InlineResponse20073Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20073Result
 */
export interface InlineResponse20073Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20073Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<UnstableSensor>}
     * @memberof InlineResponse20073Result
     */
    'rows'?: Array<UnstableSensor>;
}
/**
 * 
 * @export
 * @interface InlineResponse20074
 */
export interface InlineResponse20074 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20074
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20074
     */
    'error'?: string;
    /**
     * 
     * @type {Array<AggregatedUnstableSensor>}
     * @memberof InlineResponse20074
     */
    'result'?: Array<AggregatedUnstableSensor>;
}
/**
 * 
 * @export
 * @interface InlineResponse20075
 */
export interface InlineResponse20075 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20075
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20075
     */
    'error'?: string;
    /**
     * 
     * @type {FillMissedReadingsResponse}
     * @memberof InlineResponse20075
     */
    'result'?: FillMissedReadingsResponse;
}
/**
 * 
 * @export
 * @interface InlineResponse20076
 */
export interface InlineResponse20076 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20076
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20076
     */
    'error'?: string;
    /**
     * 
     * @type {Array<AggregatedTimeseriesGap>}
     * @memberof InlineResponse20076
     */
    'result'?: Array<AggregatedTimeseriesGap>;
}
/**
 * 
 * @export
 * @interface InlineResponse20077
 */
export interface InlineResponse20077 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20077
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20077
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20077Result}
     * @memberof InlineResponse20077
     */
    'result'?: InlineResponse20077Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20077Result
 */
export interface InlineResponse20077Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20077Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<Report>}
     * @memberof InlineResponse20077Result
     */
    'rows'?: Array<Report>;
}
/**
 * 
 * @export
 * @interface InlineResponse20078
 */
export interface InlineResponse20078 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20078
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20078
     */
    'error'?: string;
    /**
     * 
     * @type {Array<UserGlobalNotification>}
     * @memberof InlineResponse20078
     */
    'result'?: Array<UserGlobalNotification>;
}
/**
 * 
 * @export
 * @interface InlineResponse20079
 */
export interface InlineResponse20079 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20079
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20079
     */
    'error'?: string;
    /**
     * 
     * @type {Array<UserNotificationResponseItemByProject>}
     * @memberof InlineResponse20079
     */
    'result'?: Array<UserNotificationResponseItemByProject>;
}
/**
 * 
 * @export
 * @interface InlineResponse2007Result
 */
export interface InlineResponse2007Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2007Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<User>}
     * @memberof InlineResponse2007Result
     */
    'rows'?: Array<User>;
}
/**
 * 
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2008
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2008
     */
    'error'?: string;
    /**
     * 
     * @type {Array<AssignForemanResponse>}
     * @memberof InlineResponse2008
     */
    'result'?: Array<AssignForemanResponse>;
}
/**
 * 
 * @export
 * @interface InlineResponse20080
 */
export interface InlineResponse20080 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20080
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20080
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20080Result}
     * @memberof InlineResponse20080
     */
    'result'?: InlineResponse20080Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20080Result
 */
export interface InlineResponse20080Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20080Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ManufacturerAnnouncement>}
     * @memberof InlineResponse20080Result
     */
    'rows'?: Array<ManufacturerAnnouncement>;
}
/**
 * 
 * @export
 * @interface InlineResponse20081
 */
export interface InlineResponse20081 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20081
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20081
     */
    'error'?: string;
    /**
     * 
     * @type {ManufacturerAnnouncement}
     * @memberof InlineResponse20081
     */
    'result'?: ManufacturerAnnouncement;
}
/**
 * 
 * @export
 * @interface InlineResponse20082
 */
export interface InlineResponse20082 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20082
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20082
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20082Result}
     * @memberof InlineResponse20082
     */
    'result'?: InlineResponse20082Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20082Result
 */
export interface InlineResponse20082Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20082Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ManufacturerAnnouncementType>}
     * @memberof InlineResponse20082Result
     */
    'rows'?: Array<ManufacturerAnnouncementType>;
}
/**
 * 
 * @export
 * @interface InlineResponse20083
 */
export interface InlineResponse20083 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20083
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20083
     */
    'error'?: string;
    /**
     * 
     * @type {ManufacturerAnnouncementType}
     * @memberof InlineResponse20083
     */
    'result'?: ManufacturerAnnouncementType;
}
/**
 * 
 * @export
 * @interface InlineResponse20084
 */
export interface InlineResponse20084 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20084
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20084
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20084Result}
     * @memberof InlineResponse20084
     */
    'result'?: InlineResponse20084Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20084Result
 */
export interface InlineResponse20084Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20084Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<UserMessageChannel>}
     * @memberof InlineResponse20084Result
     */
    'rows'?: Array<UserMessageChannel>;
}
/**
 * 
 * @export
 * @interface InlineResponse20085
 */
export interface InlineResponse20085 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20085
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20085
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20085Result}
     * @memberof InlineResponse20085
     */
    'result'?: InlineResponse20085Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20085Result
 */
export interface InlineResponse20085Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20085Result
     */
    'count': number;
    /**
     * 
     * @type {Array<SensorBackupUplinkItem>}
     * @memberof InlineResponse20085Result
     */
    'rows': Array<SensorBackupUplinkItem>;
}
/**
 * 
 * @export
 * @interface InlineResponse20086
 */
export interface InlineResponse20086 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20086
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20086
     */
    'error'?: string;
    /**
     * 
     * @type {SensorExtendedDiagnostics}
     * @memberof InlineResponse20086
     */
    'result'?: SensorExtendedDiagnostics;
}
/**
 * 
 * @export
 * @interface InlineResponse20087
 */
export interface InlineResponse20087 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20087
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20087
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20087Result}
     * @memberof InlineResponse20087
     */
    'result'?: InlineResponse20087Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20087Result
 */
export interface InlineResponse20087Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20087Result
     */
    'count': number;
    /**
     * 
     * @type {Array<Task>}
     * @memberof InlineResponse20087Result
     */
    'rows': Array<Task>;
}
/**
 * 
 * @export
 * @interface InlineResponse20088
 */
export interface InlineResponse20088 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20088
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20088
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20088Result}
     * @memberof InlineResponse20088
     */
    'result'?: InlineResponse20088Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20088Result
 */
export interface InlineResponse20088Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20088Result
     */
    'count': number;
    /**
     * 
     * @type {Array<ProductImport>}
     * @memberof InlineResponse20088Result
     */
    'rows': Array<ProductImport>;
}
/**
 * 
 * @export
 * @interface InlineResponse20089
 */
export interface InlineResponse20089 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20089
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20089
     */
    'error'?: string;
    /**
     * 
     * @type {ProductImport}
     * @memberof InlineResponse20089
     */
    'result'?: ProductImport;
}
/**
 * 
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2009
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2009
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse2009Result}
     * @memberof InlineResponse2009
     */
    'result'?: InlineResponse2009Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20090
 */
export interface InlineResponse20090 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20090
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20090
     */
    'error'?: string;
    /**
     * 
     * @type {ParsedAdvPacket}
     * @memberof InlineResponse20090
     */
    'result'?: ParsedAdvPacket;
}
/**
 * 
 * @export
 * @interface InlineResponse20091
 */
export interface InlineResponse20091 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20091
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20091
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20091Result}
     * @memberof InlineResponse20091
     */
    'result'?: InlineResponse20091Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20091Result
 */
export interface InlineResponse20091Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20091Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<SystemMessageHistory>}
     * @memberof InlineResponse20091Result
     */
    'rows'?: Array<SystemMessageHistory>;
}
/**
 * 
 * @export
 * @interface InlineResponse20092
 */
export interface InlineResponse20092 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20092
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20092
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20092Result}
     * @memberof InlineResponse20092
     */
    'result'?: InlineResponse20092Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20092Result
 */
export interface InlineResponse20092Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20092Result
     */
    'count': number;
    /**
     * 
     * @type {Array<ManufacturerAnnouncementCategory>}
     * @memberof InlineResponse20092Result
     */
    'rows': Array<ManufacturerAnnouncementCategory>;
}
/**
 * 
 * @export
 * @interface InlineResponse20093
 */
export interface InlineResponse20093 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20093
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20093
     */
    'error'?: string;
    /**
     * 
     * @type {ManufacturerAnnouncementCategory}
     * @memberof InlineResponse20093
     */
    'result'?: ManufacturerAnnouncementCategory;
}
/**
 * 
 * @export
 * @interface InlineResponse20094
 */
export interface InlineResponse20094 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20094
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20094
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20094Result}
     * @memberof InlineResponse20094
     */
    'result'?: InlineResponse20094Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20094Result
 */
export interface InlineResponse20094Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20094Result
     */
    'count': number;
    /**
     * 
     * @type {Array<WagnerDeviceFirmware>}
     * @memberof InlineResponse20094Result
     */
    'rows': Array<WagnerDeviceFirmware>;
}
/**
 * 
 * @export
 * @interface InlineResponse20095
 */
export interface InlineResponse20095 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20095
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20095
     */
    'error'?: string;
    /**
     * 
     * @type {WagnerDeviceFirmware}
     * @memberof InlineResponse20095
     */
    'result'?: WagnerDeviceFirmware;
}
/**
 * 
 * @export
 * @interface InlineResponse20096
 */
export interface InlineResponse20096 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20096
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20096
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20096Result}
     * @memberof InlineResponse20096
     */
    'result'?: InlineResponse20096Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20096Result
 */
export interface InlineResponse20096Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20096Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<Document>}
     * @memberof InlineResponse20096Result
     */
    'rows'?: Array<Document>;
}
/**
 * 
 * @export
 * @interface InlineResponse20097
 */
export interface InlineResponse20097 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20097
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20097
     */
    'error'?: string;
    /**
     * 
     * @type {Report}
     * @memberof InlineResponse20097
     */
    'result'?: Report;
}
/**
 * 
 * @export
 * @interface InlineResponse20098
 */
export interface InlineResponse20098 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20098
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20098
     */
    'error'?: string;
    /**
     * 
     * @type {InlineResponse20098Result}
     * @memberof InlineResponse20098
     */
    'result'?: InlineResponse20098Result;
}
/**
 * 
 * @export
 * @interface InlineResponse20098Result
 */
export interface InlineResponse20098Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20098Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<AdminAlert>}
     * @memberof InlineResponse20098Result
     */
    'rows'?: Array<AdminAlert>;
}
/**
 * 
 * @export
 * @interface InlineResponse20099
 */
export interface InlineResponse20099 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20099
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20099
     */
    'error'?: string;
    /**
     * 
     * @type {Array<CombinedReportIncludedEvent>}
     * @memberof InlineResponse20099
     */
    'result'?: Array<CombinedReportIncludedEvent>;
}
/**
 * 
 * @export
 * @interface InlineResponse2009Result
 */
export interface InlineResponse2009Result {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2009Result
     */
    'count'?: number;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof InlineResponse2009Result
     */
    'rows'?: Array<UserRole>;
}
/**
 * 
 * @export
 * @interface IsAssignedSensorResponse
 */
export interface IsAssignedSensorResponse {
    /**
     * 
     * @type {boolean}
     * @memberof IsAssignedSensorResponse
     */
    'isAssigned': boolean;
    /**
     * 
     * @type {number}
     * @memberof IsAssignedSensorResponse
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof IsAssignedSensorResponse
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IsAssignedSensorResponse
     */
    'projectSectionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IsAssignedSensorResponse
     */
    'projectName'?: string;
    /**
     * 
     * @type {Sensor}
     * @memberof IsAssignedSensorResponse
     */
    'sensor': Sensor;
}
/**
 * 
 * @export
 * @interface Label
 */
export interface Label {
    /**
     * 
     * @type {number}
     * @memberof Label
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Label
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Label
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Label
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface LabelCreation
 */
export interface LabelCreation {
    /**
     * 
     * @type {string}
     * @memberof LabelCreation
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface LastAction
 */
export interface LastAction {
    /**
     * 
     * @type {string}
     * @memberof LastAction
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof LastAction
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface LatestWagnerDeviceFirmwareResponse
 */
export interface LatestWagnerDeviceFirmwareResponse {
    /**
     * 
     * @type {WagnerDeviceFirmware}
     * @memberof LatestWagnerDeviceFirmwareResponse
     */
    'dataGrabber': WagnerDeviceFirmware;
    /**
     * 
     * @type {WagnerDeviceFirmware}
     * @memberof LatestWagnerDeviceFirmwareResponse
     */
    'rangeExtender': WagnerDeviceFirmware;
}
/**
 * 
 * @export
 * @interface Light
 */
export interface Light {
    /**
     * 
     * @type {string}
     * @memberof Light
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Light
     */
    'uniqueId': string;
    /**
     * 
     * @type {string}
     * @memberof Light
     */
    'sensorType': string;
    /**
     * 
     * @type {string}
     * @memberof Light
     */
    'time': string;
    /**
     * 
     * @type {number}
     * @memberof Light
     */
    'light': number;
    /**
     * 
     * @type {number}
     * @memberof Light
     */
    'contractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Light
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Light
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Light
     */
    'isInRange'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Light
     */
    'isDelayed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Light
     */
    'isCharging'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Light
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Light
     */
    'updatedAt': string;
    /**
     * 
     * @type {Contractor}
     * @memberof Light
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Project}
     * @memberof Light
     */
    'project'?: Project;
    /**
     * 
     * @type {ProjectSection}
     * @memberof Light
     */
    'projectSection'?: ProjectSection;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'uniqueId': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'sensorType': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'time': string;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'altitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'radius'?: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'accuracy'?: number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'locationType': string;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'contractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Location
     */
    'isInRange'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Location
     */
    'isDelayed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Location
     */
    'isCharging'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'updatedAt': string;
    /**
     * 
     * @type {Contractor}
     * @memberof Location
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Project}
     * @memberof Location
     */
    'project'?: Project;
    /**
     * 
     * @type {ProjectSection}
     * @memberof Location
     */
    'projectSection'?: ProjectSection;
}
/**
 * 
 * @export
 * @interface ManageAdminNotification
 */
export interface ManageAdminNotification {
    /**
     * 
     * @type {string}
     * @memberof ManageAdminNotification
     */
    'notificationType': string;
    /**
     * 
     * @type {boolean}
     * @memberof ManageAdminNotification
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface ManageNotification
 */
export interface ManageNotification {
    /**
     * 
     * @type {number}
     * @memberof ManageNotification
     */
    'projectId': number;
    /**
     * 
     * @type {number}
     * @memberof ManageNotification
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof ManageNotification
     */
    'notificationType': string;
    /**
     * 
     * @type {boolean}
     * @memberof ManageNotification
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface ManageSensorBLEStatePayload
 */
export interface ManageSensorBLEStatePayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof ManageSensorBLEStatePayload
     */
    'sensorImeis': Array<string>;
}
/**
 * 
 * @export
 * @interface ManageSensorBLEStateResponse
 */
export interface ManageSensorBLEStateResponse {
    /**
     * 
     * @type {string}
     * @memberof ManageSensorBLEStateResponse
     */
    'sensorImei': string;
    /**
     * 
     * @type {string}
     * @memberof ManageSensorBLEStateResponse
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ManageSensorBLEStateResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ManagingStatesProductProjectSection
 */
export interface ManagingStatesProductProjectSection {
    /**
     * 
     * @type {Array<ManagingStatesProductProjectSectionItem>}
     * @memberof ManagingStatesProductProjectSection
     */
    'products': Array<ManagingStatesProductProjectSectionItem>;
    /**
     * 
     * @type {string}
     * @memberof ManagingStatesProductProjectSection
     */
    'comment'?: string;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof ManagingStatesProductProjectSection
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface ManagingStatesProductProjectSectionItem
 */
export interface ManagingStatesProductProjectSectionItem {
    /**
     * 
     * @type {number}
     * @memberof ManagingStatesProductProjectSectionItem
     */
    'productProjectSectionId': number;
    /**
     * 
     * @type {boolean}
     * @memberof ManagingStatesProductProjectSectionItem
     */
    'isActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManagingStatesProductProjectSectionItem
     */
    'isMonitored': boolean;
}
/**
 * 
 * @export
 * @interface ManagingStatesProductProjectSectionResponse
 */
export interface ManagingStatesProductProjectSectionResponse {
    /**
     * 
     * @type {number}
     * @memberof ManagingStatesProductProjectSectionResponse
     */
    'productProjectSectionId': number;
    /**
     * 
     * @type {boolean}
     * @memberof ManagingStatesProductProjectSectionResponse
     */
    'result': boolean;
    /**
     * 
     * @type {string}
     * @memberof ManagingStatesProductProjectSectionResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface Manufacturer
 */
export interface Manufacturer {
    /**
     * 
     * @type {number}
     * @memberof Manufacturer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Manufacturer
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Manufacturer
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Manufacturer
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Manufacturer
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Manufacturer
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Manufacturer
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Manufacturer
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof Manufacturer
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Manufacturer
     */
    'address2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Manufacturer
     */
    'fullAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof Manufacturer
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Manufacturer
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof Manufacturer
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Manufacturer
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Manufacturer
     */
    'usersAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Manufacturer
     */
    'productsInUseCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Manufacturer
     */
    'productsAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Manufacturer
     */
    'photo'?: string;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Manufacturer
     */
    'products'?: Array<Product>;
    /**
     * 
     * @type {Array<User>}
     * @memberof Manufacturer
     */
    'users'?: Array<User>;
    /**
     * 
     * @type {boolean}
     * @memberof Manufacturer
     */
    'isVerified'?: boolean;
}
/**
 * 
 * @export
 * @interface ManufacturerAnnouncement
 */
export interface ManufacturerAnnouncement {
    /**
     * 
     * @type {number}
     * @memberof ManufacturerAnnouncement
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncement
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerAnnouncement
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerAnnouncement
     */
    'manufacturerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncement
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncement
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncement
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManufacturerAnnouncement
     */
    'isVisibleContactMeButton'?: boolean;
    /**
     * 
     * @type {Array<ManufacturerAnnouncementPost>}
     * @memberof ManufacturerAnnouncement
     */
    'posts'?: Array<ManufacturerAnnouncementPost>;
    /**
     * 
     * @type {ManufacturerAnnouncementType}
     * @memberof ManufacturerAnnouncement
     */
    'type'?: ManufacturerAnnouncementType;
    /**
     * 
     * @type {Manufacturer}
     * @memberof ManufacturerAnnouncement
     */
    'manufacturer'?: Manufacturer;
    /**
     * 
     * @type {Array<ManufacturerAnnouncementCategory>}
     * @memberof ManufacturerAnnouncement
     */
    'categories'?: Array<ManufacturerAnnouncementCategory>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ManufacturerAnnouncement
     */
    'categoryIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface ManufacturerAnnouncementCategory
 */
export interface ManufacturerAnnouncementCategory {
    /**
     * 
     * @type {number}
     * @memberof ManufacturerAnnouncementCategory
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementCategory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementCategory
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementCategory
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementCategory
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ManufacturerAnnouncementDashboardStatistics
 */
export interface ManufacturerAnnouncementDashboardStatistics {
    /**
     * 
     * @type {number}
     * @memberof ManufacturerAnnouncementDashboardStatistics
     */
    'allNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerAnnouncementDashboardStatistics
     */
    'uniqueManufacturersNumber': number;
    /**
     * 
     * @type {Array<ManufacturerAnnouncementDashboardStatisticsCategories>}
     * @memberof ManufacturerAnnouncementDashboardStatistics
     */
    'categories': Array<ManufacturerAnnouncementDashboardStatisticsCategories>;
}
/**
 * 
 * @export
 * @interface ManufacturerAnnouncementDashboardStatisticsCategories
 */
export interface ManufacturerAnnouncementDashboardStatisticsCategories {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementDashboardStatisticsCategories
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementDashboardStatisticsCategories
     */
    'displayName': string;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerAnnouncementDashboardStatisticsCategories
     */
    'announcementsNumber': number;
}
/**
 * 
 * @export
 * @interface ManufacturerAnnouncementPost
 */
export interface ManufacturerAnnouncementPost {
    /**
     * 
     * @type {number}
     * @memberof ManufacturerAnnouncementPost
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementPost
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementPost
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementPost
     */
    'videoLink'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerAnnouncementPost
     */
    'manufacturerAnnouncementId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementPost
     */
    'photo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementPost
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementPost
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ManufacturerAnnouncementRequestPayload
 */
export interface ManufacturerAnnouncementRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementRequestPayload
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof ManufacturerAnnouncementRequestPayload
     */
    'includeUserPhone'?: boolean;
}
/**
 * 
 * @export
 * @interface ManufacturerAnnouncementType
 */
export interface ManufacturerAnnouncementType {
    /**
     * 
     * @type {number}
     * @memberof ManufacturerAnnouncementType
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementType
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementType
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementType
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAnnouncementType
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ManufacturerCreation
 */
export interface ManufacturerCreation {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerCreation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerCreation
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerCreation
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerCreation
     */
    'address1'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManufacturerCreation
     */
    'isVerified'?: boolean;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ManufacturerCreation
     */
    'photo'?: AttachmentParams;
}
/**
 * 
 * @export
 * @interface ManufacturerEditing
 */
export interface ManufacturerEditing {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerEditing
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerEditing
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerEditing
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerEditing
     */
    'address1'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManufacturerEditing
     */
    'isVerified'?: boolean;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ManufacturerEditing
     */
    'photo'?: AttachmentParams;
}
/**
 * 
 * @export
 * @interface MergeProductsPayload
 */
export interface MergeProductsPayload {
    /**
     * 
     * @type {number}
     * @memberof MergeProductsPayload
     */
    'targetProductDraftId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof MergeProductsPayload
     */
    'mergedProductDraftsIds': Array<number>;
}
/**
 * 
 * @export
 * @interface MobileSettings
 */
export interface MobileSettings {
    /**
     * 
     * @type {WagnerProbeSettings}
     * @memberof MobileSettings
     */
    'wagnerProbeSettings': WagnerProbeSettings;
}
/**
 * 
 * @export
 * @interface NWFAMoistureMapRegion
 */
export interface NWFAMoistureMapRegion {
    /**
     * 
     * @type {number}
     * @memberof NWFAMoistureMapRegion
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof NWFAMoistureMapRegion
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof NWFAMoistureMapRegion
     */
    'regionName': string;
    /**
     * 
     * @type {number}
     * @memberof NWFAMoistureMapRegion
     */
    'moistureMin': number;
    /**
     * 
     * @type {number}
     * @memberof NWFAMoistureMapRegion
     */
    'moistureMax': number;
    /**
     * 
     * @type {string}
     * @memberof NWFAMoistureMapRegion
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NWFAMoistureMapRegion
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface NoteDashboardStatistics
 */
export interface NoteDashboardStatistics {
    /**
     * 
     * @type {number}
     * @memberof NoteDashboardStatistics
     */
    'notesNumber': number;
}
/**
 * 
 * @export
 * @interface ParseAdvPacketPayload
 */
export interface ParseAdvPacketPayload {
    /**
     * 
     * @type {string}
     * @memberof ParseAdvPacketPayload
     */
    'advPacket': string;
}
/**
 * 
 * @export
 * @interface ParsedAdvPacket
 */
export interface ParsedAdvPacket {
    /**
     * 
     * @type {number}
     * @memberof ParsedAdvPacket
     */
    'temperatureF': number;
    /**
     * 
     * @type {number}
     * @memberof ParsedAdvPacket
     */
    'temperatureC': number;
    /**
     * 
     * @type {number}
     * @memberof ParsedAdvPacket
     */
    'humidity': number;
    /**
     * 
     * @type {string}
     * @memberof ParsedAdvPacket
     */
    'readingTime': string;
    /**
     * 
     * @type {string}
     * @memberof ParsedAdvPacket
     */
    'deviceTime': string;
    /**
     * 
     * @type {string}
     * @memberof ParsedAdvPacket
     */
    'serialNumber': string;
    /**
     * 
     * @type {number}
     * @memberof ParsedAdvPacket
     */
    'repeaterBatteryLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParsedAdvPacket
     */
    'dataGrabberBatteryLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParsedAdvPacket
     */
    'deviceType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParsedAdvPacket
     */
    'firmwareVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParsedAdvPacket
     */
    'acclimationTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParsedAdvPacket
     */
    'acclimationTemperatureF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParsedAdvPacket
     */
    'acclimationHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParsedAdvPacket
     */
    'ambientTemperatureF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParsedAdvPacket
     */
    'ambientHumidity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParsedAdvPacket
     */
    'isScanResponse'?: boolean;
}
/**
 * 
 * @export
 * @interface PhotoDashboardStatistics
 */
export interface PhotoDashboardStatistics {
    /**
     * 
     * @type {number}
     * @memberof PhotoDashboardStatistics
     */
    'photosNumber': number;
    /**
     * 
     * @type {number}
     * @memberof PhotoDashboardStatistics
     */
    'notePhotosNumber': number;
    /**
     * 
     * @type {number}
     * @memberof PhotoDashboardStatistics
     */
    'productAdditionPhotosNumber': number;
    /**
     * 
     * @type {number}
     * @memberof PhotoDashboardStatistics
     */
    'floorcloudSensorConnectionPhotosNumber': number;
    /**
     * 
     * @type {number}
     * @memberof PhotoDashboardStatistics
     */
    'rapidRhProbeConnectionPhotosNumber': number;
    /**
     * 
     * @type {number}
     * @memberof PhotoDashboardStatistics
     */
    'taskPhotosNumber': number;
}
/**
 * 
 * @export
 * @interface Pressure
 */
export interface Pressure {
    /**
     * 
     * @type {string}
     * @memberof Pressure
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Pressure
     */
    'uniqueId': string;
    /**
     * 
     * @type {string}
     * @memberof Pressure
     */
    'sensorType': string;
    /**
     * 
     * @type {string}
     * @memberof Pressure
     */
    'time': string;
    /**
     * 
     * @type {number}
     * @memberof Pressure
     */
    'pressure': number;
    /**
     * 
     * @type {number}
     * @memberof Pressure
     */
    'contractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pressure
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pressure
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Pressure
     */
    'isInRange'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Pressure
     */
    'isDelayed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Pressure
     */
    'isCharging'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Pressure
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Pressure
     */
    'updatedAt': string;
    /**
     * 
     * @type {Contractor}
     * @memberof Pressure
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Project}
     * @memberof Pressure
     */
    'project'?: Project;
    /**
     * 
     * @type {ProjectSection}
     * @memberof Pressure
     */
    'projectSection'?: ProjectSection;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'packageTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'manufacturerId': number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'shelfLife'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'lowTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'highTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'lowTempC'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'highTempC'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'lowHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'highHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'highSlabMVER'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'lowSlabRH'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'highSlabRH'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'lowSurfaceTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'highSurfaceTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'lowSurfaceTempC'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'highSurfaceTempC'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'lowSlabPH'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'highSlabPH'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'inUseCount'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'boardWidth'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'boardWidthFraction'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'updatedAt': string;
    /**
     * 
     * @type {ProductType}
     * @memberof Product
     */
    'productType'?: ProductType;
    /**
     * 
     * @type {ProductPackageType}
     * @memberof Product
     */
    'productPackageType'?: ProductPackageType;
    /**
     * 
     * @type {Manufacturer}
     * @memberof Product
     */
    'manufacturer'?: Manufacturer;
    /**
     * 
     * @type {Array<ProductTip>}
     * @memberof Product
     */
    'tips'?: Array<ProductTip>;
    /**
     * 
     * @type {Array<ProductProjectSection>}
     * @memberof Product
     */
    'productsProjectSections'?: Array<ProductProjectSection>;
    /**
     * 
     * @type {Array<ProjectSection>}
     * @memberof Product
     */
    'projectSections'?: Array<ProjectSection>;
    /**
     * 
     * @type {Array<ProductProject>}
     * @memberof Product
     */
    'productsProjects'?: Array<ProductProject>;
    /**
     * 
     * @type {Array<Project>}
     * @memberof Product
     */
    'projects'?: Array<Project>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'manufacturerComment'?: string;
}
/**
 * 
 * @export
 * @interface ProductAssignNote
 */
export interface ProductAssignNote {
    /**
     * 
     * @type {string}
     * @memberof ProductAssignNote
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ProductAssignPayload
 */
export interface ProductAssignPayload {
    /**
     * 
     * @type {number}
     * @memberof ProductAssignPayload
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductAssignPayload
     */
    'amount'?: number;
    /**
     * 
     * @type {Array<ProjectCreationV2Photo>}
     * @memberof ProductAssignPayload
     */
    'photo'?: Array<ProjectCreationV2Photo>;
    /**
     * 
     * @type {Array<ProjectCreationV2Photo>}
     * @memberof ProductAssignPayload
     */
    'video'?: Array<ProjectCreationV2Photo>;
    /**
     * 
     * @type {Array<AttachmentParams>}
     * @memberof ProductAssignPayload
     */
    'file'?: Array<AttachmentParams>;
    /**
     * 
     * @type {ProductAssignNote}
     * @memberof ProductAssignPayload
     */
    'note'?: ProductAssignNote;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof ProductAssignPayload
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface ProductCreation
 */
export interface ProductCreation {
    /**
     * 
     * @type {string}
     * @memberof ProductCreation
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductCreation
     */
    'manufacturerId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductCreation
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCreation
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCreation
     */
    'packageTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCreation
     */
    'shelfLife'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCreation
     */
    'lowTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCreation
     */
    'highTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCreation
     */
    'lowHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCreation
     */
    'highHumidity'?: number;
    /**
     * 
     * @type {Array<ProductTipCreation>}
     * @memberof ProductCreation
     */
    'tips'?: Array<ProductTipCreation>;
    /**
     * 
     * @type {string}
     * @memberof ProductCreation
     */
    'safetyTipType'?: ProductCreationSafetyTipTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductCreation
     */
    'commonTipType'?: ProductCreationCommonTipTypeEnum;
}

export const ProductCreationSafetyTipTypeEnum = {
    Default: 'default',
    Custom: 'custom'
} as const;

export type ProductCreationSafetyTipTypeEnum = typeof ProductCreationSafetyTipTypeEnum[keyof typeof ProductCreationSafetyTipTypeEnum];
export const ProductCreationCommonTipTypeEnum = {
    Default: 'default',
    Custom: 'custom'
} as const;

export type ProductCreationCommonTipTypeEnum = typeof ProductCreationCommonTipTypeEnum[keyof typeof ProductCreationCommonTipTypeEnum];

/**
 * 
 * @export
 * @interface ProductDashboardStatistics
 */
export interface ProductDashboardStatistics {
    /**
     * 
     * @type {number}
     * @memberof ProductDashboardStatistics
     */
    'manufacturersNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDashboardStatistics
     */
    'verifiedManufacturersNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDashboardStatistics
     */
    'productsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDashboardStatistics
     */
    'publishedProductsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDashboardStatistics
     */
    'inUseProductsNumber': number;
}
/**
 * 
 * @export
 * @interface ProductDraft
 */
export interface ProductDraft {
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductDraft
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDraft
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDraft
     */
    'safetyTipType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDraft
     */
    'commonTipType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDraft
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDraft
     */
    'manufacturerComment'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'packageTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'manufacturerId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'shelfLife'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'lowTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'highTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'lowTempC'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'highTempC'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'lowHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'highHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'highSlabMVER'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'lowSlabRH'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'highSlabRH'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'lowSurfaceTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'highSurfaceTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'lowSurfaceTempC'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'highSurfaceTempC'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'lowSlabPH'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'highSlabPH'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'boardWidth'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDraft
     */
    'boardWidthFraction'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDraft
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDraft
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'lastEditedBy'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraft
     */
    'lastPublishedBy'?: number;
    /**
     * 
     * @type {User}
     * @memberof ProductDraft
     */
    'editor'?: User;
    /**
     * 
     * @type {User}
     * @memberof ProductDraft
     */
    'publisher'?: User;
    /**
     * 
     * @type {Product}
     * @memberof ProductDraft
     */
    'original'?: Product;
    /**
     * 
     * @type {Array<ProductTip>}
     * @memberof ProductDraft
     */
    'commonCustomTips'?: Array<ProductTip>;
    /**
     * 
     * @type {Array<ProductTip>}
     * @memberof ProductDraft
     */
    'safetyCustomTips'?: Array<ProductTip>;
}
/**
 * 
 * @export
 * @interface ProductDraftCreation
 */
export interface ProductDraftCreation {
    /**
     * 
     * @type {string}
     * @memberof ProductDraftCreation
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductDraftCreation
     */
    'manufacturerId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductDraftCreation
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDraftCreation
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraftCreation
     */
    'packageTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraftCreation
     */
    'shelfLife'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraftCreation
     */
    'lowTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraftCreation
     */
    'highTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraftCreation
     */
    'lowHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraftCreation
     */
    'highHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraftCreation
     */
    'highSlabMVER'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraftCreation
     */
    'lowSlabRH'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraftCreation
     */
    'highSlabRH'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraftCreation
     */
    'lowSurfaceTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraftCreation
     */
    'highSurfaceTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraftCreation
     */
    'lowSlabPH'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraftCreation
     */
    'highSlabPH'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDraftCreation
     */
    'boardWidth'?: number;
    /**
     * 
     * @type {Array<ProductTipCreation>}
     * @memberof ProductDraftCreation
     */
    'commonCustomTips'?: Array<ProductTipCreation>;
    /**
     * 
     * @type {Array<ProductTipCreation>}
     * @memberof ProductDraftCreation
     */
    'safetyCustomTips'?: Array<ProductTipCreation>;
    /**
     * 
     * @type {string}
     * @memberof ProductDraftCreation
     */
    'safetyTipType'?: ProductDraftCreationSafetyTipTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductDraftCreation
     */
    'commonTipType'?: ProductDraftCreationCommonTipTypeEnum;
}

export const ProductDraftCreationSafetyTipTypeEnum = {
    Default: 'default',
    Custom: 'custom'
} as const;

export type ProductDraftCreationSafetyTipTypeEnum = typeof ProductDraftCreationSafetyTipTypeEnum[keyof typeof ProductDraftCreationSafetyTipTypeEnum];
export const ProductDraftCreationCommonTipTypeEnum = {
    Default: 'default',
    Custom: 'custom'
} as const;

export type ProductDraftCreationCommonTipTypeEnum = typeof ProductDraftCreationCommonTipTypeEnum[keyof typeof ProductDraftCreationCommonTipTypeEnum];

/**
 * 
 * @export
 * @interface ProductEditing
 */
export interface ProductEditing {
    /**
     * 
     * @type {string}
     * @memberof ProductEditing
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductEditing
     */
    'manufacturerId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductEditing
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductEditing
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductEditing
     */
    'packageTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductEditing
     */
    'shelfLife'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductEditing
     */
    'lowTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductEditing
     */
    'highTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductEditing
     */
    'lowHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductEditing
     */
    'highHumidity'?: number;
    /**
     * 
     * @type {Array<ProductTipEditing>}
     * @memberof ProductEditing
     */
    'tips'?: Array<ProductTipEditing>;
}
/**
 * 
 * @export
 * @interface ProductImport
 */
export interface ProductImport {
    /**
     * 
     * @type {number}
     * @memberof ProductImport
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductImport
     */
    'status'?: ProductImportStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductImport
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductImport
     */
    'bucketName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductImport
     */
    'authorId'?: number;
    /**
     * 
     * @type {Array<ImportedProductItem>}
     * @memberof ProductImport
     */
    'createdProducts'?: Array<ImportedProductItem>;
    /**
     * 
     * @type {Array<ImportedProductItem>}
     * @memberof ProductImport
     */
    'updatedProducts'?: Array<ImportedProductItem>;
    /**
     * 
     * @type {Array<ImportedProductItem>}
     * @memberof ProductImport
     */
    'errorProducts'?: Array<ImportedProductItem>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductImport
     */
    'commonErrorMessages'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductImport
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductImport
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {User}
     * @memberof ProductImport
     */
    'author'?: User;
    /**
     * 
     * @type {string}
     * @memberof ProductImport
     */
    'fileLink'?: string;
}

export const ProductImportStatusEnum = {
    Success: 'success',
    ErrorsDetected: 'errors_detected',
    Failed: 'failed'
} as const;

export type ProductImportStatusEnum = typeof ProductImportStatusEnum[keyof typeof ProductImportStatusEnum];

/**
 * 
 * @export
 * @interface ProductPackageType
 */
export interface ProductPackageType {
    /**
     * 
     * @type {number}
     * @memberof ProductPackageType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductPackageType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductPackageType
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductPackageType
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ProductProject
 */
export interface ProductProject {
    /**
     * 
     * @type {number}
     * @memberof ProductProject
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductProject
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductProject
     */
    'projectId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductProject
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductProject
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {Project}
     * @memberof ProductProject
     */
    'project'?: Project;
}
/**
 * 
 * @export
 * @interface ProductProjectSection
 */
export interface ProductProjectSection {
    /**
     * 
     * @type {number}
     * @memberof ProductProjectSection
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductProjectSection
     */
    'productId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductProjectSection
     */
    'productProjectId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductProjectSection
     */
    'projectSectionId': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductProjectSection
     */
    'isMonitored'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductProjectSection
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof ProductProjectSection
     */
    'attachments'?: Array<Attachment>;
    /**
     * 
     * @type {string}
     * @memberof ProductProjectSection
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductProjectSection
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<CurrentAlertData>}
     * @memberof ProductProjectSection
     */
    'currentAlertData'?: Array<CurrentAlertData>;
    /**
     * 
     * @type {Array<Alert>}
     * @memberof ProductProjectSection
     */
    'alerts'?: Array<Alert>;
    /**
     * 
     * @type {Product}
     * @memberof ProductProjectSection
     */
    'product'?: Product;
    /**
     * 
     * @type {ProjectSection}
     * @memberof ProductProjectSection
     */
    'projectSection'?: ProjectSection;
    /**
     * 
     * @type {Array<Task>}
     * @memberof ProductProjectSection
     */
    'tasks'?: Array<Task>;
}
/**
 * 
 * @export
 * @interface ProductRequest
 */
export interface ProductRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductRequest
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductRequest
     */
    'authorId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductRequest
     */
    'projectId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductRequest
     */
    'contractorId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductRequest
     */
    'statusId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductRequest
     */
    'request': string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequest
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequest
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequest
     */
    'updatedAt': string;
    /**
     * 
     * @type {Project}
     * @memberof ProductRequest
     */
    'project'?: Project;
    /**
     * 
     * @type {User}
     * @memberof ProductRequest
     */
    'author'?: User;
    /**
     * 
     * @type {Contractor}
     * @memberof ProductRequest
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {ProductRequestStatus}
     * @memberof ProductRequest
     */
    'status'?: ProductRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof ProductRequest
     */
    'doneResponseStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequest
     */
    'doneResponseSummary'?: string;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof ProductRequest
     */
    'attachments'?: Array<Attachment>;
}
/**
 * 
 * @export
 * @interface ProductRequestPayload
 */
export interface ProductRequestPayload {
    /**
     * 
     * @type {number}
     * @memberof ProductRequestPayload
     */
    'projectId': number;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestPayload
     */
    'description': string;
    /**
     * 
     * @type {Array<ProjectCreationV2Photo>}
     * @memberof ProductRequestPayload
     */
    'photo'?: Array<ProjectCreationV2Photo>;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof ProductRequestPayload
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface ProductRequestStatus
 */
export interface ProductRequestStatus {
    /**
     * 
     * @type {number}
     * @memberof ProductRequestStatus
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestStatus
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestStatus
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestStatus
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestStatus
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ProductRequestSummaryPayload
 */
export interface ProductRequestSummaryPayload {
    /**
     * 
     * @type {string}
     * @memberof ProductRequestSummaryPayload
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestSummaryPayload
     */
    'recipient': string;
}
/**
 * 
 * @export
 * @interface ProductTip
 */
export interface ProductTip {
    /**
     * 
     * @type {number}
     * @memberof ProductTip
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductTip
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof ProductTip
     */
    'link'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductTip
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductTip
     */
    'productDraftId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductTip
     */
    'typeId'?: number;
    /**
     * 
     * @type {ProductTipType}
     * @memberof ProductTip
     */
    'type'?: ProductTipType;
    /**
     * 
     * @type {string}
     * @memberof ProductTip
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductTip
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ProductTipCreation
 */
export interface ProductTipCreation {
    /**
     * 
     * @type {string}
     * @memberof ProductTipCreation
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof ProductTipCreation
     */
    'link'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductTipCreation
     */
    'typeId': number;
}
/**
 * 
 * @export
 * @interface ProductTipEditing
 */
export interface ProductTipEditing {
    /**
     * 
     * @type {number}
     * @memberof ProductTipEditing
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductTipEditing
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof ProductTipEditing
     */
    'link'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductTipEditing
     */
    'typeId': number;
}
/**
 * 
 * @export
 * @interface ProductTipType
 */
export interface ProductTipType {
    /**
     * 
     * @type {number}
     * @memberof ProductTipType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductTipType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductTipType
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductTipType
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ProductTipsManaging
 */
export interface ProductTipsManaging {
    /**
     * 
     * @type {Array<ProductTipEditing>}
     * @memberof ProductTipsManaging
     */
    'tips'?: Array<ProductTipEditing>;
    /**
     * 
     * @type {Array<ProductTipsManagingIds>}
     * @memberof ProductTipsManaging
     */
    'ids'?: Array<ProductTipsManagingIds>;
}
/**
 * 
 * @export
 * @interface ProductTipsManagingIds
 */
export interface ProductTipsManagingIds {
    /**
     * 
     * @type {number}
     * @memberof ProductTipsManagingIds
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductTipsManagingIds
     */
    'productDraftId': number;
}
/**
 * 
 * @export
 * @interface ProductTipsManagingResponse
 */
export interface ProductTipsManagingResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ProductTipsManagingResponse
     */
    'result': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductTipsManagingResponse
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductTipsManagingResponse
     */
    'productDraftId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductTipsManagingResponse
     */
    'productId'?: number;
}
/**
 * 
 * @export
 * @interface ProductType
 */
export interface ProductType {
    /**
     * 
     * @type {number}
     * @memberof ProductType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductType
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductType
     */
    'productsCount'?: number;
    /**
     * 
     * @type {Array<ProductTypeTip>}
     * @memberof ProductType
     */
    'tips'?: Array<ProductTypeTip>;
    /**
     * 
     * @type {string}
     * @memberof ProductType
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductType
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductType
     */
    'isSuitableForWoodMeasurements': boolean;
}
/**
 * 
 * @export
 * @interface ProductTypeCreation
 */
export interface ProductTypeCreation {
    /**
     * 
     * @type {string}
     * @memberof ProductTypeCreation
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProductTypeTip
 */
export interface ProductTypeTip {
    /**
     * 
     * @type {number}
     * @memberof ProductTypeTip
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductTypeTip
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof ProductTypeTip
     */
    'link'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductTypeTip
     */
    'productTypeId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductTypeTip
     */
    'typeId'?: number;
    /**
     * 
     * @type {ProductTipType}
     * @memberof ProductTypeTip
     */
    'type'?: ProductTipType;
    /**
     * 
     * @type {ProductType}
     * @memberof ProductTypeTip
     */
    'productType'?: ProductType;
    /**
     * 
     * @type {string}
     * @memberof ProductTypeTip
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductTypeTip
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ProductTypeTipsUpdate
 */
export interface ProductTypeTipsUpdate {
    /**
     * 
     * @type {Array<ProductTypeTipsUpdateTips>}
     * @memberof ProductTypeTipsUpdate
     */
    'tips': Array<ProductTypeTipsUpdateTips>;
}
/**
 * 
 * @export
 * @interface ProductTypeTipsUpdateTips
 */
export interface ProductTypeTipsUpdateTips {
    /**
     * 
     * @type {number}
     * @memberof ProductTypeTipsUpdateTips
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductTypeTipsUpdateTips
     */
    'content': string;
    /**
     * 
     * @type {number}
     * @memberof ProductTypeTipsUpdateTips
     */
    'productTypeId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductTypeTipsUpdateTips
     */
    'typeId': number;
}
/**
 * 
 * @export
 * @interface ProductTypeUpdate
 */
export interface ProductTypeUpdate {
    /**
     * 
     * @type {string}
     * @memberof ProductTypeUpdate
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTypeUpdate
     */
    'isSuitableForWoodMeasurements'?: boolean;
}
/**
 * 
 * @export
 * @interface ProductsDraftsPublishBulk
 */
export interface ProductsDraftsPublishBulk {
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductsDraftsPublishBulk
     */
    'draftIds': Array<number>;
}
/**
 * 
 * @export
 * @interface ProductsDraftsPublishResponse
 */
export interface ProductsDraftsPublishResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ProductsDraftsPublishResponse
     */
    'result': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductsDraftsPublishResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ProductDraft}
     * @memberof ProductsDraftsPublishResponse
     */
    'productDraft'?: ProductDraft;
}
/**
 * 
 * @export
 * @interface ProductsDraftsUpdate
 */
export interface ProductsDraftsUpdate {
    /**
     * 
     * @type {number}
     * @memberof ProductsDraftsUpdate
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductsDraftsUpdate
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsDraftsUpdate
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsDraftsUpdate
     */
    'manufacturerComment'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductsDraftsUpdate
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDraftsUpdate
     */
    'packageTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDraftsUpdate
     */
    'shelfLife'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDraftsUpdate
     */
    'lowTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDraftsUpdate
     */
    'highTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDraftsUpdate
     */
    'lowHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDraftsUpdate
     */
    'highHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDraftsUpdate
     */
    'highSlabMVER'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDraftsUpdate
     */
    'lowSlabRH'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDraftsUpdate
     */
    'highSlabRH'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDraftsUpdate
     */
    'lowSurfaceTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDraftsUpdate
     */
    'highSurfaceTempF'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDraftsUpdate
     */
    'lowSlabPH'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDraftsUpdate
     */
    'highSlabPH'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDraftsUpdate
     */
    'boardWidth'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductsDraftsUpdate
     */
    'boardWidthFraction'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsDraftsUpdate
     */
    'safetyTipType'?: ProductsDraftsUpdateSafetyTipTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductsDraftsUpdate
     */
    'commonTipType'?: ProductsDraftsUpdateCommonTipTypeEnum;
    /**
     * 
     * @type {Array<ProductTipEditing>}
     * @memberof ProductsDraftsUpdate
     */
    'commonCustomTips'?: Array<ProductTipEditing>;
    /**
     * 
     * @type {Array<ProductTipEditing>}
     * @memberof ProductsDraftsUpdate
     */
    'safetyCustomTips'?: Array<ProductTipEditing>;
}

export const ProductsDraftsUpdateSafetyTipTypeEnum = {
    Default: 'default',
    Custom: 'custom'
} as const;

export type ProductsDraftsUpdateSafetyTipTypeEnum = typeof ProductsDraftsUpdateSafetyTipTypeEnum[keyof typeof ProductsDraftsUpdateSafetyTipTypeEnum];
export const ProductsDraftsUpdateCommonTipTypeEnum = {
    Default: 'default',
    Custom: 'custom'
} as const;

export type ProductsDraftsUpdateCommonTipTypeEnum = typeof ProductsDraftsUpdateCommonTipTypeEnum[keyof typeof ProductsDraftsUpdateCommonTipTypeEnum];

/**
 * 
 * @export
 * @interface ProductsDraftsUpdateResponse
 */
export interface ProductsDraftsUpdateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ProductsDraftsUpdateResponse
     */
    'result': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductsDraftsUpdateResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ProductDraft}
     * @memberof ProductsDraftsUpdateResponse
     */
    'product'?: ProductDraft;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'address2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'fullAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'area'?: number;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'buildingTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'generalContractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'contractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'architectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    'isActiveProject'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    'isArchivedProject'?: boolean;
    /**
     * 
     * @type {GeneralContractor}
     * @memberof Project
     */
    'generalContractor'?: GeneralContractor;
    /**
     * 
     * @type {Contractor}
     * @memberof Project
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Architect}
     * @memberof Project
     */
    'architect'?: Architect;
    /**
     * 
     * @type {Array<ProjectSection>}
     * @memberof Project
     */
    'sections'?: Array<ProjectSection>;
    /**
     * 
     * @type {Array<User>}
     * @memberof Project
     */
    'users'?: Array<User>;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'photo'?: string;
    /**
     * 
     * @type {ProjectType}
     * @memberof Project
     */
    'projectType'?: ProjectType;
    /**
     * 
     * @type {BuildingType}
     * @memberof Project
     */
    'buildingType'?: BuildingType;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    'isActiveNotifications'?: boolean;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Project
     */
    'attachments'?: Array<Attachment>;
    /**
     * 
     * @type {Array<Event>}
     * @memberof Project
     */
    'events'?: Array<Event>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Project
     */
    'documents'?: Array<Document>;
    /**
     * 
     * @type {UserProject}
     * @memberof Project
     */
    'usersProjects'?: UserProject;
}
/**
 * 
 * @export
 * @interface ProjectArchiving
 */
export interface ProjectArchiving {
    /**
     * 
     * @type {string}
     * @memberof ProjectArchiving
     */
    'note'?: string;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof ProjectArchiving
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface ProjectBuildingTypeItemDashboardStatistics
 */
export interface ProjectBuildingTypeItemDashboardStatistics {
    /**
     * 
     * @type {string}
     * @memberof ProjectBuildingTypeItemDashboardStatistics
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectBuildingTypeItemDashboardStatistics
     */
    'projectsNumber': number;
}
/**
 * 
 * @export
 * @interface ProjectCreation
 */
export interface ProjectCreation {
    /**
     * 
     * @type {string}
     * @memberof ProjectCreation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreation
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreation
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreation
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreation
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreation
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreation
     */
    'address2'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreation
     */
    'area'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreation
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreation
     */
    'buildingTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreation
     */
    'contractorId': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreation
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreation
     */
    'longitude'?: number;
    /**
     * 
     * @type {ProjectCreationGeneralContractor}
     * @memberof ProjectCreation
     */
    'generalContractor'?: ProjectCreationGeneralContractor;
    /**
     * 
     * @type {ProjectCreationGeneralContractor}
     * @memberof ProjectCreation
     */
    'architect'?: ProjectCreationGeneralContractor;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ProjectCreation
     */
    'photo'?: AttachmentParams;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof ProjectCreation
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface ProjectCreationGeneralContractor
 */
export interface ProjectCreationGeneralContractor {
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationGeneralContractor
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationGeneralContractor
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationGeneralContractor
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationGeneralContractor
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationGeneralContractor
     */
    'contactPerson'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationGeneralContractor
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface ProjectCreationV2
 */
export interface ProjectCreationV2 {
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationV2
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationV2
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationV2
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationV2
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationV2
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationV2
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationV2
     */
    'address2'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreationV2
     */
    'area'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreationV2
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreationV2
     */
    'buildingTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreationV2
     */
    'contractorId': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreationV2
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreationV2
     */
    'longitude'?: number;
    /**
     * 
     * @type {ProjectCreationGeneralContractor}
     * @memberof ProjectCreationV2
     */
    'generalContractor'?: ProjectCreationGeneralContractor;
    /**
     * 
     * @type {ProjectCreationGeneralContractor}
     * @memberof ProjectCreationV2
     */
    'architect'?: ProjectCreationGeneralContractor;
    /**
     * 
     * @type {ProjectCreationV2Photo}
     * @memberof ProjectCreationV2
     */
    'photo'?: ProjectCreationV2Photo;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof ProjectCreationV2
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface ProjectCreationV2Photo
 */
export interface ProjectCreationV2Photo {
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ProjectCreationV2Photo
     */
    'original'?: AttachmentParams;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ProjectCreationV2Photo
     */
    'thumbnail'?: AttachmentParams;
}
/**
 * 
 * @export
 * @interface ProjectCreationV3
 */
export interface ProjectCreationV3 {
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationV3
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationV3
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationV3
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationV3
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationV3
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationV3
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationV3
     */
    'address2'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreationV3
     */
    'area'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreationV3
     */
    'typeId': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreationV3
     */
    'buildingTypeId': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreationV3
     */
    'contractorId': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreationV3
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreationV3
     */
    'longitude'?: number;
    /**
     * 
     * @type {ProjectCreationGeneralContractor}
     * @memberof ProjectCreationV3
     */
    'generalContractor'?: ProjectCreationGeneralContractor;
    /**
     * 
     * @type {ProjectCreationGeneralContractor}
     * @memberof ProjectCreationV3
     */
    'architect'?: ProjectCreationGeneralContractor;
    /**
     * 
     * @type {ProjectCreationV2Photo}
     * @memberof ProjectCreationV3
     */
    'photo'?: ProjectCreationV2Photo;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof ProjectCreationV3
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface ProjectDashboardStatistics
 */
export interface ProjectDashboardStatistics {
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardStatistics
     */
    'statesNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardStatistics
     */
    'contractorsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardStatistics
     */
    'activatedContractorsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardStatistics
     */
    'deactivatedContractorsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardStatistics
     */
    'paidContractorsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardStatistics
     */
    'paidActivatedContractorsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardStatistics
     */
    'paidDeactivatedContractorsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardStatistics
     */
    'usersNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardStatistics
     */
    'activeUsersNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardStatistics
     */
    'archivedUsersNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardStatistics
     */
    'projectsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardStatistics
     */
    'activeProjectsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardStatistics
     */
    'archivedProjectsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardStatistics
     */
    'sectionsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardStatistics
     */
    'activeSectionsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardStatistics
     */
    'archivedSectionsNumber': number;
}
/**
 * 
 * @export
 * @interface ProjectSection
 */
export interface ProjectSection {
    /**
     * 
     * @type {number}
     * @memberof ProjectSection
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectSection
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectSection
     */
    'projectId': number;
    /**
     * 
     * @type {Array<Sensor>}
     * @memberof ProjectSection
     */
    'sensors'?: Array<Sensor>;
    /**
     * 
     * @type {Array<ProjectSectionSensor>}
     * @memberof ProjectSection
     */
    'projectSectionsSensors'?: Array<ProjectSectionSensor>;
    /**
     * 
     * @type {Array<Product>}
     * @memberof ProjectSection
     */
    'products'?: Array<Product>;
    /**
     * 
     * @type {Array<Task>}
     * @memberof ProjectSection
     */
    'tasks'?: Array<Task>;
    /**
     * 
     * @type {Array<SpecificLocation>}
     * @memberof ProjectSection
     */
    'specificLocations'?: Array<SpecificLocation>;
    /**
     * 
     * @type {Array<ProjectSectionAlerts>}
     * @memberof ProjectSection
     */
    'alerts'?: Array<ProjectSectionAlerts>;
    /**
     * 
     * @type {Array<CurrentAlertData>}
     * @memberof ProjectSection
     */
    'currentAlertData'?: Array<CurrentAlertData>;
    /**
     * 
     * @type {string}
     * @memberof ProjectSection
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSection
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSection
     */
    'archivedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSection
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSection
     */
    'isActiveSection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSection
     */
    'isArchivedSection'?: boolean;
    /**
     * 
     * @type {WagnerProbeSummary}
     * @memberof ProjectSection
     */
    'wagnerProbeSummary'?: WagnerProbeSummary;
    /**
     * 
     * @type {Array<WagnerProbe>}
     * @memberof ProjectSection
     */
    'wagnerProbes'?: Array<WagnerProbe>;
    /**
     * 
     * @type {Array<SlabConditionRanges>}
     * @memberof ProjectSection
     */
    'slabConditionRanges'?: Array<SlabConditionRanges>;
    /**
     * 
     * @type {Project}
     * @memberof ProjectSection
     */
    'project'?: Project;
}
/**
 * 
 * @export
 * @interface ProjectSectionAlerts
 */
export interface ProjectSectionAlerts {
    /**
     * 
     * @type {number}
     * @memberof ProjectSectionAlerts
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface ProjectSectionArchiving
 */
export interface ProjectSectionArchiving {
    /**
     * 
     * @type {string}
     * @memberof ProjectSectionArchiving
     */
    'note'?: string;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof ProjectSectionArchiving
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface ProjectSectionCreation
 */
export interface ProjectSectionCreation {
    /**
     * 
     * @type {string}
     * @memberof ProjectSectionCreation
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProjectSectionCreation
     */
    'productIds'?: Array<number>;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof ProjectSectionCreation
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface ProjectSectionSensor
 */
export interface ProjectSectionSensor {
    /**
     * 
     * @type {number}
     * @memberof ProjectSectionSensor
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSectionSensor
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectSectionSensor
     */
    'disconnectTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSectionSensor
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSectionSensor
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectSectionSensor
     */
    'projectId': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSectionSensor
     */
    'projectSectionId': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSectionSensor
     */
    'sensorId': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSectionSensor
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSectionSensor
     */
    'contractorId': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectSectionSensor
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSectionSensor
     */
    'updatedAt': string;
    /**
     * 
     * @type {Project}
     * @memberof ProjectSectionSensor
     */
    'project'?: Project;
    /**
     * 
     * @type {Sensor}
     * @memberof ProjectSectionSensor
     */
    'sensor'?: Sensor;
    /**
     * 
     * @type {SpecificLocation}
     * @memberof ProjectSectionSensor
     */
    'specificLocation'?: SpecificLocation;
    /**
     * 
     * @type {ProjectSection}
     * @memberof ProjectSectionSensor
     */
    'projectSection'?: ProjectSection;
    /**
     * 
     * @type {Array<SensorRangeHistory>}
     * @memberof ProjectSectionSensor
     */
    'ranges'?: Array<SensorRangeHistory>;
    /**
     * 
     * @type {Array<Alert>}
     * @memberof ProjectSectionSensor
     */
    'alerts'?: Array<Alert>;
    /**
     * 
     * @type {string}
     * @memberof ProjectSectionSensor
     */
    'firstReadingAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSectionSensor
     */
    'lastReadingAt'?: string;
}
/**
 * 
 * @export
 * @interface ProjectSectionUnarchiving
 */
export interface ProjectSectionUnarchiving {
    /**
     * 
     * @type {EventCoordinates}
     * @memberof ProjectSectionUnarchiving
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface ProjectStats
 */
export interface ProjectStats {
    /**
     * 
     * @type {number}
     * @memberof ProjectStats
     */
    'projectId': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectStats
     */
    'sensorsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectStats
     */
    'activeAlertsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectStats
     */
    'pastAlertsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectStats
     */
    'probesCount': number;
}
/**
 * 
 * @export
 * @interface ProjectType
 */
export interface ProjectType {
    /**
     * 
     * @type {number}
     * @memberof ProjectType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectType
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectType
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectType
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ProjectTypeItemDashboardStatistics
 */
export interface ProjectTypeItemDashboardStatistics {
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItemDashboardStatistics
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeItemDashboardStatistics
     */
    'projectsNumber': number;
}
/**
 * 
 * @export
 * @interface ProjectUnarchiving
 */
export interface ProjectUnarchiving {
    /**
     * 
     * @type {EventCoordinates}
     * @memberof ProjectUnarchiving
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface ProjectUpdating
 */
export interface ProjectUpdating {
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdating
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdating
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdating
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdating
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdating
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdating
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdating
     */
    'address2'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdating
     */
    'area'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdating
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdating
     */
    'buildingTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdating
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdating
     */
    'longitude'?: number;
    /**
     * 
     * @type {ProjectCreationGeneralContractor}
     * @memberof ProjectUpdating
     */
    'generalContractor'?: ProjectCreationGeneralContractor;
    /**
     * 
     * @type {ProjectCreationGeneralContractor}
     * @memberof ProjectUpdating
     */
    'architect'?: ProjectCreationGeneralContractor;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ProjectUpdating
     */
    'photo'?: AttachmentParams;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof ProjectUpdating
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface ProjectUpdatingV2
 */
export interface ProjectUpdatingV2 {
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdatingV2
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdatingV2
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdatingV2
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdatingV2
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdatingV2
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdatingV2
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdatingV2
     */
    'address2'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdatingV2
     */
    'area'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdatingV2
     */
    'typeId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdatingV2
     */
    'buildingTypeId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdatingV2
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdatingV2
     */
    'longitude'?: number;
    /**
     * 
     * @type {ProjectCreationGeneralContractor}
     * @memberof ProjectUpdatingV2
     */
    'generalContractor'?: ProjectCreationGeneralContractor;
    /**
     * 
     * @type {ProjectCreationGeneralContractor}
     * @memberof ProjectUpdatingV2
     */
    'architect'?: ProjectCreationGeneralContractor;
    /**
     * 
     * @type {ProjectUpdatingV3Photo}
     * @memberof ProjectUpdatingV2
     */
    'photo': ProjectUpdatingV3Photo | null;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof ProjectUpdatingV2
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface ProjectUpdatingV3
 */
export interface ProjectUpdatingV3 {
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdatingV3
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdatingV3
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdatingV3
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdatingV3
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdatingV3
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdatingV3
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdatingV3
     */
    'address2'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdatingV3
     */
    'area': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdatingV3
     */
    'typeId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdatingV3
     */
    'buildingTypeId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdatingV3
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdatingV3
     */
    'longitude'?: number;
    /**
     * 
     * @type {ProjectCreationGeneralContractor}
     * @memberof ProjectUpdatingV3
     */
    'generalContractor'?: ProjectCreationGeneralContractor;
    /**
     * 
     * @type {ProjectCreationGeneralContractor}
     * @memberof ProjectUpdatingV3
     */
    'architect'?: ProjectCreationGeneralContractor;
    /**
     * 
     * @type {ProjectUpdatingV3Photo}
     * @memberof ProjectUpdatingV3
     */
    'photo': ProjectUpdatingV3Photo | null;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof ProjectUpdatingV3
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface ProjectUpdatingV3Photo
 */
export interface ProjectUpdatingV3Photo {
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ProjectUpdatingV3Photo
     */
    'original'?: AttachmentParams;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ProjectUpdatingV3Photo
     */
    'thumbnail'?: AttachmentParams;
}
/**
 * 
 * @export
 * @interface RapidRHSensorDashboardStatistics
 */
export interface RapidRHSensorDashboardStatistics {
    /**
     * 
     * @type {number}
     * @memberof RapidRHSensorDashboardStatistics
     */
    'sensorsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof RapidRHSensorDashboardStatistics
     */
    'inUseSensorsNumber': number;
}
/**
 * 
 * @export
 * @interface Readout
 */
export interface Readout {
    /**
     * 
     * @type {Array<Temperature>}
     * @memberof Readout
     */
    'temperature'?: Array<Temperature>;
    /**
     * 
     * @type {Array<Humidity>}
     * @memberof Readout
     */
    'humidity'?: Array<Humidity>;
    /**
     * 
     * @type {Array<Dewpoint>}
     * @memberof Readout
     */
    'dewpoint'?: Array<Dewpoint>;
    /**
     * 
     * @type {Array<Battery>}
     * @memberof Readout
     */
    'battery'?: Array<Battery>;
    /**
     * 
     * @type {Array<SignalStrength>}
     * @memberof Readout
     */
    'signalStrength'?: Array<SignalStrength>;
    /**
     * 
     * @type {Array<Light>}
     * @memberof Readout
     */
    'light'?: Array<Light>;
    /**
     * 
     * @type {Array<Pressure>}
     * @memberof Readout
     */
    'pressure'?: Array<Pressure>;
    /**
     * 
     * @type {Array<Location>}
     * @memberof Readout
     */
    'cellularLocation'?: Array<Location>;
    /**
     * 
     * @type {Array<Location>}
     * @memberof Readout
     */
    'gpsLocation'?: Array<Location>;
    /**
     * 
     * @type {Array<WagnerProbeReading>}
     * @memberof Readout
     */
    'ble'?: Array<WagnerProbeReading>;
}
/**
 * 
 * @export
 * @interface RefreshLinkPayload
 */
export interface RefreshLinkPayload {
    /**
     * 
     * @type {string}
     * @memberof RefreshLinkPayload
     */
    'link': string;
}
/**
 * 
 * @export
 * @interface Release
 */
export interface Release {
    /**
     * 
     * @type {number}
     * @memberof Release
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Release
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof Release
     */
    'description': string;
    /**
     * 
     * @type {Array<Feature>}
     * @memberof Release
     */
    'features': Array<Feature>;
    /**
     * 
     * @type {string}
     * @memberof Release
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Release
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ReleaseCreation
 */
export interface ReleaseCreation {
    /**
     * 
     * @type {string}
     * @memberof ReleaseCreation
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseCreation
     */
    'description': string;
    /**
     * 
     * @type {Array<FeatureCreationParams>}
     * @memberof ReleaseCreation
     */
    'features'?: Array<FeatureCreationParams>;
}
/**
 * 
 * @export
 * @interface ReleaseEditing
 */
export interface ReleaseEditing {
    /**
     * 
     * @type {string}
     * @memberof ReleaseEditing
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReleaseEditing
     */
    'description'?: string;
    /**
     * 
     * @type {Array<FeatureEditingParams>}
     * @memberof ReleaseEditing
     */
    'features'?: Array<FeatureEditingParams>;
}
/**
 * 
 * @export
 * @interface RemoveForeman
 */
export interface RemoveForeman {
    /**
     * 
     * @type {number}
     * @memberof RemoveForeman
     */
    'projectId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof RemoveForeman
     */
    'userIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface RemoveForemanMobile
 */
export interface RemoveForemanMobile {
    /**
     * 
     * @type {number}
     * @memberof RemoveForemanMobile
     */
    'projectId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof RemoveForemanMobile
     */
    'userIds': Array<number>;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof RemoveForemanMobile
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface RemoveForemanResponse
 */
export interface RemoveForemanResponse {
    /**
     * 
     * @type {number}
     * @memberof RemoveForemanResponse
     */
    'projectId': number;
    /**
     * 
     * @type {number}
     * @memberof RemoveForemanResponse
     */
    'userId': number;
    /**
     * 
     * @type {boolean}
     * @memberof RemoveForemanResponse
     */
    'result': boolean;
    /**
     * 
     * @type {string}
     * @memberof RemoveForemanResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface RemoveUserFromProjects
 */
export interface RemoveUserFromProjects {
    /**
     * 
     * @type {Array<number>}
     * @memberof RemoveUserFromProjects
     */
    'projectIds': Array<number>;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof RemoveUserFromProjects
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {number}
     * @memberof Report
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'status': string;
    /**
     * 
     * @type {boolean}
     * @memberof Report
     */
    'isIncludedGeneralContractor': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Report
     */
    'isIncludedArchitector': boolean;
    /**
     * 
     * @type {ReportSettings}
     * @memberof Report
     */
    'settings'?: ReportSettings;
    /**
     * 
     * @type {number}
     * @memberof Report
     */
    'typeId': number;
    /**
     * 
     * @type {number}
     * @memberof Report
     */
    'projectId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'projectName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'downloadLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'expiredAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'bucket'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'fileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Report
     */
    'authorId'?: number;
    /**
     * 
     * @type {User}
     * @memberof Report
     */
    'author'?: User;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'timezoneName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Report
     */
    'utcOffset'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Report
     */
    'isAdminAuthor'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Report
     */
    'isHiddenProducts'?: boolean;
    /**
     * 
     * @type {Array<ProjectSection>}
     * @memberof Report
     */
    'projectSections'?: Array<ProjectSection>;
    /**
     * 
     * @type {Array<Project>}
     * @memberof Report
     */
    'projects'?: Array<Project>;
    /**
     * 
     * @type {Array<ReportEvent>}
     * @memberof Report
     */
    'reportsEvents'?: Array<ReportEvent>;
    /**
     * 
     * @type {boolean}
     * @memberof Report
     */
    'isAllProjectSectionsSelected'?: boolean;
    /**
     * 
     * @type {Contractor}
     * @memberof Report
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {ReportType}
     * @memberof Report
     */
    'type'?: ReportType;
    /**
     * 
     * @type {Array<Subreport>}
     * @memberof Report
     */
    'subreports'?: Array<Subreport>;
    /**
     * 
     * @type {Array<ReportProjectSection>}
     * @memberof Report
     */
    'reportProjectSections'?: Array<ReportProjectSection>;
    /**
     * 
     * @type {Array<ReportComponent>}
     * @memberof Report
     */
    'components'?: Array<ReportComponent>;
}
/**
 * 
 * @export
 * @interface ReportComponent
 */
export interface ReportComponent {
    /**
     * 
     * @type {number}
     * @memberof ReportComponent
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportComponent
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportComponent
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportComponent
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportComponent
     */
    'isVisible'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportComponent
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportComponent
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ReportCreation
 */
export interface ReportCreation {
    /**
     * 
     * @type {string}
     * @memberof ReportCreation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReportCreation
     */
    'summary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreation
     */
    'isIncludedGeneralContractor': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreation
     */
    'isIncludedArchitector': boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportCreation
     */
    'type': ReportCreationTypeEnum;
    /**
     * 
     * @type {ReportCreationSettings}
     * @memberof ReportCreation
     */
    'settings': ReportCreationSettings;
    /**
     * 
     * @type {Array<ReportEventCreation>}
     * @memberof ReportCreation
     */
    'events': Array<ReportEventCreation>;
    /**
     * 
     * @type {string}
     * @memberof ReportCreation
     */
    'timezoneName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportCreation
     */
    'utcOffset': number;
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreation
     */
    'isHiddenProducts'?: boolean;
}

export const ReportCreationTypeEnum = {
    Weekly: 'weekly'
} as const;

export type ReportCreationTypeEnum = typeof ReportCreationTypeEnum[keyof typeof ReportCreationTypeEnum];

/**
 * 
 * @export
 * @interface ReportCreationSettings
 */
export interface ReportCreationSettings {
    /**
     * 
     * @type {number}
     * @memberof ReportCreationSettings
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCreationSettings
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportCreationSettings
     */
    'dateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportCreationSettings
     */
    'dateTo'?: string;
}
/**
 * 
 * @export
 * @interface ReportCreationV2
 */
export interface ReportCreationV2 {
    /**
     * 
     * @type {string}
     * @memberof ReportCreationV2
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReportCreationV2
     */
    'summary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreationV2
     */
    'isIncludedGeneralContractor': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreationV2
     */
    'isIncludedArchitector': boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportCreationV2
     */
    'type': ReportCreationV2TypeEnum;
    /**
     * 
     * @type {ReportCreationV2Settings}
     * @memberof ReportCreationV2
     */
    'settings': ReportCreationV2Settings;
    /**
     * 
     * @type {ReportCreationV2Events}
     * @memberof ReportCreationV2
     */
    'events'?: ReportCreationV2Events;
    /**
     * 
     * @type {string}
     * @memberof ReportCreationV2
     */
    'timezoneName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportCreationV2
     */
    'utcOffset': number;
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreationV2
     */
    'isHiddenProducts'?: boolean;
}

export const ReportCreationV2TypeEnum = {
    Weekly: 'weekly',
    JobSiteConditions: 'job_site_conditions',
    SlabConditions: 'slab_conditions'
} as const;

export type ReportCreationV2TypeEnum = typeof ReportCreationV2TypeEnum[keyof typeof ReportCreationV2TypeEnum];

/**
 * 
 * @export
 * @interface ReportCreationV2Events
 */
export interface ReportCreationV2Events {
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreationV2Events
     */
    'includeAll': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreationV2Events
     */
    'projectSectionIdRequired': boolean;
    /**
     * 
     * @type {Array<ReportEventCreation>}
     * @memberof ReportCreationV2Events
     */
    'include'?: Array<ReportEventCreation>;
    /**
     * 
     * @type {Array<ReportEventCreation>}
     * @memberof ReportCreationV2Events
     */
    'exclude'?: Array<ReportEventCreation>;
    /**
     * 
     * @type {Array<ReportEventCreation>}
     * @memberof ReportCreationV2Events
     */
    'excludePhotos'?: Array<ReportEventCreation>;
}
/**
 * 
 * @export
 * @interface ReportCreationV2Settings
 */
export interface ReportCreationV2Settings {
    /**
     * 
     * @type {number}
     * @memberof ReportCreationV2Settings
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCreationV2Settings
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportCreationV2Settings
     */
    'dateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportCreationV2Settings
     */
    'dateTo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreationV2Settings
     */
    'isIncludedSlabCharts'?: boolean;
}
/**
 * 
 * @export
 * @interface ReportCreationV3
 */
export interface ReportCreationV3 {
    /**
     * 
     * @type {string}
     * @memberof ReportCreationV3
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportCreationV3
     */
    'summary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreationV3
     */
    'isIncludedGeneralContractor'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreationV3
     */
    'isIncludedArchitector'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportCreationV3
     */
    'type': ReportCreationV3TypeEnum;
    /**
     * 
     * @type {ReportSettings}
     * @memberof ReportCreationV3
     */
    'settings': ReportSettings;
    /**
     * 
     * @type {ReportCreationV3Events}
     * @memberof ReportCreationV3
     */
    'events'?: ReportCreationV3Events;
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreationV3
     */
    'isHiddenProducts'?: boolean;
}

export const ReportCreationV3TypeEnum = {
    Weekly: 'weekly',
    JobSiteConditions: 'job_site_conditions',
    SlabConditions: 'slab_conditions',
    ClimateRequirements: 'climate_requirements',
    ClimateConditions: 'climate_conditions',
    ContractorProjects: 'contractor_projects',
    CombinedReport: 'combined_report'
} as const;

export type ReportCreationV3TypeEnum = typeof ReportCreationV3TypeEnum[keyof typeof ReportCreationV3TypeEnum];

/**
 * 
 * @export
 * @interface ReportCreationV3Events
 */
export interface ReportCreationV3Events {
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreationV3Events
     */
    'includeAll': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreationV3Events
     */
    'projectSectionIdRequired': boolean;
    /**
     * 
     * @type {Array<ReportEventCreation>}
     * @memberof ReportCreationV3Events
     */
    'include'?: Array<ReportEventCreation>;
    /**
     * 
     * @type {Array<ReportEventCreation>}
     * @memberof ReportCreationV3Events
     */
    'exclude'?: Array<ReportEventCreation>;
    /**
     * 
     * @type {Array<ReportEventCreation>}
     * @memberof ReportCreationV3Events
     */
    'excludePhotos'?: Array<ReportEventCreation>;
    /**
     * 
     * @type {ReportCreationV3EventsFilters}
     * @memberof ReportCreationV3Events
     */
    'filters'?: ReportCreationV3EventsFilters;
}
/**
 * 
 * @export
 * @interface ReportCreationV3EventsFilters
 */
export interface ReportCreationV3EventsFilters {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportCreationV3EventsFilters
     */
    'typeId'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreationV3EventsFilters
     */
    'withMedia'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportCreationV3EventsFilters
     */
    'distributedToAll'?: boolean;
}
/**
 * 
 * @export
 * @interface ReportEvent
 */
export interface ReportEvent {
    /**
     * 
     * @type {number}
     * @memberof ReportEvent
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportEvent
     */
    'reportId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportEvent
     */
    'eventId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportEvent
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportEvent
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {Report}
     * @memberof ReportEvent
     */
    'report'?: Report;
    /**
     * 
     * @type {Event}
     * @memberof ReportEvent
     */
    'event'?: Event;
    /**
     * 
     * @type {Array<ReportEventAttachment>}
     * @memberof ReportEvent
     */
    'attachments'?: Array<ReportEventAttachment>;
}
/**
 * 
 * @export
 * @interface ReportEventAttachment
 */
export interface ReportEventAttachment {
    /**
     * 
     * @type {number}
     * @memberof ReportEventAttachment
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportEventAttachment
     */
    'reportEventId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportEventAttachment
     */
    'bucket'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportEventAttachment
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportEventAttachment
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportEventAttachment
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ReportEventAttachmentCreation
 */
export interface ReportEventAttachmentCreation {
    /**
     * 
     * @type {string}
     * @memberof ReportEventAttachmentCreation
     */
    'bucket': string;
    /**
     * 
     * @type {string}
     * @memberof ReportEventAttachmentCreation
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface ReportEventCreation
 */
export interface ReportEventCreation {
    /**
     * 
     * @type {number}
     * @memberof ReportEventCreation
     */
    'eventId': number;
    /**
     * 
     * @type {Array<ReportEventAttachmentCreation>}
     * @memberof ReportEventCreation
     */
    'photo'?: Array<ReportEventAttachmentCreation>;
}
/**
 * 
 * @export
 * @interface ReportLinkRegeneration
 */
export interface ReportLinkRegeneration {
    /**
     * 
     * @type {number}
     * @memberof ReportLinkRegeneration
     */
    'reportId': number;
}
/**
 * 
 * @export
 * @interface ReportProjectSection
 */
export interface ReportProjectSection {
    /**
     * 
     * @type {number}
     * @memberof ReportProjectSection
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportProjectSection
     */
    'reportId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportProjectSection
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportProjectSection
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportProjectSection
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {ProjectSection}
     * @memberof ReportProjectSection
     */
    'projectSection'?: ProjectSection;
    /**
     * 
     * @type {Report}
     * @memberof ReportProjectSection
     */
    'report'?: Report;
}
/**
 * 
 * @export
 * @interface ReportSettings
 */
export interface ReportSettings {
    /**
     * 
     * @type {number}
     * @memberof ReportSettings
     */
    'projectId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportSettings
     */
    'projectIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ReportSettings
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportSettings
     */
    'projectSectionIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ReportSettings
     */
    'dateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportSettings
     */
    'dateTo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportSettings
     */
    'isIncludedSlabCharts'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportSettings
     */
    'timezoneName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportSettings
     */
    'timezone'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportSettings
     */
    'utcOffset'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportSettings
     */
    'componentIds'?: Array<number>;
    /**
     * 
     * @type {Array<ReportSettingsSubreports>}
     * @memberof ReportSettings
     */
    'subreports'?: Array<ReportSettingsSubreports>;
}
/**
 * 
 * @export
 * @interface ReportSettingsSubreports
 */
export interface ReportSettingsSubreports {
    /**
     * 
     * @type {number}
     * @memberof ReportSettingsSubreports
     */
    'projectId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportSettingsSubreports
     */
    'projectSectionIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ReportSettingsSubreports
     */
    'dateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportSettingsSubreports
     */
    'dateTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportSettingsSubreports
     */
    'timezoneName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportSettingsSubreports
     */
    'timezone'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportSettingsSubreports
     */
    'utcOffset'?: number;
}
/**
 * 
 * @export
 * @interface ReportType
 */
export interface ReportType {
    /**
     * 
     * @type {number}
     * @memberof ReportType
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReportType
     */
    'displayName': string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportType
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportType
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportType
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportType
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ReportTypeItemDashboardStatistics
 */
export interface ReportTypeItemDashboardStatistics {
    /**
     * 
     * @type {string}
     * @memberof ReportTypeItemDashboardStatistics
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ReportTypeItemDashboardStatistics
     */
    'reportsNumber': number;
}
/**
 * 
 * @export
 * @interface SaveFirebaseRegistrationTokenPayload
 */
export interface SaveFirebaseRegistrationTokenPayload {
    /**
     * 
     * @type {string}
     * @memberof SaveFirebaseRegistrationTokenPayload
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface SendCodePayload
 */
export interface SendCodePayload {
    /**
     * 
     * @type {string}
     * @memberof SendCodePayload
     */
    'to': string;
}
/**
 * 
 * @export
 * @interface SendCodeResponse
 */
export interface SendCodeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SendCodeResponse
     */
    'status': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SendCodeResponse
     */
    'termsAndPrivacyAccepted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SendCodeResponse
     */
    'isAgreementAccepted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SendCodeResponse
     */
    'termsOfUseLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendCodeResponse
     */
    'privacyPolicyLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendCodeResponse
     */
    'contractorAgreementLink'?: string;
}
/**
 * 
 * @export
 * @interface Sensor
 */
export interface Sensor {
    /**
     * 
     * @type {number}
     * @memberof Sensor
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'deviceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'imei'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'devEUI'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'applicationEUI'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'applicationKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof Sensor
     */
    'isOnline'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'onlineStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'uniqueId': string;
    /**
     * 
     * @type {number}
     * @memberof Sensor
     */
    'stateId': number;
    /**
     * 
     * @type {number}
     * @memberof Sensor
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Sensor
     */
    'modelId'?: number;
    /**
     * 
     * @type {SensorModel}
     * @memberof Sensor
     */
    'sensorModel'?: SensorModel;
    /**
     * 
     * @type {SensorCurrentData}
     * @memberof Sensor
     */
    'currentData'?: SensorCurrentData;
    /**
     * 
     * @type {SensorCurrentConfig}
     * @memberof Sensor
     */
    'currentConfig'?: SensorCurrentConfig;
    /**
     * 
     * @type {Array<ProjectSectionSensor>}
     * @memberof Sensor
     */
    'projectSectionsSensors'?: Array<ProjectSectionSensor>;
    /**
     * 
     * @type {Readout}
     * @memberof Sensor
     */
    'readout'?: Readout;
    /**
     * 
     * @type {SensorState}
     * @memberof Sensor
     */
    'state'?: SensorState;
    /**
     * 
     * @type {SensorType}
     * @memberof Sensor
     */
    'type'?: SensorType;
    /**
     * 
     * @type {Array<Contractor>}
     * @memberof Sensor
     */
    'contractors'?: Array<Contractor>;
    /**
     * 
     * @type {Array<ContractorSensor>}
     * @memberof Sensor
     */
    'contractorSensors'?: Array<ContractorSensor>;
    /**
     * 
     * @type {ProjectSectionSensor}
     * @memberof Sensor
     */
    'ProjectSectionSensor'?: ProjectSectionSensor;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'firmware'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Sensor
     */
    'isOutdatedData'?: boolean;
    /**
     * 
     * @type {SensorLastSentConfiguration}
     * @memberof Sensor
     */
    'lastSentConfig'?: SensorLastSentConfiguration;
    /**
     * 
     * @type {ThingspaceSensor}
     * @memberof Sensor
     */
    'thingspaceSensor'?: ThingspaceSensor;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'technicalStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'statusUpdatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'comment'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Sensor
     */
    'isEnabledBluetooth'?: boolean;
    /**
     * 
     * @type {Array<FirmwareHistoryItem>}
     * @memberof Sensor
     */
    'firmwareHistory'?: Array<FirmwareHistoryItem>;
    /**
     * 
     * @type {boolean}
     * @memberof Sensor
     */
    'isStagingSensor'?: boolean;
    /**
     * 
     * @type {Array<Event>}
     * @memberof Sensor
     */
    'events'?: Array<Event>;
}
/**
 * 
 * @export
 * @interface SensorAssignPayload
 */
export interface SensorAssignPayload {
    /**
     * 
     * @type {number}
     * @memberof SensorAssignPayload
     */
    'contractorId': number;
    /**
     * 
     * @type {string}
     * @memberof SensorAssignPayload
     */
    'note'?: string;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof SensorAssignPayload
     */
    'coordinates'?: EventCoordinates;
    /**
     * 
     * @type {Array<ProjectCreationV2Photo>}
     * @memberof SensorAssignPayload
     */
    'photo'?: Array<ProjectCreationV2Photo>;
    /**
     * 
     * @type {Array<ProjectCreationV2Photo>}
     * @memberof SensorAssignPayload
     */
    'video'?: Array<ProjectCreationV2Photo>;
    /**
     * 
     * @type {Array<AttachmentParams>}
     * @memberof SensorAssignPayload
     */
    'file'?: Array<AttachmentParams>;
}
/**
 * 
 * @export
 * @interface SensorAssignToContractorPayload
 */
export interface SensorAssignToContractorPayload {
    /**
     * 
     * @type {number}
     * @memberof SensorAssignToContractorPayload
     */
    'contractorId': number | null;
}
/**
 * 
 * @export
 * @interface SensorBackupUplinkItem
 */
export interface SensorBackupUplinkItem {
    /**
     * 
     * @type {string}
     * @memberof SensorBackupUplinkItem
     */
    'link': string;
    /**
     * 
     * @type {string}
     * @memberof SensorBackupUplinkItem
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof SensorBackupUplinkItem
     */
    'datetime': string;
    /**
     * 
     * @type {number}
     * @memberof SensorBackupUplinkItem
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface SensorConfiguration
 */
export interface SensorConfiguration {
    /**
     * 
     * @type {SensorConfigurationDevice}
     * @memberof SensorConfiguration
     */
    'device'?: SensorConfigurationDevice;
    /**
     * 
     * @type {SensorConfigurationBle}
     * @memberof SensorConfiguration
     */
    'ble'?: SensorConfigurationBle;
    /**
     * 
     * @type {SensorConfigurationTemperature}
     * @memberof SensorConfiguration
     */
    'temperature'?: SensorConfigurationTemperature;
    /**
     * 
     * @type {SensorConfigurationTemperature}
     * @memberof SensorConfiguration
     */
    'humidity'?: SensorConfigurationTemperature;
    /**
     * 
     * @type {SensorConfigurationTemperature}
     * @memberof SensorConfiguration
     */
    'pressure'?: SensorConfigurationTemperature;
    /**
     * 
     * @type {SensorConfigurationTemperature}
     * @memberof SensorConfiguration
     */
    'light'?: SensorConfigurationTemperature;
    /**
     * 
     * @type {SensorConfigurationTemperature}
     * @memberof SensorConfiguration
     */
    'location'?: SensorConfigurationTemperature;
    /**
     * 
     * @type {SensorConfigurationTemperature}
     * @memberof SensorConfiguration
     */
    'accelerometer'?: SensorConfigurationTemperature;
    /**
     * 
     * @type {SensorConfigurationTemperature}
     * @memberof SensorConfiguration
     */
    'gyro'?: SensorConfigurationTemperature;
    /**
     * 
     * @type {SensorConfigurationTemperature}
     * @memberof SensorConfiguration
     */
    'battery'?: SensorConfigurationTemperature;
    /**
     * 
     * @type {SensorConfigurationTemperature}
     * @memberof SensorConfiguration
     */
    'rfSignal'?: SensorConfigurationTemperature;
}
/**
 * 
 * @export
 * @interface SensorConfigurationBle
 */
export interface SensorConfigurationBle {
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationBle
     */
    'monitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationBle
     */
    'opMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationBle
     */
    'reportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationBle
     */
    'reportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationBle
     */
    'reportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationBle
     */
    'scanDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationBle
     */
    'minSigStr'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationBle
     */
    'dataMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationBle
     */
    'maxNumScan'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationBle
     */
    'manufacturerId'?: number;
}
/**
 * 
 * @export
 * @interface SensorConfigurationDevice
 */
export interface SensorConfigurationDevice {
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationDevice
     */
    'commMaxAwakeDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationDevice
     */
    'commMinAwakeDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationDevice
     */
    'commSynchPeriod'?: number;
    /**
     * 
     * @type {string}
     * @memberof SensorConfigurationDevice
     */
    'commTypeUpdate'?: string;
}
/**
 * 
 * @export
 * @interface SensorConfigurationTemperature
 */
export interface SensorConfigurationTemperature {
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationTemperature
     */
    'monitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationTemperature
     */
    'opMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationTemperature
     */
    'reportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationTemperature
     */
    'reportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorConfigurationTemperature
     */
    'reportType'?: number;
}
/**
 * 
 * @export
 * @interface SensorCurrentConfig
 */
export interface SensorCurrentConfig {
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'sensorId': number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'commMaxAwakeDuration': number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'commMinAwakeDuration': number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'commPeriodSet': number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'commSynchPeriod': number;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentConfig
     */
    'commType': string;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentConfig
     */
    'commTypeUpdate': string;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentConfig
     */
    'time': string;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentConfig
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentConfig
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'bleMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'bleOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'bleReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'bleReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'bleReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'bleScanDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'bleMinSigStr'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'bleDataMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'bleMaxNumScan'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'bleManufacturerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'temperatureMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'temperatureOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'temperatureReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'temperatureReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'temperatureReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'humidityMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'humidityOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'humidityReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'humidityReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'humidityReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'pressureMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'pressureOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'pressureReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'pressureReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'pressureReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'lightMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'lightOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'lightReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'lightReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'lightReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'locationMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'locationOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'locationReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'locationReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'locationReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'accelerometerMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'accelerometerOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'accelerometerReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'accelerometerReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'accelerometerReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'gyroMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'gyroOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'gyroReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'gyroReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'gyroReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'batteryMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'batteryOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'batteryReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'batteryReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'batteryReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'rfSignalMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'rfSignalOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'rfSignalReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'rfSignalReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentConfig
     */
    'rfSignalReportType'?: number;
}
/**
 * 
 * @export
 * @interface SensorCurrentData
 */
export interface SensorCurrentData {
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'sensorId': number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'temperatureC'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'temperatureF'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'humidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'dewPointC'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'dewPointF'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'batteryLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'signalStrength'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'dewpointDeviationF'?: number;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'dewpointDeviationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'time'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'temperatureTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'humidityTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'dewPointTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'batteryLevelTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'signalStrengthTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'latitudeCellural'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'longitudeCellural'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'latitudeGps'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'longitudeGps'?: number;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'locationUpdatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'locationGpsUpdatedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'accelerationRms'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'accelerationX'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'accelerationY'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'accelerationZ'?: number;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'accelerationTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'light'?: number;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'lightTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'orientationMotionX'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'orientationMotionY'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'orientationMotionZ'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'orientationTiltZ'?: number;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'orientationTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof SensorCurrentData
     */
    'pressure'?: number;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'pressureTime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SensorCurrentData
     */
    'isInRangeTemperature'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SensorCurrentData
     */
    'isInRangeHumidity'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'firmwareVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'firmwareVersionTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorCurrentData
     */
    'configurationTime'?: string;
}
/**
 * 
 * @export
 * @interface SensorDashboardStatistics
 */
export interface SensorDashboardStatistics {
    /**
     * 
     * @type {number}
     * @memberof SensorDashboardStatistics
     */
    'sensorsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof SensorDashboardStatistics
     */
    'onlineSensorsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof SensorDashboardStatistics
     */
    'freeSensorsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof SensorDashboardStatistics
     */
    'assignedSensorsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof SensorDashboardStatistics
     */
    'inUseSensorsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof SensorDashboardStatistics
     */
    'archivedSensorsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof SensorDashboardStatistics
     */
    'activeInUseSensorsNumber': number;
}
/**
 * 
 * @export
 * @interface SensorDefaultConfiguration
 */
export interface SensorDefaultConfiguration {
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof SensorDefaultConfiguration
     */
    'firmware'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'commMaxAwakeDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'commMinAwakeDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'commSynchPeriod'?: number;
    /**
     * 
     * @type {string}
     * @memberof SensorDefaultConfiguration
     */
    'commTypeUpdate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorDefaultConfiguration
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorDefaultConfiguration
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'bleMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'bleOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'bleReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'bleReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'bleReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'bleScanDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'bleMinSigStr'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'bleDataMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'bleMaxNumScan'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'bleManufacturerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'temperatureMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'temperatureOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'temperatureReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'temperatureReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'temperatureReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'humidityMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'humidityOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'humidityReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'humidityReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'humidityReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'pressureMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'pressureOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'pressureReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'pressureReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'pressureReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'lightMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'lightOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'lightReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'lightReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'lightReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'locationMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'locationOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'locationReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'locationReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'locationReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'accelerometerMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'accelerometerOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'accelerometerReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'accelerometerReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'accelerometerReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'gyroMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'gyroOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'gyroReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'gyroReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'gyroReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'batteryMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'batteryOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'batteryReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'batteryReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'batteryReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'rfSignalMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'rfSignalOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'rfSignalReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'rfSignalReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDefaultConfiguration
     */
    'rfSignalReportType'?: number;
}
/**
 * 
 * @export
 * @interface SensorExtendedDiagnostics
 */
export interface SensorExtendedDiagnostics {
    /**
     * 
     * @type {Array<SensorExtendedDiagnosticsCategories>}
     * @memberof SensorExtendedDiagnostics
     */
    'categories': Array<SensorExtendedDiagnosticsCategories>;
}
/**
 * 
 * @export
 * @interface SensorExtendedDiagnosticsCategories
 */
export interface SensorExtendedDiagnosticsCategories {
    /**
     * 
     * @type {string}
     * @memberof SensorExtendedDiagnosticsCategories
     */
    'categoryName': string;
    /**
     * 
     * @type {Array<SensorExtendedDiagnosticsExtendedAttributes>}
     * @memberof SensorExtendedDiagnosticsCategories
     */
    'extendedAttributes'?: Array<SensorExtendedDiagnosticsExtendedAttributes>;
}
/**
 * 
 * @export
 * @interface SensorExtendedDiagnosticsExtendedAttributes
 */
export interface SensorExtendedDiagnosticsExtendedAttributes {
    /**
     * 
     * @type {string}
     * @memberof SensorExtendedDiagnosticsExtendedAttributes
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof SensorExtendedDiagnosticsExtendedAttributes
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface SensorLastSentConfiguration
 */
export interface SensorLastSentConfiguration {
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'sensorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'commMaxAwakeDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'commMinAwakeDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'commSynchPeriod'?: number;
    /**
     * 
     * @type {string}
     * @memberof SensorLastSentConfiguration
     */
    'commTypeUpdate'?: string;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'bleMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'bleOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'bleReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'bleReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'bleReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'bleScanDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'bleMinSigStr'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'bleDataMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'bleMaxNumScan'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'bleManufacturerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'temperatureMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'temperatureOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'temperatureReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'temperatureReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'temperatureReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'humidityMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'humidityOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'humidityReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'humidityReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'humidityReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'pressureMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'pressureOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'pressureReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'pressureReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'pressureReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'lightMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'lightOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'lightReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'lightReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'lightReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'locationMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'locationOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'locationReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'locationReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'locationReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'accelerometerMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'accelerometerOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'accelerometerReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'accelerometerReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'accelerometerReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'gyroMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'gyroOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'gyroReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'gyroReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'gyroReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'batteryMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'batteryOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'batteryReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'batteryReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'batteryReportType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'rfSignalMonitorPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'rfSignalOpMode'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'rfSignalReportOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'rfSignalReportPeriod'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorLastSentConfiguration
     */
    'rfSignalReportType'?: number;
    /**
     * 
     * @type {string}
     * @memberof SensorLastSentConfiguration
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorLastSentConfiguration
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SensorLastSentConfiguration
     */
    'firmware'?: boolean;
}
/**
 * 
 * @export
 * @interface SensorModel
 */
export interface SensorModel {
    /**
     * 
     * @type {number}
     * @memberof SensorModel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SensorModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SensorModel
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorModel
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SensorModel
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface SensorRangeHistory
 */
export interface SensorRangeHistory {
    /**
     * 
     * @type {number}
     * @memberof SensorRangeHistory
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SensorRangeHistory
     */
    'minTemperature'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorRangeHistory
     */
    'maxTemperature'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorRangeHistory
     */
    'minHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorRangeHistory
     */
    'maxHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorRangeHistory
     */
    'minDewpointDeviationF'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorRangeHistory
     */
    'maxDewpointDeviationF'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorRangeHistory
     */
    'projectSectionSensorId': number;
    /**
     * 
     * @type {string}
     * @memberof SensorRangeHistory
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorRangeHistory
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SensorRangeHistory
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface SensorState
 */
export interface SensorState {
    /**
     * 
     * @type {number}
     * @memberof SensorState
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SensorState
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SensorState
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SensorState
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface SensorType
 */
export interface SensorType {
    /**
     * 
     * @type {number}
     * @memberof SensorType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SensorType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SensorType
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorType
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SensorType
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface SensorUpdateState
 */
export interface SensorUpdateState {
    /**
     * 
     * @type {number}
     * @memberof SensorUpdateState
     */
    'stateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SensorUpdateState
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    'userId': number;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    'contractorId': number;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'platform': SessionPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'updatedAt': string;
    /**
     * 
     * @type {Device}
     * @memberof Session
     */
    'device': Device;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'deviceId'?: string;
}

export const SessionPlatformEnum = {
    Admin: 'admin',
    Web: 'web',
    Mobile: 'mobile'
} as const;

export type SessionPlatformEnum = typeof SessionPlatformEnum[keyof typeof SessionPlatformEnum];

/**
 * 
 * @export
 * @interface ShareProjectDetails
 */
export interface ShareProjectDetails {
    /**
     * 
     * @type {string}
     * @memberof ShareProjectDetails
     */
    'type': ShareProjectDetailsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ShareProjectDetails
     */
    'text'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShareProjectDetails
     */
    'recipients': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ShareProjectDetails
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShareProjectDetails
     */
    'sensorId'?: string;
    /**
     * 
     * @type {Array<ShareProjectDetailsPhoto>}
     * @memberof ShareProjectDetails
     */
    'photo'?: Array<ShareProjectDetailsPhoto>;
    /**
     * 
     * @type {Array<ShareProjectDetailsGraphics>}
     * @memberof ShareProjectDetails
     */
    'graphics'?: Array<ShareProjectDetailsGraphics>;
    /**
     * 
     * @type {string}
     * @memberof ShareProjectDetails
     */
    'datetimeFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareProjectDetails
     */
    'datetimeTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareProjectDetails
     */
    'datetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareProjectDetails
     */
    'timezone'?: string;
    /**
     * 
     * @type {ShareProjectDetailsReadings}
     * @memberof ShareProjectDetails
     */
    'readings'?: ShareProjectDetailsReadings;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof ShareProjectDetails
     */
    'coordinates'?: EventCoordinates;
}

export const ShareProjectDetailsTypeEnum = {
    AmbientConditions: 'share_project_ambient_conditions',
    SlabConditions: 'share_project_slab_conditions',
    SlabConditionsList: 'share_project_slab_conditions_list'
} as const;

export type ShareProjectDetailsTypeEnum = typeof ShareProjectDetailsTypeEnum[keyof typeof ShareProjectDetailsTypeEnum];

/**
 * 
 * @export
 * @interface ShareProjectDetailsGraphics
 */
export interface ShareProjectDetailsGraphics {
    /**
     * 
     * @type {string}
     * @memberof ShareProjectDetailsGraphics
     */
    'type'?: ShareProjectDetailsGraphicsTypeEnum;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ShareProjectDetailsGraphics
     */
    'original': AttachmentParams;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ShareProjectDetailsGraphics
     */
    'thumbnail'?: AttachmentParams;
}

export const ShareProjectDetailsGraphicsTypeEnum = {
    Temperature: 'temperature',
    Humidity: 'humidity',
    Dewpoint: 'dewpoint'
} as const;

export type ShareProjectDetailsGraphicsTypeEnum = typeof ShareProjectDetailsGraphicsTypeEnum[keyof typeof ShareProjectDetailsGraphicsTypeEnum];

/**
 * 
 * @export
 * @interface ShareProjectDetailsPhoto
 */
export interface ShareProjectDetailsPhoto {
    /**
     * 
     * @type {string}
     * @memberof ShareProjectDetailsPhoto
     */
    'type'?: string;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ShareProjectDetailsPhoto
     */
    'original'?: AttachmentParams;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof ShareProjectDetailsPhoto
     */
    'thumbnail'?: AttachmentParams;
}
/**
 * 
 * @export
 * @interface ShareProjectDetailsReadings
 */
export interface ShareProjectDetailsReadings {
    /**
     * 
     * @type {number}
     * @memberof ShareProjectDetailsReadings
     */
    'ambientTemperature'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShareProjectDetailsReadings
     */
    'ambientHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShareProjectDetailsReadings
     */
    'dewPoint'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShareProjectDetailsReadings
     */
    'slabTemperature'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShareProjectDetailsReadings
     */
    'slabHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShareProjectDetailsReadings
     */
    'targetSlabTemperature'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShareProjectDetailsReadings
     */
    'targetSlabHumidity'?: number;
}
/**
 * 
 * @export
 * @interface SignInPayload
 */
export interface SignInPayload {
    /**
     * 
     * @type {string}
     * @memberof SignInPayload
     */
    'to': string;
    /**
     * 
     * @type {string}
     * @memberof SignInPayload
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof SignInPayload
     */
    'termsAndPrivacyAccepted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SignInPayload
     */
    'isAgreementAccepted'?: boolean;
}
/**
 * 
 * @export
 * @interface SignalStrength
 */
export interface SignalStrength {
    /**
     * 
     * @type {string}
     * @memberof SignalStrength
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SignalStrength
     */
    'uniqueId': string;
    /**
     * 
     * @type {string}
     * @memberof SignalStrength
     */
    'sensorType': string;
    /**
     * 
     * @type {string}
     * @memberof SignalStrength
     */
    'time': string;
    /**
     * 
     * @type {number}
     * @memberof SignalStrength
     */
    'signalStrength': number;
    /**
     * 
     * @type {number}
     * @memberof SignalStrength
     */
    'contractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SignalStrength
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SignalStrength
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SignalStrength
     */
    'isInRange'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SignalStrength
     */
    'isDelayed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SignalStrength
     */
    'isCharging'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SignalStrength
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SignalStrength
     */
    'updatedAt': string;
    /**
     * 
     * @type {Contractor}
     * @memberof SignalStrength
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Project}
     * @memberof SignalStrength
     */
    'project'?: Project;
    /**
     * 
     * @type {ProjectSection}
     * @memberof SignalStrength
     */
    'projectSection'?: ProjectSection;
}
/**
 * 
 * @export
 * @interface SlabConditionRanges
 */
export interface SlabConditionRanges {
    /**
     * 
     * @type {number}
     * @memberof SlabConditionRanges
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SlabConditionRanges
     */
    'slabRHMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof SlabConditionRanges
     */
    'slabTemperatureFMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof SlabConditionRanges
     */
    'projectId': number;
    /**
     * 
     * @type {number}
     * @memberof SlabConditionRanges
     */
    'projectSectionId': number;
    /**
     * 
     * @type {string}
     * @memberof SlabConditionRanges
     */
    'endDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SlabConditionRanges
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof SlabConditionRanges
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SlabConditionRanges
     */
    'updatedAt': string;
    /**
     * 
     * @type {Project}
     * @memberof SlabConditionRanges
     */
    'project'?: Project;
    /**
     * 
     * @type {ProjectSection}
     * @memberof SlabConditionRanges
     */
    'projectSection'?: ProjectSection;
}
/**
 * 
 * @export
 * @interface SlabConditionRangesCreationPayload
 */
export interface SlabConditionRangesCreationPayload {
    /**
     * 
     * @type {number}
     * @memberof SlabConditionRangesCreationPayload
     */
    'slabRHMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof SlabConditionRangesCreationPayload
     */
    'slabTemperatureFMin'?: number;
}
/**
 * 
 * @export
 * @interface SlabConditionRangesUpdatingPayload
 */
export interface SlabConditionRangesUpdatingPayload {
    /**
     * 
     * @type {number}
     * @memberof SlabConditionRangesUpdatingPayload
     */
    'slabRHMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof SlabConditionRangesUpdatingPayload
     */
    'slabTemperatureFMin'?: number;
}
/**
 * 
 * @export
 * @interface SpecificLocation
 */
export interface SpecificLocation {
    /**
     * 
     * @type {number}
     * @memberof SpecificLocation
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SpecificLocation
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SpecificLocation
     */
    'projectSectionId': number;
    /**
     * 
     * @type {string}
     * @memberof SpecificLocation
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SpecificLocation
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface Subreport
 */
export interface Subreport {
    /**
     * 
     * @type {number}
     * @memberof Subreport
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Subreport
     */
    'contractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Subreport
     */
    'rootReportId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Subreport
     */
    'projectId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Subreport
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Subreport
     */
    'isIncludedGeneralContractor'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Subreport
     */
    'isIncludedArchitector'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Subreport
     */
    'downloadLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof Subreport
     */
    'expiredAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Subreport
     */
    'bucket'?: string;
    /**
     * 
     * @type {string}
     * @memberof Subreport
     */
    'fileName'?: string;
    /**
     * 
     * @type {SubreportSettings}
     * @memberof Subreport
     */
    'settings'?: SubreportSettings;
    /**
     * 
     * @type {string}
     * @memberof Subreport
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Subreport
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {Project}
     * @memberof Subreport
     */
    'project'?: Project;
    /**
     * 
     * @type {Contractor}
     * @memberof Subreport
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Report}
     * @memberof Subreport
     */
    'rootReport'?: Report;
}
/**
 * 
 * @export
 * @interface SubreportSettings
 */
export interface SubreportSettings {
    /**
     * 
     * @type {string}
     * @memberof SubreportSettings
     */
    'dateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubreportSettings
     */
    'dateTo'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof SubreportSettings
     */
    'componentIds'?: Array<number>;
    /**
     * 
     * @type {Array<ReportSettingsSubreports>}
     * @memberof SubreportSettings
     */
    'subreports'?: Array<ReportSettingsSubreports>;
}
/**
 * 
 * @export
 * @interface SupportInformation
 */
export interface SupportInformation {
    /**
     * 
     * @type {string}
     * @memberof SupportInformation
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof SupportInformation
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SupportInformation
     */
    'nwfaPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportInformation
     */
    'nwfaEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportInformation
     */
    'howToLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportInformation
     */
    'latestRelease'?: string;
}
/**
 * 
 * @export
 * @interface SystemMessageAudienceType
 */
export interface SystemMessageAudienceType {
    /**
     * 
     * @type {number}
     * @memberof SystemMessageAudienceType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageAudienceType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageAudienceType
     */
    'displayName': string;
    /**
     * 
     * @type {boolean}
     * @memberof SystemMessageAudienceType
     */
    'isActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof SystemMessageAudienceType
     */
    'messageTypeId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageAudienceType
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageAudienceType
     */
    'updatedAt': string;
    /**
     * 
     * @type {SystemMessageType}
     * @memberof SystemMessageAudienceType
     */
    'messageType'?: SystemMessageType;
}
/**
 * 
 * @export
 * @interface SystemMessageHistory
 */
export interface SystemMessageHistory {
    /**
     * 
     * @type {number}
     * @memberof SystemMessageHistory
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SystemMessageHistory
     */
    'authorId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageHistory
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageHistory
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageHistory
     */
    'messageType': SystemMessageHistoryMessageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageHistory
     */
    'transportType': SystemMessageHistoryTransportTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageHistory
     */
    'messageAudience': SystemMessageHistoryMessageAudienceEnum;
    /**
     * 
     * @type {SystemMessagePayloadCustomParams}
     * @memberof SystemMessageHistory
     */
    'customParams'?: SystemMessagePayloadCustomParams;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageHistory
     */
    'status': SystemMessageHistoryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageHistory
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageHistory
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {User}
     * @memberof SystemMessageHistory
     */
    'author'?: User;
    /**
     * 
     * @type {Array<SystemMessageRecipientHistory>}
     * @memberof SystemMessageHistory
     */
    'recipients'?: Array<SystemMessageRecipientHistory>;
    /**
     * 
     * @type {number}
     * @memberof SystemMessageHistory
     */
    'recipientsCount'?: number;
}

export const SystemMessageHistoryMessageTypeEnum = {
    System: 'system',
    Manufacturer: 'manufacturer'
} as const;

export type SystemMessageHistoryMessageTypeEnum = typeof SystemMessageHistoryMessageTypeEnum[keyof typeof SystemMessageHistoryMessageTypeEnum];
export const SystemMessageHistoryTransportTypeEnum = {
    Push: 'push',
    Email: 'email',
    Both: 'both'
} as const;

export type SystemMessageHistoryTransportTypeEnum = typeof SystemMessageHistoryTransportTypeEnum[keyof typeof SystemMessageHistoryTransportTypeEnum];
export const SystemMessageHistoryMessageAudienceEnum = {
    All: 'all',
    CustomSet: 'custom_set',
    UsersBySpecificProduct: 'users_by_specific_product',
    ContractorUsers: 'contractor_users',
    ManufacturerProductUsers: 'manufacturer_product_users'
} as const;

export type SystemMessageHistoryMessageAudienceEnum = typeof SystemMessageHistoryMessageAudienceEnum[keyof typeof SystemMessageHistoryMessageAudienceEnum];
export const SystemMessageHistoryStatusEnum = {
    InProgress: 'in_progress',
    Success: 'success',
    Error: 'error'
} as const;

export type SystemMessageHistoryStatusEnum = typeof SystemMessageHistoryStatusEnum[keyof typeof SystemMessageHistoryStatusEnum];

/**
 * 
 * @export
 * @interface SystemMessagePayload
 */
export interface SystemMessagePayload {
    /**
     * 
     * @type {string}
     * @memberof SystemMessagePayload
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessagePayload
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessagePayload
     */
    'messageType': SystemMessagePayloadMessageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemMessagePayload
     */
    'transportType': SystemMessagePayloadTransportTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemMessagePayload
     */
    'messageAudience': SystemMessagePayloadMessageAudienceEnum;
    /**
     * 
     * @type {SystemMessagePayloadCustomParams}
     * @memberof SystemMessagePayload
     */
    'customParams'?: SystemMessagePayloadCustomParams;
}

export const SystemMessagePayloadMessageTypeEnum = {
    System: 'system',
    Manufacturer: 'manufacturer'
} as const;

export type SystemMessagePayloadMessageTypeEnum = typeof SystemMessagePayloadMessageTypeEnum[keyof typeof SystemMessagePayloadMessageTypeEnum];
export const SystemMessagePayloadTransportTypeEnum = {
    Push: 'push',
    Email: 'email',
    Both: 'both'
} as const;

export type SystemMessagePayloadTransportTypeEnum = typeof SystemMessagePayloadTransportTypeEnum[keyof typeof SystemMessagePayloadTransportTypeEnum];
export const SystemMessagePayloadMessageAudienceEnum = {
    All: 'all',
    CustomSet: 'custom_set',
    UsersBySpecificProduct: 'users_by_specific_product',
    ContractorUsers: 'contractor_users',
    ManufacturerProductUsers: 'manufacturer_product_users'
} as const;

export type SystemMessagePayloadMessageAudienceEnum = typeof SystemMessagePayloadMessageAudienceEnum[keyof typeof SystemMessagePayloadMessageAudienceEnum];

/**
 * 
 * @export
 * @interface SystemMessagePayloadCustomParams
 */
export interface SystemMessagePayloadCustomParams {
    /**
     * 
     * @type {Array<number>}
     * @memberof SystemMessagePayloadCustomParams
     */
    'userIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof SystemMessagePayloadCustomParams
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemMessagePayloadCustomParams
     */
    'manufacturerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemMessagePayloadCustomParams
     */
    'contractorId'?: number;
}
/**
 * 
 * @export
 * @interface SystemMessageRecipientHistory
 */
export interface SystemMessageRecipientHistory {
    /**
     * 
     * @type {number}
     * @memberof SystemMessageRecipientHistory
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SystemMessageRecipientHistory
     */
    'systemMessageHistoryId': number;
    /**
     * 
     * @type {number}
     * @memberof SystemMessageRecipientHistory
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageRecipientHistory
     */
    'status': SystemMessageRecipientHistoryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageRecipientHistory
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageRecipientHistory
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {SystemMessageHistory}
     * @memberof SystemMessageRecipientHistory
     */
    'systemMessage'?: SystemMessageHistory;
    /**
     * 
     * @type {User}
     * @memberof SystemMessageRecipientHistory
     */
    'user'?: User;
}

export const SystemMessageRecipientHistoryStatusEnum = {
    InProgress: 'in_progress',
    Success: 'success',
    Error: 'error'
} as const;

export type SystemMessageRecipientHistoryStatusEnum = typeof SystemMessageRecipientHistoryStatusEnum[keyof typeof SystemMessageRecipientHistoryStatusEnum];

/**
 * 
 * @export
 * @interface SystemMessageTransportType
 */
export interface SystemMessageTransportType {
    /**
     * 
     * @type {number}
     * @memberof SystemMessageTransportType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageTransportType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageTransportType
     */
    'displayName': string;
    /**
     * 
     * @type {boolean}
     * @memberof SystemMessageTransportType
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageTransportType
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageTransportType
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface SystemMessageType
 */
export interface SystemMessageType {
    /**
     * 
     * @type {number}
     * @memberof SystemMessageType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageType
     */
    'displayName': string;
    /**
     * 
     * @type {boolean}
     * @memberof SystemMessageType
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageType
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageType
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<SystemMessageAudienceType>}
     * @memberof SystemMessageType
     */
    'audience'?: Array<SystemMessageAudienceType>;
}
/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'contractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {ProjectSection}
     * @memberof Task
     */
    'projectSection'?: ProjectSection;
    /**
     * 
     * @type {Project}
     * @memberof Task
     */
    'project'?: Project;
    /**
     * 
     * @type {Contractor}
     * @memberof Task
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {SpecificLocation}
     * @memberof Task
     */
    'specificLocation'?: SpecificLocation;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<ProductProjectSection>}
     * @memberof Task
     */
    'productsProjectSections'?: Array<ProductProjectSection>;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'assigneeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'authorId'?: number;
    /**
     * 
     * @type {User}
     * @memberof Task
     */
    'assignee'?: User;
    /**
     * 
     * @type {User}
     * @memberof Task
     */
    'author'?: User;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Task
     */
    'attachments'?: Array<Attachment>;
    /**
     * 
     * @type {Array<TaskPhotos>}
     * @memberof Task
     */
    'photos'?: Array<TaskPhotos>;
}
/**
 * 
 * @export
 * @interface TaskArchiving
 */
export interface TaskArchiving {
    /**
     * 
     * @type {boolean}
     * @memberof TaskArchiving
     */
    'continueProductsMonitoring': boolean;
    /**
     * 
     * @type {string}
     * @memberof TaskArchiving
     */
    'comment'?: string;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof TaskArchiving
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface TaskCreation
 */
export interface TaskCreation {
    /**
     * 
     * @type {string}
     * @memberof TaskCreation
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof TaskCreation
     */
    'projectSectionId': number;
    /**
     * 
     * @type {number}
     * @memberof TaskCreation
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof TaskCreation
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface TaskCreationV2
 */
export interface TaskCreationV2 {
    /**
     * 
     * @type {string}
     * @memberof TaskCreationV2
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof TaskCreationV2
     */
    'projectSectionId': number;
    /**
     * 
     * @type {number}
     * @memberof TaskCreationV2
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof TaskCreationV2
     */
    'coordinates'?: EventCoordinates;
    /**
     * 
     * @type {number}
     * @memberof TaskCreationV2
     */
    'assigneeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskCreationV2
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ProjectCreationV2Photo>}
     * @memberof TaskCreationV2
     */
    'photos'?: Array<ProjectCreationV2Photo>;
}
/**
 * 
 * @export
 * @interface TaskOriginal
 */
export interface TaskOriginal {
    /**
     * 
     * @type {string}
     * @memberof TaskOriginal
     */
    'link': string;
    /**
     * 
     * @type {string}
     * @memberof TaskOriginal
     */
    'bucket': string;
    /**
     * 
     * @type {string}
     * @memberof TaskOriginal
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface TaskPhotos
 */
export interface TaskPhotos {
    /**
     * 
     * @type {TaskOriginal}
     * @memberof TaskPhotos
     */
    'original': TaskOriginal;
    /**
     * 
     * @type {TaskOriginal}
     * @memberof TaskPhotos
     */
    'thumbnail': TaskOriginal;
}
/**
 * 
 * @export
 * @interface TaskUnarchiving
 */
export interface TaskUnarchiving {
    /**
     * 
     * @type {EventCoordinates}
     * @memberof TaskUnarchiving
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface Temperature
 */
export interface Temperature {
    /**
     * 
     * @type {string}
     * @memberof Temperature
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Temperature
     */
    'uniqueId': string;
    /**
     * 
     * @type {string}
     * @memberof Temperature
     */
    'sensorType': string;
    /**
     * 
     * @type {string}
     * @memberof Temperature
     */
    'time': string;
    /**
     * 
     * @type {number}
     * @memberof Temperature
     */
    'temperatureC': number;
    /**
     * 
     * @type {number}
     * @memberof Temperature
     */
    'temperatureF': number;
    /**
     * 
     * @type {number}
     * @memberof Temperature
     */
    'contractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Temperature
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Temperature
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Temperature
     */
    'isInRange'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Temperature
     */
    'isDelayed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Temperature
     */
    'isCharging'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Temperature
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Temperature
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Temperature
     */
    'minRange'?: number;
    /**
     * 
     * @type {number}
     * @memberof Temperature
     */
    'maxRange'?: number;
    /**
     * 
     * @type {Contractor}
     * @memberof Temperature
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Project}
     * @memberof Temperature
     */
    'project'?: Project;
    /**
     * 
     * @type {ProjectSection}
     * @memberof Temperature
     */
    'projectSection'?: ProjectSection;
}
/**
 * 
 * @export
 * @interface ThingspaceSensor
 */
export interface ThingspaceSensor {
    /**
     * 
     * @type {number}
     * @memberof ThingspaceSensor
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ThingspaceSensor
     */
    'imei': string;
    /**
     * 
     * @type {string}
     * @memberof ThingspaceSensor
     */
    'deviceId': string;
    /**
     * 
     * @type {string}
     * @memberof ThingspaceSensor
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ThingspaceSensor
     */
    'updatedAt': string;
    /**
     * 
     * @type {Sensor}
     * @memberof ThingspaceSensor
     */
    'sensor'?: Sensor;
    /**
     * 
     * @type {boolean}
     * @memberof ThingspaceSensor
     */
    'isAvailableInSystem'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ThingspaceSensor
     */
    'activationStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThingspaceSensor
     */
    'simOTADate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThingspaceSensor
     */
    'createdAtInThingspace'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThingspaceSensor
     */
    'servicePlan'?: string;
}
/**
 * 
 * @export
 * @interface TimeseriesGapResponse
 */
export interface TimeseriesGapResponse {
    /**
     * 
     * @type {Array<AggregatedTimeseriesGap>}
     * @memberof TimeseriesGapResponse
     */
    'data': Array<AggregatedTimeseriesGap>;
    /**
     * 
     * @type {Array<TimeseriesGapResponseSensorsMaxGap>}
     * @memberof TimeseriesGapResponse
     */
    'sensorsMaxGap': Array<TimeseriesGapResponseSensorsMaxGap>;
}
/**
 * 
 * @export
 * @interface TimeseriesGapResponseSensorsMaxGap
 */
export interface TimeseriesGapResponseSensorsMaxGap {
    /**
     * 
     * @type {string}
     * @memberof TimeseriesGapResponseSensorsMaxGap
     */
    'imei': string;
    /**
     * 
     * @type {string}
     * @memberof TimeseriesGapResponseSensorsMaxGap
     */
    'uniqueId': string;
}
/**
 * 
 * @export
 * @interface UnarchiveResponse
 */
export interface UnarchiveResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UnarchiveResponse
     */
    'hasClone': boolean;
    /**
     * 
     * @type {User}
     * @memberof UnarchiveResponse
     */
    'user': User;
}
/**
 * 
 * @export
 * @interface UnstableSensor
 */
export interface UnstableSensor {
    /**
     * 
     * @type {number}
     * @memberof UnstableSensor
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof UnstableSensor
     */
    'sensorId': number;
    /**
     * 
     * @type {string}
     * @memberof UnstableSensor
     */
    'sensorImei': string;
    /**
     * 
     * @type {number}
     * @memberof UnstableSensor
     */
    'numberMissedUplinks': number;
    /**
     * 
     * @type {string}
     * @memberof UnstableSensor
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof UnstableSensor
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnstableSensor
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface UpdateBuildingTypePayload
 */
export interface UpdateBuildingTypePayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateBuildingTypePayload
     */
    'displayName': string;
}
/**
 * 
 * @export
 * @interface UpdateContractorPaidPlan
 */
export interface UpdateContractorPaidPlan {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateContractorPaidPlan
     */
    'isActivePaidPlan': boolean;
}
/**
 * 
 * @export
 * @interface UpdateDocumentPayload
 */
export interface UpdateDocumentPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentPayload
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentPayload
     */
    'description'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateDocumentPayload
     */
    'attachmentIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateDocumentPayload
     */
    'attachmentIdsToDelete'?: Array<number>;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof UpdateDocumentPayload
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface UpdateFirmwarePayload
 */
export interface UpdateFirmwarePayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateFirmwarePayload
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFirmwarePayload
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFirmwarePayload
     */
    'deviceType': UpdateFirmwarePayloadDeviceTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateFirmwarePayload
     */
    'attachmentIds': Array<number>;
}

export const UpdateFirmwarePayloadDeviceTypeEnum = {
    DataGrabber: 'data_grabber',
    RangeExtender: 'range_extender'
} as const;

export type UpdateFirmwarePayloadDeviceTypeEnum = typeof UpdateFirmwarePayloadDeviceTypeEnum[keyof typeof UpdateFirmwarePayloadDeviceTypeEnum];

/**
 * 
 * @export
 * @interface UpdateManufacturerAnnouncementCategoryPayload
 */
export interface UpdateManufacturerAnnouncementCategoryPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAnnouncementCategoryPayload
     */
    'displayName': string;
}
/**
 * 
 * @export
 * @interface UpdateManufacturerAnnouncementPayload
 */
export interface UpdateManufacturerAnnouncementPayload {
    /**
     * 
     * @type {number}
     * @memberof UpdateManufacturerAnnouncementPayload
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAnnouncementPayload
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAnnouncementPayload
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateManufacturerAnnouncementPayload
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateManufacturerAnnouncementPayload
     */
    'manufacturerId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateManufacturerAnnouncementPayload
     */
    'isVisibleContactMeButton'?: boolean;
    /**
     * 
     * @type {Array<UpdateManufacturerAnnouncementPostPayload>}
     * @memberof UpdateManufacturerAnnouncementPayload
     */
    'posts'?: Array<UpdateManufacturerAnnouncementPostPayload>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateManufacturerAnnouncementPayload
     */
    'categoryIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UpdateManufacturerAnnouncementPostPayload
 */
export interface UpdateManufacturerAnnouncementPostPayload {
    /**
     * 
     * @type {number}
     * @memberof UpdateManufacturerAnnouncementPostPayload
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAnnouncementPostPayload
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAnnouncementPostPayload
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAnnouncementPostPayload
     */
    'videoLink'?: string;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof UpdateManufacturerAnnouncementPostPayload
     */
    'image'?: AttachmentParams;
}
/**
 * 
 * @export
 * @interface UpdateManufacturerAnnouncementTypePayload
 */
export interface UpdateManufacturerAnnouncementTypePayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAnnouncementTypePayload
     */
    'displayName': string;
}
/**
 * 
 * @export
 * @interface UpdateMobileSettings
 */
export interface UpdateMobileSettings {
    /**
     * 
     * @type {UpdateMobileSettingsWagnerProbeSettings}
     * @memberof UpdateMobileSettings
     */
    'wagnerProbeSettings': UpdateMobileSettingsWagnerProbeSettings;
    /**
     * 
     * @type {UpdateMobileSettingsBackgroundSyncSettings}
     * @memberof UpdateMobileSettings
     */
    'backgroundSyncSettings'?: UpdateMobileSettingsBackgroundSyncSettings;
}
/**
 * 
 * @export
 * @interface UpdateMobileSettingsBackgroundSyncSettings
 */
export interface UpdateMobileSettingsBackgroundSyncSettings {
    /**
     * 
     * @type {number}
     * @memberof UpdateMobileSettingsBackgroundSyncSettings
     */
    'chunkSize': number;
}
/**
 * 
 * @export
 * @interface UpdateMobileSettingsWagnerProbeSettings
 */
export interface UpdateMobileSettingsWagnerProbeSettings {
    /**
     * 
     * @type {number}
     * @memberof UpdateMobileSettingsWagnerProbeSettings
     */
    'signalStrengthThreshold': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateMobileSettingsWagnerProbeSettings
     */
    'connectivitySignalLevel': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateMobileSettingsWagnerProbeSettings
     */
    'connectivityCheckPacketFreshnessThreshold': number;
}
/**
 * 
 * @export
 * @interface UpdateProductRequestPayload
 */
export interface UpdateProductRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequestPayload
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductRequestPayload
     */
    'statusId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateProjectSection
 */
export interface UpdateProjectSection {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectSection
     */
    'name': string;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof UpdateProjectSection
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface UpdateProjectTypePayload
 */
export interface UpdateProjectTypePayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectTypePayload
     */
    'displayName': string;
}
/**
 * 
 * @export
 * @interface UpdateReportTypePayload
 */
export interface UpdateReportTypePayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateReportTypePayload
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateReportTypePayload
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateReportTypePayload
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateStagingDevices
 */
export interface UpdateStagingDevices {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateStagingDevices
     */
    'stagingDevices': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateTaskResponseV2
 */
export interface UpdateTaskResponseV2 {
    /**
     * 
     * @type {Task}
     * @memberof UpdateTaskResponseV2
     */
    'task': Task;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTaskResponseV2
     */
    'hasAccess': boolean;
}
/**
 * 
 * @export
 * @interface UpdateUserMePayload
 */
export interface UpdateUserMePayload {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserMePayload
     */
    'lastReadManufacturerAnnouncementId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateUserPayload
 */
export interface UpdateUserPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPayload
     */
    'phone': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserPayload
     */
    'roleId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserPayload
     */
    'contractorId': number;
}
/**
 * 
 * @export
 * @interface UpdateWagnerProbeInstallationPeriodPayload
 */
export interface UpdateWagnerProbeInstallationPeriodPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateWagnerProbeInstallationPeriodPayload
     */
    'connectedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateWagnerProbeInstallationPeriodPayload
     */
    'measurementsEndAt': string | null;
}
/**
 * 
 * @export
 * @interface UpdateWagnerProbePayload
 */
export interface UpdateWagnerProbePayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateWagnerProbePayload
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateWagnerProbePayload
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWagnerProbePayload
     */
    'slabThickness': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateWagnerProbePayload
     */
    'holeDepth': number | null;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof UpdateWagnerProbePayload
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface UpdatingTask
 */
export interface UpdatingTask {
    /**
     * 
     * @type {string}
     * @memberof UpdatingTask
     */
    'name': string;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof UpdatingTask
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface UpdatingTaskStatus
 */
export interface UpdatingTaskStatus {
    /**
     * 
     * @type {string}
     * @memberof UpdatingTaskStatus
     */
    'status': string;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof UpdatingTaskStatus
     */
    'coordinates'?: EventCoordinates;
}
/**
 * 
 * @export
 * @interface UpdatingTaskV2
 */
export interface UpdatingTaskV2 {
    /**
     * 
     * @type {string}
     * @memberof UpdatingTaskV2
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatingTaskV2
     */
    'description': string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdatingTaskV2
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatingTaskV2
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatingTaskV2
     */
    'specificLocationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatingTaskV2
     */
    'assigneeId': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatingTaskV2
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdatingTaskV2
     */
    'status'?: string;
    /**
     * 
     * @type {EventCoordinates}
     * @memberof UpdatingTaskV2
     */
    'coordinates'?: EventCoordinates;
    /**
     * 
     * @type {Array<ProjectCreationV2Photo>}
     * @memberof UpdatingTaskV2
     */
    'photos'?: Array<ProjectCreationV2Photo>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdatingTaskV2
     */
    'attachmentIdsToDelete'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UploadFileByChunksResult
 */
export interface UploadFileByChunksResult {
    /**
     * 
     * @type {number}
     * @memberof UploadFileByChunksResult
     */
    'progress': number;
    /**
     * 
     * @type {number}
     * @memberof UploadFileByChunksResult
     */
    'totalParts': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UploadFileByChunksResult
     */
    'uploadedParts': Array<number>;
}
/**
 * 
 * @export
 * @interface UploadPhotoResult
 */
export interface UploadPhotoResult {
    /**
     * 
     * @type {string}
     * @memberof UploadPhotoResult
     */
    'originalFileName': string;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof UploadPhotoResult
     */
    'original': AttachmentParams;
    /**
     * 
     * @type {AttachmentParams}
     * @memberof UploadPhotoResult
     */
    'thumbnail': AttachmentParams;
}
/**
 * 
 * @export
 * @interface UploadXlsxProduct
 */
export interface UploadXlsxProduct {
    /**
     * 
     * @type {string}
     * @memberof UploadXlsxProduct
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UploadXlsxProduct
     */
    'createdProducts': number;
    /**
     * 
     * @type {number}
     * @memberof UploadXlsxProduct
     */
    'updatedProducts': number;
    /**
     * 
     * @type {number}
     * @memberof UploadXlsxProduct
     */
    'processedProducts': number;
    /**
     * 
     * @type {Array<XlsxSheetProblem>}
     * @memberof UploadXlsxProduct
     */
    'sheetProblems': Array<XlsxSheetProblem>;
}
/**
 * 
 * @export
 * @interface UploadedFileResult
 */
export interface UploadedFileResult {
    /**
     * 
     * @type {string}
     * @memberof UploadedFileResult
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof UploadedFileResult
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof UploadedFileResult
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface UsageDashboardStatistics
 */
export interface UsageDashboardStatistics {
    /**
     * 
     * @type {number}
     * @memberof UsageDashboardStatistics
     */
    'monitoredSectionsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof UsageDashboardStatistics
     */
    'monitoredHoursNumber': number;
    /**
     * 
     * @type {number}
     * @memberof UsageDashboardStatistics
     */
    'tasksNumber': number;
    /**
     * 
     * @type {number}
     * @memberof UsageDashboardStatistics
     */
    'installedProductsNumber': number;
    /**
     * 
     * @type {UsageDashboardStatisticsAlert}
     * @memberof UsageDashboardStatistics
     */
    'alert': UsageDashboardStatisticsAlert;
    /**
     * 
     * @type {UsageDashboardStatisticsReport}
     * @memberof UsageDashboardStatistics
     */
    'report': UsageDashboardStatisticsReport;
    /**
     * 
     * @type {UsageDashboardStatisticsSharedDetails}
     * @memberof UsageDashboardStatistics
     */
    'sharedDetails': UsageDashboardStatisticsSharedDetails;
    /**
     * 
     * @type {UsageDashboardStatisticsTask}
     * @memberof UsageDashboardStatistics
     */
    'task': UsageDashboardStatisticsTask;
    /**
     * 
     * @type {NoteDashboardStatistics}
     * @memberof UsageDashboardStatistics
     */
    'note': NoteDashboardStatistics;
    /**
     * 
     * @type {PhotoDashboardStatistics}
     * @memberof UsageDashboardStatistics
     */
    'photo': PhotoDashboardStatistics;
}
/**
 * 
 * @export
 * @interface UsageDashboardStatisticsAlert
 */
export interface UsageDashboardStatisticsAlert {
    /**
     * 
     * @type {number}
     * @memberof UsageDashboardStatisticsAlert
     */
    'alertsNumber': number;
    /**
     * 
     * @type {Array<AlertTypeItemDashboardStatistics>}
     * @memberof UsageDashboardStatisticsAlert
     */
    'types': Array<AlertTypeItemDashboardStatistics>;
}
/**
 * 
 * @export
 * @interface UsageDashboardStatisticsReport
 */
export interface UsageDashboardStatisticsReport {
    /**
     * 
     * @type {number}
     * @memberof UsageDashboardStatisticsReport
     */
    'reportsNumber': number;
    /**
     * 
     * @type {Array<ReportTypeItemDashboardStatistics>}
     * @memberof UsageDashboardStatisticsReport
     */
    'types': Array<ReportTypeItemDashboardStatistics>;
}
/**
 * 
 * @export
 * @interface UsageDashboardStatisticsSharedDetails
 */
export interface UsageDashboardStatisticsSharedDetails {
    /**
     * 
     * @type {number}
     * @memberof UsageDashboardStatisticsSharedDetails
     */
    'sharedAlertsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof UsageDashboardStatisticsSharedDetails
     */
    'sharedAmbientConditionsNumber': number;
    /**
     * 
     * @type {number}
     * @memberof UsageDashboardStatisticsSharedDetails
     */
    'sharedRapidRhListNumber': number;
    /**
     * 
     * @type {number}
     * @memberof UsageDashboardStatisticsSharedDetails
     */
    'sharedRapidRhDetailsNumber': number;
}
/**
 * 
 * @export
 * @interface UsageDashboardStatisticsTask
 */
export interface UsageDashboardStatisticsTask {
    /**
     * 
     * @type {number}
     * @memberof UsageDashboardStatisticsTask
     */
    'tasksNumber': number;
    /**
     * 
     * @type {number}
     * @memberof UsageDashboardStatisticsTask
     */
    'assignedTasksNumber': number;
    /**
     * 
     * @type {number}
     * @memberof UsageDashboardStatisticsTask
     */
    'unassignedTasksNumber': number;
    /**
     * 
     * @type {number}
     * @memberof UsageDashboardStatisticsTask
     */
    'activeTasksNumber': number;
    /**
     * 
     * @type {number}
     * @memberof UsageDashboardStatisticsTask
     */
    'completedTasksNumber': number;
    /**
     * 
     * @type {number}
     * @memberof UsageDashboardStatisticsTask
     */
    'archivedTasksNumber': number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone': string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'roleId': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastActive'?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'contractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'manufacturerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedAt': string;
    /**
     * 
     * @type {UserRole}
     * @memberof User
     */
    'role'?: UserRole;
    /**
     * 
     * @type {Contractor}
     * @memberof User
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Manufacturer}
     * @memberof User
     */
    'manufacturer'?: Manufacturer;
    /**
     * 
     * @type {Array<UserProjectNotification>}
     * @memberof User
     */
    'notifications'?: Array<UserProjectNotification>;
    /**
     * 
     * @type {Array<AdminNotification>}
     * @memberof User
     */
    'adminNotifications'?: Array<AdminNotification>;
    /**
     * 
     * @type {Array<Project>}
     * @memberof User
     */
    'projects'?: Array<Project>;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'projectsCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastVersionApp'?: string;
    /**
     * 
     * @type {LastAction}
     * @memberof User
     */
    'lastAction'?: LastAction;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isArchived': boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'messageChannel'?: string;
}
/**
 * 
 * @export
 * @interface UserCreation
 */
export interface UserCreation {
    /**
     * 
     * @type {string}
     * @memberof UserCreation
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UserCreation
     */
    'roleId': number;
    /**
     * 
     * @type {number}
     * @memberof UserCreation
     */
    'contractorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCreation
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreation
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface UserEditing
 */
export interface UserEditing {
    /**
     * 
     * @type {string}
     * @memberof UserEditing
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UserEditing
     */
    'roleId': number;
    /**
     * 
     * @type {string}
     * @memberof UserEditing
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserEditing
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof UserEditing
     */
    'messageChannel': string;
}
/**
 * 
 * @export
 * @interface UserGlobalNotification
 */
export interface UserGlobalNotification {
    /**
     * 
     * @type {number}
     * @memberof UserGlobalNotification
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof UserGlobalNotification
     */
    'userId': number;
    /**
     * 
     * @type {number}
     * @memberof UserGlobalNotification
     */
    'globalNotificationId': number;
    /**
     * 
     * @type {boolean}
     * @memberof UserGlobalNotification
     */
    'isActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserGlobalNotification
     */
    'isVisible'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserGlobalNotification
     */
    'createdAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserGlobalNotification
     */
    'updatedAt'?: boolean;
    /**
     * 
     * @type {GlobalNotification}
     * @memberof UserGlobalNotification
     */
    'globalNotification'?: GlobalNotification;
    /**
     * 
     * @type {User}
     * @memberof UserGlobalNotification
     */
    'user'?: User;
}
/**
 * 
 * @export
 * @interface UserGlobalNotificationPayloadByType
 */
export interface UserGlobalNotificationPayloadByType {
    /**
     * 
     * @type {Array<UserGlobalNotificationPayloadByTypeTypes>}
     * @memberof UserGlobalNotificationPayloadByType
     */
    'types': Array<UserGlobalNotificationPayloadByTypeTypes>;
}
/**
 * 
 * @export
 * @interface UserGlobalNotificationPayloadByTypeTypes
 */
export interface UserGlobalNotificationPayloadByTypeTypes {
    /**
     * 
     * @type {string}
     * @memberof UserGlobalNotificationPayloadByTypeTypes
     */
    'name': UserGlobalNotificationPayloadByTypeTypesNameEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserGlobalNotificationPayloadByTypeTypes
     */
    'value': boolean;
}

export const UserGlobalNotificationPayloadByTypeTypesNameEnum = {
    AlertCreated: 'alert_created',
    AlertClosed: 'alert_closed',
    DewpointIssue: 'dewpoint_issue',
    ProductOutOfSpecification: 'product_out_of_specification',
    ProductInSpecification: 'product_in_specification',
    SensorBattery25: 'sensor_battery_25',
    SensorBattery10: 'sensor_battery_10',
    SensorOffline: 'sensor_offline',
    EventNote: 'event_note',
    TrialPeriodXDaysLeft: 'trial_period_x_days_left',
    TrialPeriodEnded: 'trial_period_ended',
    SystemMessage: 'system_message',
    RapidRhFirstReading: 'rapid_rh_first_reading',
    ClimateSensorFirstReading: 'climate_sensor_first_reading'
} as const;

export type UserGlobalNotificationPayloadByTypeTypesNameEnum = typeof UserGlobalNotificationPayloadByTypeTypesNameEnum[keyof typeof UserGlobalNotificationPayloadByTypeTypesNameEnum];

/**
 * 
 * @export
 * @interface UserMessageChannel
 */
export interface UserMessageChannel {
    /**
     * 
     * @type {number}
     * @memberof UserMessageChannel
     */
    'id': number;
    /**
     * 
     * @type {UserMessageChannels}
     * @memberof UserMessageChannel
     */
    'name': UserMessageChannels;
    /**
     * 
     * @type {string}
     * @memberof UserMessageChannel
     */
    'displayName': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserMessageChannel
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserMessageChannel
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserMessageChannel
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserMessageChannels = {
    Sms: 'sms',
    Call: 'call',
    Email: 'email',
    Whatsapp: 'whatsapp'
} as const;

export type UserMessageChannels = typeof UserMessageChannels[keyof typeof UserMessageChannels];


/**
 * 
 * @export
 * @interface UserNotificationPayloadByProject
 */
export interface UserNotificationPayloadByProject {
    /**
     * 
     * @type {Array<UserNotificationPayloadByProjectProjects>}
     * @memberof UserNotificationPayloadByProject
     */
    'projects': Array<UserNotificationPayloadByProjectProjects>;
}
/**
 * 
 * @export
 * @interface UserNotificationPayloadByProjectProjects
 */
export interface UserNotificationPayloadByProjectProjects {
    /**
     * 
     * @type {number}
     * @memberof UserNotificationPayloadByProjectProjects
     */
    'projectId': number;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotificationPayloadByProjectProjects
     */
    'value': boolean;
}
/**
 * 
 * @export
 * @interface UserNotificationPayloadByType
 */
export interface UserNotificationPayloadByType {
    /**
     * 
     * @type {Array<UserNotificationPayloadByTypeTypes>}
     * @memberof UserNotificationPayloadByType
     */
    'types': Array<UserNotificationPayloadByTypeTypes>;
}
/**
 * 
 * @export
 * @interface UserNotificationPayloadByTypeTypes
 */
export interface UserNotificationPayloadByTypeTypes {
    /**
     * 
     * @type {string}
     * @memberof UserNotificationPayloadByTypeTypes
     */
    'name': UserNotificationPayloadByTypeTypesNameEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotificationPayloadByTypeTypes
     */
    'value': boolean;
}

export const UserNotificationPayloadByTypeTypesNameEnum = {
    AlertCreatedClosed: 'alert_created_closed',
    Dewpoint: 'dewpoint',
    ProductOutInSpec: 'product_out_in_spec',
    System: 'system',
    SensorOffline: 'sensor_offline',
    SensorBattery25: 'sensor_battery_25',
    SensorBattery10: 'sensor_battery_10'
} as const;

export type UserNotificationPayloadByTypeTypesNameEnum = typeof UserNotificationPayloadByTypeTypesNameEnum[keyof typeof UserNotificationPayloadByTypeTypesNameEnum];

/**
 * 
 * @export
 * @interface UserNotificationPayloadByTypeV2
 */
export interface UserNotificationPayloadByTypeV2 {
    /**
     * 
     * @type {Array<UserNotificationPayloadByTypeV2Types>}
     * @memberof UserNotificationPayloadByTypeV2
     */
    'types': Array<UserNotificationPayloadByTypeV2Types>;
}
/**
 * 
 * @export
 * @interface UserNotificationPayloadByTypeV2Types
 */
export interface UserNotificationPayloadByTypeV2Types {
    /**
     * 
     * @type {string}
     * @memberof UserNotificationPayloadByTypeV2Types
     */
    'name': UserNotificationPayloadByTypeV2TypesNameEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotificationPayloadByTypeV2Types
     */
    'value': boolean;
}

export const UserNotificationPayloadByTypeV2TypesNameEnum = {
    AlertCreatedClosed: 'alert_created_closed',
    Dewpoint: 'dewpoint',
    ProductOutInSpec: 'product_out_in_spec',
    System: 'system',
    SensorOffline: 'sensor_offline',
    SensorBattery25: 'sensor_battery_25',
    SensorBattery10: 'sensor_battery_10',
    SensorFirstReading: 'sensor_first_reading',
    SharedProjectEvent: 'shared_project_event'
} as const;

export type UserNotificationPayloadByTypeV2TypesNameEnum = typeof UserNotificationPayloadByTypeV2TypesNameEnum[keyof typeof UserNotificationPayloadByTypeV2TypesNameEnum];

/**
 * 
 * @export
 * @interface UserNotificationResponseItemByProject
 */
export interface UserNotificationResponseItemByProject {
    /**
     * 
     * @type {Project}
     * @memberof UserNotificationResponseItemByProject
     */
    'project': Project;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotificationResponseItemByProject
     */
    'value': boolean;
}
/**
 * 
 * @export
 * @interface UserNotificationResponseItemByType
 */
export interface UserNotificationResponseItemByType {
    /**
     * 
     * @type {string}
     * @memberof UserNotificationResponseItemByType
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotificationResponseItemByType
     */
    'value': boolean;
}
/**
 * 
 * @export
 * @interface UserNotificationResponseItemByTypeV2
 */
export interface UserNotificationResponseItemByTypeV2 {
    /**
     * 
     * @type {string}
     * @memberof UserNotificationResponseItemByTypeV2
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotificationResponseItemByTypeV2
     */
    'value': boolean;
}
/**
 * 
 * @export
 * @interface UserProject
 */
export interface UserProject {
    /**
     * 
     * @type {number}
     * @memberof UserProject
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserProject
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserProject
     */
    'projectId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserProject
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProject
     */
    'isEnabledNotifications'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'endAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {User}
     * @memberof UserProject
     */
    'user'?: User;
    /**
     * 
     * @type {Project}
     * @memberof UserProject
     */
    'project'?: Project;
}
/**
 * 
 * @export
 * @interface UserProjectNotification
 */
export interface UserProjectNotification {
    /**
     * 
     * @type {number}
     * @memberof UserProjectNotification
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof UserProjectNotification
     */
    'projectId': number;
    /**
     * 
     * @type {number}
     * @memberof UserProjectNotification
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof UserProjectNotification
     */
    'notificationType': string;
    /**
     * 
     * @type {string}
     * @memberof UserProjectNotification
     */
    'notificationTypeDisplayName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProjectNotification
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProjectNotification
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserProjectNotification
     */
    'updatedAt': string;
    /**
     * 
     * @type {Project}
     * @memberof UserProjectNotification
     */
    'project'?: Project;
}
/**
 * 
 * @export
 * @interface UserRole
 */
export interface UserRole {
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'displayName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserRole
     */
    'isAdmin': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    'uiSorting'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UserSignOutPayloadV2
 */
export interface UserSignOutPayloadV2 {
    /**
     * 
     * @type {string}
     * @memberof UserSignOutPayloadV2
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface UserTasksResponseV2
 */
export interface UserTasksResponseV2 {
    /**
     * 
     * @type {Array<Task>}
     * @memberof UserTasksResponseV2
     */
    'assignedToMe': Array<Task>;
    /**
     * 
     * @type {Array<Task>}
     * @memberof UserTasksResponseV2
     */
    'createdByMe': Array<Task>;
    /**
     * 
     * @type {Array<Task>}
     * @memberof UserTasksResponseV2
     */
    'unassigned': Array<Task>;
}
/**
 * 
 * @export
 * @interface WagnerDeviceFirmware
 */
export interface WagnerDeviceFirmware {
    /**
     * 
     * @type {number}
     * @memberof WagnerDeviceFirmware
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof WagnerDeviceFirmware
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof WagnerDeviceFirmware
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof WagnerDeviceFirmware
     */
    'deviceType': string;
    /**
     * 
     * @type {string}
     * @memberof WagnerDeviceFirmware
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof WagnerDeviceFirmware
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof WagnerDeviceFirmware
     */
    'attachments'?: Array<Attachment>;
}
/**
 * 
 * @export
 * @interface WagnerProbe
 */
export interface WagnerProbe {
    /**
     * 
     * @type {number}
     * @memberof WagnerProbe
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbe
     */
    'probeId': string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbe
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbe
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbe
     */
    'slabThickness'?: number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbe
     */
    'holeDepth'?: number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbe
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbe
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbe
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbe
     */
    'updatedAt': string;
    /**
     * 
     * @type {WagnerProbeCurrentData}
     * @memberof WagnerProbe
     */
    'currentData'?: WagnerProbeCurrentData;
    /**
     * 
     * @type {Array<WagnerProbeReadout>}
     * @memberof WagnerProbe
     */
    'readout'?: Array<WagnerProbeReadout>;
    /**
     * 
     * @type {Array<WagnerProbeChartReadout>}
     * @memberof WagnerProbe
     */
    'chartReadout'?: Array<WagnerProbeChartReadout>;
    /**
     * 
     * @type {Array<Event>}
     * @memberof WagnerProbe
     */
    'events'?: Array<Event>;
    /**
     * 
     * @type {ProjectSection}
     * @memberof WagnerProbe
     */
    'projectSection'?: ProjectSection;
    /**
     * 
     * @type {Project}
     * @memberof WagnerProbe
     */
    'project'?: Project;
    /**
     * 
     * @type {Contractor}
     * @memberof WagnerProbe
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Array<WagnerProbeReading>}
     * @memberof WagnerProbe
     */
    'readings'?: Array<WagnerProbeReading>;
    /**
     * 
     * @type {Sensor}
     * @memberof WagnerProbe
     */
    'verizonSensor'?: Sensor;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbe
     */
    'connectedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbe
     */
    'measurementsEndAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbe
     */
    'installedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbe
     */
    'firstReadingAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbe
     */
    'lastReadingAt'?: string;
}
/**
 * 
 * @export
 * @interface WagnerProbeChartReadout
 */
export interface WagnerProbeChartReadout {
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeChartReadout
     */
    'timeBucket': string;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeChartReadout
     */
    'avgSlabTemperatureF': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeChartReadout
     */
    'avgSlabTemperatureC': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeChartReadout
     */
    'avgSlabHumidity': number;
    /**
     * 
     * @type {boolean}
     * @memberof WagnerProbeChartReadout
     */
    'isInRangeTemperatureF'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WagnerProbeChartReadout
     */
    'isInRangeHumidity'?: boolean;
}
/**
 * 
 * @export
 * @interface WagnerProbeCurrentData
 */
export interface WagnerProbeCurrentData {
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeCurrentData
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeCurrentData
     */
    'wagnerProbeId': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeCurrentData
     */
    'slabTemperatureC': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeCurrentData
     */
    'slabTemperatureF': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeCurrentData
     */
    'slabHumidity': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeCurrentData
     */
    'signalStrength'?: number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeCurrentData
     */
    'batteryLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeCurrentData
     */
    'readingTime': string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeCurrentData
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeCurrentData
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof WagnerProbeCurrentData
     */
    'isInRangeTemperatureF'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WagnerProbeCurrentData
     */
    'isInRangeHumidity'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeCurrentData
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeCurrentData
     */
    'readerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeCurrentData
     */
    'verizonGatId'?: string;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeCurrentData
     */
    'repeaterBatteryLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeCurrentData
     */
    'deviceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeCurrentData
     */
    'receivedFromDataGrabberAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeCurrentData
     */
    'receivedFromMiniGrabberAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeCurrentData
     */
    'receivedFromTotalReaderAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeCurrentData
     */
    'receivedFromRepeaterAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeCurrentData
     */
    'receivedViaPhoneAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeCurrentData
     */
    'receivedViaGatAt'?: string;
}
/**
 * 
 * @export
 * @interface WagnerProbeReading
 */
export interface WagnerProbeReading {
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReading
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReading
     */
    'probeId': string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReading
     */
    'readerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReading
     */
    'repeaterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReading
     */
    'verizonGatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReading
     */
    'readerName'?: string;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeReading
     */
    'slabTemperatureC'?: number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeReading
     */
    'slabTemperatureF': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeReading
     */
    'slabHumidity': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeReading
     */
    'signalStrength'?: number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeReading
     */
    'batteryLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeReading
     */
    'repeaterBatteryLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeReading
     */
    'contractorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeReading
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeReading
     */
    'projectSectionId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WagnerProbeReading
     */
    'isHistorical'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReading
     */
    'readingTime': string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReading
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReading
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WagnerProbeReading
     */
    'isInRangeTemperature'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WagnerProbeReading
     */
    'isInRangeHumidity'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReading
     */
    'receivedFromDataGrabberAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReading
     */
    'receivedFromMiniGrabberAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReading
     */
    'receivedFromTotalReaderAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReading
     */
    'receivedFromRepeaterAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReading
     */
    'receivedViaPhoneAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReading
     */
    'receivedViaGatAt'?: string;
    /**
     * 
     * @type {Contractor}
     * @memberof WagnerProbeReading
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {Project}
     * @memberof WagnerProbeReading
     */
    'project'?: Project;
    /**
     * 
     * @type {ProjectSection}
     * @memberof WagnerProbeReading
     */
    'projectSection'?: ProjectSection;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeReading
     */
    'gapInMinutes'?: number;
}
/**
 * 
 * @export
 * @interface WagnerProbeReadout
 */
export interface WagnerProbeReadout {
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReadout
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeReadout
     */
    'lastSlabTemperatureF': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeReadout
     */
    'lastSlabTemperatureC': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeReadout
     */
    'lastSlabHumidity': number;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeReadout
     */
    'lastReadingTime': string;
    /**
     * 
     * @type {boolean}
     * @memberof WagnerProbeReadout
     */
    'isInRangeTemperatureF'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WagnerProbeReadout
     */
    'isInRangeHumidity'?: boolean;
}
/**
 * 
 * @export
 * @interface WagnerProbeSettings
 */
export interface WagnerProbeSettings {
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeSettings
     */
    'signalStrengthThreshold': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeSettings
     */
    'connectivitySignalLevel': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeSettings
     */
    'connectivityCheckPacketFreshnessThreshold': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeSettings
     */
    'wagnerDeviceFirmwareUpdateListeningTimeout': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeSettings
     */
    'wagnerDeviceFirmwareUpdateSignalStrengthThreshold': number;
}
/**
 * 
 * @export
 * @interface WagnerProbeSummary
 */
export interface WagnerProbeSummary {
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeSummary
     */
    'minTemperatureF': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeSummary
     */
    'maxTemperatureF': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeSummary
     */
    'minHumidity': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeSummary
     */
    'maxHumidity': number;
    /**
     * 
     * @type {number}
     * @memberof WagnerProbeSummary
     */
    'probeCount': number;
    /**
     * 
     * @type {string}
     * @memberof WagnerProbeSummary
     */
    'lastUpdatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof WagnerProbeSummary
     */
    'isInRangeTemperatureF'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WagnerProbeSummary
     */
    'isInRangeHumidity'?: boolean;
}
/**
 * 
 * @export
 * @interface WoodEquilibriumPayload
 */
export interface WoodEquilibriumPayload {
    /**
     * 
     * @type {Array<number>}
     * @memberof WoodEquilibriumPayload
     */
    'mcMeasurments': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumPayload
     */
    'mcAverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumPayload
     */
    'humidity': number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumPayload
     */
    'temperatureF': number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumPayload
     */
    'nwfaMoistureMapRegionId': number;
}
/**
 * 
 * @export
 * @interface WoodEquilibriumPayloadV2
 */
export interface WoodEquilibriumPayloadV2 {
    /**
     * 
     * @type {Array<number>}
     * @memberof WoodEquilibriumPayloadV2
     */
    'mcMeasurments': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumPayloadV2
     */
    'mcAverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumPayloadV2
     */
    'humidity': number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumPayloadV2
     */
    'temperatureF': number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumPayloadV2
     */
    'nwfaMoistureMapRegionId': number;
    /**
     * 
     * @type {string}
     * @memberof WoodEquilibriumPayloadV2
     */
    'subfloorType'?: WoodEquilibriumPayloadV2SubfloorTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof WoodEquilibriumPayloadV2
     */
    'mcSubfloorMeasurments'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof WoodEquilibriumPayloadV2
     */
    'boardWidthLessThan3'?: boolean;
}

export const WoodEquilibriumPayloadV2SubfloorTypeEnum = {
    Plywood: 'plywood',
    Osb: 'osb',
    Concrete: 'concrete',
    ConcreteWagner: 'concreteWagner'
} as const;

export type WoodEquilibriumPayloadV2SubfloorTypeEnum = typeof WoodEquilibriumPayloadV2SubfloorTypeEnum[keyof typeof WoodEquilibriumPayloadV2SubfloorTypeEnum];

/**
 * 
 * @export
 * @interface WoodEquilibriumResponse
 */
export interface WoodEquilibriumResponse {
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponse
     */
    'measuredMc': number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponse
     */
    'equilibriumMc': number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponse
     */
    'productMCRangeMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponse
     */
    'productMCRangeMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponse
     */
    'nwfaMCRangeMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponse
     */
    'nwfaMCRangeMin'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WoodEquilibriumResponse
     */
    'hasProblems': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WoodEquilibriumResponse
     */
    'isInRangeEquilibriumMc': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WoodEquilibriumResponse
     */
    'isInRangeMeasuredMc': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof WoodEquilibriumResponse
     */
    'messages': Array<string>;
}
/**
 * 
 * @export
 * @interface WoodEquilibriumResponseV2
 */
export interface WoodEquilibriumResponseV2 {
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponseV2
     */
    'measuredMc': number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponseV2
     */
    'equilibriumMc': number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponseV2
     */
    'subfloorMc'?: number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponseV2
     */
    'subfloorMCRangeMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponseV2
     */
    'subfloorMCRangeMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponseV2
     */
    'productMinTemperature'?: number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponseV2
     */
    'productMaxTemperature'?: number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponseV2
     */
    'productMinHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponseV2
     */
    'productMaxHumidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponseV2
     */
    'productMCRangeMax': number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponseV2
     */
    'productMCRangeMin': number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponseV2
     */
    'nwfaMCRangeMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof WoodEquilibriumResponseV2
     */
    'nwfaMCRangeMin'?: number;
    /**
     * 
     * @type {string}
     * @memberof WoodEquilibriumResponseV2
     */
    'inRangeEquilibriumMc': string;
    /**
     * 
     * @type {string}
     * @memberof WoodEquilibriumResponseV2
     */
    'inRangeMeasuredMc': string;
    /**
     * 
     * @type {string}
     * @memberof WoodEquilibriumResponseV2
     */
    'subfloorMCInRange': string | null;
    /**
     * 
     * @type {WoodEquilibriumResponseV2Messages}
     * @memberof WoodEquilibriumResponseV2
     */
    'messages': WoodEquilibriumResponseV2Messages;
    /**
     * 
     * @type {boolean}
     * @memberof WoodEquilibriumResponseV2
     */
    'isInRangeHumidity': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof WoodEquilibriumResponseV2
     */
    'isInRangeTemperature': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof WoodEquilibriumResponseV2
     */
    'subfloorMCRange': string | null;
}
/**
 * 
 * @export
 * @interface WoodEquilibriumResponseV2Messages
 */
export interface WoodEquilibriumResponseV2Messages {
    /**
     * 
     * @type {Array<string>}
     * @memberof WoodEquilibriumResponseV2Messages
     */
    'equilibriumMc'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WoodEquilibriumResponseV2Messages
     */
    'woodMc'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WoodEquilibriumResponseV2Messages
     */
    'subfloorMc'?: Array<string>;
}
/**
 * 
 * @export
 * @interface XlsxSheetProblem
 */
export interface XlsxSheetProblem {
    /**
     * 
     * @type {number}
     * @memberof XlsxSheetProblem
     */
    'row'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof XlsxSheetProblem
     */
    'reasons'?: Array<string>;
}

/**
 * AdminAPIApi - axios parameter creator
 * @export
 */
export const AdminAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return a list of alerts
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [search] Search by name and description
         * @param {number} [projectId] The project id
         * @param {number} [projectSectionId] The project section id
         * @param {number} [contractorId] The contractor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAlertsGet: async (limit?: number, offset?: number, sort?: string, search?: string, projectId?: number, projectSectionId?: number, contractorId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (projectSectionId !== undefined) {
                localVarQueryParameter['projectSectionId'] = projectSectionId;
            }

            if (contractorId !== undefined) {
                localVarQueryParameter['contractorId'] = contractorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a single attachment
         * @param {number} attachmentId ID of attachment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAttachmentsAttachmentIdGet: async (attachmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('v1AdminAttachmentsAttachmentIdGet', 'attachmentId', attachmentId)
            const localVarPath = `/v1/admin/attachments/{attachmentId}`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a list of attachments
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {'pending' | 'processing' | 'processed' | 'failed' | 'cancelled'} [status] Status of attachments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAttachmentsGet: async (limit?: number, offset?: number, sort?: string, status?: 'pending' | 'processing' | 'processed' | 'failed' | 'cancelled', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns building type list.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminBuildingTypesGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/building-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new building type
         * @param {CreateBuildingTypePayload} [payload] Payload for building type creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminBuildingTypesPost: async (payload?: CreateBuildingTypePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/building-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a true if building type successfully deleted
         * @param {number} typeId The building type ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminBuildingTypesTypeIdDelete: async (typeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeId' is not null or undefined
            assertParamExists('v1AdminBuildingTypesTypeIdDelete', 'typeId', typeId)
            const localVarPath = `/v1/admin/building-types/{typeId}`
                .replace(`{${"typeId"}}`, encodeURIComponent(String(typeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a building type state.
         * @param {number} typeId The building type ID
         * @param {UpdateBuildingTypePayload} [payload] Payload for updating building type state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminBuildingTypesTypeIdPatch: async (typeId: number, payload?: UpdateBuildingTypePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeId' is not null or undefined
            assertParamExists('v1AdminBuildingTypesTypeIdPatch', 'typeId', typeId)
            const localVarPath = `/v1/admin/building-types/{typeId}`
                .replace(`{${"typeId"}}`, encodeURIComponent(String(typeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns configuration settings
         * @param {number} configParamId The cofiguration param identifier
         * @param {ConfigurationParamUpdate} [payload] Payload to update configuration param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminConfigurationConfigParamIdPut: async (configParamId: number, payload?: ConfigurationParamUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configParamId' is not null or undefined
            assertParamExists('v1AdminConfigurationConfigParamIdPut', 'configParamId', configParamId)
            const localVarPath = `/v1/admin/configuration/{configParamId}`
                .replace(`{${"configParamId"}}`, encodeURIComponent(String(configParamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns configuration settings
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminConfigurationGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns mobile settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminConfigurationMobileSettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/configuration/mobile-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a mobile settings
         * @param {UpdateMobileSettings} [updateMobileSettings] Payload for updating a mobile settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminConfigurationMobileSettingsPut: async (updateMobileSettings?: UpdateMobileSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/configuration/mobile-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMobileSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bulk update configuration settings
         * @param {Array<ConfigurationParamUpdateBulkItem>} [payload] Payload to update configuration parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminConfigurationPut: async (payload?: Array<ConfigurationParamUpdateBulkItem>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of staging devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminConfigurationSensorAllocationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/configuration/sensor-allocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update list of staging devices
         * @param {UpdateStagingDevices} [updateStagingDevices] Payload for updating list of staging devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminConfigurationSensorAllocationPut: async (updateStagingDevices?: UpdateStagingDevices, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/configuration/sensor-allocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStagingDevices, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activates contractor
         * @param {number} contractorId The contractor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminContractorsContractorIdActivatePost: async (contractorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractorId' is not null or undefined
            assertParamExists('v1AdminContractorsContractorIdActivatePost', 'contractorId', contractorId)
            const localVarPath = `/v1/admin/contractors/{contractorId}/activate`
                .replace(`{${"contractorId"}}`, encodeURIComponent(String(contractorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivates contractor
         * @param {number} contractorId The contractor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminContractorsContractorIdDeactivatePost: async (contractorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractorId' is not null or undefined
            assertParamExists('v1AdminContractorsContractorIdDeactivatePost', 'contractorId', contractorId)
            const localVarPath = `/v1/admin/contractors/{contractorId}/deactivate`
                .replace(`{${"contractorId"}}`, encodeURIComponent(String(contractorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrurns contractor
         * @param {number} contractorId The contractor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminContractorsContractorIdGet: async (contractorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractorId' is not null or undefined
            assertParamExists('v1AdminContractorsContractorIdGet', 'contractorId', contractorId)
            const localVarPath = `/v1/admin/contractors/{contractorId}`
                .replace(`{${"contractorId"}}`, encodeURIComponent(String(contractorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the contractor paid plan
         * @param {number} contractorId The contractor id
         * @param {UpdateContractorPaidPlan} payload Payload for updating contractor paid plan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminContractorsContractorIdPaidPlanPut: async (contractorId: number, payload: UpdateContractorPaidPlan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractorId' is not null or undefined
            assertParamExists('v1AdminContractorsContractorIdPaidPlanPut', 'contractorId', contractorId)
            // verify required parameter 'payload' is not null or undefined
            assertParamExists('v1AdminContractorsContractorIdPaidPlanPut', 'payload', payload)
            const localVarPath = `/v1/admin/contractors/{contractorId}/paid-plan`
                .replace(`{${"contractorId"}}`, encodeURIComponent(String(contractorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates contractor
         * @param {number} contractorId The contractor id
         * @param {ContractorEditing} [payload] Payload to update contractor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminContractorsContractorIdPut: async (contractorId: number, payload?: ContractorEditing, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractorId' is not null or undefined
            assertParamExists('v1AdminContractorsContractorIdPut', 'contractorId', contractorId)
            const localVarPath = `/v1/admin/contractors/{contractorId}`
                .replace(`{${"contractorId"}}`, encodeURIComponent(String(contractorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of contractor tokens history
         * @param {number} contractorId The contractor ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminContractorsContractorIdTokensHistoryGet: async (contractorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractorId' is not null or undefined
            assertParamExists('v1AdminContractorsContractorIdTokensHistoryGet', 'contractorId', contractorId)
            const localVarPath = `/v1/admin/contractors/{contractorId}/tokens-history`
                .replace(`{${"contractorId"}}`, encodeURIComponent(String(contractorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of contractors
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [name] Filter by contractors name
         * @param {string} [search] Search by name, phone, email, address
         * @param {boolean} [isActivePaidPlan] Filter by the status of a paid plan
         * @param {boolean} [isActiveTrial] Filter by the status of a trial period
         * @param {boolean} [isRoot] Filter by the root sign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminContractorsGet: async (limit?: number, offset?: number, sort?: string, name?: string, search?: string, isActivePaidPlan?: boolean, isActiveTrial?: boolean, isRoot?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/contractors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (isActivePaidPlan !== undefined) {
                localVarQueryParameter['isActivePaidPlan'] = isActivePaidPlan;
            }

            if (isActiveTrial !== undefined) {
                localVarQueryParameter['isActiveTrial'] = isActiveTrial;
            }

            if (isRoot !== undefined) {
                localVarQueryParameter['isRoot'] = isRoot;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates contractor
         * @param {ContractorCreation} [payload] Payload to create contractor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminContractorsPost: async (payload?: ContractorCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/contractors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a statistics for dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminDashboardGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Parse rapid rh advertisement packet
         * @param {ParseAdvPacketPayload} payload Payload parse a packet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminDiagnosticsParseAdvPacketPost: async (payload: ParseAdvPacketPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            assertParamExists('v1AdminDiagnosticsParseAdvPacketPost', 'payload', payload)
            const localVarPath = `/v1/admin/diagnostics/parse-adv-packet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns aggregated timeseries gap data
         * @param {string} dateFrom Date from
         * @param {string} [dateTo] Date to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminDiagnosticsTimeseriesGapGet: async (dateFrom: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('v1AdminDiagnosticsTimeseriesGapGet', 'dateFrom', dateFrom)
            const localVarPath = `/v1/admin/diagnostics/timeseries-gap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns aggregated timeseries handling gap data
         * @param {string} dateFrom Date from
         * @param {string} [dateTo] Date to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminDiagnosticsTimeseriesHandlingGapGet: async (dateFrom: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('v1AdminDiagnosticsTimeseriesHandlingGapGet', 'dateFrom', dateFrom)
            const localVarPath = `/v1/admin/diagnostics/timeseries-handling-gap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a list of documents
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [search] Search by name and description
         * @param {number} [projectId] The project id
         * @param {number} [contractorId] The contractor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminDocumentsGet: async (limit?: number, offset?: number, sort?: string, search?: string, projectId?: number, contractorId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (contractorId !== undefined) {
                localVarQueryParameter['contractorId'] = contractorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of events
         * @param {number} eventId The event identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminEventsEventIdGet: async (eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('v1AdminEventsEventIdGet', 'eventId', eventId)
            const localVarPath = `/v1/admin/events/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of events
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [projectId] The project ID
         * @param {number} [userId] The user ID
         * @param {string} [typeId] The event type ID
         * @param {boolean} [hideServiceAccount] The attribute to hide or not events created by service account
         * @param {number} [contractorId] The contractor ID
         * @param {number} [sensorId] The sensor ID
         * @param {boolean} [withPhotos] The attribute to return events with photos or not
         * @param {number} [rapidRhProbeId] The rapid RH probe ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminEventsGet: async (limit?: number, offset?: number, sort?: string, projectId?: number, userId?: number, typeId?: string, hideServiceAccount?: boolean, contractorId?: number, sensorId?: number, withPhotos?: boolean, rapidRhProbeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (typeId !== undefined) {
                localVarQueryParameter['typeId'] = typeId;
            }

            if (hideServiceAccount !== undefined) {
                localVarQueryParameter['hideServiceAccount'] = hideServiceAccount;
            }

            if (contractorId !== undefined) {
                localVarQueryParameter['contractorId'] = contractorId;
            }

            if (sensorId !== undefined) {
                localVarQueryParameter['sensorId'] = sensorId;
            }

            if (withPhotos !== undefined) {
                localVarQueryParameter['withPhotos'] = withPhotos;
            }

            if (rapidRhProbeId !== undefined) {
                localVarQueryParameter['rapidRhProbeId'] = rapidRhProbeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update event type by id
         * @param {number} eventTypeId Event type Id
         * @param {EventTypeUpdatePayload} [payload] Payload for update event type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminEventsTypesEventTypeIdPatch: async (eventTypeId: number, payload?: EventTypeUpdatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventTypeId' is not null or undefined
            assertParamExists('v1AdminEventsTypesEventTypeIdPatch', 'eventTypeId', eventTypeId)
            const localVarPath = `/v1/admin/events/types/{eventTypeId}`
                .replace(`{${"eventTypeId"}}`, encodeURIComponent(String(eventTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns event types list.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminEventsTypesGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/events/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of labels
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminLabelsGet: async (sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/labels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a label
         * @param {LabelCreation} [payload] Payload to create a label
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminLabelsPost: async (payload?: LabelCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/labels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a manufacturer announcement category
         * @param {number} categoryId The category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsCategoriesCategoryIdDelete: async (categoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('v1AdminManufacturersAnnouncementsCategoriesCategoryIdDelete', 'categoryId', categoryId)
            const localVarPath = `/v1/admin/manufacturers/announcements/categories/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a manufacturer announcement category
         * @param {number} categoryId The category id
         * @param {UpdateManufacturerAnnouncementCategoryPayload} updateManufacturerAnnouncementCategoryPayload Payload to update a manufacturer announcement category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsCategoriesCategoryIdPut: async (categoryId: number, updateManufacturerAnnouncementCategoryPayload: UpdateManufacturerAnnouncementCategoryPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('v1AdminManufacturersAnnouncementsCategoriesCategoryIdPut', 'categoryId', categoryId)
            // verify required parameter 'updateManufacturerAnnouncementCategoryPayload' is not null or undefined
            assertParamExists('v1AdminManufacturersAnnouncementsCategoriesCategoryIdPut', 'updateManufacturerAnnouncementCategoryPayload', updateManufacturerAnnouncementCategoryPayload)
            const localVarPath = `/v1/admin/manufacturers/announcements/categories/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateManufacturerAnnouncementCategoryPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a list of manufacturer announcement categories
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsCategoriesGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/manufacturers/announcements/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new manufacturer announcement category
         * @param {CreateManufacturerAnnouncementCategoryPayload} createManufacturerAnnouncementCategoryPayload Payload to create a new manufacturer announcement category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsCategoriesPost: async (createManufacturerAnnouncementCategoryPayload: CreateManufacturerAnnouncementCategoryPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManufacturerAnnouncementCategoryPayload' is not null or undefined
            assertParamExists('v1AdminManufacturersAnnouncementsCategoriesPost', 'createManufacturerAnnouncementCategoryPayload', createManufacturerAnnouncementCategoryPayload)
            const localVarPath = `/v1/admin/manufacturers/announcements/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createManufacturerAnnouncementCategoryPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of manufacturer announcements
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/manufacturers/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a manufacturer announcement
         * @param {number} manufacturerAnnouncementId The manufacturer announcement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdDelete: async (manufacturerAnnouncementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerAnnouncementId' is not null or undefined
            assertParamExists('v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdDelete', 'manufacturerAnnouncementId', manufacturerAnnouncementId)
            const localVarPath = `/v1/admin/manufacturers/announcements/{manufacturerAnnouncementId}`
                .replace(`{${"manufacturerAnnouncementId"}}`, encodeURIComponent(String(manufacturerAnnouncementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a manufacturer announcement
         * @param {number} manufacturerAnnouncementId The manufacturer announcement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdGet: async (manufacturerAnnouncementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerAnnouncementId' is not null or undefined
            assertParamExists('v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdGet', 'manufacturerAnnouncementId', manufacturerAnnouncementId)
            const localVarPath = `/v1/admin/manufacturers/announcements/{manufacturerAnnouncementId}`
                .replace(`{${"manufacturerAnnouncementId"}}`, encodeURIComponent(String(manufacturerAnnouncementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a manufacturer announcement
         * @param {number} manufacturerAnnouncementId The manufacturer announcement id
         * @param {UpdateManufacturerAnnouncementPayload} [payload] Payload to update a manufacturer announcement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdPut: async (manufacturerAnnouncementId: number, payload?: UpdateManufacturerAnnouncementPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerAnnouncementId' is not null or undefined
            assertParamExists('v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdPut', 'manufacturerAnnouncementId', manufacturerAnnouncementId)
            const localVarPath = `/v1/admin/manufacturers/announcements/{manufacturerAnnouncementId}`
                .replace(`{${"manufacturerAnnouncementId"}}`, encodeURIComponent(String(manufacturerAnnouncementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a manufacturer announcement
         * @param {CreateManufacturerAnnouncementPayload} [payload] Payload to create a manufacturer announcement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsPost: async (payload?: CreateManufacturerAnnouncementPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/manufacturers/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of manufacturer announcement types
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsTypesGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/manufacturers/announcements/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new manufacturer announcement type
         * @param {CreateManufacturerAnnouncementTypePayload} createManufacturerAnnouncementTypePayload Payload to create a new manufacturer announcement type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsTypesPost: async (createManufacturerAnnouncementTypePayload: CreateManufacturerAnnouncementTypePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManufacturerAnnouncementTypePayload' is not null or undefined
            assertParamExists('v1AdminManufacturersAnnouncementsTypesPost', 'createManufacturerAnnouncementTypePayload', createManufacturerAnnouncementTypePayload)
            const localVarPath = `/v1/admin/manufacturers/announcements/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createManufacturerAnnouncementTypePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a manufacturer announcement type
         * @param {number} typeId The type id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsTypesTypeIdDelete: async (typeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeId' is not null or undefined
            assertParamExists('v1AdminManufacturersAnnouncementsTypesTypeIdDelete', 'typeId', typeId)
            const localVarPath = `/v1/admin/manufacturers/announcements/types/{typeId}`
                .replace(`{${"typeId"}}`, encodeURIComponent(String(typeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a manufacturer announcement type
         * @param {number} typeId The type id
         * @param {UpdateManufacturerAnnouncementTypePayload} updateManufacturerAnnouncementTypePayload Payload to update a manufacturer announcement type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsTypesTypeIdPut: async (typeId: number, updateManufacturerAnnouncementTypePayload: UpdateManufacturerAnnouncementTypePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeId' is not null or undefined
            assertParamExists('v1AdminManufacturersAnnouncementsTypesTypeIdPut', 'typeId', typeId)
            // verify required parameter 'updateManufacturerAnnouncementTypePayload' is not null or undefined
            assertParamExists('v1AdminManufacturersAnnouncementsTypesTypeIdPut', 'updateManufacturerAnnouncementTypePayload', updateManufacturerAnnouncementTypePayload)
            const localVarPath = `/v1/admin/manufacturers/announcements/types/{typeId}`
                .replace(`{${"typeId"}}`, encodeURIComponent(String(typeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateManufacturerAnnouncementTypePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a manufacturer list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [name] Filter by name
         * @param {string} [search] Search by name, phone, email, address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersGet: async (limit?: number, offset?: number, sort?: string, name?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a manufacturer
         * @param {number} manufacturerId The manufacturer id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersManufacturerIdDelete: async (manufacturerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('v1AdminManufacturersManufacturerIdDelete', 'manufacturerId', manufacturerId)
            const localVarPath = `/v1/admin/manufacturers/{manufacturerId}`
                .replace(`{${"manufacturerId"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrurns manufacturer
         * @param {number} manufacturerId The manufacturer id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersManufacturerIdGet: async (manufacturerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('v1AdminManufacturersManufacturerIdGet', 'manufacturerId', manufacturerId)
            const localVarPath = `/v1/admin/manufacturers/{manufacturerId}`
                .replace(`{${"manufacturerId"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates manufacturer
         * @param {number} manufacturerId The manufacturer id
         * @param {ManufacturerEditing} [payload] Payload to update manufacturer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersManufacturerIdPut: async (manufacturerId: number, payload?: ManufacturerEditing, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('v1AdminManufacturersManufacturerIdPut', 'manufacturerId', manufacturerId)
            const localVarPath = `/v1/admin/manufacturers/{manufacturerId}`
                .replace(`{${"manufacturerId"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a manufacturer and returns the new one.
         * @param {ManufacturerCreation} [payload] Payload for creating a manufacturer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersPost: async (payload?: ManufacturerCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the product.
         * @param {number} draftId The draft id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsDraftsDraftIdDelete: async (draftId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'draftId' is not null or undefined
            assertParamExists('v1AdminProductsDraftsDraftIdDelete', 'draftId', draftId)
            const localVarPath = `/v1/admin/products/drafts/{draftId}`
                .replace(`{${"draftId"}}`, encodeURIComponent(String(draftId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a products\' drafts.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [manufacturerId] Manufacturer ID
         * @param {number} [typeId] Product type ID
         * @param {string} [name] The product name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsDraftsGet: async (limit?: number, offset?: number, sort?: string, manufacturerId?: number, typeId?: number, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products/drafts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturerId'] = manufacturerId;
            }

            if (typeId !== undefined) {
                localVarQueryParameter['typeId'] = typeId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a product draft and returns the new one.
         * @param {ProductDraftCreation} [payload] Payload for creating a product draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsDraftsPost: async (payload?: ProductDraftCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products/drafts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publishes a products\' drafts.
         * @param {ProductsDraftsPublishBulk} [payload] Payload for publishing a products\&#39; drafts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsDraftsPublishPost: async (payload?: ProductsDraftsPublishBulk, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products/drafts/publish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates products\' drafts.
         * @param {Array<ProductsDraftsUpdate>} [payload] Payload for updating a product draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsDraftsPut: async (payload?: Array<ProductsDraftsUpdate>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products/drafts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a product list.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [manufacturerId] Manufacturer ID
         * @param {number} [typeId] Product type ID
         * @param {string} [name] The product name
         * @param {number} [projectId] The project ID
         * @param {boolean} [noShelfLife] Is shelfLife field eq null or not
         * @param {boolean} [noRanges] Is some of lowHumidity, highHumidity, lowTempF, highTempF fields eq null or not
         * @param {boolean} [noTips] Is product tips count eq 0 or not
         * @param {boolean} [inUse] Is project product count eq 0 or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsGet: async (limit?: number, offset?: number, sort?: string, manufacturerId?: number, typeId?: number, name?: string, projectId?: number, noShelfLife?: boolean, noRanges?: boolean, noTips?: boolean, inUse?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturerId'] = manufacturerId;
            }

            if (typeId !== undefined) {
                localVarQueryParameter['typeId'] = typeId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (noShelfLife !== undefined) {
                localVarQueryParameter['noShelfLife'] = noShelfLife;
            }

            if (noRanges !== undefined) {
                localVarQueryParameter['noRanges'] = noRanges;
            }

            if (noTips !== undefined) {
                localVarQueryParameter['noTips'] = noTips;
            }

            if (inUse !== undefined) {
                localVarQueryParameter['inUse'] = inUse;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a list of product imports
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsImportHistoryGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products/import-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a product import details
         * @param {number} importHistoryId The import history item id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsImportHistoryImportHistoryIdGet: async (importHistoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'importHistoryId' is not null or undefined
            assertParamExists('v1AdminProductsImportHistoryImportHistoryIdGet', 'importHistoryId', importHistoryId)
            const localVarPath = `/v1/admin/products/import-history/{importHistoryId}`
                .replace(`{${"importHistoryId"}}`, encodeURIComponent(String(importHistoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a link for downloading an import products template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsImportTemplateLinkGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products/import-template-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Merges products
         * @param {MergeProductsPayload} payload Payload to merge products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsMergePost: async (payload: MergeProductsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            assertParamExists('v1AdminProductsMergePost', 'payload', payload)
            const localVarPath = `/v1/admin/products/merge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a product package types
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsPackageTypesGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products/package-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a product and returns the new one.
         * @param {ProductCreation} [payload] Payload for creating a product
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1AdminProductsPost: async (payload?: ProductCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a true if product successfully deleted
         * @param {number} productId The product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsProductIdDelete: async (productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('v1AdminProductsProductIdDelete', 'productId', productId)
            const localVarPath = `/v1/admin/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a single product
         * @param {number} productId The product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsProductIdGet: async (productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('v1AdminProductsProductIdGet', 'productId', productId)
            const localVarPath = `/v1/admin/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a product and returns it.
         * @param {number} productId The product ID
         * @param {ProductEditing} [payload] Payload for updating a product
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1AdminProductsProductIdPut: async (productId: number, payload?: ProductEditing, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('v1AdminProductsProductIdPut', 'productId', productId)
            const localVarPath = `/v1/admin/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of product requests
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsRequestsGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns number of new product requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsRequestsNumberOfNewGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products/requests/number-of-new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a product request
         * @param {number} productRequestId The product request identifier
         * @param {UpdateProductRequestPayload} [updateProductRequestPayload] Payload to update a product request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsRequestsProductRequestIdPut: async (productRequestId: number, updateProductRequestPayload?: UpdateProductRequestPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productRequestId' is not null or undefined
            assertParamExists('v1AdminProductsRequestsProductRequestIdPut', 'productRequestId', productRequestId)
            const localVarPath = `/v1/admin/products/requests/{productRequestId}`
                .replace(`{${"productRequestId"}}`, encodeURIComponent(String(productRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a product request summary
         * @param {number} productRequestId The product request identifier
         * @param {ProductRequestSummaryPayload} [productRequestSummaryPayload] Payload to update a product request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsRequestsProductRequestIdSendSummaryPost: async (productRequestId: number, productRequestSummaryPayload?: ProductRequestSummaryPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productRequestId' is not null or undefined
            assertParamExists('v1AdminProductsRequestsProductRequestIdSendSummaryPost', 'productRequestId', productRequestId)
            const localVarPath = `/v1/admin/products/requests/{productRequestId}/send-summary`
                .replace(`{${"productRequestId"}}`, encodeURIComponent(String(productRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productRequestSummaryPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of product request statuses
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsRequestsStatusesGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products/requests/statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a product tip types
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsTipTypesGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products/tip-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a product tips.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [tipTypeId] The tip\&#39;s type ID
         * @param {number} [productId] The product ID
         * @param {number} [productDraftId] The product\&#39;s draft ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsTipsCustomGet: async (limit?: number, offset?: number, sort?: string, tipTypeId?: number, productId?: number, productDraftId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products/tips/custom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (tipTypeId !== undefined) {
                localVarQueryParameter['tipTypeId'] = tipTypeId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (productDraftId !== undefined) {
                localVarQueryParameter['productDraftId'] = productDraftId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a product types\' tips.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [tipTypeId] The tip\&#39;s type ID
         * @param {number} [productTypeId] The product\&#39;s type ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsTipsDefaultGet: async (limit?: number, offset?: number, sort?: string, tipTypeId?: number, productTypeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products/tips/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (tipTypeId !== undefined) {
                localVarQueryParameter['tipTypeId'] = tipTypeId;
            }

            if (productTypeId !== undefined) {
                localVarQueryParameter['productTypeId'] = productTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a product types
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [withTips] Send product types with or without tips
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsTypesGet: async (limit?: number, offset?: number, sort?: string, withTips?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (withTips !== undefined) {
                localVarQueryParameter['withTips'] = withTips;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new product type
         * @param {ProductTypeCreation} [payload] Payload for the product type creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsTypesPost: async (payload?: ProductTypeCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/products/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the product type
         * @param {number} productTypeId The product type ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsTypesProductTypeIdDelete: async (productTypeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productTypeId' is not null or undefined
            assertParamExists('v1AdminProductsTypesProductTypeIdDelete', 'productTypeId', productTypeId)
            const localVarPath = `/v1/admin/products/types/{productTypeId}`
                .replace(`{${"productTypeId"}}`, encodeURIComponent(String(productTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the product type
         * @param {number} productTypeId The product type ID
         * @param {ProductTypeUpdate} [payload] Payload to update the product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsTypesProductTypeIdPut: async (productTypeId: number, payload?: ProductTypeUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productTypeId' is not null or undefined
            assertParamExists('v1AdminProductsTypesProductTypeIdPut', 'productTypeId', productTypeId)
            const localVarPath = `/v1/admin/products/types/{productTypeId}`
                .replace(`{${"productTypeId"}}`, encodeURIComponent(String(productTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the product type tips
         * @param {number} productTypeId The product type ID
         * @param {ProductTypeTipsUpdate} [payload] Payload to update the product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsTypesProductTypeIdTipsPut: async (productTypeId: number, payload?: ProductTypeTipsUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productTypeId' is not null or undefined
            assertParamExists('v1AdminProductsTypesProductTypeIdTipsPut', 'productTypeId', productTypeId)
            const localVarPath = `/v1/admin/products/types/{productTypeId}/tips`
                .replace(`{${"productTypeId"}}`, encodeURIComponent(String(productTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload xlsx
         * @param {any} xlsx Xlsx to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsUploadPut: async (xlsx: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xlsx' is not null or undefined
            assertParamExists('v1AdminProductsUploadPut', 'xlsx', xlsx)
            const localVarPath = `/v1/admin/products/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (xlsx !== undefined) { 
                localVarFormParams.append('xlsx', xlsx as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of project sections.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [projectId] Project ID
         * @param {boolean} [onlyActiveSections] Filter by the project section status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProjectSectionsGet: async (limit?: number, offset?: number, sort?: string, projectId?: number, onlyActiveSections?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/project-sections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (onlyActiveSections !== undefined) {
                localVarQueryParameter['onlyActiveSections'] = onlyActiveSections;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns single project section by id
         * @param {number} projectSectionId The project section id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProjectSectionsProjectSectionIdGet: async (projectSectionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectSectionId' is not null or undefined
            assertParamExists('v1AdminProjectSectionsProjectSectionIdGet', 'projectSectionId', projectSectionId)
            const localVarPath = `/v1/admin/project-sections/{projectSectionId}`
                .replace(`{${"projectSectionId"}}`, encodeURIComponent(String(projectSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of projects.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [contractorId] Contractor ID
         * @param {number} [typeId] Type ID
         * @param {string} [city] City of the project
         * @param {number} [productId] Product ID
         * @param {number} [userId] User ID
         * @param {string} [search] Search by project name and location
         * @param {boolean} [isActive] Filter by the project status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProjectsGet: async (limit?: number, offset?: number, sort?: string, contractorId?: number, typeId?: number, city?: string, productId?: number, userId?: number, search?: string, isActive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (contractorId !== undefined) {
                localVarQueryParameter['contractorId'] = contractorId;
            }

            if (typeId !== undefined) {
                localVarQueryParameter['typeId'] = typeId;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns single project by id
         * @param {number} projectId The project id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProjectsProjectIdGet: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('v1AdminProjectsProjectIdGet', 'projectId', projectId)
            const localVarPath = `/v1/admin/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of projects types
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProjectsTypesGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/projects/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new project type
         * @param {CreateProjectTypePayload} [payload] Payload for project type creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProjectsTypesPost: async (payload?: CreateProjectTypePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/projects/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a true if project type successfully deleted
         * @param {number} typeId The project type ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProjectsTypesTypeIdDelete: async (typeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeId' is not null or undefined
            assertParamExists('v1AdminProjectsTypesTypeIdDelete', 'typeId', typeId)
            const localVarPath = `/v1/admin/projects/types/{typeId}`
                .replace(`{${"typeId"}}`, encodeURIComponent(String(typeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a project type state.
         * @param {number} typeId The project type ID
         * @param {UpdateProjectTypePayload} [payload] Payload for updating project type state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProjectsTypesTypeIdPatch: async (typeId: number, payload?: UpdateProjectTypePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeId' is not null or undefined
            assertParamExists('v1AdminProjectsTypesTypeIdPatch', 'typeId', typeId)
            const localVarPath = `/v1/admin/projects/types/{typeId}`
                .replace(`{${"typeId"}}`, encodeURIComponent(String(typeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a release list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReleasesGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/releases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a release and returns the new one.
         * @param {ReleaseCreation} [payload] Payload for creating a release
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReleasesPost: async (payload?: ReleaseCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/releases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes release
         * @param {number} releaseId The release id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReleasesReleaseIdDelete: async (releaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1AdminReleasesReleaseIdDelete', 'releaseId', releaseId)
            const localVarPath = `/v1/admin/releases/{releaseId}`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrurns release
         * @param {number} releaseId The release id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReleasesReleaseIdGet: async (releaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1AdminReleasesReleaseIdGet', 'releaseId', releaseId)
            const localVarPath = `/v1/admin/releases/{releaseId}`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates release
         * @param {number} releaseId The release id
         * @param {ReleaseEditing} [payload] Payload to update release
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReleasesReleaseIdPut: async (releaseId: number, payload?: ReleaseEditing, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releaseId' is not null or undefined
            assertParamExists('v1AdminReleasesReleaseIdPut', 'releaseId', releaseId)
            const localVarPath = `/v1/admin/releases/{releaseId}`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return components for combined reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReportsCombinedReportsComponentsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/reports/combined-reports/components`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update component for combined reports
         * @param {number} reportComponentId The report component id
         * @param {CombinedReportComponentUpdatePayload} combinedReportComponentUpdatePayload Payload to update component for combined reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReportsCombinedReportsComponentsReportComponentIdPatch: async (reportComponentId: number, combinedReportComponentUpdatePayload: CombinedReportComponentUpdatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportComponentId' is not null or undefined
            assertParamExists('v1AdminReportsCombinedReportsComponentsReportComponentIdPatch', 'reportComponentId', reportComponentId)
            // verify required parameter 'combinedReportComponentUpdatePayload' is not null or undefined
            assertParamExists('v1AdminReportsCombinedReportsComponentsReportComponentIdPatch', 'combinedReportComponentUpdatePayload', combinedReportComponentUpdatePayload)
            const localVarPath = `/v1/admin/reports/combined-reports/components/{reportComponentId}`
                .replace(`{${"reportComponentId"}}`, encodeURIComponent(String(reportComponentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedReportComponentUpdatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns type of included events for combined reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReportsCombinedReportsIncludedEventsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/reports/combined-reports/included-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update type of included events for combined reports
         * @param {CombinedReportIncludedEventUpdatePayload} combinedReportIncludedEventUpdatePayload Payload to update type of included events for combined reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReportsCombinedReportsIncludedEventsPut: async (combinedReportIncludedEventUpdatePayload: CombinedReportIncludedEventUpdatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'combinedReportIncludedEventUpdatePayload' is not null or undefined
            assertParamExists('v1AdminReportsCombinedReportsIncludedEventsPut', 'combinedReportIncludedEventUpdatePayload', combinedReportIncludedEventUpdatePayload)
            const localVarPath = `/v1/admin/reports/combined-reports/included-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedReportIncludedEventUpdatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The ednpoint returns list of reports
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [contractorId] Filter by contractor id
         * @param {number} [projectId] Filter by project id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReportsGet: async (limit?: number, offset?: number, sort?: string, contractorId?: number, projectId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (contractorId !== undefined) {
                localVarQueryParameter['contractorId'] = contractorId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Regenerate a report\'s link and return updated report
         * @param {number} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReportsReportIdRegenerateLinkPost: async (reportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('v1AdminReportsReportIdRegenerateLinkPost', 'reportId', reportId)
            const localVarPath = `/v1/admin/reports/{reportId}/regenerate-link`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of report types
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReportsTypesGet: async (sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/reports/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of report types
         * @param {number} reportTypeId The report type identifier
         * @param {UpdateReportTypePayload} [updateReportTypePayload] Payload to update a report type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReportsTypesReportTypeIdPut: async (reportTypeId: number, updateReportTypePayload?: UpdateReportTypePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportTypeId' is not null or undefined
            assertParamExists('v1AdminReportsTypesReportTypeIdPut', 'reportTypeId', reportTypeId)
            const localVarPath = `/v1/admin/reports/types/{reportTypeId}`
                .replace(`{${"reportTypeId"}}`, encodeURIComponent(String(reportTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateReportTypePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends a request to calculate a sensor technical status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsCalculateTechnicalStatusPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sensors/calculate-technical-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a default sensor configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsDefaultConfigurationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sensors/default-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a default sensor configuration
         * @param {SensorConfiguration} [payload] Payload for updating a default sensor configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsDefaultConfigurationPut: async (payload?: SensorConfiguration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sensors/default-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fills sensors missed readings
         * @param {FillMissedReadingsBulkPayload} [fillMissedReadingsBulkPayload] Payload to fill sensors missed readings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsFillMissedReadingsPost: async (fillMissedReadingsBulkPayload?: FillMissedReadingsBulkPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sensors/fill-missed-readings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fillMissedReadingsBulkPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of sensors firmware versions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsFirmwareVersionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sensors/firmware-versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns ZIP file with generated lables for sensors
         * @param {string} sensorUniqueIds The sensors unique identifiers (uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsGenerateLabelsGet: async (sensorUniqueIds: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sensorUniqueIds' is not null or undefined
            assertParamExists('v1AdminSensorsGenerateLabelsGet', 'sensorUniqueIds', sensorUniqueIds)
            const localVarPath = `/v1/admin/sensors/generate-labels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sensorUniqueIds !== undefined) {
                localVarQueryParameter['sensorUniqueIds'] = sensorUniqueIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of sensors.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [projectId] Project ID
         * @param {number} [contractorId] Contractor ID
         * @param {number} [stateId] State ID
         * @param {string} [lastHeard] The last time the sensor sent data
         * @param {string} [lastPing] The updatedAt of the sensor current data
         * @param {string} [synchronizationPeriod] The synchronization period of a sensor, in seconds
         * @param {string} [accelerationTime] The last time of the acceleration readings
         * @param {string} [batteryTime] The last time of the battery readings
         * @param {string} [dewpointTime] The last time of the dewpoint readings
         * @param {string} [humidityTime] The last time of the humidity readings
         * @param {string} [lightTime] The last time of the light readings
         * @param {string} [locationTime] The last time of the cellular location readings
         * @param {string} [locationGpsTime] The last time of the gps location readings
         * @param {string} [orientationTime] The last time of the orientation readings
         * @param {string} [pressureTime] The last time of the pressure readings
         * @param {string} [signalStrengthTime] The last time of the signal strength readings
         * @param {string} [temperatureTime] The last time of the temperature readings
         * @param {number} [batteryLevel] Battery charge percentage
         * @param {number} [signalStrength] The signal strength level
         * @param {string} [search] Search by Sensor ID, IMEI, Dev EUI
         * @param {string} [currentSensorConfiguration] Type of a sensor\&#39;s configuration
         * @param {boolean} [isBleEnabled] Filter by status of BLE module
         * @param {string} [firmware] Filter by sensor firmware version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsGet: async (limit?: number, offset?: number, sort?: string, projectId?: number, contractorId?: number, stateId?: number, lastHeard?: string, lastPing?: string, synchronizationPeriod?: string, accelerationTime?: string, batteryTime?: string, dewpointTime?: string, humidityTime?: string, lightTime?: string, locationTime?: string, locationGpsTime?: string, orientationTime?: string, pressureTime?: string, signalStrengthTime?: string, temperatureTime?: string, batteryLevel?: number, signalStrength?: number, search?: string, currentSensorConfiguration?: string, isBleEnabled?: boolean, firmware?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sensors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (contractorId !== undefined) {
                localVarQueryParameter['contractorId'] = contractorId;
            }

            if (stateId !== undefined) {
                localVarQueryParameter['stateId'] = stateId;
            }

            if (lastHeard !== undefined) {
                localVarQueryParameter['lastHeard'] = lastHeard;
            }

            if (lastPing !== undefined) {
                localVarQueryParameter['lastPing'] = lastPing;
            }

            if (synchronizationPeriod !== undefined) {
                localVarQueryParameter['synchronizationPeriod'] = synchronizationPeriod;
            }

            if (accelerationTime !== undefined) {
                localVarQueryParameter['accelerationTime'] = accelerationTime;
            }

            if (batteryTime !== undefined) {
                localVarQueryParameter['batteryTime'] = batteryTime;
            }

            if (dewpointTime !== undefined) {
                localVarQueryParameter['dewpointTime'] = dewpointTime;
            }

            if (humidityTime !== undefined) {
                localVarQueryParameter['humidityTime'] = humidityTime;
            }

            if (lightTime !== undefined) {
                localVarQueryParameter['lightTime'] = lightTime;
            }

            if (locationTime !== undefined) {
                localVarQueryParameter['locationTime'] = locationTime;
            }

            if (locationGpsTime !== undefined) {
                localVarQueryParameter['locationGpsTime'] = locationGpsTime;
            }

            if (orientationTime !== undefined) {
                localVarQueryParameter['orientationTime'] = orientationTime;
            }

            if (pressureTime !== undefined) {
                localVarQueryParameter['pressureTime'] = pressureTime;
            }

            if (signalStrengthTime !== undefined) {
                localVarQueryParameter['signalStrengthTime'] = signalStrengthTime;
            }

            if (temperatureTime !== undefined) {
                localVarQueryParameter['temperatureTime'] = temperatureTime;
            }

            if (batteryLevel !== undefined) {
                localVarQueryParameter['batteryLevel'] = batteryLevel;
            }

            if (signalStrength !== undefined) {
                localVarQueryParameter['signalStrength'] = signalStrength;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (currentSensorConfiguration !== undefined) {
                localVarQueryParameter['currentSensorConfiguration'] = currentSensorConfiguration;
            }

            if (isBleEnabled !== undefined) {
                localVarQueryParameter['isBleEnabled'] = isBleEnabled;
            }

            if (firmware !== undefined) {
                localVarQueryParameter['firmware'] = firmware;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return GPX waypoints file with for all sensors
         * @param {'gps' | 'cellid'} type Location type
         * @param {string} [authorization] Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsGpxWaypointsDataGet: async (type: 'gps' | 'cellid', authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('v1AdminSensorsGpxWaypointsDataGet', 'type', type)
            const localVarPath = `/v1/admin/sensors/gpx-waypoints-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (authorization !== undefined) {
                localVarQueryParameter['Authorization'] = authorization;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of sensors for map.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [contractorId] Contractor ID
         * @param {number} [stateId] State ID
         * @param {string} [lastHeard] The last time the sensor sent data
         * @param {number} [batteryLevel] Battery charge percentage
         * @param {string} [search] Search by Sensor ID, IMEI, Dev EUI
         * @param {string} [lastPing] The updatedAt of the sensor current data
         * @param {string} [synchronizationPeriod] The synchronization period of a sensor, in seconds
         * @param {string} [accelerationTime] The last time of the acceleration readings
         * @param {string} [batteryTime] The last time of the battery readings
         * @param {string} [dewpointTime] The last time of the dewpoint readings
         * @param {string} [humidityTime] The last time of the humidity readings
         * @param {string} [lightTime] The last time of the light readings
         * @param {string} [locationTime] The last time of the cellular location readings
         * @param {string} [locationGpsTime] The last time of the gps location readings
         * @param {string} [orientationTime] The last time of the orientation readings
         * @param {string} [pressureTime] The last time of the pressure readings
         * @param {string} [signalStrengthTime] The last time of the signal strength readings
         * @param {string} [temperatureTime] The last time of the temperature readings
         * @param {number} [signalStrength] The signal strength level
         * @param {string} [currentSensorConfiguration] Type of a sensor\&#39;s configuration
         * @param {boolean} [isBleEnabled] Filter by status of BLE module
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsMapViewGet: async (limit?: number, offset?: number, sort?: string, contractorId?: number, stateId?: number, lastHeard?: string, batteryLevel?: number, search?: string, lastPing?: string, synchronizationPeriod?: string, accelerationTime?: string, batteryTime?: string, dewpointTime?: string, humidityTime?: string, lightTime?: string, locationTime?: string, locationGpsTime?: string, orientationTime?: string, pressureTime?: string, signalStrengthTime?: string, temperatureTime?: string, signalStrength?: number, currentSensorConfiguration?: string, isBleEnabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sensors/map-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (contractorId !== undefined) {
                localVarQueryParameter['contractorId'] = contractorId;
            }

            if (stateId !== undefined) {
                localVarQueryParameter['stateId'] = stateId;
            }

            if (lastHeard !== undefined) {
                localVarQueryParameter['lastHeard'] = lastHeard;
            }

            if (batteryLevel !== undefined) {
                localVarQueryParameter['batteryLevel'] = batteryLevel;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (lastPing !== undefined) {
                localVarQueryParameter['lastPing'] = lastPing;
            }

            if (synchronizationPeriod !== undefined) {
                localVarQueryParameter['synchronizationPeriod'] = synchronizationPeriod;
            }

            if (accelerationTime !== undefined) {
                localVarQueryParameter['accelerationTime'] = accelerationTime;
            }

            if (batteryTime !== undefined) {
                localVarQueryParameter['batteryTime'] = batteryTime;
            }

            if (dewpointTime !== undefined) {
                localVarQueryParameter['dewpointTime'] = dewpointTime;
            }

            if (humidityTime !== undefined) {
                localVarQueryParameter['humidityTime'] = humidityTime;
            }

            if (lightTime !== undefined) {
                localVarQueryParameter['lightTime'] = lightTime;
            }

            if (locationTime !== undefined) {
                localVarQueryParameter['locationTime'] = locationTime;
            }

            if (locationGpsTime !== undefined) {
                localVarQueryParameter['locationGpsTime'] = locationGpsTime;
            }

            if (orientationTime !== undefined) {
                localVarQueryParameter['orientationTime'] = orientationTime;
            }

            if (pressureTime !== undefined) {
                localVarQueryParameter['pressureTime'] = pressureTime;
            }

            if (signalStrengthTime !== undefined) {
                localVarQueryParameter['signalStrengthTime'] = signalStrengthTime;
            }

            if (temperatureTime !== undefined) {
                localVarQueryParameter['temperatureTime'] = temperatureTime;
            }

            if (signalStrength !== undefined) {
                localVarQueryParameter['signalStrength'] = signalStrength;
            }

            if (currentSensorConfiguration !== undefined) {
                localVarQueryParameter['currentSensorConfiguration'] = currentSensorConfiguration;
            }

            if (isBleEnabled !== undefined) {
                localVarQueryParameter['isBleEnabled'] = isBleEnabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a sensor state list.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsStatesGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sensors/states`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns thingspace sensors list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [search] Sensor\&#39;s imei for searching
         * @param {string} [isAvailableInSystem] Returns thingspace sensors which are available in system or no
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsThingspaceSensorsGet: async (limit?: number, offset?: number, sort?: string, search?: string, isAvailableInSystem?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sensors/thingspace-sensors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (isAvailableInSystem !== undefined) {
                localVarQueryParameter['isAvailableInSystem'] = isAvailableInSystem;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Turn off BLE for a batch of sensors
         * @param {ManageSensorBLEStatePayload} manageSensorBLEStatePayload Payload to turn off BLE for a batch of sensors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsTurnOffBlePost: async (manageSensorBLEStatePayload: ManageSensorBLEStatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manageSensorBLEStatePayload' is not null or undefined
            assertParamExists('v1AdminSensorsTurnOffBlePost', 'manageSensorBLEStatePayload', manageSensorBLEStatePayload)
            const localVarPath = `/v1/admin/sensors/turn-off-ble`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manageSensorBLEStatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Turn on BLE for a batch of sensors
         * @param {ManageSensorBLEStatePayload} manageSensorBLEStatePayload Payload to turn on BLE for a batch of sensors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsTurnOnBlePost: async (manageSensorBLEStatePayload: ManageSensorBLEStatePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manageSensorBLEStatePayload' is not null or undefined
            assertParamExists('v1AdminSensorsTurnOnBlePost', 'manageSensorBLEStatePayload', manageSensorBLEStatePayload)
            const localVarPath = `/v1/admin/sensors/turn-on-ble`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manageSensorBLEStatePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assigns sensor to specific contractor
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {SensorAssignToContractorPayload} [payload] Payload for assigning sensor to contractor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdAssignToContractorPost: async (uniqueId: string, payload?: SensorAssignToContractorPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueId' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdAssignToContractorPost', 'uniqueId', uniqueId)
            const localVarPath = `/v1/admin/sensors/{uniqueId}/assign-to-contractor`
                .replace(`{${"uniqueId"}}`, encodeURIComponent(String(uniqueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the backup uplinks for the sensor
         * @param {string} uniqueId The sensor unique id
         * @param {string} [date] The backup uplink date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdBackupUplinksGet: async (uniqueId: string, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueId' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdBackupUplinksGet', 'uniqueId', uniqueId)
            const localVarPath = `/v1/admin/sensors/{uniqueId}/backup-uplinks`
                .replace(`{${"uniqueId"}}`, encodeURIComponent(String(uniqueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a sensor configuration
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdConfigurationGet: async (uniqueId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueId' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdConfigurationGet', 'uniqueId', uniqueId)
            const localVarPath = `/v1/admin/sensors/{uniqueId}/configuration`
                .replace(`{${"uniqueId"}}`, encodeURIComponent(String(uniqueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Setups a sensor configuration
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {SensorConfiguration} [payload] Payload for a sensor configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdConfigurationPost: async (uniqueId: string, payload?: SensorConfiguration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueId' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdConfigurationPost', 'uniqueId', uniqueId)
            const localVarPath = `/v1/admin/sensors/{uniqueId}/configuration`
                .replace(`{${"uniqueId"}}`, encodeURIComponent(String(uniqueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Configures sensor
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdConfigurePost: async (uniqueId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueId' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdConfigurePost', 'uniqueId', uniqueId)
            const localVarPath = `/v1/admin/sensors/{uniqueId}/configure`
                .replace(`{${"uniqueId"}}`, encodeURIComponent(String(uniqueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the sensor extended diagnostics information
         * @param {string} uniqueId The sensor unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdExtendedDiagnosticsGet: async (uniqueId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueId' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdExtendedDiagnosticsGet', 'uniqueId', uniqueId)
            const localVarPath = `/v1/admin/sensors/{uniqueId}/extended-diagnostics`
                .replace(`{${"uniqueId"}}`, encodeURIComponent(String(uniqueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks and fills missed readings
         * @param {string} uniqueId The sensor unique id (uniqueId)
         * @param {FillMissedReadingsPayload} [fillMissedReadingsPayload] Payload to check and fill missed readings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdFillMissedReadingsPost: async (uniqueId: string, fillMissedReadingsPayload?: FillMissedReadingsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueId' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdFillMissedReadingsPost', 'uniqueId', uniqueId)
            const localVarPath = `/v1/admin/sensors/{uniqueId}/fill-missed-readings`
                .replace(`{${"uniqueId"}}`, encodeURIComponent(String(uniqueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fillMissedReadingsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns sensor with new firmware version
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdFirmwareVersionGet: async (uniqueId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueId' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdFirmwareVersionGet', 'uniqueId', uniqueId)
            const localVarPath = `/v1/admin/sensors/{uniqueId}/firmware-version`
                .replace(`{${"uniqueId"}}`, encodeURIComponent(String(uniqueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a sensor.
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdGet: async (uniqueId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueId' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdGet', 'uniqueId', uniqueId)
            const localVarPath = `/v1/admin/sensors/{uniqueId}`
                .replace(`{${"uniqueId"}}`, encodeURIComponent(String(uniqueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns GPX file with location data
         * @param {string} uniqueId The unique id of a sensor
         * @param {'gps' | 'cellid'} type Location type
         * @param {string} dateFrom Date from
         * @param {string} [dateTo] Date to
         * @param {string} [authorization] Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdGpxDataGet: async (uniqueId: string, type: 'gps' | 'cellid', dateFrom: string, dateTo?: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueId' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdGpxDataGet', 'uniqueId', uniqueId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdGpxDataGet', 'type', type)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdGpxDataGet', 'dateFrom', dateFrom)
            const localVarPath = `/v1/admin/sensors/{uniqueId}/gpx-data`
                .replace(`{${"uniqueId"}}`, encodeURIComponent(String(uniqueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo;
            }

            if (authorization !== undefined) {
                localVarQueryParameter['Authorization'] = authorization;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of past projects for a sensor
         * @param {string} uniqueId The sensor unique id (uniqueId)
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdPastProjectsGet: async (uniqueId: string, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueId' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdPastProjectsGet', 'uniqueId', uniqueId)
            const localVarPath = `/v1/admin/sensors/{uniqueId}/past-projects`
                .replace(`{${"uniqueId"}}`, encodeURIComponent(String(uniqueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a sensor state.
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {SensorUpdateState} [payload] Payload for updating a sensor state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdPatch: async (uniqueId: string, payload?: SensorUpdateState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueId' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdPatch', 'uniqueId', uniqueId)
            const localVarPath = `/v1/admin/sensors/{uniqueId}`
                .replace(`{${"uniqueId"}}`, encodeURIComponent(String(uniqueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a sensor readout
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {string} [timeFrom] Time from
         * @param {string} [timeTo] Time to
         * @param {'temperature' | 'humidity' | 'battery' | 'signalStrength' | 'light' | 'pressure' | 'cellularLocation' | 'gpsLocation' | 'ble'} [reading] Kind of reading
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdReadoutGet: async (uniqueId: string, timeFrom?: string, timeTo?: string, reading?: 'temperature' | 'humidity' | 'battery' | 'signalStrength' | 'light' | 'pressure' | 'cellularLocation' | 'gpsLocation' | 'ble', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueId' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdReadoutGet', 'uniqueId', uniqueId)
            const localVarPath = `/v1/admin/sensors/{uniqueId}/readout`
                .replace(`{${"uniqueId"}}`, encodeURIComponent(String(uniqueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (timeFrom !== undefined) {
                localVarQueryParameter['timeFrom'] = timeFrom;
            }

            if (timeTo !== undefined) {
                localVarQueryParameter['timeTo'] = timeTo;
            }

            if (reading !== undefined) {
                localVarQueryParameter['reading'] = reading;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of related rapid RH probes
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdRelatedRapidRhProbesGet: async (uniqueId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueId' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdRelatedRapidRhProbesGet', 'uniqueId', uniqueId)
            const localVarPath = `/v1/admin/sensors/{uniqueId}/related-rapid-rh-probes`
                .replace(`{${"uniqueId"}}`, encodeURIComponent(String(uniqueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The endpoint retrieves sensor firmware information
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdRetrieveSensorFirmwarePost: async (uniqueId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueId' is not null or undefined
            assertParamExists('v1AdminSensorsUniqueIdRetrieveSensorFirmwarePost', 'uniqueId', uniqueId)
            const localVarPath = `/v1/admin/sensors/{uniqueId}/retrieve-sensor-firmware`
                .replace(`{${"uniqueId"}}`, encodeURIComponent(String(uniqueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns aggregated list of unstable sensors
         * @param {string} [timeFrom] Time from
         * @param {string} [timeTo] Time to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUnstableSensorsChartGet: async (timeFrom?: string, timeTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sensors/unstable-sensors-chart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (timeFrom !== undefined) {
                localVarQueryParameter['timeFrom'] = timeFrom;
            }

            if (timeTo !== undefined) {
                localVarQueryParameter['timeTo'] = timeTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of unstable sensors
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [search] Search by device id (imei, etc.)
         * @param {string} [datetimeFrom] Datetime from
         * @param {string} [datetimeTo] Datetime to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUnstableSensorsGet: async (limit?: number, offset?: number, sort?: string, search?: string, datetimeFrom?: string, datetimeTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sensors/unstable-sensors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (datetimeFrom !== undefined) {
                localVarQueryParameter['datetimeFrom'] = datetimeFrom;
            }

            if (datetimeTo !== undefined) {
                localVarQueryParameter['datetimeTo'] = datetimeTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return system messages history
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSystemMessagesHistoryGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/system-messages/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send system messages via push or email
         * @param {SystemMessagePayload} [payload] Payload for sending system messages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSystemMessagesSendPost: async (payload?: SystemMessagePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/system-messages/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns system message transport types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSystemMessagesTransportTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/system-messages/transport-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns system message types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSystemMessagesTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/system-messages/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a list of tasks
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [search] Search by name, description, author, assignee, contractor, project and section
         * @param {boolean} [isActive] Active or archived task
         * @param {string} [status] Active or closed task
         * @param {number} [contractorId] Filter by contractor id
         * @param {number} [projectId] Filter by project id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminTasksGet: async (limit?: number, offset?: number, sort?: string, search?: string, isActive?: boolean, status?: string, contractorId?: number, projectId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (contractorId !== undefined) {
                localVarQueryParameter['contractorId'] = contractorId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Manages admin\'s notifications
         * @param {ManageAdminNotification} [payload] Payload to manage admin\&#39;s notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersAdminNotificationsManagePut: async (payload?: ManageAdminNotification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/users/admin-notifications/manage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns CSV file with all users assigned to any contractor
         * @param {string} authorization Authorization token
         * @param {string} [clientTimeZone] Client time zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersExportContractorsUsersGet: async (authorization: string, clientTimeZone?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('v1AdminUsersExportContractorsUsersGet', 'authorization', authorization)
            const localVarPath = `/v1/admin/users/export-contractors-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization !== undefined) {
                localVarQueryParameter['Authorization'] = authorization;
            }

            if (clientTimeZone !== undefined) {
                localVarQueryParameter['clientTimeZone'] = clientTimeZone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assigns the foreman to the project
         * @param {AssignForeman} [payload] Payload for assigning the foreman to the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersForemansAssignToProjectPost: async (payload?: AssignForeman, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/users/foremans/assign-to-project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a foreman list
         * @param {number} contractorId The contractor id
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersForemansGet: async (contractorId: number, limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractorId' is not null or undefined
            assertParamExists('v1AdminUsersForemansGet', 'contractorId', contractorId)
            const localVarPath = `/v1/admin/users/foremans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (contractorId !== undefined) {
                localVarQueryParameter['contractorId'] = contractorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes the foreman from the project
         * @param {RemoveForeman} [payload] Payload for removing the foreman from the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersForemansRemoveFromProjectPost: async (payload?: RemoveForeman, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/users/foremans/remove-from-project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a user list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {Array<number>} [roleIds] The user role id
         * @param {Array<number>} [contractorIds] The contractor id
         * @param {Array<number>} [manufacturerIds] The manufacturer id
         * @param {string} [search] Search by name, phone, email
         * @param {number} [lastActive] Last active days
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersGet: async (limit?: number, offset?: number, sort?: string, roleIds?: Array<number>, contractorIds?: Array<number>, manufacturerIds?: Array<number>, search?: string, lastActive?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (roleIds) {
                localVarQueryParameter['roleIds'] = roleIds.join(COLLECTION_FORMATS.csv);
            }

            if (contractorIds) {
                localVarQueryParameter['contractorIds'] = contractorIds.join(COLLECTION_FORMATS.csv);
            }

            if (manufacturerIds) {
                localVarQueryParameter['manufacturerIds'] = manufacturerIds.join(COLLECTION_FORMATS.csv);
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (lastActive !== undefined) {
                localVarQueryParameter['lastActive'] = lastActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a user message channel list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersMessageChannelsGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/users/message-channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Manages user\'s notifications
         * @param {ManageNotification} [payload] Payload to manage user\&#39;s notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersNotificationsManagePut: async (payload?: ManageNotification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/users/notifications/manage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a user
         * @param {UserCreation} [payload] Payload for creating a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersPost: async (payload?: UserCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a user role list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersRolesGet: async (limit?: number, offset?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/users/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends verification code for admin sign in
         * @param {SendCodePayload} [payload] Payload for sending verification code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersSignInSendCodePost: async (payload?: SendCodePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/users/sign-in/send-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign out an user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersSignOutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/users/sign-out`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Manages user\'s admin notifications
         * @param {number} userId The user id
         * @param {ManageAdminNotification} [payload] Payload to manage user\&#39;s admin notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdAdminNotificationsManagePut: async (userId: number, payload?: ManageAdminNotification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('v1AdminUsersUserIdAdminNotificationsManagePut', 'userId', userId)
            const localVarPath = `/v1/admin/users/{userId}/admin-notifications/manage`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Archives a user
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdArchivePost: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('v1AdminUsersUserIdArchivePost', 'userId', userId)
            const localVarPath = `/v1/admin/users/{userId}/archive`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a user
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdDelete: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('v1AdminUsersUserIdDelete', 'userId', userId)
            const localVarPath = `/v1/admin/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a user
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdGet: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('v1AdminUsersUserIdGet', 'userId', userId)
            const localVarPath = `/v1/admin/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a user\'s project notifications
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdNotificationsByProjectGet: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('v1AdminUsersUserIdNotificationsByProjectGet', 'userId', userId)
            const localVarPath = `/v1/admin/users/{userId}/notifications/by-project`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a user\'s project notifications
         * @param {number} userId The user id
         * @param {UserNotificationPayloadByProject} [payload] Payload to update user\&#39;s project notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdNotificationsByProjectPut: async (userId: number, payload?: UserNotificationPayloadByProject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('v1AdminUsersUserIdNotificationsByProjectPut', 'userId', userId)
            const localVarPath = `/v1/admin/users/{userId}/notifications/by-project`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a user\'s global notifications
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdNotificationsByTypeGet: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('v1AdminUsersUserIdNotificationsByTypeGet', 'userId', userId)
            const localVarPath = `/v1/admin/users/{userId}/notifications/by-type`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a user\'s global notifications
         * @param {number} userId The user id
         * @param {UserGlobalNotificationPayloadByType} [payload] Payload to update user\&#39;s global notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdNotificationsByTypePut: async (userId: number, payload?: UserGlobalNotificationPayloadByType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('v1AdminUsersUserIdNotificationsByTypePut', 'userId', userId)
            const localVarPath = `/v1/admin/users/{userId}/notifications/by-type`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates user
         * @param {number} userId The user id
         * @param {UserEditing} [payload] Payload to update user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdPut: async (userId: number, payload?: UserEditing, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('v1AdminUsersUserIdPut', 'userId', userId)
            const localVarPath = `/v1/admin/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the user\'s session
         * @param {number} userId The user identifier
         * @param {Session} [payload] Payload to delete the user\&#39;s session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdSessionsDelete: async (userId: number, payload?: Session, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('v1AdminUsersUserIdSessionsDelete', 'userId', userId)
            const localVarPath = `/v1/admin/users/{userId}/sessions`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns user\'s active sessions
         * @param {number} userId The user identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdSessionsGet: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('v1AdminUsersUserIdSessionsGet', 'userId', userId)
            const localVarPath = `/v1/admin/users/{userId}/sessions`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unarchives a user
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdUnarchivePost: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('v1AdminUsersUserIdUnarchivePost', 'userId', userId)
            const localVarPath = `/v1/admin/users/{userId}/unarchive`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Wagner device firmware
         * @param {number} firmwareId The firmware id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesFirmwaresFirmwareIdDelete: async (firmwareId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmwareId' is not null or undefined
            assertParamExists('v1AdminWagnerProbesFirmwaresFirmwareIdDelete', 'firmwareId', firmwareId)
            const localVarPath = `/v1/admin/wagner-probes/firmwares/{firmwareId}`
                .replace(`{${"firmwareId"}}`, encodeURIComponent(String(firmwareId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a Wagner device firmware details
         * @param {number} firmwareId The firmware id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesFirmwaresFirmwareIdGet: async (firmwareId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmwareId' is not null or undefined
            assertParamExists('v1AdminWagnerProbesFirmwaresFirmwareIdGet', 'firmwareId', firmwareId)
            const localVarPath = `/v1/admin/wagner-probes/firmwares/{firmwareId}`
                .replace(`{${"firmwareId"}}`, encodeURIComponent(String(firmwareId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Wagner device firmware
         * @param {number} firmwareId The firmware id
         * @param {UpdateFirmwarePayload} updateFirmwarePayload Payload to update a Wagner device firmware
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesFirmwaresFirmwareIdPut: async (firmwareId: number, updateFirmwarePayload: UpdateFirmwarePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmwareId' is not null or undefined
            assertParamExists('v1AdminWagnerProbesFirmwaresFirmwareIdPut', 'firmwareId', firmwareId)
            // verify required parameter 'updateFirmwarePayload' is not null or undefined
            assertParamExists('v1AdminWagnerProbesFirmwaresFirmwareIdPut', 'updateFirmwarePayload', updateFirmwarePayload)
            const localVarPath = `/v1/admin/wagner-probes/firmwares/{firmwareId}`
                .replace(`{${"firmwareId"}}`, encodeURIComponent(String(firmwareId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFirmwarePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a list of Wagner device firmwares
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {'data_grabber' | 'range_extender'} [deviceType] Device type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesFirmwaresGet: async (limit?: number, offset?: number, deviceType?: 'data_grabber' | 'range_extender', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/wagner-probes/firmwares`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (deviceType !== undefined) {
                localVarQueryParameter['deviceType'] = deviceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new firmware for Wagner devices
         * @param {CreateFirmwarePayload} createFirmwarePayload Payload to create a new firmware for Wagner devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesFirmwaresPost: async (createFirmwarePayload: CreateFirmwarePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFirmwarePayload' is not null or undefined
            assertParamExists('v1AdminWagnerProbesFirmwaresPost', 'createFirmwarePayload', createFirmwarePayload)
            const localVarPath = `/v1/admin/wagner-probes/firmwares`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFirmwarePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns wagner probes
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [contractorId] The contractor id
         * @param {number} [projectId] The project id
         * @param {number} [projectSectionId] The project section id
         * @param {boolean} [connectedToProject] The filter by connectivity to project
         * @param {string} [search] The search string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesGet: async (limit?: number, offset?: number, sort?: string, contractorId?: number, projectId?: number, projectSectionId?: number, connectedToProject?: boolean, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/wagner-probes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (contractorId !== undefined) {
                localVarQueryParameter['contractorId'] = contractorId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (projectSectionId !== undefined) {
                localVarQueryParameter['projectSectionId'] = projectSectionId;
            }

            if (connectedToProject !== undefined) {
                localVarQueryParameter['connectedToProject'] = connectedToProject;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes a wagner probe
         * @param {string} probeId The wagner probe device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesProbeIdDelete: async (probeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'probeId' is not null or undefined
            assertParamExists('v1AdminWagnerProbesProbeIdDelete', 'probeId', probeId)
            const localVarPath = `/v1/admin/wagner-probes/{probeId}`
                .replace(`{${"probeId"}}`, encodeURIComponent(String(probeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a wagner probe
         * @param {string} probeId The wagner probe device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesProbeIdGet: async (probeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'probeId' is not null or undefined
            assertParamExists('v1AdminWagnerProbesProbeIdGet', 'probeId', probeId)
            const localVarPath = `/v1/admin/wagner-probes/{probeId}`
                .replace(`{${"probeId"}}`, encodeURIComponent(String(probeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a wagner probe readings
         * @param {string} probeId The wagner probe identifier(probeId)
         * @param {string} [timeFrom] Time from
         * @param {string} [timeTo] Time to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesProbeIdReadingsGet: async (probeId: string, timeFrom?: string, timeTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'probeId' is not null or undefined
            assertParamExists('v1AdminWagnerProbesProbeIdReadingsGet', 'probeId', probeId)
            const localVarPath = `/v1/admin/wagner-probes/{probeId}/readings`
                .replace(`{${"probeId"}}`, encodeURIComponent(String(probeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (timeFrom !== undefined) {
                localVarQueryParameter['timeFrom'] = timeFrom;
            }

            if (timeTo !== undefined) {
                localVarQueryParameter['timeTo'] = timeTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel attachment upload
         * @param {string} attachmentHashId The attachment hash id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AttachmentsAttachmentHashIdCancelUploadPost: async (attachmentHashId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentHashId' is not null or undefined
            assertParamExists('v1AttachmentsAttachmentHashIdCancelUploadPost', 'attachmentHashId', attachmentHashId)
            const localVarPath = `/v1/attachments/{attachmentHashId}/cancel-upload`
                .replace(`{${"attachmentHashId"}}`, encodeURIComponent(String(attachmentHashId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload photo
         * @param {Array<any>} files Files to upload
         * @param {number} [photoResizeTo] Value to resize photos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MediaFilePut: async (files: Array<any>, photoResizeTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'files' is not null or undefined
            assertParamExists('v1MediaFilePut', 'files', files)
            const localVarPath = `/v1/media/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (photoResizeTo !== undefined) { 
                localVarFormParams.append('photoResizeTo', photoResizeTo as any);
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload file by chunks
         * @param {string} xFileHashId Attachment hash id
         * @param {string} xFileName Attachment file name
         * @param {number} xFileSize Attachment file size
         * @param {number} xFilePartNumber File chunk number
         * @param {number} xTotalParts File chunks total number
         * @param {any} file 
         * @param {number} [photoResizeTo] Value to resize photos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MediaFileUploadChunksPost: async (xFileHashId: string, xFileName: string, xFileSize: number, xFilePartNumber: number, xTotalParts: number, file: any, photoResizeTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xFileHashId' is not null or undefined
            assertParamExists('v1MediaFileUploadChunksPost', 'xFileHashId', xFileHashId)
            // verify required parameter 'xFileName' is not null or undefined
            assertParamExists('v1MediaFileUploadChunksPost', 'xFileName', xFileName)
            // verify required parameter 'xFileSize' is not null or undefined
            assertParamExists('v1MediaFileUploadChunksPost', 'xFileSize', xFileSize)
            // verify required parameter 'xFilePartNumber' is not null or undefined
            assertParamExists('v1MediaFileUploadChunksPost', 'xFilePartNumber', xFilePartNumber)
            // verify required parameter 'xTotalParts' is not null or undefined
            assertParamExists('v1MediaFileUploadChunksPost', 'xTotalParts', xTotalParts)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('v1MediaFileUploadChunksPost', 'file', file)
            const localVarPath = `/v1/media/file/upload-chunks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xFileHashId !== undefined && xFileHashId !== null) {
                localVarHeaderParameter['x-file-hash-id'] = String(xFileHashId);
            }

            if (xFileName !== undefined && xFileName !== null) {
                localVarHeaderParameter['x-file-name'] = String(xFileName);
            }

            if (xFileSize !== undefined && xFileSize !== null) {
                localVarHeaderParameter['x-file-size'] = String(JSON.stringify(xFileSize));
            }

            if (xFilePartNumber !== undefined && xFilePartNumber !== null) {
                localVarHeaderParameter['x-file-part-number'] = String(JSON.stringify(xFilePartNumber));
            }

            if (xTotalParts !== undefined && xTotalParts !== null) {
                localVarHeaderParameter['x-total-parts'] = String(JSON.stringify(xTotalParts));
            }


            if (photoResizeTo !== undefined) { 
                localVarFormParams.append('photoResizeTo', photoResizeTo as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload photo
         * @param {Array<any>} photos Photos to upload
         * @param {number} [size] Value to resize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MediaPhotoPut: async (photos: Array<any>, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'photos' is not null or undefined
            assertParamExists('v1MediaPhotoPut', 'photos', photos)
            const localVarPath = `/v1/media/photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (size !== undefined) { 
                localVarFormParams.append('size', size as any);
            }
                if (photos) {
                photos.forEach((element) => {
                    localVarFormParams.append('photos', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify code and sign in user
         * @param {SignInPayload} [payload] Payload for verify code and sign in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersSignInPost: async (payload?: SignInPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign out a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersSignOutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/sign-out`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates contractor
         * @param {number} contractorId The contractor id
         * @param {ContractorEditingV2} [payload] Payload to update contractor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AdminContractorsContractorIdPut: async (contractorId: number, payload?: ContractorEditingV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractorId' is not null or undefined
            assertParamExists('v2AdminContractorsContractorIdPut', 'contractorId', contractorId)
            const localVarPath = `/v2/admin/contractors/{contractorId}`
                .replace(`{${"contractorId"}}`, encodeURIComponent(String(contractorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates contractor
         * @param {ContractorCreationV2} [payload] Payload to create contractor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AdminContractorsPost: async (payload?: ContractorCreationV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/admin/contractors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns aggregated timeseries gap data
         * @param {string} dateFrom Date from
         * @param {string} [dateTo] Date to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AdminDiagnosticsTimeseriesGapGet: async (dateFrom: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('v2AdminDiagnosticsTimeseriesGapGet', 'dateFrom', dateFrom)
            const localVarPath = `/v2/admin/diagnostics/timeseries-gap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a user
         * @param {UserCreation} [payload] Payload for creating a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AdminUsersPost: async (payload?: UserCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign out a user
         * @param {UserSignOutPayloadV2} userSignOutPayloadV2 Payload for user sign out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersSignOutPost: async (userSignOutPayloadV2: UserSignOutPayloadV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userSignOutPayloadV2' is not null or undefined
            assertParamExists('v2UsersSignOutPost', 'userSignOutPayloadV2', userSignOutPayloadV2)
            const localVarPath = `/v2/users/sign-out`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSignOutPayloadV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAPIApi - functional programming interface
 * @export
 */
export const AdminAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return a list of alerts
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [search] Search by name and description
         * @param {number} [projectId] The project id
         * @param {number} [projectSectionId] The project section id
         * @param {number} [contractorId] The contractor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminAlertsGet(limit?: number, offset?: number, sort?: string, search?: string, projectId?: number, projectSectionId?: number, contractorId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20098>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminAlertsGet(limit, offset, sort, search, projectId, projectSectionId, contractorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a single attachment
         * @param {number} attachmentId ID of attachment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminAttachmentsAttachmentIdGet(attachmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200103>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminAttachmentsAttachmentIdGet(attachmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a list of attachments
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {'pending' | 'processing' | 'processed' | 'failed' | 'cancelled'} [status] Status of attachments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminAttachmentsGet(limit?: number, offset?: number, sort?: string, status?: 'pending' | 'processing' | 'processed' | 'failed' | 'cancelled', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200106>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminAttachmentsGet(limit, offset, sort, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns building type list.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminBuildingTypesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20037>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminBuildingTypesGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new building type
         * @param {CreateBuildingTypePayload} [payload] Payload for building type creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminBuildingTypesPost(payload?: CreateBuildingTypePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20038>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminBuildingTypesPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a true if building type successfully deleted
         * @param {number} typeId The building type ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminBuildingTypesTypeIdDelete(typeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminBuildingTypesTypeIdDelete(typeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a building type state.
         * @param {number} typeId The building type ID
         * @param {UpdateBuildingTypePayload} [payload] Payload for updating building type state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminBuildingTypesTypeIdPatch(typeId: number, payload?: UpdateBuildingTypePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20038>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminBuildingTypesTypeIdPatch(typeId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns configuration settings
         * @param {number} configParamId The cofiguration param identifier
         * @param {ConfigurationParamUpdate} [payload] Payload to update configuration param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminConfigurationConfigParamIdPut(configParamId: number, payload?: ConfigurationParamUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20043>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminConfigurationConfigParamIdPut(configParamId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns configuration settings
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminConfigurationGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20041>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminConfigurationGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns mobile settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminConfigurationMobileSettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20044>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminConfigurationMobileSettingsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a mobile settings
         * @param {UpdateMobileSettings} [updateMobileSettings] Payload for updating a mobile settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminConfigurationMobileSettingsPut(updateMobileSettings?: UpdateMobileSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20044>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminConfigurationMobileSettingsPut(updateMobileSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Bulk update configuration settings
         * @param {Array<ConfigurationParamUpdateBulkItem>} [payload] Payload to update configuration parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminConfigurationPut(payload?: Array<ConfigurationParamUpdateBulkItem>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20042>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminConfigurationPut(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list of staging devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminConfigurationSensorAllocationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20072>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminConfigurationSensorAllocationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update list of staging devices
         * @param {UpdateStagingDevices} [updateStagingDevices] Payload for updating list of staging devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminConfigurationSensorAllocationPut(updateStagingDevices?: UpdateStagingDevices, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminConfigurationSensorAllocationPut(updateStagingDevices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activates contractor
         * @param {number} contractorId The contractor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminContractorsContractorIdActivatePost(contractorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminContractorsContractorIdActivatePost(contractorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivates contractor
         * @param {number} contractorId The contractor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminContractorsContractorIdDeactivatePost(contractorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminContractorsContractorIdDeactivatePost(contractorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrurns contractor
         * @param {number} contractorId The contractor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminContractorsContractorIdGet(contractorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminContractorsContractorIdGet(contractorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the contractor paid plan
         * @param {number} contractorId The contractor id
         * @param {UpdateContractorPaidPlan} payload Payload for updating contractor paid plan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminContractorsContractorIdPaidPlanPut(contractorId: number, payload: UpdateContractorPaidPlan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminContractorsContractorIdPaidPlanPut(contractorId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates contractor
         * @param {number} contractorId The contractor id
         * @param {ContractorEditing} [payload] Payload to update contractor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminContractorsContractorIdPut(contractorId: number, payload?: ContractorEditing, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminContractorsContractorIdPut(contractorId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of contractor tokens history
         * @param {number} contractorId The contractor ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminContractorsContractorIdTokensHistoryGet(contractorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200110>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminContractorsContractorIdTokensHistoryGet(contractorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of contractors
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [name] Filter by contractors name
         * @param {string} [search] Search by name, phone, email, address
         * @param {boolean} [isActivePaidPlan] Filter by the status of a paid plan
         * @param {boolean} [isActiveTrial] Filter by the status of a trial period
         * @param {boolean} [isRoot] Filter by the root sign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminContractorsGet(limit?: number, offset?: number, sort?: string, name?: string, search?: string, isActivePaidPlan?: boolean, isActiveTrial?: boolean, isRoot?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminContractorsGet(limit, offset, sort, name, search, isActivePaidPlan, isActiveTrial, isRoot, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates contractor
         * @param {ContractorCreation} [payload] Payload to create contractor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminContractorsPost(payload?: ContractorCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminContractorsPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a statistics for dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminDashboardGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20063>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminDashboardGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Parse rapid rh advertisement packet
         * @param {ParseAdvPacketPayload} payload Payload parse a packet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminDiagnosticsParseAdvPacketPost(payload: ParseAdvPacketPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20090>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminDiagnosticsParseAdvPacketPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns aggregated timeseries gap data
         * @param {string} dateFrom Date from
         * @param {string} [dateTo] Date to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminDiagnosticsTimeseriesGapGet(dateFrom: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20076>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminDiagnosticsTimeseriesGapGet(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns aggregated timeseries handling gap data
         * @param {string} dateFrom Date from
         * @param {string} [dateTo] Date to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminDiagnosticsTimeseriesHandlingGapGet(dateFrom: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200108>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminDiagnosticsTimeseriesHandlingGapGet(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a list of documents
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [search] Search by name and description
         * @param {number} [projectId] The project id
         * @param {number} [contractorId] The contractor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminDocumentsGet(limit?: number, offset?: number, sort?: string, search?: string, projectId?: number, contractorId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20096>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminDocumentsGet(limit, offset, sort, search, projectId, contractorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of events
         * @param {number} eventId The event identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminEventsEventIdGet(eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20069>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminEventsEventIdGet(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of events
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [projectId] The project ID
         * @param {number} [userId] The user ID
         * @param {string} [typeId] The event type ID
         * @param {boolean} [hideServiceAccount] The attribute to hide or not events created by service account
         * @param {number} [contractorId] The contractor ID
         * @param {number} [sensorId] The sensor ID
         * @param {boolean} [withPhotos] The attribute to return events with photos or not
         * @param {number} [rapidRhProbeId] The rapid RH probe ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminEventsGet(limit?: number, offset?: number, sort?: string, projectId?: number, userId?: number, typeId?: string, hideServiceAccount?: boolean, contractorId?: number, sensorId?: number, withPhotos?: boolean, rapidRhProbeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20068>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminEventsGet(limit, offset, sort, projectId, userId, typeId, hideServiceAccount, contractorId, sensorId, withPhotos, rapidRhProbeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update event type by id
         * @param {number} eventTypeId Event type Id
         * @param {EventTypeUpdatePayload} [payload] Payload for update event type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminEventsTypesEventTypeIdPatch(eventTypeId: number, payload?: EventTypeUpdatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminEventsTypesEventTypeIdPatch(eventTypeId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns event types list.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminEventsTypesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20036>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminEventsTypesGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list of labels
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminLabelsGet(sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20058>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminLabelsGet(sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a label
         * @param {LabelCreation} [payload] Payload to create a label
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminLabelsPost(payload?: LabelCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20059>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminLabelsPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a manufacturer announcement category
         * @param {number} categoryId The category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersAnnouncementsCategoriesCategoryIdDelete(categoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersAnnouncementsCategoriesCategoryIdDelete(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a manufacturer announcement category
         * @param {number} categoryId The category id
         * @param {UpdateManufacturerAnnouncementCategoryPayload} updateManufacturerAnnouncementCategoryPayload Payload to update a manufacturer announcement category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersAnnouncementsCategoriesCategoryIdPut(categoryId: number, updateManufacturerAnnouncementCategoryPayload: UpdateManufacturerAnnouncementCategoryPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20093>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersAnnouncementsCategoriesCategoryIdPut(categoryId, updateManufacturerAnnouncementCategoryPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a list of manufacturer announcement categories
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersAnnouncementsCategoriesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20092>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersAnnouncementsCategoriesGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new manufacturer announcement category
         * @param {CreateManufacturerAnnouncementCategoryPayload} createManufacturerAnnouncementCategoryPayload Payload to create a new manufacturer announcement category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersAnnouncementsCategoriesPost(createManufacturerAnnouncementCategoryPayload: CreateManufacturerAnnouncementCategoryPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20093>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersAnnouncementsCategoriesPost(createManufacturerAnnouncementCategoryPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of manufacturer announcements
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersAnnouncementsGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20080>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersAnnouncementsGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a manufacturer announcement
         * @param {number} manufacturerAnnouncementId The manufacturer announcement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdDelete(manufacturerAnnouncementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdDelete(manufacturerAnnouncementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a manufacturer announcement
         * @param {number} manufacturerAnnouncementId The manufacturer announcement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdGet(manufacturerAnnouncementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20081>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdGet(manufacturerAnnouncementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a manufacturer announcement
         * @param {number} manufacturerAnnouncementId The manufacturer announcement id
         * @param {UpdateManufacturerAnnouncementPayload} [payload] Payload to update a manufacturer announcement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdPut(manufacturerAnnouncementId: number, payload?: UpdateManufacturerAnnouncementPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20081>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdPut(manufacturerAnnouncementId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a manufacturer announcement
         * @param {CreateManufacturerAnnouncementPayload} [payload] Payload to create a manufacturer announcement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersAnnouncementsPost(payload?: CreateManufacturerAnnouncementPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20081>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersAnnouncementsPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of manufacturer announcement types
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersAnnouncementsTypesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20082>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersAnnouncementsTypesGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new manufacturer announcement type
         * @param {CreateManufacturerAnnouncementTypePayload} createManufacturerAnnouncementTypePayload Payload to create a new manufacturer announcement type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersAnnouncementsTypesPost(createManufacturerAnnouncementTypePayload: CreateManufacturerAnnouncementTypePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20083>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersAnnouncementsTypesPost(createManufacturerAnnouncementTypePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a manufacturer announcement type
         * @param {number} typeId The type id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersAnnouncementsTypesTypeIdDelete(typeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersAnnouncementsTypesTypeIdDelete(typeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a manufacturer announcement type
         * @param {number} typeId The type id
         * @param {UpdateManufacturerAnnouncementTypePayload} updateManufacturerAnnouncementTypePayload Payload to update a manufacturer announcement type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersAnnouncementsTypesTypeIdPut(typeId: number, updateManufacturerAnnouncementTypePayload: UpdateManufacturerAnnouncementTypePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20083>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersAnnouncementsTypesTypeIdPut(typeId, updateManufacturerAnnouncementTypePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a manufacturer list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [name] Filter by name
         * @param {string} [search] Search by name, phone, email, address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersGet(limit?: number, offset?: number, sort?: string, name?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20011>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersGet(limit, offset, sort, name, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a manufacturer
         * @param {number} manufacturerId The manufacturer id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersManufacturerIdDelete(manufacturerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersManufacturerIdDelete(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrurns manufacturer
         * @param {number} manufacturerId The manufacturer id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersManufacturerIdGet(manufacturerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersManufacturerIdGet(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates manufacturer
         * @param {number} manufacturerId The manufacturer id
         * @param {ManufacturerEditing} [payload] Payload to update manufacturer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersManufacturerIdPut(manufacturerId: number, payload?: ManufacturerEditing, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersManufacturerIdPut(manufacturerId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a manufacturer and returns the new one.
         * @param {ManufacturerCreation} [payload] Payload for creating a manufacturer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminManufacturersPost(payload?: ManufacturerCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminManufacturersPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the product.
         * @param {number} draftId The draft id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsDraftsDraftIdDelete(draftId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsDraftsDraftIdDelete(draftId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a products\' drafts.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [manufacturerId] Manufacturer ID
         * @param {number} [typeId] Product type ID
         * @param {string} [name] The product name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsDraftsGet(limit?: number, offset?: number, sort?: string, manufacturerId?: number, typeId?: number, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsDraftsGet(limit, offset, sort, manufacturerId, typeId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a product draft and returns the new one.
         * @param {ProductDraftCreation} [payload] Payload for creating a product draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsDraftsPost(payload?: ProductDraftCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20024>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsDraftsPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publishes a products\' drafts.
         * @param {ProductsDraftsPublishBulk} [payload] Payload for publishing a products\&#39; drafts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsDraftsPublishPost(payload?: ProductsDraftsPublishBulk, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20025>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsDraftsPublishPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates products\' drafts.
         * @param {Array<ProductsDraftsUpdate>} [payload] Payload for updating a product draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsDraftsPut(payload?: Array<ProductsDraftsUpdate>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20023>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsDraftsPut(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a product list.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [manufacturerId] Manufacturer ID
         * @param {number} [typeId] Product type ID
         * @param {string} [name] The product name
         * @param {number} [projectId] The project ID
         * @param {boolean} [noShelfLife] Is shelfLife field eq null or not
         * @param {boolean} [noRanges] Is some of lowHumidity, highHumidity, lowTempF, highTempF fields eq null or not
         * @param {boolean} [noTips] Is product tips count eq 0 or not
         * @param {boolean} [inUse] Is project product count eq 0 or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsGet(limit?: number, offset?: number, sort?: string, manufacturerId?: number, typeId?: number, name?: string, projectId?: number, noShelfLife?: boolean, noRanges?: boolean, noTips?: boolean, inUse?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20026>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsGet(limit, offset, sort, manufacturerId, typeId, name, projectId, noShelfLife, noRanges, noTips, inUse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a list of product imports
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsImportHistoryGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20088>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsImportHistoryGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a product import details
         * @param {number} importHistoryId The import history item id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsImportHistoryImportHistoryIdGet(importHistoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20089>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsImportHistoryImportHistoryIdGet(importHistoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a link for downloading an import products template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsImportTemplateLinkGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20053>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsImportTemplateLinkGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Merges products
         * @param {MergeProductsPayload} payload Payload to merge products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsMergePost(payload: MergeProductsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsMergePost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a product package types
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsPackageTypesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20015>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsPackageTypesGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a product and returns the new one.
         * @param {ProductCreation} [payload] Payload for creating a product
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1AdminProductsPost(payload?: ProductCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20019>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a true if product successfully deleted
         * @param {number} productId The product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsProductIdDelete(productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsProductIdDelete(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a single product
         * @param {number} productId The product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsProductIdGet(productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20019>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsProductIdGet(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a product and returns it.
         * @param {number} productId The product ID
         * @param {ProductEditing} [payload] Payload for updating a product
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async v1AdminProductsProductIdPut(productId: number, payload?: ProductEditing, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20019>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsProductIdPut(productId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of product requests
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsRequestsGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20064>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsRequestsGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns number of new product requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsRequestsNumberOfNewGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20066>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsRequestsNumberOfNewGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a product request
         * @param {number} productRequestId The product request identifier
         * @param {UpdateProductRequestPayload} [updateProductRequestPayload] Payload to update a product request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsRequestsProductRequestIdPut(productRequestId: number, updateProductRequestPayload?: UpdateProductRequestPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20067>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsRequestsProductRequestIdPut(productRequestId, updateProductRequestPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a product request summary
         * @param {number} productRequestId The product request identifier
         * @param {ProductRequestSummaryPayload} [productRequestSummaryPayload] Payload to update a product request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsRequestsProductRequestIdSendSummaryPost(productRequestId: number, productRequestSummaryPayload?: ProductRequestSummaryPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsRequestsProductRequestIdSendSummaryPost(productRequestId, productRequestSummaryPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of product request statuses
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsRequestsStatusesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20065>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsRequestsStatusesGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a product tip types
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsTipTypesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20014>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsTipTypesGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a product tips.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [tipTypeId] The tip\&#39;s type ID
         * @param {number} [productId] The product ID
         * @param {number} [productDraftId] The product\&#39;s draft ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsTipsCustomGet(limit?: number, offset?: number, sort?: string, tipTypeId?: number, productId?: number, productDraftId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20022>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsTipsCustomGet(limit, offset, sort, tipTypeId, productId, productDraftId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a product types\' tips.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [tipTypeId] The tip\&#39;s type ID
         * @param {number} [productTypeId] The product\&#39;s type ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsTipsDefaultGet(limit?: number, offset?: number, sort?: string, tipTypeId?: number, productTypeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20021>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsTipsDefaultGet(limit, offset, sort, tipTypeId, productTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a product types
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [withTips] Send product types with or without tips
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsTypesGet(limit?: number, offset?: number, sort?: string, withTips?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20016>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsTypesGet(limit, offset, sort, withTips, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates new product type
         * @param {ProductTypeCreation} [payload] Payload for the product type creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsTypesPost(payload?: ProductTypeCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20017>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsTypesPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the product type
         * @param {number} productTypeId The product type ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsTypesProductTypeIdDelete(productTypeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsTypesProductTypeIdDelete(productTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the product type
         * @param {number} productTypeId The product type ID
         * @param {ProductTypeUpdate} [payload] Payload to update the product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsTypesProductTypeIdPut(productTypeId: number, payload?: ProductTypeUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20017>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsTypesProductTypeIdPut(productTypeId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the product type tips
         * @param {number} productTypeId The product type ID
         * @param {ProductTypeTipsUpdate} [payload] Payload to update the product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsTypesProductTypeIdTipsPut(productTypeId: number, payload?: ProductTypeTipsUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20018>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsTypesProductTypeIdTipsPut(productTypeId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload xlsx
         * @param {any} xlsx Xlsx to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProductsUploadPut(xlsx: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20020>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProductsUploadPut(xlsx, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list of project sections.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [projectId] Project ID
         * @param {boolean} [onlyActiveSections] Filter by the project section status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProjectSectionsGet(limit?: number, offset?: number, sort?: string, projectId?: number, onlyActiveSections?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20032>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProjectSectionsGet(limit, offset, sort, projectId, onlyActiveSections, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns single project section by id
         * @param {number} projectSectionId The project section id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProjectSectionsProjectSectionIdGet(projectSectionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20033>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProjectSectionsProjectSectionIdGet(projectSectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list of projects.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [contractorId] Contractor ID
         * @param {number} [typeId] Type ID
         * @param {string} [city] City of the project
         * @param {number} [productId] Product ID
         * @param {number} [userId] User ID
         * @param {string} [search] Search by project name and location
         * @param {boolean} [isActive] Filter by the project status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProjectsGet(limit?: number, offset?: number, sort?: string, contractorId?: number, typeId?: number, city?: string, productId?: number, userId?: number, search?: string, isActive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20030>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProjectsGet(limit, offset, sort, contractorId, typeId, city, productId, userId, search, isActive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns single project by id
         * @param {number} projectId The project id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProjectsProjectIdGet(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20031>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProjectsProjectIdGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list of projects types
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProjectsTypesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20034>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProjectsTypesGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new project type
         * @param {CreateProjectTypePayload} [payload] Payload for project type creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProjectsTypesPost(payload?: CreateProjectTypePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20035>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProjectsTypesPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a true if project type successfully deleted
         * @param {number} typeId The project type ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProjectsTypesTypeIdDelete(typeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProjectsTypesTypeIdDelete(typeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a project type state.
         * @param {number} typeId The project type ID
         * @param {UpdateProjectTypePayload} [payload] Payload for updating project type state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminProjectsTypesTypeIdPatch(typeId: number, payload?: UpdateProjectTypePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20035>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminProjectsTypesTypeIdPatch(typeId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a release list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminReleasesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20012>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminReleasesGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a release and returns the new one.
         * @param {ReleaseCreation} [payload] Payload for creating a release
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminReleasesPost(payload?: ReleaseCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminReleasesPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes release
         * @param {number} releaseId The release id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminReleasesReleaseIdDelete(releaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminReleasesReleaseIdDelete(releaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrurns release
         * @param {number} releaseId The release id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminReleasesReleaseIdGet(releaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminReleasesReleaseIdGet(releaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates release
         * @param {number} releaseId The release id
         * @param {ReleaseEditing} [payload] Payload to update release
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminReleasesReleaseIdPut(releaseId: number, payload?: ReleaseEditing, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminReleasesReleaseIdPut(releaseId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return components for combined reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminReportsCombinedReportsComponentsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200100>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminReportsCombinedReportsComponentsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update component for combined reports
         * @param {number} reportComponentId The report component id
         * @param {CombinedReportComponentUpdatePayload} combinedReportComponentUpdatePayload Payload to update component for combined reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminReportsCombinedReportsComponentsReportComponentIdPatch(reportComponentId: number, combinedReportComponentUpdatePayload: CombinedReportComponentUpdatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200101>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminReportsCombinedReportsComponentsReportComponentIdPatch(reportComponentId, combinedReportComponentUpdatePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns type of included events for combined reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminReportsCombinedReportsIncludedEventsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20099>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminReportsCombinedReportsIncludedEventsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update type of included events for combined reports
         * @param {CombinedReportIncludedEventUpdatePayload} combinedReportIncludedEventUpdatePayload Payload to update type of included events for combined reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminReportsCombinedReportsIncludedEventsPut(combinedReportIncludedEventUpdatePayload: CombinedReportIncludedEventUpdatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20099>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminReportsCombinedReportsIncludedEventsPut(combinedReportIncludedEventUpdatePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary The ednpoint returns list of reports
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [contractorId] Filter by contractor id
         * @param {number} [projectId] Filter by project id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminReportsGet(limit?: number, offset?: number, sort?: string, contractorId?: number, projectId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20077>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminReportsGet(limit, offset, sort, contractorId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Regenerate a report\'s link and return updated report
         * @param {number} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminReportsReportIdRegenerateLinkPost(reportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20097>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminReportsReportIdRegenerateLinkPost(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of report types
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminReportsTypesGet(sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20070>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminReportsTypesGet(sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of report types
         * @param {number} reportTypeId The report type identifier
         * @param {UpdateReportTypePayload} [updateReportTypePayload] Payload to update a report type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminReportsTypesReportTypeIdPut(reportTypeId: number, updateReportTypePayload?: UpdateReportTypePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20071>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminReportsTypesReportTypeIdPut(reportTypeId, updateReportTypePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends a request to calculate a sensor technical status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsCalculateTechnicalStatusPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsCalculateTechnicalStatusPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a default sensor configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsDefaultConfigurationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20050>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsDefaultConfigurationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a default sensor configuration
         * @param {SensorConfiguration} [payload] Payload for updating a default sensor configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsDefaultConfigurationPut(payload?: SensorConfiguration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20050>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsDefaultConfigurationPut(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fills sensors missed readings
         * @param {FillMissedReadingsBulkPayload} [fillMissedReadingsBulkPayload] Payload to fill sensors missed readings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsFillMissedReadingsPost(fillMissedReadingsBulkPayload?: FillMissedReadingsBulkPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20075>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsFillMissedReadingsPost(fillMissedReadingsBulkPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of sensors firmware versions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsFirmwareVersionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20072>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsFirmwareVersionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns ZIP file with generated lables for sensors
         * @param {string} sensorUniqueIds The sensors unique identifiers (uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsGenerateLabelsGet(sensorUniqueIds: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsGenerateLabelsGet(sensorUniqueIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list of sensors.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [projectId] Project ID
         * @param {number} [contractorId] Contractor ID
         * @param {number} [stateId] State ID
         * @param {string} [lastHeard] The last time the sensor sent data
         * @param {string} [lastPing] The updatedAt of the sensor current data
         * @param {string} [synchronizationPeriod] The synchronization period of a sensor, in seconds
         * @param {string} [accelerationTime] The last time of the acceleration readings
         * @param {string} [batteryTime] The last time of the battery readings
         * @param {string} [dewpointTime] The last time of the dewpoint readings
         * @param {string} [humidityTime] The last time of the humidity readings
         * @param {string} [lightTime] The last time of the light readings
         * @param {string} [locationTime] The last time of the cellular location readings
         * @param {string} [locationGpsTime] The last time of the gps location readings
         * @param {string} [orientationTime] The last time of the orientation readings
         * @param {string} [pressureTime] The last time of the pressure readings
         * @param {string} [signalStrengthTime] The last time of the signal strength readings
         * @param {string} [temperatureTime] The last time of the temperature readings
         * @param {number} [batteryLevel] Battery charge percentage
         * @param {number} [signalStrength] The signal strength level
         * @param {string} [search] Search by Sensor ID, IMEI, Dev EUI
         * @param {string} [currentSensorConfiguration] Type of a sensor\&#39;s configuration
         * @param {boolean} [isBleEnabled] Filter by status of BLE module
         * @param {string} [firmware] Filter by sensor firmware version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsGet(limit?: number, offset?: number, sort?: string, projectId?: number, contractorId?: number, stateId?: number, lastHeard?: string, lastPing?: string, synchronizationPeriod?: string, accelerationTime?: string, batteryTime?: string, dewpointTime?: string, humidityTime?: string, lightTime?: string, locationTime?: string, locationGpsTime?: string, orientationTime?: string, pressureTime?: string, signalStrengthTime?: string, temperatureTime?: string, batteryLevel?: number, signalStrength?: number, search?: string, currentSensorConfiguration?: string, isBleEnabled?: boolean, firmware?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20029>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsGet(limit, offset, sort, projectId, contractorId, stateId, lastHeard, lastPing, synchronizationPeriod, accelerationTime, batteryTime, dewpointTime, humidityTime, lightTime, locationTime, locationGpsTime, orientationTime, pressureTime, signalStrengthTime, temperatureTime, batteryLevel, signalStrength, search, currentSensorConfiguration, isBleEnabled, firmware, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return GPX waypoints file with for all sensors
         * @param {'gps' | 'cellid'} type Location type
         * @param {string} [authorization] Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsGpxWaypointsDataGet(type: 'gps' | 'cellid', authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20046>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsGpxWaypointsDataGet(type, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list of sensors for map.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [contractorId] Contractor ID
         * @param {number} [stateId] State ID
         * @param {string} [lastHeard] The last time the sensor sent data
         * @param {number} [batteryLevel] Battery charge percentage
         * @param {string} [search] Search by Sensor ID, IMEI, Dev EUI
         * @param {string} [lastPing] The updatedAt of the sensor current data
         * @param {string} [synchronizationPeriod] The synchronization period of a sensor, in seconds
         * @param {string} [accelerationTime] The last time of the acceleration readings
         * @param {string} [batteryTime] The last time of the battery readings
         * @param {string} [dewpointTime] The last time of the dewpoint readings
         * @param {string} [humidityTime] The last time of the humidity readings
         * @param {string} [lightTime] The last time of the light readings
         * @param {string} [locationTime] The last time of the cellular location readings
         * @param {string} [locationGpsTime] The last time of the gps location readings
         * @param {string} [orientationTime] The last time of the orientation readings
         * @param {string} [pressureTime] The last time of the pressure readings
         * @param {string} [signalStrengthTime] The last time of the signal strength readings
         * @param {string} [temperatureTime] The last time of the temperature readings
         * @param {number} [signalStrength] The signal strength level
         * @param {string} [currentSensorConfiguration] Type of a sensor\&#39;s configuration
         * @param {boolean} [isBleEnabled] Filter by status of BLE module
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsMapViewGet(limit?: number, offset?: number, sort?: string, contractorId?: number, stateId?: number, lastHeard?: string, batteryLevel?: number, search?: string, lastPing?: string, synchronizationPeriod?: string, accelerationTime?: string, batteryTime?: string, dewpointTime?: string, humidityTime?: string, lightTime?: string, locationTime?: string, locationGpsTime?: string, orientationTime?: string, pressureTime?: string, signalStrengthTime?: string, temperatureTime?: string, signalStrength?: number, currentSensorConfiguration?: string, isBleEnabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20029>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsMapViewGet(limit, offset, sort, contractorId, stateId, lastHeard, batteryLevel, search, lastPing, synchronizationPeriod, accelerationTime, batteryTime, dewpointTime, humidityTime, lightTime, locationTime, locationGpsTime, orientationTime, pressureTime, signalStrengthTime, temperatureTime, signalStrength, currentSensorConfiguration, isBleEnabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a sensor state list.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsStatesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20027>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsStatesGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns thingspace sensors list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [search] Sensor\&#39;s imei for searching
         * @param {string} [isAvailableInSystem] Returns thingspace sensors which are available in system or no
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsThingspaceSensorsGet(limit?: number, offset?: number, sort?: string, search?: string, isAvailableInSystem?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20045>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsThingspaceSensorsGet(limit, offset, sort, search, isAvailableInSystem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Turn off BLE for a batch of sensors
         * @param {ManageSensorBLEStatePayload} manageSensorBLEStatePayload Payload to turn off BLE for a batch of sensors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsTurnOffBlePost(manageSensorBLEStatePayload: ManageSensorBLEStatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200105>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsTurnOffBlePost(manageSensorBLEStatePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Turn on BLE for a batch of sensors
         * @param {ManageSensorBLEStatePayload} manageSensorBLEStatePayload Payload to turn on BLE for a batch of sensors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsTurnOnBlePost(manageSensorBLEStatePayload: ManageSensorBLEStatePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200104>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsTurnOnBlePost(manageSensorBLEStatePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assigns sensor to specific contractor
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {SensorAssignToContractorPayload} [payload] Payload for assigning sensor to contractor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUniqueIdAssignToContractorPost(uniqueId: string, payload?: SensorAssignToContractorPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUniqueIdAssignToContractorPost(uniqueId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return the backup uplinks for the sensor
         * @param {string} uniqueId The sensor unique id
         * @param {string} [date] The backup uplink date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUniqueIdBackupUplinksGet(uniqueId: string, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20085>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUniqueIdBackupUplinksGet(uniqueId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a sensor configuration
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUniqueIdConfigurationGet(uniqueId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20049>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUniqueIdConfigurationGet(uniqueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Setups a sensor configuration
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {SensorConfiguration} [payload] Payload for a sensor configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUniqueIdConfigurationPost(uniqueId: string, payload?: SensorConfiguration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUniqueIdConfigurationPost(uniqueId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Configures sensor
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUniqueIdConfigurePost(uniqueId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUniqueIdConfigurePost(uniqueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return the sensor extended diagnostics information
         * @param {string} uniqueId The sensor unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUniqueIdExtendedDiagnosticsGet(uniqueId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20086>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUniqueIdExtendedDiagnosticsGet(uniqueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks and fills missed readings
         * @param {string} uniqueId The sensor unique id (uniqueId)
         * @param {FillMissedReadingsPayload} [fillMissedReadingsPayload] Payload to check and fill missed readings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUniqueIdFillMissedReadingsPost(uniqueId: string, fillMissedReadingsPayload?: FillMissedReadingsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20075>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUniqueIdFillMissedReadingsPost(uniqueId, fillMissedReadingsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns sensor with new firmware version
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUniqueIdFirmwareVersionGet(uniqueId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20028>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUniqueIdFirmwareVersionGet(uniqueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a sensor.
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUniqueIdGet(uniqueId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20028>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUniqueIdGet(uniqueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns GPX file with location data
         * @param {string} uniqueId The unique id of a sensor
         * @param {'gps' | 'cellid'} type Location type
         * @param {string} dateFrom Date from
         * @param {string} [dateTo] Date to
         * @param {string} [authorization] Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUniqueIdGpxDataGet(uniqueId: string, type: 'gps' | 'cellid', dateFrom: string, dateTo?: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20046>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUniqueIdGpxDataGet(uniqueId, type, dateFrom, dateTo, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list of past projects for a sensor
         * @param {string} uniqueId The sensor unique id (uniqueId)
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUniqueIdPastProjectsGet(uniqueId: string, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20060>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUniqueIdPastProjectsGet(uniqueId, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a sensor state.
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {SensorUpdateState} [payload] Payload for updating a sensor state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUniqueIdPatch(uniqueId: string, payload?: SensorUpdateState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20028>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUniqueIdPatch(uniqueId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a sensor readout
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {string} [timeFrom] Time from
         * @param {string} [timeTo] Time to
         * @param {'temperature' | 'humidity' | 'battery' | 'signalStrength' | 'light' | 'pressure' | 'cellularLocation' | 'gpsLocation' | 'ble'} [reading] Kind of reading
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUniqueIdReadoutGet(uniqueId: string, timeFrom?: string, timeTo?: string, reading?: 'temperature' | 'humidity' | 'battery' | 'signalStrength' | 'light' | 'pressure' | 'cellularLocation' | 'gpsLocation' | 'ble', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20061>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUniqueIdReadoutGet(uniqueId, timeFrom, timeTo, reading, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of related rapid RH probes
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUniqueIdRelatedRapidRhProbesGet(uniqueId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200109>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUniqueIdRelatedRapidRhProbesGet(uniqueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary The endpoint retrieves sensor firmware information
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUniqueIdRetrieveSensorFirmwarePost(uniqueId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUniqueIdRetrieveSensorFirmwarePost(uniqueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns aggregated list of unstable sensors
         * @param {string} [timeFrom] Time from
         * @param {string} [timeTo] Time to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUnstableSensorsChartGet(timeFrom?: string, timeTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20074>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUnstableSensorsChartGet(timeFrom, timeTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of unstable sensors
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [search] Search by device id (imei, etc.)
         * @param {string} [datetimeFrom] Datetime from
         * @param {string} [datetimeTo] Datetime to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSensorsUnstableSensorsGet(limit?: number, offset?: number, sort?: string, search?: string, datetimeFrom?: string, datetimeTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20073>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSensorsUnstableSensorsGet(limit, offset, sort, search, datetimeFrom, datetimeTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return system messages history
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSystemMessagesHistoryGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20091>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSystemMessagesHistoryGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send system messages via push or email
         * @param {SystemMessagePayload} [payload] Payload for sending system messages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSystemMessagesSendPost(payload?: SystemMessagePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSystemMessagesSendPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns system message transport types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSystemMessagesTransportTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20052>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSystemMessagesTransportTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns system message types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminSystemMessagesTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20051>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminSystemMessagesTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a list of tasks
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [search] Search by name, description, author, assignee, contractor, project and section
         * @param {boolean} [isActive] Active or archived task
         * @param {string} [status] Active or closed task
         * @param {number} [contractorId] Filter by contractor id
         * @param {number} [projectId] Filter by project id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminTasksGet(limit?: number, offset?: number, sort?: string, search?: string, isActive?: boolean, status?: string, contractorId?: number, projectId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20087>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminTasksGet(limit, offset, sort, search, isActive, status, contractorId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Manages admin\'s notifications
         * @param {ManageAdminNotification} [payload] Payload to manage admin\&#39;s notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersAdminNotificationsManagePut(payload?: ManageAdminNotification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersAdminNotificationsManagePut(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns CSV file with all users assigned to any contractor
         * @param {string} authorization Authorization token
         * @param {string} [clientTimeZone] Client time zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersExportContractorsUsersGet(authorization: string, clientTimeZone?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20046>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersExportContractorsUsersGet(authorization, clientTimeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assigns the foreman to the project
         * @param {AssignForeman} [payload] Payload for assigning the foreman to the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersForemansAssignToProjectPost(payload?: AssignForeman, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersForemansAssignToProjectPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a foreman list
         * @param {number} contractorId The contractor id
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersForemansGet(contractorId: number, limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersForemansGet(contractorId, limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes the foreman from the project
         * @param {RemoveForeman} [payload] Payload for removing the foreman from the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersForemansRemoveFromProjectPost(payload?: RemoveForeman, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersForemansRemoveFromProjectPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a user list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {Array<number>} [roleIds] The user role id
         * @param {Array<number>} [contractorIds] The contractor id
         * @param {Array<number>} [manufacturerIds] The manufacturer id
         * @param {string} [search] Search by name, phone, email
         * @param {number} [lastActive] Last active days
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersGet(limit?: number, offset?: number, sort?: string, roleIds?: Array<number>, contractorIds?: Array<number>, manufacturerIds?: Array<number>, search?: string, lastActive?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersGet(limit, offset, sort, roleIds, contractorIds, manufacturerIds, search, lastActive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a user message channel list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersMessageChannelsGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20084>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersMessageChannelsGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Manages user\'s notifications
         * @param {ManageNotification} [payload] Payload to manage user\&#39;s notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersNotificationsManagePut(payload?: ManageNotification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersNotificationsManagePut(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a user
         * @param {UserCreation} [payload] Payload for creating a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersPost(payload?: UserCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a user role list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersRolesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersRolesGet(limit, offset, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends verification code for admin sign in
         * @param {SendCodePayload} [payload] Payload for sending verification code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersSignInSendCodePost(payload?: SendCodePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersSignInSendCodePost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign out an user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersSignOutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20048>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersSignOutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Manages user\'s admin notifications
         * @param {number} userId The user id
         * @param {ManageAdminNotification} [payload] Payload to manage user\&#39;s admin notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersUserIdAdminNotificationsManagePut(userId: number, payload?: ManageAdminNotification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersUserIdAdminNotificationsManagePut(userId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Archives a user
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersUserIdArchivePost(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersUserIdArchivePost(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a user
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersUserIdDelete(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersUserIdDelete(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a user
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersUserIdGet(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a user\'s project notifications
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersUserIdNotificationsByProjectGet(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20079>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersUserIdNotificationsByProjectGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a user\'s project notifications
         * @param {number} userId The user id
         * @param {UserNotificationPayloadByProject} [payload] Payload to update user\&#39;s project notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersUserIdNotificationsByProjectPut(userId: number, payload?: UserNotificationPayloadByProject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20079>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersUserIdNotificationsByProjectPut(userId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a user\'s global notifications
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersUserIdNotificationsByTypeGet(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20078>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersUserIdNotificationsByTypeGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a user\'s global notifications
         * @param {number} userId The user id
         * @param {UserGlobalNotificationPayloadByType} [payload] Payload to update user\&#39;s global notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersUserIdNotificationsByTypePut(userId: number, payload?: UserGlobalNotificationPayloadByType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20078>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersUserIdNotificationsByTypePut(userId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates user
         * @param {number} userId The user id
         * @param {UserEditing} [payload] Payload to update user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersUserIdPut(userId: number, payload?: UserEditing, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersUserIdPut(userId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the user\'s session
         * @param {number} userId The user identifier
         * @param {Session} [payload] Payload to delete the user\&#39;s session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersUserIdSessionsDelete(userId: number, payload?: Session, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersUserIdSessionsDelete(userId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns user\'s active sessions
         * @param {number} userId The user identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersUserIdSessionsGet(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20047>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersUserIdSessionsGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unarchives a user
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminUsersUserIdUnarchivePost(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20056>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminUsersUserIdUnarchivePost(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Wagner device firmware
         * @param {number} firmwareId The firmware id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminWagnerProbesFirmwaresFirmwareIdDelete(firmwareId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminWagnerProbesFirmwaresFirmwareIdDelete(firmwareId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a Wagner device firmware details
         * @param {number} firmwareId The firmware id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminWagnerProbesFirmwaresFirmwareIdGet(firmwareId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20095>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminWagnerProbesFirmwaresFirmwareIdGet(firmwareId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Wagner device firmware
         * @param {number} firmwareId The firmware id
         * @param {UpdateFirmwarePayload} updateFirmwarePayload Payload to update a Wagner device firmware
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminWagnerProbesFirmwaresFirmwareIdPut(firmwareId: number, updateFirmwarePayload: UpdateFirmwarePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20095>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminWagnerProbesFirmwaresFirmwareIdPut(firmwareId, updateFirmwarePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a list of Wagner device firmwares
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {'data_grabber' | 'range_extender'} [deviceType] Device type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminWagnerProbesFirmwaresGet(limit?: number, offset?: number, deviceType?: 'data_grabber' | 'range_extender', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20094>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminWagnerProbesFirmwaresGet(limit, offset, deviceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new firmware for Wagner devices
         * @param {CreateFirmwarePayload} createFirmwarePayload Payload to create a new firmware for Wagner devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminWagnerProbesFirmwaresPost(createFirmwarePayload: CreateFirmwarePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20095>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminWagnerProbesFirmwaresPost(createFirmwarePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns wagner probes
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [contractorId] The contractor id
         * @param {number} [projectId] The project id
         * @param {number} [projectSectionId] The project section id
         * @param {boolean} [connectedToProject] The filter by connectivity to project
         * @param {string} [search] The search string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminWagnerProbesGet(limit?: number, offset?: number, sort?: string, contractorId?: number, projectId?: number, projectSectionId?: number, connectedToProject?: boolean, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20054>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminWagnerProbesGet(limit, offset, sort, contractorId, projectId, projectSectionId, connectedToProject, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes a wagner probe
         * @param {string} probeId The wagner probe device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminWagnerProbesProbeIdDelete(probeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminWagnerProbesProbeIdDelete(probeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a wagner probe
         * @param {string} probeId The wagner probe device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminWagnerProbesProbeIdGet(probeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20055>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminWagnerProbesProbeIdGet(probeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a wagner probe readings
         * @param {string} probeId The wagner probe identifier(probeId)
         * @param {string} [timeFrom] Time from
         * @param {string} [timeTo] Time to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AdminWagnerProbesProbeIdReadingsGet(probeId: string, timeFrom?: string, timeTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20062>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AdminWagnerProbesProbeIdReadingsGet(probeId, timeFrom, timeTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel attachment upload
         * @param {string} attachmentHashId The attachment hash id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AttachmentsAttachmentHashIdCancelUploadPost(attachmentHashId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200103>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AttachmentsAttachmentHashIdCancelUploadPost(attachmentHashId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload photo
         * @param {Array<any>} files Files to upload
         * @param {number} [photoResizeTo] Value to resize photos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MediaFilePut(files: Array<any>, photoResizeTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20040>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MediaFilePut(files, photoResizeTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload file by chunks
         * @param {string} xFileHashId Attachment hash id
         * @param {string} xFileName Attachment file name
         * @param {number} xFileSize Attachment file size
         * @param {number} xFilePartNumber File chunk number
         * @param {number} xTotalParts File chunks total number
         * @param {any} file 
         * @param {number} [photoResizeTo] Value to resize photos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MediaFileUploadChunksPost(xFileHashId: string, xFileName: string, xFileSize: number, xFilePartNumber: number, xTotalParts: number, file: any, photoResizeTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200102>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MediaFileUploadChunksPost(xFileHashId, xFileName, xFileSize, xFilePartNumber, xTotalParts, file, photoResizeTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload photo
         * @param {Array<any>} photos Photos to upload
         * @param {number} [size] Value to resize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MediaPhotoPut(photos: Array<any>, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20039>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MediaPhotoPut(photos, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify code and sign in user
         * @param {SignInPayload} [payload] Payload for verify code and sign in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersSignInPost(payload?: SignInPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UsersSignInPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign out a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersSignOutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UsersSignOutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates contractor
         * @param {number} contractorId The contractor id
         * @param {ContractorEditingV2} [payload] Payload to update contractor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AdminContractorsContractorIdPut(contractorId: number, payload?: ContractorEditingV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2AdminContractorsContractorIdPut(contractorId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates contractor
         * @param {ContractorCreationV2} [payload] Payload to create contractor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AdminContractorsPost(payload?: ContractorCreationV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2AdminContractorsPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns aggregated timeseries gap data
         * @param {string} dateFrom Date from
         * @param {string} [dateTo] Date to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AdminDiagnosticsTimeseriesGapGet(dateFrom: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200107>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2AdminDiagnosticsTimeseriesGapGet(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a user
         * @param {UserCreation} [payload] Payload for creating a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AdminUsersPost(payload?: UserCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20057>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2AdminUsersPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign out a user
         * @param {UserSignOutPayloadV2} userSignOutPayloadV2 Payload for user sign out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UsersSignOutPost(userSignOutPayloadV2: UserSignOutPayloadV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2UsersSignOutPost(userSignOutPayloadV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAPIApi - factory interface
 * @export
 */
export const AdminAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Return a list of alerts
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [search] Search by name and description
         * @param {number} [projectId] The project id
         * @param {number} [projectSectionId] The project section id
         * @param {number} [contractorId] The contractor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAlertsGet(limit?: number, offset?: number, sort?: string, search?: string, projectId?: number, projectSectionId?: number, contractorId?: number, options?: any): AxiosPromise<InlineResponse20098> {
            return localVarFp.v1AdminAlertsGet(limit, offset, sort, search, projectId, projectSectionId, contractorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a single attachment
         * @param {number} attachmentId ID of attachment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAttachmentsAttachmentIdGet(attachmentId: number, options?: any): AxiosPromise<InlineResponse200103> {
            return localVarFp.v1AdminAttachmentsAttachmentIdGet(attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a list of attachments
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {'pending' | 'processing' | 'processed' | 'failed' | 'cancelled'} [status] Status of attachments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminAttachmentsGet(limit?: number, offset?: number, sort?: string, status?: 'pending' | 'processing' | 'processed' | 'failed' | 'cancelled', options?: any): AxiosPromise<InlineResponse200106> {
            return localVarFp.v1AdminAttachmentsGet(limit, offset, sort, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns building type list.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminBuildingTypesGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse20037> {
            return localVarFp.v1AdminBuildingTypesGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new building type
         * @param {CreateBuildingTypePayload} [payload] Payload for building type creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminBuildingTypesPost(payload?: CreateBuildingTypePayload, options?: any): AxiosPromise<InlineResponse20038> {
            return localVarFp.v1AdminBuildingTypesPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a true if building type successfully deleted
         * @param {number} typeId The building type ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminBuildingTypesTypeIdDelete(typeId: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminBuildingTypesTypeIdDelete(typeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a building type state.
         * @param {number} typeId The building type ID
         * @param {UpdateBuildingTypePayload} [payload] Payload for updating building type state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminBuildingTypesTypeIdPatch(typeId: number, payload?: UpdateBuildingTypePayload, options?: any): AxiosPromise<InlineResponse20038> {
            return localVarFp.v1AdminBuildingTypesTypeIdPatch(typeId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns configuration settings
         * @param {number} configParamId The cofiguration param identifier
         * @param {ConfigurationParamUpdate} [payload] Payload to update configuration param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminConfigurationConfigParamIdPut(configParamId: number, payload?: ConfigurationParamUpdate, options?: any): AxiosPromise<InlineResponse20043> {
            return localVarFp.v1AdminConfigurationConfigParamIdPut(configParamId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns configuration settings
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminConfigurationGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse20041> {
            return localVarFp.v1AdminConfigurationGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns mobile settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminConfigurationMobileSettingsGet(options?: any): AxiosPromise<InlineResponse20044> {
            return localVarFp.v1AdminConfigurationMobileSettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a mobile settings
         * @param {UpdateMobileSettings} [updateMobileSettings] Payload for updating a mobile settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminConfigurationMobileSettingsPut(updateMobileSettings?: UpdateMobileSettings, options?: any): AxiosPromise<InlineResponse20044> {
            return localVarFp.v1AdminConfigurationMobileSettingsPut(updateMobileSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bulk update configuration settings
         * @param {Array<ConfigurationParamUpdateBulkItem>} [payload] Payload to update configuration parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminConfigurationPut(payload?: Array<ConfigurationParamUpdateBulkItem>, options?: any): AxiosPromise<InlineResponse20042> {
            return localVarFp.v1AdminConfigurationPut(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of staging devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminConfigurationSensorAllocationGet(options?: any): AxiosPromise<InlineResponse20072> {
            return localVarFp.v1AdminConfigurationSensorAllocationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update list of staging devices
         * @param {UpdateStagingDevices} [updateStagingDevices] Payload for updating list of staging devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminConfigurationSensorAllocationPut(updateStagingDevices?: UpdateStagingDevices, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminConfigurationSensorAllocationPut(updateStagingDevices, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activates contractor
         * @param {number} contractorId The contractor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminContractorsContractorIdActivatePost(contractorId: number, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.v1AdminContractorsContractorIdActivatePost(contractorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivates contractor
         * @param {number} contractorId The contractor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminContractorsContractorIdDeactivatePost(contractorId: number, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.v1AdminContractorsContractorIdDeactivatePost(contractorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrurns contractor
         * @param {number} contractorId The contractor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminContractorsContractorIdGet(contractorId: number, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.v1AdminContractorsContractorIdGet(contractorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the contractor paid plan
         * @param {number} contractorId The contractor id
         * @param {UpdateContractorPaidPlan} payload Payload for updating contractor paid plan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminContractorsContractorIdPaidPlanPut(contractorId: number, payload: UpdateContractorPaidPlan, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.v1AdminContractorsContractorIdPaidPlanPut(contractorId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates contractor
         * @param {number} contractorId The contractor id
         * @param {ContractorEditing} [payload] Payload to update contractor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminContractorsContractorIdPut(contractorId: number, payload?: ContractorEditing, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.v1AdminContractorsContractorIdPut(contractorId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of contractor tokens history
         * @param {number} contractorId The contractor ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminContractorsContractorIdTokensHistoryGet(contractorId: number, options?: any): AxiosPromise<InlineResponse200110> {
            return localVarFp.v1AdminContractorsContractorIdTokensHistoryGet(contractorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of contractors
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [name] Filter by contractors name
         * @param {string} [search] Search by name, phone, email, address
         * @param {boolean} [isActivePaidPlan] Filter by the status of a paid plan
         * @param {boolean} [isActiveTrial] Filter by the status of a trial period
         * @param {boolean} [isRoot] Filter by the root sign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminContractorsGet(limit?: number, offset?: number, sort?: string, name?: string, search?: string, isActivePaidPlan?: boolean, isActiveTrial?: boolean, isRoot?: boolean, options?: any): AxiosPromise<InlineResponse2004> {
            return localVarFp.v1AdminContractorsGet(limit, offset, sort, name, search, isActivePaidPlan, isActiveTrial, isRoot, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates contractor
         * @param {ContractorCreation} [payload] Payload to create contractor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminContractorsPost(payload?: ContractorCreation, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.v1AdminContractorsPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a statistics for dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminDashboardGet(options?: any): AxiosPromise<InlineResponse20063> {
            return localVarFp.v1AdminDashboardGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Parse rapid rh advertisement packet
         * @param {ParseAdvPacketPayload} payload Payload parse a packet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminDiagnosticsParseAdvPacketPost(payload: ParseAdvPacketPayload, options?: any): AxiosPromise<InlineResponse20090> {
            return localVarFp.v1AdminDiagnosticsParseAdvPacketPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns aggregated timeseries gap data
         * @param {string} dateFrom Date from
         * @param {string} [dateTo] Date to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminDiagnosticsTimeseriesGapGet(dateFrom: string, dateTo?: string, options?: any): AxiosPromise<InlineResponse20076> {
            return localVarFp.v1AdminDiagnosticsTimeseriesGapGet(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns aggregated timeseries handling gap data
         * @param {string} dateFrom Date from
         * @param {string} [dateTo] Date to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminDiagnosticsTimeseriesHandlingGapGet(dateFrom: string, dateTo?: string, options?: any): AxiosPromise<InlineResponse200108> {
            return localVarFp.v1AdminDiagnosticsTimeseriesHandlingGapGet(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a list of documents
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [search] Search by name and description
         * @param {number} [projectId] The project id
         * @param {number} [contractorId] The contractor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminDocumentsGet(limit?: number, offset?: number, sort?: string, search?: string, projectId?: number, contractorId?: number, options?: any): AxiosPromise<InlineResponse20096> {
            return localVarFp.v1AdminDocumentsGet(limit, offset, sort, search, projectId, contractorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of events
         * @param {number} eventId The event identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminEventsEventIdGet(eventId: number, options?: any): AxiosPromise<InlineResponse20069> {
            return localVarFp.v1AdminEventsEventIdGet(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of events
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [projectId] The project ID
         * @param {number} [userId] The user ID
         * @param {string} [typeId] The event type ID
         * @param {boolean} [hideServiceAccount] The attribute to hide or not events created by service account
         * @param {number} [contractorId] The contractor ID
         * @param {number} [sensorId] The sensor ID
         * @param {boolean} [withPhotos] The attribute to return events with photos or not
         * @param {number} [rapidRhProbeId] The rapid RH probe ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminEventsGet(limit?: number, offset?: number, sort?: string, projectId?: number, userId?: number, typeId?: string, hideServiceAccount?: boolean, contractorId?: number, sensorId?: number, withPhotos?: boolean, rapidRhProbeId?: number, options?: any): AxiosPromise<InlineResponse20068> {
            return localVarFp.v1AdminEventsGet(limit, offset, sort, projectId, userId, typeId, hideServiceAccount, contractorId, sensorId, withPhotos, rapidRhProbeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update event type by id
         * @param {number} eventTypeId Event type Id
         * @param {EventTypeUpdatePayload} [payload] Payload for update event type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminEventsTypesEventTypeIdPatch(eventTypeId: number, payload?: EventTypeUpdatePayload, options?: any): AxiosPromise<EventType> {
            return localVarFp.v1AdminEventsTypesEventTypeIdPatch(eventTypeId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns event types list.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminEventsTypesGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse20036> {
            return localVarFp.v1AdminEventsTypesGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of labels
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminLabelsGet(sort?: string, options?: any): AxiosPromise<InlineResponse20058> {
            return localVarFp.v1AdminLabelsGet(sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a label
         * @param {LabelCreation} [payload] Payload to create a label
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminLabelsPost(payload?: LabelCreation, options?: any): AxiosPromise<InlineResponse20059> {
            return localVarFp.v1AdminLabelsPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a manufacturer announcement category
         * @param {number} categoryId The category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsCategoriesCategoryIdDelete(categoryId: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminManufacturersAnnouncementsCategoriesCategoryIdDelete(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a manufacturer announcement category
         * @param {number} categoryId The category id
         * @param {UpdateManufacturerAnnouncementCategoryPayload} updateManufacturerAnnouncementCategoryPayload Payload to update a manufacturer announcement category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsCategoriesCategoryIdPut(categoryId: number, updateManufacturerAnnouncementCategoryPayload: UpdateManufacturerAnnouncementCategoryPayload, options?: any): AxiosPromise<InlineResponse20093> {
            return localVarFp.v1AdminManufacturersAnnouncementsCategoriesCategoryIdPut(categoryId, updateManufacturerAnnouncementCategoryPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a list of manufacturer announcement categories
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsCategoriesGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse20092> {
            return localVarFp.v1AdminManufacturersAnnouncementsCategoriesGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new manufacturer announcement category
         * @param {CreateManufacturerAnnouncementCategoryPayload} createManufacturerAnnouncementCategoryPayload Payload to create a new manufacturer announcement category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsCategoriesPost(createManufacturerAnnouncementCategoryPayload: CreateManufacturerAnnouncementCategoryPayload, options?: any): AxiosPromise<InlineResponse20093> {
            return localVarFp.v1AdminManufacturersAnnouncementsCategoriesPost(createManufacturerAnnouncementCategoryPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of manufacturer announcements
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse20080> {
            return localVarFp.v1AdminManufacturersAnnouncementsGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a manufacturer announcement
         * @param {number} manufacturerAnnouncementId The manufacturer announcement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdDelete(manufacturerAnnouncementId: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdDelete(manufacturerAnnouncementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a manufacturer announcement
         * @param {number} manufacturerAnnouncementId The manufacturer announcement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdGet(manufacturerAnnouncementId: number, options?: any): AxiosPromise<InlineResponse20081> {
            return localVarFp.v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdGet(manufacturerAnnouncementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a manufacturer announcement
         * @param {number} manufacturerAnnouncementId The manufacturer announcement id
         * @param {UpdateManufacturerAnnouncementPayload} [payload] Payload to update a manufacturer announcement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdPut(manufacturerAnnouncementId: number, payload?: UpdateManufacturerAnnouncementPayload, options?: any): AxiosPromise<InlineResponse20081> {
            return localVarFp.v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdPut(manufacturerAnnouncementId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a manufacturer announcement
         * @param {CreateManufacturerAnnouncementPayload} [payload] Payload to create a manufacturer announcement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsPost(payload?: CreateManufacturerAnnouncementPayload, options?: any): AxiosPromise<InlineResponse20081> {
            return localVarFp.v1AdminManufacturersAnnouncementsPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of manufacturer announcement types
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsTypesGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse20082> {
            return localVarFp.v1AdminManufacturersAnnouncementsTypesGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new manufacturer announcement type
         * @param {CreateManufacturerAnnouncementTypePayload} createManufacturerAnnouncementTypePayload Payload to create a new manufacturer announcement type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsTypesPost(createManufacturerAnnouncementTypePayload: CreateManufacturerAnnouncementTypePayload, options?: any): AxiosPromise<InlineResponse20083> {
            return localVarFp.v1AdminManufacturersAnnouncementsTypesPost(createManufacturerAnnouncementTypePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a manufacturer announcement type
         * @param {number} typeId The type id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsTypesTypeIdDelete(typeId: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminManufacturersAnnouncementsTypesTypeIdDelete(typeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a manufacturer announcement type
         * @param {number} typeId The type id
         * @param {UpdateManufacturerAnnouncementTypePayload} updateManufacturerAnnouncementTypePayload Payload to update a manufacturer announcement type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersAnnouncementsTypesTypeIdPut(typeId: number, updateManufacturerAnnouncementTypePayload: UpdateManufacturerAnnouncementTypePayload, options?: any): AxiosPromise<InlineResponse20083> {
            return localVarFp.v1AdminManufacturersAnnouncementsTypesTypeIdPut(typeId, updateManufacturerAnnouncementTypePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a manufacturer list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [name] Filter by name
         * @param {string} [search] Search by name, phone, email, address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersGet(limit?: number, offset?: number, sort?: string, name?: string, search?: string, options?: any): AxiosPromise<InlineResponse20011> {
            return localVarFp.v1AdminManufacturersGet(limit, offset, sort, name, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a manufacturer
         * @param {number} manufacturerId The manufacturer id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersManufacturerIdDelete(manufacturerId: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminManufacturersManufacturerIdDelete(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrurns manufacturer
         * @param {number} manufacturerId The manufacturer id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersManufacturerIdGet(manufacturerId: number, options?: any): AxiosPromise<InlineResponse20010> {
            return localVarFp.v1AdminManufacturersManufacturerIdGet(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates manufacturer
         * @param {number} manufacturerId The manufacturer id
         * @param {ManufacturerEditing} [payload] Payload to update manufacturer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersManufacturerIdPut(manufacturerId: number, payload?: ManufacturerEditing, options?: any): AxiosPromise<InlineResponse20010> {
            return localVarFp.v1AdminManufacturersManufacturerIdPut(manufacturerId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a manufacturer and returns the new one.
         * @param {ManufacturerCreation} [payload] Payload for creating a manufacturer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminManufacturersPost(payload?: ManufacturerCreation, options?: any): AxiosPromise<InlineResponse20010> {
            return localVarFp.v1AdminManufacturersPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the product.
         * @param {number} draftId The draft id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsDraftsDraftIdDelete(draftId: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminProductsDraftsDraftIdDelete(draftId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a products\' drafts.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [manufacturerId] Manufacturer ID
         * @param {number} [typeId] Product type ID
         * @param {string} [name] The product name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsDraftsGet(limit?: number, offset?: number, sort?: string, manufacturerId?: number, typeId?: number, name?: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminProductsDraftsGet(limit, offset, sort, manufacturerId, typeId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a product draft and returns the new one.
         * @param {ProductDraftCreation} [payload] Payload for creating a product draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsDraftsPost(payload?: ProductDraftCreation, options?: any): AxiosPromise<InlineResponse20024> {
            return localVarFp.v1AdminProductsDraftsPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publishes a products\' drafts.
         * @param {ProductsDraftsPublishBulk} [payload] Payload for publishing a products\&#39; drafts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsDraftsPublishPost(payload?: ProductsDraftsPublishBulk, options?: any): AxiosPromise<InlineResponse20025> {
            return localVarFp.v1AdminProductsDraftsPublishPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates products\' drafts.
         * @param {Array<ProductsDraftsUpdate>} [payload] Payload for updating a product draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsDraftsPut(payload?: Array<ProductsDraftsUpdate>, options?: any): AxiosPromise<InlineResponse20023> {
            return localVarFp.v1AdminProductsDraftsPut(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a product list.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [manufacturerId] Manufacturer ID
         * @param {number} [typeId] Product type ID
         * @param {string} [name] The product name
         * @param {number} [projectId] The project ID
         * @param {boolean} [noShelfLife] Is shelfLife field eq null or not
         * @param {boolean} [noRanges] Is some of lowHumidity, highHumidity, lowTempF, highTempF fields eq null or not
         * @param {boolean} [noTips] Is product tips count eq 0 or not
         * @param {boolean} [inUse] Is project product count eq 0 or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsGet(limit?: number, offset?: number, sort?: string, manufacturerId?: number, typeId?: number, name?: string, projectId?: number, noShelfLife?: boolean, noRanges?: boolean, noTips?: boolean, inUse?: boolean, options?: any): AxiosPromise<InlineResponse20026> {
            return localVarFp.v1AdminProductsGet(limit, offset, sort, manufacturerId, typeId, name, projectId, noShelfLife, noRanges, noTips, inUse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a list of product imports
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsImportHistoryGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse20088> {
            return localVarFp.v1AdminProductsImportHistoryGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a product import details
         * @param {number} importHistoryId The import history item id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsImportHistoryImportHistoryIdGet(importHistoryId: number, options?: any): AxiosPromise<InlineResponse20089> {
            return localVarFp.v1AdminProductsImportHistoryImportHistoryIdGet(importHistoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a link for downloading an import products template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsImportTemplateLinkGet(options?: any): AxiosPromise<InlineResponse20053> {
            return localVarFp.v1AdminProductsImportTemplateLinkGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Merges products
         * @param {MergeProductsPayload} payload Payload to merge products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsMergePost(payload: MergeProductsPayload, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminProductsMergePost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a product package types
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsPackageTypesGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse20015> {
            return localVarFp.v1AdminProductsPackageTypesGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a product and returns the new one.
         * @param {ProductCreation} [payload] Payload for creating a product
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1AdminProductsPost(payload?: ProductCreation, options?: any): AxiosPromise<InlineResponse20019> {
            return localVarFp.v1AdminProductsPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a true if product successfully deleted
         * @param {number} productId The product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsProductIdDelete(productId: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminProductsProductIdDelete(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a single product
         * @param {number} productId The product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsProductIdGet(productId: number, options?: any): AxiosPromise<InlineResponse20019> {
            return localVarFp.v1AdminProductsProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a product and returns it.
         * @param {number} productId The product ID
         * @param {ProductEditing} [payload] Payload for updating a product
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        v1AdminProductsProductIdPut(productId: number, payload?: ProductEditing, options?: any): AxiosPromise<InlineResponse20019> {
            return localVarFp.v1AdminProductsProductIdPut(productId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of product requests
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsRequestsGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse20064> {
            return localVarFp.v1AdminProductsRequestsGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns number of new product requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsRequestsNumberOfNewGet(options?: any): AxiosPromise<InlineResponse20066> {
            return localVarFp.v1AdminProductsRequestsNumberOfNewGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a product request
         * @param {number} productRequestId The product request identifier
         * @param {UpdateProductRequestPayload} [updateProductRequestPayload] Payload to update a product request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsRequestsProductRequestIdPut(productRequestId: number, updateProductRequestPayload?: UpdateProductRequestPayload, options?: any): AxiosPromise<InlineResponse20067> {
            return localVarFp.v1AdminProductsRequestsProductRequestIdPut(productRequestId, updateProductRequestPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a product request summary
         * @param {number} productRequestId The product request identifier
         * @param {ProductRequestSummaryPayload} [productRequestSummaryPayload] Payload to update a product request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsRequestsProductRequestIdSendSummaryPost(productRequestId: number, productRequestSummaryPayload?: ProductRequestSummaryPayload, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminProductsRequestsProductRequestIdSendSummaryPost(productRequestId, productRequestSummaryPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of product request statuses
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsRequestsStatusesGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse20065> {
            return localVarFp.v1AdminProductsRequestsStatusesGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a product tip types
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsTipTypesGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse20014> {
            return localVarFp.v1AdminProductsTipTypesGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a product tips.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [tipTypeId] The tip\&#39;s type ID
         * @param {number} [productId] The product ID
         * @param {number} [productDraftId] The product\&#39;s draft ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsTipsCustomGet(limit?: number, offset?: number, sort?: string, tipTypeId?: number, productId?: number, productDraftId?: number, options?: any): AxiosPromise<InlineResponse20022> {
            return localVarFp.v1AdminProductsTipsCustomGet(limit, offset, sort, tipTypeId, productId, productDraftId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a product types\' tips.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [tipTypeId] The tip\&#39;s type ID
         * @param {number} [productTypeId] The product\&#39;s type ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsTipsDefaultGet(limit?: number, offset?: number, sort?: string, tipTypeId?: number, productTypeId?: number, options?: any): AxiosPromise<InlineResponse20021> {
            return localVarFp.v1AdminProductsTipsDefaultGet(limit, offset, sort, tipTypeId, productTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a product types
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [withTips] Send product types with or without tips
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsTypesGet(limit?: number, offset?: number, sort?: string, withTips?: string, options?: any): AxiosPromise<InlineResponse20016> {
            return localVarFp.v1AdminProductsTypesGet(limit, offset, sort, withTips, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new product type
         * @param {ProductTypeCreation} [payload] Payload for the product type creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsTypesPost(payload?: ProductTypeCreation, options?: any): AxiosPromise<InlineResponse20017> {
            return localVarFp.v1AdminProductsTypesPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the product type
         * @param {number} productTypeId The product type ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsTypesProductTypeIdDelete(productTypeId: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminProductsTypesProductTypeIdDelete(productTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the product type
         * @param {number} productTypeId The product type ID
         * @param {ProductTypeUpdate} [payload] Payload to update the product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsTypesProductTypeIdPut(productTypeId: number, payload?: ProductTypeUpdate, options?: any): AxiosPromise<InlineResponse20017> {
            return localVarFp.v1AdminProductsTypesProductTypeIdPut(productTypeId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the product type tips
         * @param {number} productTypeId The product type ID
         * @param {ProductTypeTipsUpdate} [payload] Payload to update the product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsTypesProductTypeIdTipsPut(productTypeId: number, payload?: ProductTypeTipsUpdate, options?: any): AxiosPromise<InlineResponse20018> {
            return localVarFp.v1AdminProductsTypesProductTypeIdTipsPut(productTypeId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload xlsx
         * @param {any} xlsx Xlsx to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProductsUploadPut(xlsx: any, options?: any): AxiosPromise<InlineResponse20020> {
            return localVarFp.v1AdminProductsUploadPut(xlsx, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of project sections.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [projectId] Project ID
         * @param {boolean} [onlyActiveSections] Filter by the project section status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProjectSectionsGet(limit?: number, offset?: number, sort?: string, projectId?: number, onlyActiveSections?: boolean, options?: any): AxiosPromise<InlineResponse20032> {
            return localVarFp.v1AdminProjectSectionsGet(limit, offset, sort, projectId, onlyActiveSections, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns single project section by id
         * @param {number} projectSectionId The project section id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProjectSectionsProjectSectionIdGet(projectSectionId: number, options?: any): AxiosPromise<InlineResponse20033> {
            return localVarFp.v1AdminProjectSectionsProjectSectionIdGet(projectSectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of projects.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [contractorId] Contractor ID
         * @param {number} [typeId] Type ID
         * @param {string} [city] City of the project
         * @param {number} [productId] Product ID
         * @param {number} [userId] User ID
         * @param {string} [search] Search by project name and location
         * @param {boolean} [isActive] Filter by the project status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProjectsGet(limit?: number, offset?: number, sort?: string, contractorId?: number, typeId?: number, city?: string, productId?: number, userId?: number, search?: string, isActive?: boolean, options?: any): AxiosPromise<InlineResponse20030> {
            return localVarFp.v1AdminProjectsGet(limit, offset, sort, contractorId, typeId, city, productId, userId, search, isActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns single project by id
         * @param {number} projectId The project id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProjectsProjectIdGet(projectId: number, options?: any): AxiosPromise<InlineResponse20031> {
            return localVarFp.v1AdminProjectsProjectIdGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of projects types
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProjectsTypesGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse20034> {
            return localVarFp.v1AdminProjectsTypesGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new project type
         * @param {CreateProjectTypePayload} [payload] Payload for project type creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProjectsTypesPost(payload?: CreateProjectTypePayload, options?: any): AxiosPromise<InlineResponse20035> {
            return localVarFp.v1AdminProjectsTypesPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a true if project type successfully deleted
         * @param {number} typeId The project type ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProjectsTypesTypeIdDelete(typeId: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminProjectsTypesTypeIdDelete(typeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a project type state.
         * @param {number} typeId The project type ID
         * @param {UpdateProjectTypePayload} [payload] Payload for updating project type state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminProjectsTypesTypeIdPatch(typeId: number, payload?: UpdateProjectTypePayload, options?: any): AxiosPromise<InlineResponse20035> {
            return localVarFp.v1AdminProjectsTypesTypeIdPatch(typeId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a release list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReleasesGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse20012> {
            return localVarFp.v1AdminReleasesGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a release and returns the new one.
         * @param {ReleaseCreation} [payload] Payload for creating a release
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReleasesPost(payload?: ReleaseCreation, options?: any): AxiosPromise<InlineResponse20013> {
            return localVarFp.v1AdminReleasesPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes release
         * @param {number} releaseId The release id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReleasesReleaseIdDelete(releaseId: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminReleasesReleaseIdDelete(releaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrurns release
         * @param {number} releaseId The release id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReleasesReleaseIdGet(releaseId: number, options?: any): AxiosPromise<InlineResponse20013> {
            return localVarFp.v1AdminReleasesReleaseIdGet(releaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates release
         * @param {number} releaseId The release id
         * @param {ReleaseEditing} [payload] Payload to update release
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReleasesReleaseIdPut(releaseId: number, payload?: ReleaseEditing, options?: any): AxiosPromise<InlineResponse20013> {
            return localVarFp.v1AdminReleasesReleaseIdPut(releaseId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return components for combined reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReportsCombinedReportsComponentsGet(options?: any): AxiosPromise<InlineResponse200100> {
            return localVarFp.v1AdminReportsCombinedReportsComponentsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update component for combined reports
         * @param {number} reportComponentId The report component id
         * @param {CombinedReportComponentUpdatePayload} combinedReportComponentUpdatePayload Payload to update component for combined reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReportsCombinedReportsComponentsReportComponentIdPatch(reportComponentId: number, combinedReportComponentUpdatePayload: CombinedReportComponentUpdatePayload, options?: any): AxiosPromise<InlineResponse200101> {
            return localVarFp.v1AdminReportsCombinedReportsComponentsReportComponentIdPatch(reportComponentId, combinedReportComponentUpdatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns type of included events for combined reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReportsCombinedReportsIncludedEventsGet(options?: any): AxiosPromise<InlineResponse20099> {
            return localVarFp.v1AdminReportsCombinedReportsIncludedEventsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update type of included events for combined reports
         * @param {CombinedReportIncludedEventUpdatePayload} combinedReportIncludedEventUpdatePayload Payload to update type of included events for combined reports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReportsCombinedReportsIncludedEventsPut(combinedReportIncludedEventUpdatePayload: CombinedReportIncludedEventUpdatePayload, options?: any): AxiosPromise<InlineResponse20099> {
            return localVarFp.v1AdminReportsCombinedReportsIncludedEventsPut(combinedReportIncludedEventUpdatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary The ednpoint returns list of reports
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [contractorId] Filter by contractor id
         * @param {number} [projectId] Filter by project id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReportsGet(limit?: number, offset?: number, sort?: string, contractorId?: number, projectId?: number, options?: any): AxiosPromise<InlineResponse20077> {
            return localVarFp.v1AdminReportsGet(limit, offset, sort, contractorId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Regenerate a report\'s link and return updated report
         * @param {number} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReportsReportIdRegenerateLinkPost(reportId: number, options?: any): AxiosPromise<InlineResponse20097> {
            return localVarFp.v1AdminReportsReportIdRegenerateLinkPost(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of report types
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReportsTypesGet(sort?: string, options?: any): AxiosPromise<InlineResponse20070> {
            return localVarFp.v1AdminReportsTypesGet(sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of report types
         * @param {number} reportTypeId The report type identifier
         * @param {UpdateReportTypePayload} [updateReportTypePayload] Payload to update a report type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminReportsTypesReportTypeIdPut(reportTypeId: number, updateReportTypePayload?: UpdateReportTypePayload, options?: any): AxiosPromise<InlineResponse20071> {
            return localVarFp.v1AdminReportsTypesReportTypeIdPut(reportTypeId, updateReportTypePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends a request to calculate a sensor technical status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsCalculateTechnicalStatusPost(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminSensorsCalculateTechnicalStatusPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a default sensor configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsDefaultConfigurationGet(options?: any): AxiosPromise<InlineResponse20050> {
            return localVarFp.v1AdminSensorsDefaultConfigurationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a default sensor configuration
         * @param {SensorConfiguration} [payload] Payload for updating a default sensor configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsDefaultConfigurationPut(payload?: SensorConfiguration, options?: any): AxiosPromise<InlineResponse20050> {
            return localVarFp.v1AdminSensorsDefaultConfigurationPut(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fills sensors missed readings
         * @param {FillMissedReadingsBulkPayload} [fillMissedReadingsBulkPayload] Payload to fill sensors missed readings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsFillMissedReadingsPost(fillMissedReadingsBulkPayload?: FillMissedReadingsBulkPayload, options?: any): AxiosPromise<InlineResponse20075> {
            return localVarFp.v1AdminSensorsFillMissedReadingsPost(fillMissedReadingsBulkPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of sensors firmware versions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsFirmwareVersionsGet(options?: any): AxiosPromise<InlineResponse20072> {
            return localVarFp.v1AdminSensorsFirmwareVersionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns ZIP file with generated lables for sensors
         * @param {string} sensorUniqueIds The sensors unique identifiers (uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsGenerateLabelsGet(sensorUniqueIds: string, options?: any): AxiosPromise<any> {
            return localVarFp.v1AdminSensorsGenerateLabelsGet(sensorUniqueIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of sensors.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [projectId] Project ID
         * @param {number} [contractorId] Contractor ID
         * @param {number} [stateId] State ID
         * @param {string} [lastHeard] The last time the sensor sent data
         * @param {string} [lastPing] The updatedAt of the sensor current data
         * @param {string} [synchronizationPeriod] The synchronization period of a sensor, in seconds
         * @param {string} [accelerationTime] The last time of the acceleration readings
         * @param {string} [batteryTime] The last time of the battery readings
         * @param {string} [dewpointTime] The last time of the dewpoint readings
         * @param {string} [humidityTime] The last time of the humidity readings
         * @param {string} [lightTime] The last time of the light readings
         * @param {string} [locationTime] The last time of the cellular location readings
         * @param {string} [locationGpsTime] The last time of the gps location readings
         * @param {string} [orientationTime] The last time of the orientation readings
         * @param {string} [pressureTime] The last time of the pressure readings
         * @param {string} [signalStrengthTime] The last time of the signal strength readings
         * @param {string} [temperatureTime] The last time of the temperature readings
         * @param {number} [batteryLevel] Battery charge percentage
         * @param {number} [signalStrength] The signal strength level
         * @param {string} [search] Search by Sensor ID, IMEI, Dev EUI
         * @param {string} [currentSensorConfiguration] Type of a sensor\&#39;s configuration
         * @param {boolean} [isBleEnabled] Filter by status of BLE module
         * @param {string} [firmware] Filter by sensor firmware version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsGet(limit?: number, offset?: number, sort?: string, projectId?: number, contractorId?: number, stateId?: number, lastHeard?: string, lastPing?: string, synchronizationPeriod?: string, accelerationTime?: string, batteryTime?: string, dewpointTime?: string, humidityTime?: string, lightTime?: string, locationTime?: string, locationGpsTime?: string, orientationTime?: string, pressureTime?: string, signalStrengthTime?: string, temperatureTime?: string, batteryLevel?: number, signalStrength?: number, search?: string, currentSensorConfiguration?: string, isBleEnabled?: boolean, firmware?: string, options?: any): AxiosPromise<InlineResponse20029> {
            return localVarFp.v1AdminSensorsGet(limit, offset, sort, projectId, contractorId, stateId, lastHeard, lastPing, synchronizationPeriod, accelerationTime, batteryTime, dewpointTime, humidityTime, lightTime, locationTime, locationGpsTime, orientationTime, pressureTime, signalStrengthTime, temperatureTime, batteryLevel, signalStrength, search, currentSensorConfiguration, isBleEnabled, firmware, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return GPX waypoints file with for all sensors
         * @param {'gps' | 'cellid'} type Location type
         * @param {string} [authorization] Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsGpxWaypointsDataGet(type: 'gps' | 'cellid', authorization?: string, options?: any): AxiosPromise<InlineResponse20046> {
            return localVarFp.v1AdminSensorsGpxWaypointsDataGet(type, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of sensors for map.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [contractorId] Contractor ID
         * @param {number} [stateId] State ID
         * @param {string} [lastHeard] The last time the sensor sent data
         * @param {number} [batteryLevel] Battery charge percentage
         * @param {string} [search] Search by Sensor ID, IMEI, Dev EUI
         * @param {string} [lastPing] The updatedAt of the sensor current data
         * @param {string} [synchronizationPeriod] The synchronization period of a sensor, in seconds
         * @param {string} [accelerationTime] The last time of the acceleration readings
         * @param {string} [batteryTime] The last time of the battery readings
         * @param {string} [dewpointTime] The last time of the dewpoint readings
         * @param {string} [humidityTime] The last time of the humidity readings
         * @param {string} [lightTime] The last time of the light readings
         * @param {string} [locationTime] The last time of the cellular location readings
         * @param {string} [locationGpsTime] The last time of the gps location readings
         * @param {string} [orientationTime] The last time of the orientation readings
         * @param {string} [pressureTime] The last time of the pressure readings
         * @param {string} [signalStrengthTime] The last time of the signal strength readings
         * @param {string} [temperatureTime] The last time of the temperature readings
         * @param {number} [signalStrength] The signal strength level
         * @param {string} [currentSensorConfiguration] Type of a sensor\&#39;s configuration
         * @param {boolean} [isBleEnabled] Filter by status of BLE module
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsMapViewGet(limit?: number, offset?: number, sort?: string, contractorId?: number, stateId?: number, lastHeard?: string, batteryLevel?: number, search?: string, lastPing?: string, synchronizationPeriod?: string, accelerationTime?: string, batteryTime?: string, dewpointTime?: string, humidityTime?: string, lightTime?: string, locationTime?: string, locationGpsTime?: string, orientationTime?: string, pressureTime?: string, signalStrengthTime?: string, temperatureTime?: string, signalStrength?: number, currentSensorConfiguration?: string, isBleEnabled?: boolean, options?: any): AxiosPromise<InlineResponse20029> {
            return localVarFp.v1AdminSensorsMapViewGet(limit, offset, sort, contractorId, stateId, lastHeard, batteryLevel, search, lastPing, synchronizationPeriod, accelerationTime, batteryTime, dewpointTime, humidityTime, lightTime, locationTime, locationGpsTime, orientationTime, pressureTime, signalStrengthTime, temperatureTime, signalStrength, currentSensorConfiguration, isBleEnabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a sensor state list.
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsStatesGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse20027> {
            return localVarFp.v1AdminSensorsStatesGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns thingspace sensors list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [search] Sensor\&#39;s imei for searching
         * @param {string} [isAvailableInSystem] Returns thingspace sensors which are available in system or no
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsThingspaceSensorsGet(limit?: number, offset?: number, sort?: string, search?: string, isAvailableInSystem?: string, options?: any): AxiosPromise<InlineResponse20045> {
            return localVarFp.v1AdminSensorsThingspaceSensorsGet(limit, offset, sort, search, isAvailableInSystem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Turn off BLE for a batch of sensors
         * @param {ManageSensorBLEStatePayload} manageSensorBLEStatePayload Payload to turn off BLE for a batch of sensors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsTurnOffBlePost(manageSensorBLEStatePayload: ManageSensorBLEStatePayload, options?: any): AxiosPromise<InlineResponse200105> {
            return localVarFp.v1AdminSensorsTurnOffBlePost(manageSensorBLEStatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Turn on BLE for a batch of sensors
         * @param {ManageSensorBLEStatePayload} manageSensorBLEStatePayload Payload to turn on BLE for a batch of sensors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsTurnOnBlePost(manageSensorBLEStatePayload: ManageSensorBLEStatePayload, options?: any): AxiosPromise<InlineResponse200104> {
            return localVarFp.v1AdminSensorsTurnOnBlePost(manageSensorBLEStatePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assigns sensor to specific contractor
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {SensorAssignToContractorPayload} [payload] Payload for assigning sensor to contractor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdAssignToContractorPost(uniqueId: string, payload?: SensorAssignToContractorPayload, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminSensorsUniqueIdAssignToContractorPost(uniqueId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the backup uplinks for the sensor
         * @param {string} uniqueId The sensor unique id
         * @param {string} [date] The backup uplink date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdBackupUplinksGet(uniqueId: string, date?: string, options?: any): AxiosPromise<InlineResponse20085> {
            return localVarFp.v1AdminSensorsUniqueIdBackupUplinksGet(uniqueId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a sensor configuration
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdConfigurationGet(uniqueId: string, options?: any): AxiosPromise<InlineResponse20049> {
            return localVarFp.v1AdminSensorsUniqueIdConfigurationGet(uniqueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Setups a sensor configuration
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {SensorConfiguration} [payload] Payload for a sensor configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdConfigurationPost(uniqueId: string, payload?: SensorConfiguration, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminSensorsUniqueIdConfigurationPost(uniqueId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Configures sensor
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdConfigurePost(uniqueId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminSensorsUniqueIdConfigurePost(uniqueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the sensor extended diagnostics information
         * @param {string} uniqueId The sensor unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdExtendedDiagnosticsGet(uniqueId: string, options?: any): AxiosPromise<InlineResponse20086> {
            return localVarFp.v1AdminSensorsUniqueIdExtendedDiagnosticsGet(uniqueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks and fills missed readings
         * @param {string} uniqueId The sensor unique id (uniqueId)
         * @param {FillMissedReadingsPayload} [fillMissedReadingsPayload] Payload to check and fill missed readings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdFillMissedReadingsPost(uniqueId: string, fillMissedReadingsPayload?: FillMissedReadingsPayload, options?: any): AxiosPromise<InlineResponse20075> {
            return localVarFp.v1AdminSensorsUniqueIdFillMissedReadingsPost(uniqueId, fillMissedReadingsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns sensor with new firmware version
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdFirmwareVersionGet(uniqueId: string, options?: any): AxiosPromise<InlineResponse20028> {
            return localVarFp.v1AdminSensorsUniqueIdFirmwareVersionGet(uniqueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a sensor.
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdGet(uniqueId: string, options?: any): AxiosPromise<InlineResponse20028> {
            return localVarFp.v1AdminSensorsUniqueIdGet(uniqueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns GPX file with location data
         * @param {string} uniqueId The unique id of a sensor
         * @param {'gps' | 'cellid'} type Location type
         * @param {string} dateFrom Date from
         * @param {string} [dateTo] Date to
         * @param {string} [authorization] Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdGpxDataGet(uniqueId: string, type: 'gps' | 'cellid', dateFrom: string, dateTo?: string, authorization?: string, options?: any): AxiosPromise<InlineResponse20046> {
            return localVarFp.v1AdminSensorsUniqueIdGpxDataGet(uniqueId, type, dateFrom, dateTo, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of past projects for a sensor
         * @param {string} uniqueId The sensor unique id (uniqueId)
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdPastProjectsGet(uniqueId: string, sort?: string, options?: any): AxiosPromise<InlineResponse20060> {
            return localVarFp.v1AdminSensorsUniqueIdPastProjectsGet(uniqueId, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a sensor state.
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {SensorUpdateState} [payload] Payload for updating a sensor state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdPatch(uniqueId: string, payload?: SensorUpdateState, options?: any): AxiosPromise<InlineResponse20028> {
            return localVarFp.v1AdminSensorsUniqueIdPatch(uniqueId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a sensor readout
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {string} [timeFrom] Time from
         * @param {string} [timeTo] Time to
         * @param {'temperature' | 'humidity' | 'battery' | 'signalStrength' | 'light' | 'pressure' | 'cellularLocation' | 'gpsLocation' | 'ble'} [reading] Kind of reading
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdReadoutGet(uniqueId: string, timeFrom?: string, timeTo?: string, reading?: 'temperature' | 'humidity' | 'battery' | 'signalStrength' | 'light' | 'pressure' | 'cellularLocation' | 'gpsLocation' | 'ble', options?: any): AxiosPromise<InlineResponse20061> {
            return localVarFp.v1AdminSensorsUniqueIdReadoutGet(uniqueId, timeFrom, timeTo, reading, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of related rapid RH probes
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdRelatedRapidRhProbesGet(uniqueId: string, options?: any): AxiosPromise<InlineResponse200109> {
            return localVarFp.v1AdminSensorsUniqueIdRelatedRapidRhProbesGet(uniqueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary The endpoint retrieves sensor firmware information
         * @param {string} uniqueId The device unique identifier(uniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUniqueIdRetrieveSensorFirmwarePost(uniqueId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminSensorsUniqueIdRetrieveSensorFirmwarePost(uniqueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns aggregated list of unstable sensors
         * @param {string} [timeFrom] Time from
         * @param {string} [timeTo] Time to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUnstableSensorsChartGet(timeFrom?: string, timeTo?: string, options?: any): AxiosPromise<InlineResponse20074> {
            return localVarFp.v1AdminSensorsUnstableSensorsChartGet(timeFrom, timeTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of unstable sensors
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [search] Search by device id (imei, etc.)
         * @param {string} [datetimeFrom] Datetime from
         * @param {string} [datetimeTo] Datetime to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSensorsUnstableSensorsGet(limit?: number, offset?: number, sort?: string, search?: string, datetimeFrom?: string, datetimeTo?: string, options?: any): AxiosPromise<InlineResponse20073> {
            return localVarFp.v1AdminSensorsUnstableSensorsGet(limit, offset, sort, search, datetimeFrom, datetimeTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return system messages history
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSystemMessagesHistoryGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse20091> {
            return localVarFp.v1AdminSystemMessagesHistoryGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send system messages via push or email
         * @param {SystemMessagePayload} [payload] Payload for sending system messages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSystemMessagesSendPost(payload?: SystemMessagePayload, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminSystemMessagesSendPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns system message transport types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSystemMessagesTransportTypesGet(options?: any): AxiosPromise<InlineResponse20052> {
            return localVarFp.v1AdminSystemMessagesTransportTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns system message types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminSystemMessagesTypesGet(options?: any): AxiosPromise<InlineResponse20051> {
            return localVarFp.v1AdminSystemMessagesTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a list of tasks
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {string} [search] Search by name, description, author, assignee, contractor, project and section
         * @param {boolean} [isActive] Active or archived task
         * @param {string} [status] Active or closed task
         * @param {number} [contractorId] Filter by contractor id
         * @param {number} [projectId] Filter by project id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminTasksGet(limit?: number, offset?: number, sort?: string, search?: string, isActive?: boolean, status?: string, contractorId?: number, projectId?: number, options?: any): AxiosPromise<InlineResponse20087> {
            return localVarFp.v1AdminTasksGet(limit, offset, sort, search, isActive, status, contractorId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Manages admin\'s notifications
         * @param {ManageAdminNotification} [payload] Payload to manage admin\&#39;s notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersAdminNotificationsManagePut(payload?: ManageAdminNotification, options?: any): AxiosPromise<InlineResponse2005> {
            return localVarFp.v1AdminUsersAdminNotificationsManagePut(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns CSV file with all users assigned to any contractor
         * @param {string} authorization Authorization token
         * @param {string} [clientTimeZone] Client time zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersExportContractorsUsersGet(authorization: string, clientTimeZone?: string, options?: any): AxiosPromise<InlineResponse20046> {
            return localVarFp.v1AdminUsersExportContractorsUsersGet(authorization, clientTimeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assigns the foreman to the project
         * @param {AssignForeman} [payload] Payload for assigning the foreman to the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersForemansAssignToProjectPost(payload?: AssignForeman, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.v1AdminUsersForemansAssignToProjectPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a foreman list
         * @param {number} contractorId The contractor id
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersForemansGet(contractorId: number, limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.v1AdminUsersForemansGet(contractorId, limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes the foreman from the project
         * @param {RemoveForeman} [payload] Payload for removing the foreman from the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersForemansRemoveFromProjectPost(payload?: RemoveForeman, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminUsersForemansRemoveFromProjectPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a user list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {Array<number>} [roleIds] The user role id
         * @param {Array<number>} [contractorIds] The contractor id
         * @param {Array<number>} [manufacturerIds] The manufacturer id
         * @param {string} [search] Search by name, phone, email
         * @param {number} [lastActive] Last active days
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersGet(limit?: number, offset?: number, sort?: string, roleIds?: Array<number>, contractorIds?: Array<number>, manufacturerIds?: Array<number>, search?: string, lastActive?: number, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.v1AdminUsersGet(limit, offset, sort, roleIds, contractorIds, manufacturerIds, search, lastActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersMeGet(options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.v1AdminUsersMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a user message channel list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersMessageChannelsGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse20084> {
            return localVarFp.v1AdminUsersMessageChannelsGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Manages user\'s notifications
         * @param {ManageNotification} [payload] Payload to manage user\&#39;s notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersNotificationsManagePut(payload?: ManageNotification, options?: any): AxiosPromise<InlineResponse2006> {
            return localVarFp.v1AdminUsersNotificationsManagePut(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a user
         * @param {UserCreation} [payload] Payload for creating a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersPost(payload?: UserCreation, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminUsersPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a user role list
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersRolesGet(limit?: number, offset?: number, sort?: string, options?: any): AxiosPromise<InlineResponse2009> {
            return localVarFp.v1AdminUsersRolesGet(limit, offset, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends verification code for admin sign in
         * @param {SendCodePayload} [payload] Payload for sending verification code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersSignInSendCodePost(payload?: SendCodePayload, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.v1AdminUsersSignInSendCodePost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign out an user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersSignOutPost(options?: any): AxiosPromise<InlineResponse20048> {
            return localVarFp.v1AdminUsersSignOutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Manages user\'s admin notifications
         * @param {number} userId The user id
         * @param {ManageAdminNotification} [payload] Payload to manage user\&#39;s admin notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdAdminNotificationsManagePut(userId: number, payload?: ManageAdminNotification, options?: any): AxiosPromise<InlineResponse2005> {
            return localVarFp.v1AdminUsersUserIdAdminNotificationsManagePut(userId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Archives a user
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdArchivePost(userId: number, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.v1AdminUsersUserIdArchivePost(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a user
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdDelete(userId: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminUsersUserIdDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a user
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdGet(userId: number, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.v1AdminUsersUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a user\'s project notifications
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdNotificationsByProjectGet(userId: number, options?: any): AxiosPromise<InlineResponse20079> {
            return localVarFp.v1AdminUsersUserIdNotificationsByProjectGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a user\'s project notifications
         * @param {number} userId The user id
         * @param {UserNotificationPayloadByProject} [payload] Payload to update user\&#39;s project notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdNotificationsByProjectPut(userId: number, payload?: UserNotificationPayloadByProject, options?: any): AxiosPromise<InlineResponse20079> {
            return localVarFp.v1AdminUsersUserIdNotificationsByProjectPut(userId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a user\'s global notifications
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdNotificationsByTypeGet(userId: number, options?: any): AxiosPromise<InlineResponse20078> {
            return localVarFp.v1AdminUsersUserIdNotificationsByTypeGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a user\'s global notifications
         * @param {number} userId The user id
         * @param {UserGlobalNotificationPayloadByType} [payload] Payload to update user\&#39;s global notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdNotificationsByTypePut(userId: number, payload?: UserGlobalNotificationPayloadByType, options?: any): AxiosPromise<InlineResponse20078> {
            return localVarFp.v1AdminUsersUserIdNotificationsByTypePut(userId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates user
         * @param {number} userId The user id
         * @param {UserEditing} [payload] Payload to update user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdPut(userId: number, payload?: UserEditing, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.v1AdminUsersUserIdPut(userId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the user\'s session
         * @param {number} userId The user identifier
         * @param {Session} [payload] Payload to delete the user\&#39;s session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdSessionsDelete(userId: number, payload?: Session, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminUsersUserIdSessionsDelete(userId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns user\'s active sessions
         * @param {number} userId The user identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdSessionsGet(userId: number, options?: any): AxiosPromise<InlineResponse20047> {
            return localVarFp.v1AdminUsersUserIdSessionsGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unarchives a user
         * @param {number} userId The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminUsersUserIdUnarchivePost(userId: number, options?: any): AxiosPromise<InlineResponse20056> {
            return localVarFp.v1AdminUsersUserIdUnarchivePost(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Wagner device firmware
         * @param {number} firmwareId The firmware id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesFirmwaresFirmwareIdDelete(firmwareId: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminWagnerProbesFirmwaresFirmwareIdDelete(firmwareId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a Wagner device firmware details
         * @param {number} firmwareId The firmware id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesFirmwaresFirmwareIdGet(firmwareId: number, options?: any): AxiosPromise<InlineResponse20095> {
            return localVarFp.v1AdminWagnerProbesFirmwaresFirmwareIdGet(firmwareId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Wagner device firmware
         * @param {number} firmwareId The firmware id
         * @param {UpdateFirmwarePayload} updateFirmwarePayload Payload to update a Wagner device firmware
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesFirmwaresFirmwareIdPut(firmwareId: number, updateFirmwarePayload: UpdateFirmwarePayload, options?: any): AxiosPromise<InlineResponse20095> {
            return localVarFp.v1AdminWagnerProbesFirmwaresFirmwareIdPut(firmwareId, updateFirmwarePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a list of Wagner device firmwares
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {'data_grabber' | 'range_extender'} [deviceType] Device type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesFirmwaresGet(limit?: number, offset?: number, deviceType?: 'data_grabber' | 'range_extender', options?: any): AxiosPromise<InlineResponse20094> {
            return localVarFp.v1AdminWagnerProbesFirmwaresGet(limit, offset, deviceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new firmware for Wagner devices
         * @param {CreateFirmwarePayload} createFirmwarePayload Payload to create a new firmware for Wagner devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesFirmwaresPost(createFirmwarePayload: CreateFirmwarePayload, options?: any): AxiosPromise<InlineResponse20095> {
            return localVarFp.v1AdminWagnerProbesFirmwaresPost(createFirmwarePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns wagner probes
         * @param {number} [limit] Limit of rows
         * @param {number} [offset] Offset for rows
         * @param {string} [sort] Sort for rows
         * @param {number} [contractorId] The contractor id
         * @param {number} [projectId] The project id
         * @param {number} [projectSectionId] The project section id
         * @param {boolean} [connectedToProject] The filter by connectivity to project
         * @param {string} [search] The search string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesGet(limit?: number, offset?: number, sort?: string, contractorId?: number, projectId?: number, projectSectionId?: number, connectedToProject?: boolean, search?: string, options?: any): AxiosPromise<InlineResponse20054> {
            return localVarFp.v1AdminWagnerProbesGet(limit, offset, sort, contractorId, projectId, projectSectionId, connectedToProject, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes a wagner probe
         * @param {string} probeId The wagner probe device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesProbeIdDelete(probeId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1AdminWagnerProbesProbeIdDelete(probeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a wagner probe
         * @param {string} probeId The wagner probe device id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesProbeIdGet(probeId: string, options?: any): AxiosPromise<InlineResponse20055> {
            return localVarFp.v1AdminWagnerProbesProbeIdGet(probeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a wagner probe readings
         * @param {string} probeId The wagner probe identifier(probeId)
         * @param {string} [timeFrom] Time from
         * @param {string} [timeTo] Time to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AdminWagnerProbesProbeIdReadingsGet(probeId: string, timeFrom?: string, timeTo?: string, options?: any): AxiosPromise<InlineResponse20062> {
            return localVarFp.v1AdminWagnerProbesProbeIdReadingsGet(probeId, timeFrom, timeTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel attachment upload
         * @param {string} attachmentHashId The attachment hash id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AttachmentsAttachmentHashIdCancelUploadPost(attachmentHashId: string, options?: any): AxiosPromise<InlineResponse200103> {
            return localVarFp.v1AttachmentsAttachmentHashIdCancelUploadPost(attachmentHashId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload photo
         * @param {Array<any>} files Files to upload
         * @param {number} [photoResizeTo] Value to resize photos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MediaFilePut(files: Array<any>, photoResizeTo?: number, options?: any): AxiosPromise<InlineResponse20040> {
            return localVarFp.v1MediaFilePut(files, photoResizeTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload file by chunks
         * @param {string} xFileHashId Attachment hash id
         * @param {string} xFileName Attachment file name
         * @param {number} xFileSize Attachment file size
         * @param {number} xFilePartNumber File chunk number
         * @param {number} xTotalParts File chunks total number
         * @param {any} file 
         * @param {number} [photoResizeTo] Value to resize photos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MediaFileUploadChunksPost(xFileHashId: string, xFileName: string, xFileSize: number, xFilePartNumber: number, xTotalParts: number, file: any, photoResizeTo?: number, options?: any): AxiosPromise<InlineResponse200102> {
            return localVarFp.v1MediaFileUploadChunksPost(xFileHashId, xFileName, xFileSize, xFilePartNumber, xTotalParts, file, photoResizeTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload photo
         * @param {Array<any>} photos Photos to upload
         * @param {number} [size] Value to resize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MediaPhotoPut(photos: Array<any>, size?: number, options?: any): AxiosPromise<InlineResponse20039> {
            return localVarFp.v1MediaPhotoPut(photos, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify code and sign in user
         * @param {SignInPayload} [payload] Payload for verify code and sign in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersSignInPost(payload?: SignInPayload, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.v1UsersSignInPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign out a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersSignOutPost(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1UsersSignOutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates contractor
         * @param {number} contractorId The contractor id
         * @param {ContractorEditingV2} [payload] Payload to update contractor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AdminContractorsContractorIdPut(contractorId: number, payload?: ContractorEditingV2, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.v2AdminContractorsContractorIdPut(contractorId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates contractor
         * @param {ContractorCreationV2} [payload] Payload to create contractor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AdminContractorsPost(payload?: ContractorCreationV2, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.v2AdminContractorsPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns aggregated timeseries gap data
         * @param {string} dateFrom Date from
         * @param {string} [dateTo] Date to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AdminDiagnosticsTimeseriesGapGet(dateFrom: string, dateTo?: string, options?: any): AxiosPromise<InlineResponse200107> {
            return localVarFp.v2AdminDiagnosticsTimeseriesGapGet(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a user
         * @param {UserCreation} [payload] Payload for creating a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AdminUsersPost(payload?: UserCreation, options?: any): AxiosPromise<InlineResponse20057> {
            return localVarFp.v2AdminUsersPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign out a user
         * @param {UserSignOutPayloadV2} userSignOutPayloadV2 Payload for user sign out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersSignOutPost(userSignOutPayloadV2: UserSignOutPayloadV2, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v2UsersSignOutPost(userSignOutPayloadV2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminAPIApi - object-oriented interface
 * @export
 * @class AdminAPIApi
 * @extends {BaseAPI}
 */
export class AdminAPIApi extends BaseAPI {
    /**
     * 
     * @summary Return a list of alerts
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {string} [search] Search by name and description
     * @param {number} [projectId] The project id
     * @param {number} [projectSectionId] The project section id
     * @param {number} [contractorId] The contractor id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminAlertsGet(limit?: number, offset?: number, sort?: string, search?: string, projectId?: number, projectSectionId?: number, contractorId?: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminAlertsGet(limit, offset, sort, search, projectId, projectSectionId, contractorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a single attachment
     * @param {number} attachmentId ID of attachment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminAttachmentsAttachmentIdGet(attachmentId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminAttachmentsAttachmentIdGet(attachmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a list of attachments
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {'pending' | 'processing' | 'processed' | 'failed' | 'cancelled'} [status] Status of attachments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminAttachmentsGet(limit?: number, offset?: number, sort?: string, status?: 'pending' | 'processing' | 'processed' | 'failed' | 'cancelled', options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminAttachmentsGet(limit, offset, sort, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns building type list.
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminBuildingTypesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminBuildingTypesGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new building type
     * @param {CreateBuildingTypePayload} [payload] Payload for building type creation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminBuildingTypesPost(payload?: CreateBuildingTypePayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminBuildingTypesPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a true if building type successfully deleted
     * @param {number} typeId The building type ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminBuildingTypesTypeIdDelete(typeId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminBuildingTypesTypeIdDelete(typeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a building type state.
     * @param {number} typeId The building type ID
     * @param {UpdateBuildingTypePayload} [payload] Payload for updating building type state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminBuildingTypesTypeIdPatch(typeId: number, payload?: UpdateBuildingTypePayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminBuildingTypesTypeIdPatch(typeId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns configuration settings
     * @param {number} configParamId The cofiguration param identifier
     * @param {ConfigurationParamUpdate} [payload] Payload to update configuration param
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminConfigurationConfigParamIdPut(configParamId: number, payload?: ConfigurationParamUpdate, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminConfigurationConfigParamIdPut(configParamId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns configuration settings
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminConfigurationGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminConfigurationGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns mobile settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminConfigurationMobileSettingsGet(options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminConfigurationMobileSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a mobile settings
     * @param {UpdateMobileSettings} [updateMobileSettings] Payload for updating a mobile settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminConfigurationMobileSettingsPut(updateMobileSettings?: UpdateMobileSettings, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminConfigurationMobileSettingsPut(updateMobileSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bulk update configuration settings
     * @param {Array<ConfigurationParamUpdateBulkItem>} [payload] Payload to update configuration parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminConfigurationPut(payload?: Array<ConfigurationParamUpdateBulkItem>, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminConfigurationPut(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of staging devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminConfigurationSensorAllocationGet(options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminConfigurationSensorAllocationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update list of staging devices
     * @param {UpdateStagingDevices} [updateStagingDevices] Payload for updating list of staging devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminConfigurationSensorAllocationPut(updateStagingDevices?: UpdateStagingDevices, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminConfigurationSensorAllocationPut(updateStagingDevices, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activates contractor
     * @param {number} contractorId The contractor id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminContractorsContractorIdActivatePost(contractorId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminContractorsContractorIdActivatePost(contractorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivates contractor
     * @param {number} contractorId The contractor id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminContractorsContractorIdDeactivatePost(contractorId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminContractorsContractorIdDeactivatePost(contractorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrurns contractor
     * @param {number} contractorId The contractor id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminContractorsContractorIdGet(contractorId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminContractorsContractorIdGet(contractorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the contractor paid plan
     * @param {number} contractorId The contractor id
     * @param {UpdateContractorPaidPlan} payload Payload for updating contractor paid plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminContractorsContractorIdPaidPlanPut(contractorId: number, payload: UpdateContractorPaidPlan, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminContractorsContractorIdPaidPlanPut(contractorId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates contractor
     * @param {number} contractorId The contractor id
     * @param {ContractorEditing} [payload] Payload to update contractor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminContractorsContractorIdPut(contractorId: number, payload?: ContractorEditing, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminContractorsContractorIdPut(contractorId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of contractor tokens history
     * @param {number} contractorId The contractor ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminContractorsContractorIdTokensHistoryGet(contractorId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminContractorsContractorIdTokensHistoryGet(contractorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of contractors
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {string} [name] Filter by contractors name
     * @param {string} [search] Search by name, phone, email, address
     * @param {boolean} [isActivePaidPlan] Filter by the status of a paid plan
     * @param {boolean} [isActiveTrial] Filter by the status of a trial period
     * @param {boolean} [isRoot] Filter by the root sign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminContractorsGet(limit?: number, offset?: number, sort?: string, name?: string, search?: string, isActivePaidPlan?: boolean, isActiveTrial?: boolean, isRoot?: boolean, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminContractorsGet(limit, offset, sort, name, search, isActivePaidPlan, isActiveTrial, isRoot, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates contractor
     * @param {ContractorCreation} [payload] Payload to create contractor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminContractorsPost(payload?: ContractorCreation, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminContractorsPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a statistics for dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminDashboardGet(options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminDashboardGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Parse rapid rh advertisement packet
     * @param {ParseAdvPacketPayload} payload Payload parse a packet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminDiagnosticsParseAdvPacketPost(payload: ParseAdvPacketPayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminDiagnosticsParseAdvPacketPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns aggregated timeseries gap data
     * @param {string} dateFrom Date from
     * @param {string} [dateTo] Date to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminDiagnosticsTimeseriesGapGet(dateFrom: string, dateTo?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminDiagnosticsTimeseriesGapGet(dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns aggregated timeseries handling gap data
     * @param {string} dateFrom Date from
     * @param {string} [dateTo] Date to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminDiagnosticsTimeseriesHandlingGapGet(dateFrom: string, dateTo?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminDiagnosticsTimeseriesHandlingGapGet(dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a list of documents
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {string} [search] Search by name and description
     * @param {number} [projectId] The project id
     * @param {number} [contractorId] The contractor id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminDocumentsGet(limit?: number, offset?: number, sort?: string, search?: string, projectId?: number, contractorId?: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminDocumentsGet(limit, offset, sort, search, projectId, contractorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of events
     * @param {number} eventId The event identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminEventsEventIdGet(eventId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminEventsEventIdGet(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of events
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {number} [projectId] The project ID
     * @param {number} [userId] The user ID
     * @param {string} [typeId] The event type ID
     * @param {boolean} [hideServiceAccount] The attribute to hide or not events created by service account
     * @param {number} [contractorId] The contractor ID
     * @param {number} [sensorId] The sensor ID
     * @param {boolean} [withPhotos] The attribute to return events with photos or not
     * @param {number} [rapidRhProbeId] The rapid RH probe ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminEventsGet(limit?: number, offset?: number, sort?: string, projectId?: number, userId?: number, typeId?: string, hideServiceAccount?: boolean, contractorId?: number, sensorId?: number, withPhotos?: boolean, rapidRhProbeId?: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminEventsGet(limit, offset, sort, projectId, userId, typeId, hideServiceAccount, contractorId, sensorId, withPhotos, rapidRhProbeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update event type by id
     * @param {number} eventTypeId Event type Id
     * @param {EventTypeUpdatePayload} [payload] Payload for update event type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminEventsTypesEventTypeIdPatch(eventTypeId: number, payload?: EventTypeUpdatePayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminEventsTypesEventTypeIdPatch(eventTypeId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns event types list.
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminEventsTypesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminEventsTypesGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of labels
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminLabelsGet(sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminLabelsGet(sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a label
     * @param {LabelCreation} [payload] Payload to create a label
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminLabelsPost(payload?: LabelCreation, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminLabelsPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a manufacturer announcement category
     * @param {number} categoryId The category id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersAnnouncementsCategoriesCategoryIdDelete(categoryId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersAnnouncementsCategoriesCategoryIdDelete(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a manufacturer announcement category
     * @param {number} categoryId The category id
     * @param {UpdateManufacturerAnnouncementCategoryPayload} updateManufacturerAnnouncementCategoryPayload Payload to update a manufacturer announcement category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersAnnouncementsCategoriesCategoryIdPut(categoryId: number, updateManufacturerAnnouncementCategoryPayload: UpdateManufacturerAnnouncementCategoryPayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersAnnouncementsCategoriesCategoryIdPut(categoryId, updateManufacturerAnnouncementCategoryPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a list of manufacturer announcement categories
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersAnnouncementsCategoriesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersAnnouncementsCategoriesGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new manufacturer announcement category
     * @param {CreateManufacturerAnnouncementCategoryPayload} createManufacturerAnnouncementCategoryPayload Payload to create a new manufacturer announcement category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersAnnouncementsCategoriesPost(createManufacturerAnnouncementCategoryPayload: CreateManufacturerAnnouncementCategoryPayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersAnnouncementsCategoriesPost(createManufacturerAnnouncementCategoryPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of manufacturer announcements
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersAnnouncementsGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersAnnouncementsGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a manufacturer announcement
     * @param {number} manufacturerAnnouncementId The manufacturer announcement id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdDelete(manufacturerAnnouncementId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdDelete(manufacturerAnnouncementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a manufacturer announcement
     * @param {number} manufacturerAnnouncementId The manufacturer announcement id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdGet(manufacturerAnnouncementId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdGet(manufacturerAnnouncementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a manufacturer announcement
     * @param {number} manufacturerAnnouncementId The manufacturer announcement id
     * @param {UpdateManufacturerAnnouncementPayload} [payload] Payload to update a manufacturer announcement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdPut(manufacturerAnnouncementId: number, payload?: UpdateManufacturerAnnouncementPayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdPut(manufacturerAnnouncementId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a manufacturer announcement
     * @param {CreateManufacturerAnnouncementPayload} [payload] Payload to create a manufacturer announcement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersAnnouncementsPost(payload?: CreateManufacturerAnnouncementPayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersAnnouncementsPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of manufacturer announcement types
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersAnnouncementsTypesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersAnnouncementsTypesGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new manufacturer announcement type
     * @param {CreateManufacturerAnnouncementTypePayload} createManufacturerAnnouncementTypePayload Payload to create a new manufacturer announcement type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersAnnouncementsTypesPost(createManufacturerAnnouncementTypePayload: CreateManufacturerAnnouncementTypePayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersAnnouncementsTypesPost(createManufacturerAnnouncementTypePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a manufacturer announcement type
     * @param {number} typeId The type id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersAnnouncementsTypesTypeIdDelete(typeId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersAnnouncementsTypesTypeIdDelete(typeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a manufacturer announcement type
     * @param {number} typeId The type id
     * @param {UpdateManufacturerAnnouncementTypePayload} updateManufacturerAnnouncementTypePayload Payload to update a manufacturer announcement type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersAnnouncementsTypesTypeIdPut(typeId: number, updateManufacturerAnnouncementTypePayload: UpdateManufacturerAnnouncementTypePayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersAnnouncementsTypesTypeIdPut(typeId, updateManufacturerAnnouncementTypePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a manufacturer list
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {string} [name] Filter by name
     * @param {string} [search] Search by name, phone, email, address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersGet(limit?: number, offset?: number, sort?: string, name?: string, search?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersGet(limit, offset, sort, name, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a manufacturer
     * @param {number} manufacturerId The manufacturer id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersManufacturerIdDelete(manufacturerId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersManufacturerIdDelete(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrurns manufacturer
     * @param {number} manufacturerId The manufacturer id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersManufacturerIdGet(manufacturerId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersManufacturerIdGet(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates manufacturer
     * @param {number} manufacturerId The manufacturer id
     * @param {ManufacturerEditing} [payload] Payload to update manufacturer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersManufacturerIdPut(manufacturerId: number, payload?: ManufacturerEditing, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersManufacturerIdPut(manufacturerId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a manufacturer and returns the new one.
     * @param {ManufacturerCreation} [payload] Payload for creating a manufacturer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminManufacturersPost(payload?: ManufacturerCreation, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminManufacturersPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the product.
     * @param {number} draftId The draft id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsDraftsDraftIdDelete(draftId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsDraftsDraftIdDelete(draftId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a products\' drafts.
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {number} [manufacturerId] Manufacturer ID
     * @param {number} [typeId] Product type ID
     * @param {string} [name] The product name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsDraftsGet(limit?: number, offset?: number, sort?: string, manufacturerId?: number, typeId?: number, name?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsDraftsGet(limit, offset, sort, manufacturerId, typeId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a product draft and returns the new one.
     * @param {ProductDraftCreation} [payload] Payload for creating a product draft
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsDraftsPost(payload?: ProductDraftCreation, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsDraftsPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publishes a products\' drafts.
     * @param {ProductsDraftsPublishBulk} [payload] Payload for publishing a products\&#39; drafts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsDraftsPublishPost(payload?: ProductsDraftsPublishBulk, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsDraftsPublishPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates products\' drafts.
     * @param {Array<ProductsDraftsUpdate>} [payload] Payload for updating a product draft
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsDraftsPut(payload?: Array<ProductsDraftsUpdate>, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsDraftsPut(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a product list.
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {number} [manufacturerId] Manufacturer ID
     * @param {number} [typeId] Product type ID
     * @param {string} [name] The product name
     * @param {number} [projectId] The project ID
     * @param {boolean} [noShelfLife] Is shelfLife field eq null or not
     * @param {boolean} [noRanges] Is some of lowHumidity, highHumidity, lowTempF, highTempF fields eq null or not
     * @param {boolean} [noTips] Is product tips count eq 0 or not
     * @param {boolean} [inUse] Is project product count eq 0 or not
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsGet(limit?: number, offset?: number, sort?: string, manufacturerId?: number, typeId?: number, name?: string, projectId?: number, noShelfLife?: boolean, noRanges?: boolean, noTips?: boolean, inUse?: boolean, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsGet(limit, offset, sort, manufacturerId, typeId, name, projectId, noShelfLife, noRanges, noTips, inUse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a list of product imports
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsImportHistoryGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsImportHistoryGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a product import details
     * @param {number} importHistoryId The import history item id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsImportHistoryImportHistoryIdGet(importHistoryId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsImportHistoryImportHistoryIdGet(importHistoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a link for downloading an import products template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsImportTemplateLinkGet(options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsImportTemplateLinkGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Merges products
     * @param {MergeProductsPayload} payload Payload to merge products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsMergePost(payload: MergeProductsPayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsMergePost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a product package types
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsPackageTypesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsPackageTypesGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a product and returns the new one.
     * @param {ProductCreation} [payload] Payload for creating a product
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsPost(payload?: ProductCreation, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a true if product successfully deleted
     * @param {number} productId The product ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsProductIdDelete(productId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsProductIdDelete(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a single product
     * @param {number} productId The product ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsProductIdGet(productId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a product and returns it.
     * @param {number} productId The product ID
     * @param {ProductEditing} [payload] Payload for updating a product
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsProductIdPut(productId: number, payload?: ProductEditing, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsProductIdPut(productId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of product requests
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsRequestsGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsRequestsGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns number of new product requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsRequestsNumberOfNewGet(options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsRequestsNumberOfNewGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a product request
     * @param {number} productRequestId The product request identifier
     * @param {UpdateProductRequestPayload} [updateProductRequestPayload] Payload to update a product request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsRequestsProductRequestIdPut(productRequestId: number, updateProductRequestPayload?: UpdateProductRequestPayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsRequestsProductRequestIdPut(productRequestId, updateProductRequestPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a product request summary
     * @param {number} productRequestId The product request identifier
     * @param {ProductRequestSummaryPayload} [productRequestSummaryPayload] Payload to update a product request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsRequestsProductRequestIdSendSummaryPost(productRequestId: number, productRequestSummaryPayload?: ProductRequestSummaryPayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsRequestsProductRequestIdSendSummaryPost(productRequestId, productRequestSummaryPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of product request statuses
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsRequestsStatusesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsRequestsStatusesGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a product tip types
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsTipTypesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsTipTypesGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a product tips.
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {number} [tipTypeId] The tip\&#39;s type ID
     * @param {number} [productId] The product ID
     * @param {number} [productDraftId] The product\&#39;s draft ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsTipsCustomGet(limit?: number, offset?: number, sort?: string, tipTypeId?: number, productId?: number, productDraftId?: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsTipsCustomGet(limit, offset, sort, tipTypeId, productId, productDraftId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a product types\' tips.
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {number} [tipTypeId] The tip\&#39;s type ID
     * @param {number} [productTypeId] The product\&#39;s type ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsTipsDefaultGet(limit?: number, offset?: number, sort?: string, tipTypeId?: number, productTypeId?: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsTipsDefaultGet(limit, offset, sort, tipTypeId, productTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a product types
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {string} [withTips] Send product types with or without tips
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsTypesGet(limit?: number, offset?: number, sort?: string, withTips?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsTypesGet(limit, offset, sort, withTips, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new product type
     * @param {ProductTypeCreation} [payload] Payload for the product type creation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsTypesPost(payload?: ProductTypeCreation, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsTypesPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the product type
     * @param {number} productTypeId The product type ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsTypesProductTypeIdDelete(productTypeId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsTypesProductTypeIdDelete(productTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the product type
     * @param {number} productTypeId The product type ID
     * @param {ProductTypeUpdate} [payload] Payload to update the product type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsTypesProductTypeIdPut(productTypeId: number, payload?: ProductTypeUpdate, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsTypesProductTypeIdPut(productTypeId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the product type tips
     * @param {number} productTypeId The product type ID
     * @param {ProductTypeTipsUpdate} [payload] Payload to update the product type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsTypesProductTypeIdTipsPut(productTypeId: number, payload?: ProductTypeTipsUpdate, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsTypesProductTypeIdTipsPut(productTypeId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload xlsx
     * @param {any} xlsx Xlsx to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProductsUploadPut(xlsx: any, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProductsUploadPut(xlsx, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of project sections.
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {number} [projectId] Project ID
     * @param {boolean} [onlyActiveSections] Filter by the project section status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProjectSectionsGet(limit?: number, offset?: number, sort?: string, projectId?: number, onlyActiveSections?: boolean, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProjectSectionsGet(limit, offset, sort, projectId, onlyActiveSections, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns single project section by id
     * @param {number} projectSectionId The project section id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProjectSectionsProjectSectionIdGet(projectSectionId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProjectSectionsProjectSectionIdGet(projectSectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of projects.
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {number} [contractorId] Contractor ID
     * @param {number} [typeId] Type ID
     * @param {string} [city] City of the project
     * @param {number} [productId] Product ID
     * @param {number} [userId] User ID
     * @param {string} [search] Search by project name and location
     * @param {boolean} [isActive] Filter by the project status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProjectsGet(limit?: number, offset?: number, sort?: string, contractorId?: number, typeId?: number, city?: string, productId?: number, userId?: number, search?: string, isActive?: boolean, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProjectsGet(limit, offset, sort, contractorId, typeId, city, productId, userId, search, isActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns single project by id
     * @param {number} projectId The project id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProjectsProjectIdGet(projectId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProjectsProjectIdGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of projects types
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProjectsTypesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProjectsTypesGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new project type
     * @param {CreateProjectTypePayload} [payload] Payload for project type creation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProjectsTypesPost(payload?: CreateProjectTypePayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProjectsTypesPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a true if project type successfully deleted
     * @param {number} typeId The project type ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProjectsTypesTypeIdDelete(typeId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProjectsTypesTypeIdDelete(typeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a project type state.
     * @param {number} typeId The project type ID
     * @param {UpdateProjectTypePayload} [payload] Payload for updating project type state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminProjectsTypesTypeIdPatch(typeId: number, payload?: UpdateProjectTypePayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminProjectsTypesTypeIdPatch(typeId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a release list
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminReleasesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminReleasesGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a release and returns the new one.
     * @param {ReleaseCreation} [payload] Payload for creating a release
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminReleasesPost(payload?: ReleaseCreation, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminReleasesPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes release
     * @param {number} releaseId The release id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminReleasesReleaseIdDelete(releaseId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminReleasesReleaseIdDelete(releaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrurns release
     * @param {number} releaseId The release id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminReleasesReleaseIdGet(releaseId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminReleasesReleaseIdGet(releaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates release
     * @param {number} releaseId The release id
     * @param {ReleaseEditing} [payload] Payload to update release
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminReleasesReleaseIdPut(releaseId: number, payload?: ReleaseEditing, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminReleasesReleaseIdPut(releaseId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return components for combined reports
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminReportsCombinedReportsComponentsGet(options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminReportsCombinedReportsComponentsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update component for combined reports
     * @param {number} reportComponentId The report component id
     * @param {CombinedReportComponentUpdatePayload} combinedReportComponentUpdatePayload Payload to update component for combined reports
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminReportsCombinedReportsComponentsReportComponentIdPatch(reportComponentId: number, combinedReportComponentUpdatePayload: CombinedReportComponentUpdatePayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminReportsCombinedReportsComponentsReportComponentIdPatch(reportComponentId, combinedReportComponentUpdatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns type of included events for combined reports
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminReportsCombinedReportsIncludedEventsGet(options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminReportsCombinedReportsIncludedEventsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update type of included events for combined reports
     * @param {CombinedReportIncludedEventUpdatePayload} combinedReportIncludedEventUpdatePayload Payload to update type of included events for combined reports
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminReportsCombinedReportsIncludedEventsPut(combinedReportIncludedEventUpdatePayload: CombinedReportIncludedEventUpdatePayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminReportsCombinedReportsIncludedEventsPut(combinedReportIncludedEventUpdatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary The ednpoint returns list of reports
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {number} [contractorId] Filter by contractor id
     * @param {number} [projectId] Filter by project id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminReportsGet(limit?: number, offset?: number, sort?: string, contractorId?: number, projectId?: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminReportsGet(limit, offset, sort, contractorId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Regenerate a report\'s link and return updated report
     * @param {number} reportId The report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminReportsReportIdRegenerateLinkPost(reportId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminReportsReportIdRegenerateLinkPost(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of report types
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminReportsTypesGet(sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminReportsTypesGet(sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of report types
     * @param {number} reportTypeId The report type identifier
     * @param {UpdateReportTypePayload} [updateReportTypePayload] Payload to update a report type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminReportsTypesReportTypeIdPut(reportTypeId: number, updateReportTypePayload?: UpdateReportTypePayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminReportsTypesReportTypeIdPut(reportTypeId, updateReportTypePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends a request to calculate a sensor technical status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsCalculateTechnicalStatusPost(options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsCalculateTechnicalStatusPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a default sensor configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsDefaultConfigurationGet(options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsDefaultConfigurationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a default sensor configuration
     * @param {SensorConfiguration} [payload] Payload for updating a default sensor configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsDefaultConfigurationPut(payload?: SensorConfiguration, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsDefaultConfigurationPut(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fills sensors missed readings
     * @param {FillMissedReadingsBulkPayload} [fillMissedReadingsBulkPayload] Payload to fill sensors missed readings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsFillMissedReadingsPost(fillMissedReadingsBulkPayload?: FillMissedReadingsBulkPayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsFillMissedReadingsPost(fillMissedReadingsBulkPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of sensors firmware versions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsFirmwareVersionsGet(options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsFirmwareVersionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns ZIP file with generated lables for sensors
     * @param {string} sensorUniqueIds The sensors unique identifiers (uniqueId)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsGenerateLabelsGet(sensorUniqueIds: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsGenerateLabelsGet(sensorUniqueIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of sensors.
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {number} [projectId] Project ID
     * @param {number} [contractorId] Contractor ID
     * @param {number} [stateId] State ID
     * @param {string} [lastHeard] The last time the sensor sent data
     * @param {string} [lastPing] The updatedAt of the sensor current data
     * @param {string} [synchronizationPeriod] The synchronization period of a sensor, in seconds
     * @param {string} [accelerationTime] The last time of the acceleration readings
     * @param {string} [batteryTime] The last time of the battery readings
     * @param {string} [dewpointTime] The last time of the dewpoint readings
     * @param {string} [humidityTime] The last time of the humidity readings
     * @param {string} [lightTime] The last time of the light readings
     * @param {string} [locationTime] The last time of the cellular location readings
     * @param {string} [locationGpsTime] The last time of the gps location readings
     * @param {string} [orientationTime] The last time of the orientation readings
     * @param {string} [pressureTime] The last time of the pressure readings
     * @param {string} [signalStrengthTime] The last time of the signal strength readings
     * @param {string} [temperatureTime] The last time of the temperature readings
     * @param {number} [batteryLevel] Battery charge percentage
     * @param {number} [signalStrength] The signal strength level
     * @param {string} [search] Search by Sensor ID, IMEI, Dev EUI
     * @param {string} [currentSensorConfiguration] Type of a sensor\&#39;s configuration
     * @param {boolean} [isBleEnabled] Filter by status of BLE module
     * @param {string} [firmware] Filter by sensor firmware version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsGet(limit?: number, offset?: number, sort?: string, projectId?: number, contractorId?: number, stateId?: number, lastHeard?: string, lastPing?: string, synchronizationPeriod?: string, accelerationTime?: string, batteryTime?: string, dewpointTime?: string, humidityTime?: string, lightTime?: string, locationTime?: string, locationGpsTime?: string, orientationTime?: string, pressureTime?: string, signalStrengthTime?: string, temperatureTime?: string, batteryLevel?: number, signalStrength?: number, search?: string, currentSensorConfiguration?: string, isBleEnabled?: boolean, firmware?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsGet(limit, offset, sort, projectId, contractorId, stateId, lastHeard, lastPing, synchronizationPeriod, accelerationTime, batteryTime, dewpointTime, humidityTime, lightTime, locationTime, locationGpsTime, orientationTime, pressureTime, signalStrengthTime, temperatureTime, batteryLevel, signalStrength, search, currentSensorConfiguration, isBleEnabled, firmware, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return GPX waypoints file with for all sensors
     * @param {'gps' | 'cellid'} type Location type
     * @param {string} [authorization] Authorization token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsGpxWaypointsDataGet(type: 'gps' | 'cellid', authorization?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsGpxWaypointsDataGet(type, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of sensors for map.
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {number} [contractorId] Contractor ID
     * @param {number} [stateId] State ID
     * @param {string} [lastHeard] The last time the sensor sent data
     * @param {number} [batteryLevel] Battery charge percentage
     * @param {string} [search] Search by Sensor ID, IMEI, Dev EUI
     * @param {string} [lastPing] The updatedAt of the sensor current data
     * @param {string} [synchronizationPeriod] The synchronization period of a sensor, in seconds
     * @param {string} [accelerationTime] The last time of the acceleration readings
     * @param {string} [batteryTime] The last time of the battery readings
     * @param {string} [dewpointTime] The last time of the dewpoint readings
     * @param {string} [humidityTime] The last time of the humidity readings
     * @param {string} [lightTime] The last time of the light readings
     * @param {string} [locationTime] The last time of the cellular location readings
     * @param {string} [locationGpsTime] The last time of the gps location readings
     * @param {string} [orientationTime] The last time of the orientation readings
     * @param {string} [pressureTime] The last time of the pressure readings
     * @param {string} [signalStrengthTime] The last time of the signal strength readings
     * @param {string} [temperatureTime] The last time of the temperature readings
     * @param {number} [signalStrength] The signal strength level
     * @param {string} [currentSensorConfiguration] Type of a sensor\&#39;s configuration
     * @param {boolean} [isBleEnabled] Filter by status of BLE module
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsMapViewGet(limit?: number, offset?: number, sort?: string, contractorId?: number, stateId?: number, lastHeard?: string, batteryLevel?: number, search?: string, lastPing?: string, synchronizationPeriod?: string, accelerationTime?: string, batteryTime?: string, dewpointTime?: string, humidityTime?: string, lightTime?: string, locationTime?: string, locationGpsTime?: string, orientationTime?: string, pressureTime?: string, signalStrengthTime?: string, temperatureTime?: string, signalStrength?: number, currentSensorConfiguration?: string, isBleEnabled?: boolean, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsMapViewGet(limit, offset, sort, contractorId, stateId, lastHeard, batteryLevel, search, lastPing, synchronizationPeriod, accelerationTime, batteryTime, dewpointTime, humidityTime, lightTime, locationTime, locationGpsTime, orientationTime, pressureTime, signalStrengthTime, temperatureTime, signalStrength, currentSensorConfiguration, isBleEnabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a sensor state list.
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsStatesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsStatesGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns thingspace sensors list
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {string} [search] Sensor\&#39;s imei for searching
     * @param {string} [isAvailableInSystem] Returns thingspace sensors which are available in system or no
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsThingspaceSensorsGet(limit?: number, offset?: number, sort?: string, search?: string, isAvailableInSystem?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsThingspaceSensorsGet(limit, offset, sort, search, isAvailableInSystem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Turn off BLE for a batch of sensors
     * @param {ManageSensorBLEStatePayload} manageSensorBLEStatePayload Payload to turn off BLE for a batch of sensors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsTurnOffBlePost(manageSensorBLEStatePayload: ManageSensorBLEStatePayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsTurnOffBlePost(manageSensorBLEStatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Turn on BLE for a batch of sensors
     * @param {ManageSensorBLEStatePayload} manageSensorBLEStatePayload Payload to turn on BLE for a batch of sensors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsTurnOnBlePost(manageSensorBLEStatePayload: ManageSensorBLEStatePayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsTurnOnBlePost(manageSensorBLEStatePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assigns sensor to specific contractor
     * @param {string} uniqueId The device unique identifier(uniqueId)
     * @param {SensorAssignToContractorPayload} [payload] Payload for assigning sensor to contractor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUniqueIdAssignToContractorPost(uniqueId: string, payload?: SensorAssignToContractorPayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUniqueIdAssignToContractorPost(uniqueId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the backup uplinks for the sensor
     * @param {string} uniqueId The sensor unique id
     * @param {string} [date] The backup uplink date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUniqueIdBackupUplinksGet(uniqueId: string, date?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUniqueIdBackupUplinksGet(uniqueId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a sensor configuration
     * @param {string} uniqueId The device unique identifier(uniqueId)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUniqueIdConfigurationGet(uniqueId: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUniqueIdConfigurationGet(uniqueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Setups a sensor configuration
     * @param {string} uniqueId The device unique identifier(uniqueId)
     * @param {SensorConfiguration} [payload] Payload for a sensor configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUniqueIdConfigurationPost(uniqueId: string, payload?: SensorConfiguration, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUniqueIdConfigurationPost(uniqueId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Configures sensor
     * @param {string} uniqueId The device unique identifier(uniqueId)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUniqueIdConfigurePost(uniqueId: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUniqueIdConfigurePost(uniqueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the sensor extended diagnostics information
     * @param {string} uniqueId The sensor unique id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUniqueIdExtendedDiagnosticsGet(uniqueId: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUniqueIdExtendedDiagnosticsGet(uniqueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks and fills missed readings
     * @param {string} uniqueId The sensor unique id (uniqueId)
     * @param {FillMissedReadingsPayload} [fillMissedReadingsPayload] Payload to check and fill missed readings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUniqueIdFillMissedReadingsPost(uniqueId: string, fillMissedReadingsPayload?: FillMissedReadingsPayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUniqueIdFillMissedReadingsPost(uniqueId, fillMissedReadingsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns sensor with new firmware version
     * @param {string} uniqueId The device unique identifier(uniqueId)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUniqueIdFirmwareVersionGet(uniqueId: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUniqueIdFirmwareVersionGet(uniqueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a sensor.
     * @param {string} uniqueId The device unique identifier(uniqueId)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUniqueIdGet(uniqueId: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUniqueIdGet(uniqueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns GPX file with location data
     * @param {string} uniqueId The unique id of a sensor
     * @param {'gps' | 'cellid'} type Location type
     * @param {string} dateFrom Date from
     * @param {string} [dateTo] Date to
     * @param {string} [authorization] Authorization token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUniqueIdGpxDataGet(uniqueId: string, type: 'gps' | 'cellid', dateFrom: string, dateTo?: string, authorization?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUniqueIdGpxDataGet(uniqueId, type, dateFrom, dateTo, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of past projects for a sensor
     * @param {string} uniqueId The sensor unique id (uniqueId)
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUniqueIdPastProjectsGet(uniqueId: string, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUniqueIdPastProjectsGet(uniqueId, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a sensor state.
     * @param {string} uniqueId The device unique identifier(uniqueId)
     * @param {SensorUpdateState} [payload] Payload for updating a sensor state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUniqueIdPatch(uniqueId: string, payload?: SensorUpdateState, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUniqueIdPatch(uniqueId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a sensor readout
     * @param {string} uniqueId The device unique identifier(uniqueId)
     * @param {string} [timeFrom] Time from
     * @param {string} [timeTo] Time to
     * @param {'temperature' | 'humidity' | 'battery' | 'signalStrength' | 'light' | 'pressure' | 'cellularLocation' | 'gpsLocation' | 'ble'} [reading] Kind of reading
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUniqueIdReadoutGet(uniqueId: string, timeFrom?: string, timeTo?: string, reading?: 'temperature' | 'humidity' | 'battery' | 'signalStrength' | 'light' | 'pressure' | 'cellularLocation' | 'gpsLocation' | 'ble', options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUniqueIdReadoutGet(uniqueId, timeFrom, timeTo, reading, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of related rapid RH probes
     * @param {string} uniqueId The device unique identifier(uniqueId)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUniqueIdRelatedRapidRhProbesGet(uniqueId: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUniqueIdRelatedRapidRhProbesGet(uniqueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary The endpoint retrieves sensor firmware information
     * @param {string} uniqueId The device unique identifier(uniqueId)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUniqueIdRetrieveSensorFirmwarePost(uniqueId: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUniqueIdRetrieveSensorFirmwarePost(uniqueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns aggregated list of unstable sensors
     * @param {string} [timeFrom] Time from
     * @param {string} [timeTo] Time to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUnstableSensorsChartGet(timeFrom?: string, timeTo?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUnstableSensorsChartGet(timeFrom, timeTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of unstable sensors
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {string} [search] Search by device id (imei, etc.)
     * @param {string} [datetimeFrom] Datetime from
     * @param {string} [datetimeTo] Datetime to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSensorsUnstableSensorsGet(limit?: number, offset?: number, sort?: string, search?: string, datetimeFrom?: string, datetimeTo?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSensorsUnstableSensorsGet(limit, offset, sort, search, datetimeFrom, datetimeTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return system messages history
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSystemMessagesHistoryGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSystemMessagesHistoryGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send system messages via push or email
     * @param {SystemMessagePayload} [payload] Payload for sending system messages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSystemMessagesSendPost(payload?: SystemMessagePayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSystemMessagesSendPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns system message transport types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSystemMessagesTransportTypesGet(options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSystemMessagesTransportTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns system message types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminSystemMessagesTypesGet(options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminSystemMessagesTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a list of tasks
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {string} [search] Search by name, description, author, assignee, contractor, project and section
     * @param {boolean} [isActive] Active or archived task
     * @param {string} [status] Active or closed task
     * @param {number} [contractorId] Filter by contractor id
     * @param {number} [projectId] Filter by project id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminTasksGet(limit?: number, offset?: number, sort?: string, search?: string, isActive?: boolean, status?: string, contractorId?: number, projectId?: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminTasksGet(limit, offset, sort, search, isActive, status, contractorId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Manages admin\'s notifications
     * @param {ManageAdminNotification} [payload] Payload to manage admin\&#39;s notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersAdminNotificationsManagePut(payload?: ManageAdminNotification, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersAdminNotificationsManagePut(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns CSV file with all users assigned to any contractor
     * @param {string} authorization Authorization token
     * @param {string} [clientTimeZone] Client time zone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersExportContractorsUsersGet(authorization: string, clientTimeZone?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersExportContractorsUsersGet(authorization, clientTimeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assigns the foreman to the project
     * @param {AssignForeman} [payload] Payload for assigning the foreman to the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersForemansAssignToProjectPost(payload?: AssignForeman, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersForemansAssignToProjectPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a foreman list
     * @param {number} contractorId The contractor id
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersForemansGet(contractorId: number, limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersForemansGet(contractorId, limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes the foreman from the project
     * @param {RemoveForeman} [payload] Payload for removing the foreman from the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersForemansRemoveFromProjectPost(payload?: RemoveForeman, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersForemansRemoveFromProjectPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a user list
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {Array<number>} [roleIds] The user role id
     * @param {Array<number>} [contractorIds] The contractor id
     * @param {Array<number>} [manufacturerIds] The manufacturer id
     * @param {string} [search] Search by name, phone, email
     * @param {number} [lastActive] Last active days
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersGet(limit?: number, offset?: number, sort?: string, roleIds?: Array<number>, contractorIds?: Array<number>, manufacturerIds?: Array<number>, search?: string, lastActive?: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersGet(limit, offset, sort, roleIds, contractorIds, manufacturerIds, search, lastActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersMeGet(options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a user message channel list
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersMessageChannelsGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersMessageChannelsGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Manages user\'s notifications
     * @param {ManageNotification} [payload] Payload to manage user\&#39;s notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersNotificationsManagePut(payload?: ManageNotification, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersNotificationsManagePut(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a user
     * @param {UserCreation} [payload] Payload for creating a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersPost(payload?: UserCreation, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a user role list
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersRolesGet(limit?: number, offset?: number, sort?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersRolesGet(limit, offset, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends verification code for admin sign in
     * @param {SendCodePayload} [payload] Payload for sending verification code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersSignInSendCodePost(payload?: SendCodePayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersSignInSendCodePost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign out an user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersSignOutPost(options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersSignOutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Manages user\'s admin notifications
     * @param {number} userId The user id
     * @param {ManageAdminNotification} [payload] Payload to manage user\&#39;s admin notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersUserIdAdminNotificationsManagePut(userId: number, payload?: ManageAdminNotification, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersUserIdAdminNotificationsManagePut(userId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Archives a user
     * @param {number} userId The user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersUserIdArchivePost(userId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersUserIdArchivePost(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a user
     * @param {number} userId The user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersUserIdDelete(userId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersUserIdDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a user
     * @param {number} userId The user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersUserIdGet(userId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a user\'s project notifications
     * @param {number} userId The user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersUserIdNotificationsByProjectGet(userId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersUserIdNotificationsByProjectGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a user\'s project notifications
     * @param {number} userId The user id
     * @param {UserNotificationPayloadByProject} [payload] Payload to update user\&#39;s project notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersUserIdNotificationsByProjectPut(userId: number, payload?: UserNotificationPayloadByProject, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersUserIdNotificationsByProjectPut(userId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a user\'s global notifications
     * @param {number} userId The user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersUserIdNotificationsByTypeGet(userId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersUserIdNotificationsByTypeGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a user\'s global notifications
     * @param {number} userId The user id
     * @param {UserGlobalNotificationPayloadByType} [payload] Payload to update user\&#39;s global notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersUserIdNotificationsByTypePut(userId: number, payload?: UserGlobalNotificationPayloadByType, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersUserIdNotificationsByTypePut(userId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates user
     * @param {number} userId The user id
     * @param {UserEditing} [payload] Payload to update user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersUserIdPut(userId: number, payload?: UserEditing, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersUserIdPut(userId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the user\'s session
     * @param {number} userId The user identifier
     * @param {Session} [payload] Payload to delete the user\&#39;s session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersUserIdSessionsDelete(userId: number, payload?: Session, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersUserIdSessionsDelete(userId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns user\'s active sessions
     * @param {number} userId The user identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersUserIdSessionsGet(userId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersUserIdSessionsGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unarchives a user
     * @param {number} userId The user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminUsersUserIdUnarchivePost(userId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminUsersUserIdUnarchivePost(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Wagner device firmware
     * @param {number} firmwareId The firmware id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminWagnerProbesFirmwaresFirmwareIdDelete(firmwareId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminWagnerProbesFirmwaresFirmwareIdDelete(firmwareId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a Wagner device firmware details
     * @param {number} firmwareId The firmware id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminWagnerProbesFirmwaresFirmwareIdGet(firmwareId: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminWagnerProbesFirmwaresFirmwareIdGet(firmwareId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Wagner device firmware
     * @param {number} firmwareId The firmware id
     * @param {UpdateFirmwarePayload} updateFirmwarePayload Payload to update a Wagner device firmware
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminWagnerProbesFirmwaresFirmwareIdPut(firmwareId: number, updateFirmwarePayload: UpdateFirmwarePayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminWagnerProbesFirmwaresFirmwareIdPut(firmwareId, updateFirmwarePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a list of Wagner device firmwares
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {'data_grabber' | 'range_extender'} [deviceType] Device type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminWagnerProbesFirmwaresGet(limit?: number, offset?: number, deviceType?: 'data_grabber' | 'range_extender', options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminWagnerProbesFirmwaresGet(limit, offset, deviceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new firmware for Wagner devices
     * @param {CreateFirmwarePayload} createFirmwarePayload Payload to create a new firmware for Wagner devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminWagnerProbesFirmwaresPost(createFirmwarePayload: CreateFirmwarePayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminWagnerProbesFirmwaresPost(createFirmwarePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns wagner probes
     * @param {number} [limit] Limit of rows
     * @param {number} [offset] Offset for rows
     * @param {string} [sort] Sort for rows
     * @param {number} [contractorId] The contractor id
     * @param {number} [projectId] The project id
     * @param {number} [projectSectionId] The project section id
     * @param {boolean} [connectedToProject] The filter by connectivity to project
     * @param {string} [search] The search string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminWagnerProbesGet(limit?: number, offset?: number, sort?: string, contractorId?: number, projectId?: number, projectSectionId?: number, connectedToProject?: boolean, search?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminWagnerProbesGet(limit, offset, sort, contractorId, projectId, projectSectionId, connectedToProject, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes a wagner probe
     * @param {string} probeId The wagner probe device id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminWagnerProbesProbeIdDelete(probeId: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminWagnerProbesProbeIdDelete(probeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a wagner probe
     * @param {string} probeId The wagner probe device id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminWagnerProbesProbeIdGet(probeId: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminWagnerProbesProbeIdGet(probeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a wagner probe readings
     * @param {string} probeId The wagner probe identifier(probeId)
     * @param {string} [timeFrom] Time from
     * @param {string} [timeTo] Time to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AdminWagnerProbesProbeIdReadingsGet(probeId: string, timeFrom?: string, timeTo?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AdminWagnerProbesProbeIdReadingsGet(probeId, timeFrom, timeTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel attachment upload
     * @param {string} attachmentHashId The attachment hash id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1AttachmentsAttachmentHashIdCancelUploadPost(attachmentHashId: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1AttachmentsAttachmentHashIdCancelUploadPost(attachmentHashId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload photo
     * @param {Array<any>} files Files to upload
     * @param {number} [photoResizeTo] Value to resize photos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1MediaFilePut(files: Array<any>, photoResizeTo?: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1MediaFilePut(files, photoResizeTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload file by chunks
     * @param {string} xFileHashId Attachment hash id
     * @param {string} xFileName Attachment file name
     * @param {number} xFileSize Attachment file size
     * @param {number} xFilePartNumber File chunk number
     * @param {number} xTotalParts File chunks total number
     * @param {any} file 
     * @param {number} [photoResizeTo] Value to resize photos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1MediaFileUploadChunksPost(xFileHashId: string, xFileName: string, xFileSize: number, xFilePartNumber: number, xTotalParts: number, file: any, photoResizeTo?: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1MediaFileUploadChunksPost(xFileHashId, xFileName, xFileSize, xFilePartNumber, xTotalParts, file, photoResizeTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload photo
     * @param {Array<any>} photos Photos to upload
     * @param {number} [size] Value to resize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1MediaPhotoPut(photos: Array<any>, size?: number, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1MediaPhotoPut(photos, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify code and sign in user
     * @param {SignInPayload} [payload] Payload for verify code and sign in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1UsersSignInPost(payload?: SignInPayload, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1UsersSignInPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign out a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v1UsersSignOutPost(options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v1UsersSignOutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates contractor
     * @param {number} contractorId The contractor id
     * @param {ContractorEditingV2} [payload] Payload to update contractor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v2AdminContractorsContractorIdPut(contractorId: number, payload?: ContractorEditingV2, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v2AdminContractorsContractorIdPut(contractorId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates contractor
     * @param {ContractorCreationV2} [payload] Payload to create contractor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v2AdminContractorsPost(payload?: ContractorCreationV2, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v2AdminContractorsPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns aggregated timeseries gap data
     * @param {string} dateFrom Date from
     * @param {string} [dateTo] Date to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v2AdminDiagnosticsTimeseriesGapGet(dateFrom: string, dateTo?: string, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v2AdminDiagnosticsTimeseriesGapGet(dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a user
     * @param {UserCreation} [payload] Payload for creating a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v2AdminUsersPost(payload?: UserCreation, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v2AdminUsersPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign out a user
     * @param {UserSignOutPayloadV2} userSignOutPayloadV2 Payload for user sign out
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAPIApi
     */
    public v2UsersSignOutPost(userSignOutPayloadV2: UserSignOutPayloadV2, options?: AxiosRequestConfig) {
        return AdminAPIApiFp(this.configuration).v2UsersSignOutPost(userSignOutPayloadV2, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MobileAPIApi - axios parameter creator
 * @export
 */
export const MobileAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel attachment upload
         * @param {string} attachmentHashId The attachment hash id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AttachmentsAttachmentHashIdCancelUploadPost: async (attachmentHashId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentHashId' is not null or undefined
            assertParamExists('v1AttachmentsAttachmentHashIdCancelUploadPost', 'attachmentHashId', attachmentHashId)
            const localVarPath = `/v1/attachments/{attachmentHashId}/cancel-upload`
                .replace(`{${"attachmentHashId"}}`, encodeURIComponent(String(attachmentHashId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload photo
         * @param {Array<any>} files Files to upload
         * @param {number} [photoResizeTo] Value to resize photos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MediaFilePut: async (files: Array<any>, photoResizeTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'files' is not null or undefined
            assertParamExists('v1MediaFilePut', 'files', files)
            const localVarPath = `/v1/media/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (photoResizeTo !== undefined) { 
                localVarFormParams.append('photoResizeTo', photoResizeTo as any);
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload file by chunks
         * @param {string} xFileHashId Attachment hash id
         * @param {string} xFileName Attachment file name
         * @param {number} xFileSize Attachment file size
         * @param {number} xFilePartNumber File chunk number
         * @param {number} xTotalParts File chunks total number
         * @param {any} file 
         * @param {number} [photoResizeTo] Value to resize photos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MediaFileUploadChunksPost: async (xFileHashId: string, xFileName: string, xFileSize: number, xFilePartNumber: number, xTotalParts: number, file: any, photoResizeTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xFileHashId' is not null or undefined
            assertParamExists('v1MediaFileUploadChunksPost', 'xFileHashId', xFileHashId)
            // verify required parameter 'xFileName' is not null or undefined
            assertParamExists('v1MediaFileUploadChunksPost', 'xFileName', xFileName)
            // verify required parameter 'xFileSize' is not null or undefined
            assertParamExists('v1MediaFileUploadChunksPost', 'xFileSize', xFileSize)
            // verify required parameter 'xFilePartNumber' is not null or undefined
            assertParamExists('v1MediaFileUploadChunksPost', 'xFilePartNumber', xFilePartNumber)
            // verify required parameter 'xTotalParts' is not null or undefined
            assertParamExists('v1MediaFileUploadChunksPost', 'xTotalParts', xTotalParts)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('v1MediaFileUploadChunksPost', 'file', file)
            const localVarPath = `/v1/media/file/upload-chunks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xFileHashId !== undefined && xFileHashId !== null) {
                localVarHeaderParameter['x-file-hash-id'] = String(xFileHashId);
            }

            if (xFileName !== undefined && xFileName !== null) {
                localVarHeaderParameter['x-file-name'] = String(xFileName);
            }

            if (xFileSize !== undefined && xFileSize !== null) {
                localVarHeaderParameter['x-file-size'] = String(JSON.stringify(xFileSize));
            }

            if (xFilePartNumber !== undefined && xFilePartNumber !== null) {
                localVarHeaderParameter['x-file-part-number'] = String(JSON.stringify(xFilePartNumber));
            }

            if (xTotalParts !== undefined && xTotalParts !== null) {
                localVarHeaderParameter['x-total-parts'] = String(JSON.stringify(xTotalParts));
            }


            if (photoResizeTo !== undefined) { 
                localVarFormParams.append('photoResizeTo', photoResizeTo as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload photo
         * @param {Array<any>} photos Photos to upload
         * @param {number} [size] Value to resize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MediaPhotoPut: async (photos: Array<any>, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'photos' is not null or undefined
            assertParamExists('v1MediaPhotoPut', 'photos', photos)
            const localVarPath = `/v1/media/photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (size !== undefined) { 
                localVarFormParams.append('size', size as any);
            }
                if (photos) {
                photos.forEach((element) => {
                    localVarFormParams.append('photos', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify code and sign in user
         * @param {SignInPayload} [payload] Payload for verify code and sign in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersSignInPost: async (payload?: SignInPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign out a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersSignOutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/sign-out`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign out a user
         * @param {UserSignOutPayloadV2} userSignOutPayloadV2 Payload for user sign out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersSignOutPost: async (userSignOutPayloadV2: UserSignOutPayloadV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userSignOutPayloadV2' is not null or undefined
            assertParamExists('v2UsersSignOutPost', 'userSignOutPayloadV2', userSignOutPayloadV2)
            const localVarPath = `/v2/users/sign-out`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSignOutPayloadV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileAPIApi - functional programming interface
 * @export
 */
export const MobileAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel attachment upload
         * @param {string} attachmentHashId The attachment hash id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AttachmentsAttachmentHashIdCancelUploadPost(attachmentHashId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200103>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AttachmentsAttachmentHashIdCancelUploadPost(attachmentHashId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload photo
         * @param {Array<any>} files Files to upload
         * @param {number} [photoResizeTo] Value to resize photos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MediaFilePut(files: Array<any>, photoResizeTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20040>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MediaFilePut(files, photoResizeTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload file by chunks
         * @param {string} xFileHashId Attachment hash id
         * @param {string} xFileName Attachment file name
         * @param {number} xFileSize Attachment file size
         * @param {number} xFilePartNumber File chunk number
         * @param {number} xTotalParts File chunks total number
         * @param {any} file 
         * @param {number} [photoResizeTo] Value to resize photos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MediaFileUploadChunksPost(xFileHashId: string, xFileName: string, xFileSize: number, xFilePartNumber: number, xTotalParts: number, file: any, photoResizeTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200102>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MediaFileUploadChunksPost(xFileHashId, xFileName, xFileSize, xFilePartNumber, xTotalParts, file, photoResizeTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload photo
         * @param {Array<any>} photos Photos to upload
         * @param {number} [size] Value to resize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MediaPhotoPut(photos: Array<any>, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20039>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MediaPhotoPut(photos, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify code and sign in user
         * @param {SignInPayload} [payload] Payload for verify code and sign in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersSignInPost(payload?: SignInPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UsersSignInPost(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign out a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersSignOutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UsersSignOutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign out a user
         * @param {UserSignOutPayloadV2} userSignOutPayloadV2 Payload for user sign out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UsersSignOutPost(userSignOutPayloadV2: UserSignOutPayloadV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2UsersSignOutPost(userSignOutPayloadV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MobileAPIApi - factory interface
 * @export
 */
export const MobileAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel attachment upload
         * @param {string} attachmentHashId The attachment hash id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AttachmentsAttachmentHashIdCancelUploadPost(attachmentHashId: string, options?: any): AxiosPromise<InlineResponse200103> {
            return localVarFp.v1AttachmentsAttachmentHashIdCancelUploadPost(attachmentHashId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload photo
         * @param {Array<any>} files Files to upload
         * @param {number} [photoResizeTo] Value to resize photos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MediaFilePut(files: Array<any>, photoResizeTo?: number, options?: any): AxiosPromise<InlineResponse20040> {
            return localVarFp.v1MediaFilePut(files, photoResizeTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload file by chunks
         * @param {string} xFileHashId Attachment hash id
         * @param {string} xFileName Attachment file name
         * @param {number} xFileSize Attachment file size
         * @param {number} xFilePartNumber File chunk number
         * @param {number} xTotalParts File chunks total number
         * @param {any} file 
         * @param {number} [photoResizeTo] Value to resize photos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MediaFileUploadChunksPost(xFileHashId: string, xFileName: string, xFileSize: number, xFilePartNumber: number, xTotalParts: number, file: any, photoResizeTo?: number, options?: any): AxiosPromise<InlineResponse200102> {
            return localVarFp.v1MediaFileUploadChunksPost(xFileHashId, xFileName, xFileSize, xFilePartNumber, xTotalParts, file, photoResizeTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload photo
         * @param {Array<any>} photos Photos to upload
         * @param {number} [size] Value to resize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MediaPhotoPut(photos: Array<any>, size?: number, options?: any): AxiosPromise<InlineResponse20039> {
            return localVarFp.v1MediaPhotoPut(photos, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify code and sign in user
         * @param {SignInPayload} [payload] Payload for verify code and sign in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersSignInPost(payload?: SignInPayload, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.v1UsersSignInPost(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign out a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersSignOutPost(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v1UsersSignOutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign out a user
         * @param {UserSignOutPayloadV2} userSignOutPayloadV2 Payload for user sign out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersSignOutPost(userSignOutPayloadV2: UserSignOutPayloadV2, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.v2UsersSignOutPost(userSignOutPayloadV2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MobileAPIApi - object-oriented interface
 * @export
 * @class MobileAPIApi
 * @extends {BaseAPI}
 */
export class MobileAPIApi extends BaseAPI {
    /**
     * 
     * @summary Cancel attachment upload
     * @param {string} attachmentHashId The attachment hash id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAPIApi
     */
    public v1AttachmentsAttachmentHashIdCancelUploadPost(attachmentHashId: string, options?: AxiosRequestConfig) {
        return MobileAPIApiFp(this.configuration).v1AttachmentsAttachmentHashIdCancelUploadPost(attachmentHashId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload photo
     * @param {Array<any>} files Files to upload
     * @param {number} [photoResizeTo] Value to resize photos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAPIApi
     */
    public v1MediaFilePut(files: Array<any>, photoResizeTo?: number, options?: AxiosRequestConfig) {
        return MobileAPIApiFp(this.configuration).v1MediaFilePut(files, photoResizeTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload file by chunks
     * @param {string} xFileHashId Attachment hash id
     * @param {string} xFileName Attachment file name
     * @param {number} xFileSize Attachment file size
     * @param {number} xFilePartNumber File chunk number
     * @param {number} xTotalParts File chunks total number
     * @param {any} file 
     * @param {number} [photoResizeTo] Value to resize photos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAPIApi
     */
    public v1MediaFileUploadChunksPost(xFileHashId: string, xFileName: string, xFileSize: number, xFilePartNumber: number, xTotalParts: number, file: any, photoResizeTo?: number, options?: AxiosRequestConfig) {
        return MobileAPIApiFp(this.configuration).v1MediaFileUploadChunksPost(xFileHashId, xFileName, xFileSize, xFilePartNumber, xTotalParts, file, photoResizeTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload photo
     * @param {Array<any>} photos Photos to upload
     * @param {number} [size] Value to resize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAPIApi
     */
    public v1MediaPhotoPut(photos: Array<any>, size?: number, options?: AxiosRequestConfig) {
        return MobileAPIApiFp(this.configuration).v1MediaPhotoPut(photos, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify code and sign in user
     * @param {SignInPayload} [payload] Payload for verify code and sign in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAPIApi
     */
    public v1UsersSignInPost(payload?: SignInPayload, options?: AxiosRequestConfig) {
        return MobileAPIApiFp(this.configuration).v1UsersSignInPost(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign out a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAPIApi
     */
    public v1UsersSignOutPost(options?: AxiosRequestConfig) {
        return MobileAPIApiFp(this.configuration).v1UsersSignOutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign out a user
     * @param {UserSignOutPayloadV2} userSignOutPayloadV2 Payload for user sign out
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAPIApi
     */
    public v2UsersSignOutPost(userSignOutPayloadV2: UserSignOutPayloadV2, options?: AxiosRequestConfig) {
        return MobileAPIApiFp(this.configuration).v2UsersSignOutPost(userSignOutPayloadV2, options).then((request) => request(this.axios, this.basePath));
    }
}


