import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { WagnerProbe } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  uniqueId: string;
  options?: Omit<
    UseQueryOptions<unknown, Error, WagnerProbe[]>,
    'queryKey' | 'queryFn'
  >;
}

export const useSensorRelatedRapidRhProbesRequest = ({ uniqueId, options }: RequestParams) => {
  const api = useApiInstance()

  return useQuery<unknown, Error, WagnerProbe[]>(
    [Entities.Sensors, uniqueId, 'relatedRapidRhProbes'],
    async () => {
      const { data } = await api.v1AdminSensorsUniqueIdRelatedRapidRhProbesGet(uniqueId)
      return data
    },
    { ...options },
  )
}
