import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error, { id: number }, boolean>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useDeleteWagnerDeviceFirmwareRequest = ({ options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, { id: number }, boolean>(
    [Entities.WagnerDeviceFirmware, 'delete'],
    async ({ id }) => {
      const { data } = await api.v1AdminWagnerProbesFirmwaresFirmwareIdDelete(id)
      return data
    },
    {
      ...options,
    },
  )
}
