import { Typography } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid-premium"
import distanceInWords from "date-fns/formatDistance"
import format from "date-fns/format"
import { WagnerProbe } from "src/api"
import { InnerLink } from "src/components/ui"
import { capitalizeFirstLetter } from "src/utils/formatters"

export namespace RapidRHColumnsDataGrid {
  export const ProbeId: GridColDef<WagnerProbe> = {
    field: "probeId",
    headerName: "Probe ID",
    type: "string",
    minWidth: 180,
    editable: false,
    renderCell: ({ value }) => (
      <InnerLink to={`/wagner-probes/${value}`}>{value}</InnerLink>
    )
  }
  export const IsOnline: GridColDef<WagnerProbe> = {
    field: "isOnline",
    headerName: "Online",
    minWidth: 80,
    editable: false,
    valueGetter: (params) => {
      const readingTime = params.row?.currentData?.readingTime || null
      if (readingTime) {
        const twoHours = 1000 * 60 * 60 * 2 // two hours in ms
        const differnce = Date.now() - new Date(readingTime).getTime()
        return differnce > 0 && differnce <= twoHours ? "Yes" : "No"
      }
      return "-"
    },
    renderCell: ({ value }) => {
      if (value === "-") {
        return value
      }

      return <div className="rowitem">
        <Typography variant="body2" color={value === "Yes" ? "green" : "red"}>{value}</Typography>
      </div>
    },
  }
  export const ReadingTime: GridColDef<WagnerProbe> = {
    field: "readingTime",
    headerName: "Last ping",
    minWidth: 180,
    editable: false,
    valueGetter: (params) => {
      const readingTime = params.row?.currentData?.readingTime
      if (!readingTime) {
        return "-"
      }

      return distanceInWords(new Date(readingTime), new Date())
    },
  }
  export const Label: GridColDef<WagnerProbe> = {
    field: "label",
    headerName: "Label",
    type: "string",
    minWidth: 180,
    editable: false,
  }
  export const ReaderId: GridColDef<WagnerProbe> = {
    field: "readerId",
    headerName: "Grabber ID",
    type: "string",
    valueGetter: (params) => params.row?.currentData?.readerId || "-",
    minWidth: 130,
    editable: false,
  }
  export const VerizonGAT: GridColDef<WagnerProbe> = {
    field: "verizonGatId",
    headerName: "Verizon IMEI",
    type: "string",
    valueGetter: (params) => params.row?.currentData?.verizonGatId || "-",
    minWidth: 180,
    editable: false,
  }
  export const Contractor: GridColDef<WagnerProbe> = {
    field: "contractor",
    headerName: "Contractor",
    type: "string",
    valueGetter: (params) => params.row?.contractor?.name || "-",
    minWidth: 180,
    editable: false,
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      const contractorId = row.contractor?.id || row.project?.contractor?.id
      return (
        <InnerLink to={`/contractors/${contractorId}`}>{value}</InnerLink>
      )
    }
  }
  export const Project: GridColDef<WagnerProbe> = {
    field: "project",
    headerName: "Project",
    type: "string",
    valueGetter: (params) => params.row?.project?.name || "-",
    minWidth: 180,
    editable: false,
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      return (
        <InnerLink to={`/projects/${row.project?.id}`}>{value}</InnerLink>
      )
    }
  }
  export const ProjectSection: GridColDef<WagnerProbe> = {
    field: "projectSection",
    headerName: "Project section",
    type: "string",
    valueGetter: (params) => params.row?.projectSection?.name || "-",
    minWidth: 180,
    editable: false,
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      return (
        <InnerLink to={`/sections/${row.projectSection?.id}`}>{value}</InnerLink>
      )
    }
  }
  export const LastDeviceType: GridColDef<WagnerProbe> = {
    field: "deviceType",
    headerName: "Last Device Type",
    type: "string",
    valueGetter: (params) => params.row?.currentData?.deviceType || null,
    minWidth: 150,
    editable: false,
    renderCell: ({ value }) => {
      return <div className="rowitem">
        {value ? capitalizeFirstLetter(value).replace("_", " ") : "-"}
      </div>
    },
  }
  export const LastSignalStrength: GridColDef<WagnerProbe> = {
    field: "signalStrength",
    headerName: "Last Signal Strength",
    type: "number",
    valueGetter: (params) => params.row?.currentData?.signalStrength || null,
    minWidth: 180,
    editable: false,
  }
  export const LastBatteryLevel: GridColDef<WagnerProbe> = {
    field: "batteryLevel",
    headerName: "Last Battery Level",
    type: "number",
    valueGetter: (params) => params.row?.currentData?.batteryLevel || null,
    minWidth: 180,
    editable: false,
  }
  export const ReceivedFromDataGrabber: GridColDef<WagnerProbe> = {
    field: "receivedFromDataGrabberAt",
    headerName: "Received From Data Grabber",
    type: "number",
    valueGetter: (params) => params.row?.currentData?.receivedFromDataGrabberAt || null,
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
    minWidth: 180,
    editable: false,
  }
  export const ReceivedFromMiniGrabber: GridColDef<WagnerProbe> = {
    field: "receivedFromMiniGrabberAt",
    headerName: "Received From Mini Grabber",
    type: "number",
    valueGetter: (params) => params.row?.currentData?.receivedFromMiniGrabberAt || null,
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
    minWidth: 180,
    editable: false,
  }
  export const ReceivedFromTotalReader: GridColDef<WagnerProbe> = {
    field: "receivedFromTotalReaderAt",
    headerName: "Received From Total Reader",
    type: "number",
    valueGetter: (params) => params.row?.currentData?.receivedFromTotalReaderAt || null,
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
    minWidth: 180,
    editable: false,
  }
  export const ReceivedFromRepeater: GridColDef<WagnerProbe> = {
    field: "receivedFromRepeaterAt",
    headerName: "Received From Repeater",
    type: "number",
    valueGetter: (params) => params.row?.currentData?.receivedFromRepeaterAt || null,
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
    minWidth: 180,
    editable: false,
  }
  export const ReceivedViaPhone: GridColDef<WagnerProbe> = {
    field: "receivedViaPhoneAt",
    headerName: "Received Via Phone",
    type: "number",
    valueGetter: (params) => params.row?.currentData?.receivedViaPhoneAt || null,
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
    minWidth: 180,
    editable: false,
  }
  export const ReceivedViaGAT: GridColDef<WagnerProbe> = {
    field: "receivedViaGatAt",
    headerName: "Received Via GAT",
    type: "number",
    valueGetter: (params) => params.row?.currentData?.receivedViaGatAt || null,
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
    minWidth: 180,
    editable: false,
  }
  export const CreatedAt: GridColDef<WagnerProbe> = {
    field: "createdAt",
    headerName: "Created At",
    type: "string",
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
    minWidth: 200,
    editable: false,
  }
}
