import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { TimeseriesGapResponse } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  params?: {
    dateFrom: string;
    dateTo?: string;
  };
  options?: Omit<
    UseQueryOptions<TimeseriesGapResponse, Error>,
    'queryKey' | 'queryFn'
  >;
}

export const useAggregatedTimeseriesGapRequest = ({
  params = { dateFrom: new Date().toISOString() },
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  return useQuery<TimeseriesGapResponse, Error>(
    [Entities.Diagnostics, JSON.stringify(params)],
    async () => {
      const { dateFrom, dateTo } = params
      const { data } = await api.v2AdminDiagnosticsTimeseriesGapGet(dateFrom, dateTo)
      return data as TimeseriesGapResponse
    },
    options,
  )
}
