import { FC, useMemo } from 'react'
import { Box, Button, Grid, Typography } from "@mui/material"
import * as Yup from "yup"
import { useFormik, FormikProvider, Form } from 'formik'
import { ConfigurationParameterTypeEnum, ConfigurationParamUpdateBulkItem } from 'src/api'
import { DataLoad, TextField } from 'src/components/ui'
import { useAddPopupMessage, useDialog } from 'src/hooks/ui'
import { useConfigurationListRequest, useEditConfigurationParamBulkRequest } from 'src/hooks/api'
import { DialogNames } from 'src/enums'
import { SettingsItem, UpdateConfigurationSettingsModal } from 'src/components'

const validationSchema = Yup.object({
  [ConfigurationParameterTypeEnum.PrefreezingValueLimit]: Yup.number().nullable(),
  [ConfigurationParameterTypeEnum.FreezingThreshold]: Yup.number().nullable(),
})

export const AlertSettingsParams: FC = () => {
  const {
    openDialog: openDialogConfigurationSettings,
    closeDialog: closeDialogConfigurationSettings,
  } = useDialog(DialogNames.ConfigurationSettings)
  const {
    data: settings,
    isInitialLoading,
    isError,
    refetch: refetchSettings
  } = useConfigurationListRequest({
    params: { limit: 100 },
    options: { enabled: true }
  })
  const request = useEditConfigurationParamBulkRequest()
  const addPopupMessage = useAddPopupMessage()
  
  const updateSettings = (payload: ConfigurationParamUpdateBulkItem[]) => {
    request.mutate(
      payload,
      {
        onSuccess: async (response) => {
          const textParts = response.map((item) => {
            return item.error ? `Error: ${item.error}` : `Updated: "${item.result.name}"`
          })
          const type = (() => {
            const hasError = response.some((item) => item.error)
            const hasSuccess = response.some((item) => !item.error)
            if (hasError && hasSuccess) return "warning"
            if (hasError) return "error"
            return "success"
          })()
          addPopupMessage({ text: textParts.join('. '), type })
          closeDialogConfigurationSettings()
          await refetchSettings()
        },
        onError: ({ message: text }) => {
          addPopupMessage({ text, type: "error" })
        }
      }
    )
  }

  const alertSettings = useMemo(() => {
    return (settings || []).filter((item) => ([
      ConfigurationParameterTypeEnum.FreezingThreshold,
      ConfigurationParameterTypeEnum.PrefreezingValueLimit
    ] as ConfigurationParameterTypeEnum[]).includes(item.type))
  }, [settings])

  const initialValues: Partial<Record<ConfigurationParameterTypeEnum, string>> = {
    [ConfigurationParameterTypeEnum.PrefreezingValueLimit]: alertSettings.find((item) => item.type === ConfigurationParameterTypeEnum.PrefreezingValueLimit)?.value || '0',
    [ConfigurationParameterTypeEnum.FreezingThreshold]: alertSettings.find((item) => item.type === ConfigurationParameterTypeEnum.FreezingThreshold)?.value || '0',
  }

  const formik = useFormik<Partial<Record<ConfigurationParameterTypeEnum, string>>>({
    initialValues: initialValues,
    onSubmit: (data) => {
      const payload: ConfigurationParamUpdateBulkItem[] = []
      for (const [rootKey, rootValue] of Object.entries(data)) {
        const setting = alertSettings.find((item) => item.type === rootKey)
        if (setting) {
          payload.push({
            id: setting.id,
            value: String(rootValue),
          })
        }
      }
      updateSettings(payload)
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  })
  const { values } = formik
  const settingsForModal: SettingsItem[] = useMemo(() => alertSettings.map((item) => {
    const value = values[item.type]
    return {
      name: item.name,
      oldValue: item.value,
      newValue: value,
    }
  }),[alertSettings, values])

  if (!alertSettings) return null


  return (
    <DataLoad isLoading={isInitialLoading} isError={isError}>
      <Typography variant="h4">Alert Settings</Typography>
      <Box width="500px" mt={2}>
        <FormikProvider value={formik}>
          <Form>
            <Grid container spacing={1}>
              <Grid item container>
                <Typography variant="h5" sx={{ mb: 2 }}>Freezing Alerts</Typography>
                <Grid item xs={12}>
                  <TextField name={ConfigurationParameterTypeEnum.PrefreezingValueLimit} label="Prefreezing value, °F" TextFieldProps={{
                    type: "number"
                  }} />
                </Grid>
                <Grid item xs={12}>
                  <TextField name={ConfigurationParameterTypeEnum.FreezingThreshold} label="Freezing threshold, °F" TextFieldProps={{
                    type: "number",
                    inputProps: {
                      step: "0.1"
                    }
                  }} />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item>
                  <Box pt={2}>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={openDialogConfigurationSettings}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
                <Grid item>
                  <Box pt={2}>
                    <Button
                      size="large"
                      variant="outlined"
                      color="primary"
                      onClick={() => formik.resetForm()}
                    >
                      Reset
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <UpdateConfigurationSettingsModal
              onEditSettings={formik.handleSubmit}
              onResetSettings={() => {
                formik.resetForm()
                closeDialogConfigurationSettings()
              }}
              onCloseDialog={closeDialogConfigurationSettings}
              settings={settingsForModal}
              title="Update alert settings"
              isLoading={request.isLoading}
            />
          </Form>
        </FormikProvider>
      </Box>
    </DataLoad>
  )
}
