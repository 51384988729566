import React from "react"
import { GridColDef } from '@mui/x-data-grid-premium'
import format from "date-fns/format"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { InnerLink, OuterLink } from "src/components/ui"
import { Attachment } from "src/api"
import { ImagesListItem } from "src/contexts"

export namespace AttachmentColumns {
  export const FileName: GridColDef<Attachment> = {
    field: "fileName",
    headerName: "File name",
    type: "string",
    minWidth: 350,
    editable: false,
    valueGetter: ({ row }) => row.fileName || row.originalFileName || null,
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      return (
        <InnerLink to={`/attachments/${row.id}`}>{value}</InnerLink>
      )
    }
  }

  export const Status: GridColDef<Attachment> = {
    field: "status",
    headerName: "Status",
    type: "string",
    minWidth: 100,
    editable: false,
  }

  export const Type: GridColDef<Attachment> = {
    field: "type",
    headerName: "Type",
    type: "string",
    minWidth: 130,
    editable: false,
  }

  export const RelatedTo: GridColDef<Attachment> = {
    field: "event",
    headerName: "Related to",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => {
      if (row.eventId) return 'Event'
      if (row.projectDocumentId) return 'Document'
      if (row.taskId) return 'Task'
      return null
    },
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      if (value === 'Event') {
        return (
          <InnerLink to={`/events/${row.eventId}`}>Event</InnerLink>
        )
      }
      return (
        <Typography variant="body2">{value}</Typography>
      )
    }
  }

  export const Contractor: GridColDef<Attachment> = {
    field: "project.contractor.name",
    headerName: "Contractor",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row.event?.contractor?.name || row.projectDocument?.contractor?.name || row.task?.contractor?.name || null,
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      const contractorId = row.event?.contractorId || row.projectDocument?.contractorId || row.task?.contractorId || null
      return (
        <InnerLink to={`/contractors/${contractorId}`}>{value}</InnerLink>
      )
    }
  }

  export const Project: GridColDef<Attachment> = {
    field: "project.name",
    headerName: "Project",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row.event?.project?.name || row.projectDocument?.project?.name || row.task?.project?.name || null,
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      const projectId = row.event?.projectId || row.projectDocument?.projectId || row.task?.projectId || null
      return (
        <InnerLink to={`/projects/${projectId}`}>{value}</InnerLink>
      )
    }
  }

  export const ProjectSection: GridColDef<Attachment> = {
    field: "projectSection.name",
    headerName: "Section",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row.event?.projectSection?.name || row.task?.projectSection?.name || null,
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      const projectSectionId = row.event?.projectSectionId || row.task?.projectSectionId || null
      return (
        <InnerLink to={`/sections/${projectSectionId}`}>{value}</InnerLink>
      )
    }
  }

  export const Author: GridColDef<Attachment> = {
    field: "author.name",
    headerName: "Author",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row.author?.name || null,
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      return (
        <InnerLink to={`/users/${row.authorId}`}>{value}</InnerLink>
      )
    }
  }

  export const CreatedAt: GridColDef<Attachment> = {
    field: "createdAt",
    headerName: "Created at",
    type: "string",
    minWidth: 200,
    editable: false,
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
  }

  export const File = (openGallery: (imgs: ImagesListItem[]) => void): GridColDef<Attachment> => ({
    field: "link",
    headerName: "File",
    type: "string",
    minWidth: 150,
    editable: false,
    renderCell: ({ row }) => {
      if (!row.link || row.status !== 'processed') {
        return <Typography variant="body2">-</Typography>
      }

      if (row.type === 'photo') {
        const images: { src: string }[] = [{
          src: row.link,
        }]
  
        return (
          <Box onClick={() => openGallery(images)}>
            <Typography
              variant="body2"
              color='#037BEB'
              sx={{ cursor: 'pointer' }}
            >
              Open
            </Typography>
          </Box>
        )
      }

      return (
        <Typography variant="body2">
          <OuterLink to={row.link}>Download</OuterLink>
        </Typography>
      )
    },
  })

  export const Size: GridColDef<Attachment> = {
    field: "size",
    headerName: "Size",
    type: "number",
    minWidth: 100,
    editable: false,
    valueFormatter: ({ value }) => value ? `${(value / 1024 / 1024).toFixed(2)} MB` : '-',
  }

  export const AmountOfChunks: GridColDef<Attachment> = {
    field: "totalParts",
    headerName: "Total chunks",
    type: "number",
    minWidth: 100,
    editable: false,
  }

  export const AmountOfUploadedChunks: GridColDef<Attachment> = {
    field: "uploadedParts",
    headerName: "Uploaded chunks",
    type: "number",
    minWidth: 150,
    editable: false,
    valueFormatter: ({ value }) => value && Array.isArray(value) ? value.length : '-',
  }
}
