import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { AggregatedTimeseriesHandlingGap } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  params?: {
    dateFrom: string;
    dateTo?: string;
  };
  options?: Omit<
    UseQueryOptions<AggregatedTimeseriesHandlingGap[], Error>,
    'queryKey' | 'queryFn'
  >;
}

export const useAggregatedTimeseriesHandlingGapRequest = ({
  params = { dateFrom: new Date().toISOString() },
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  return useQuery<AggregatedTimeseriesHandlingGap[], Error>(
    [Entities.Diagnostics, JSON.stringify(params)],
    async () => {
      const { dateFrom, dateTo } = params
      const { data } = await api.v1AdminDiagnosticsTimeseriesHandlingGapGet(dateFrom, dateTo)
      return data as AggregatedTimeseriesHandlingGap[]
    },
    options,
  )
}
