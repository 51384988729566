import { FC, useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import {
  DataGridPremium,
  GridColDef,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium'
import { useContractorRequest, useContractorTokensHistoryRequest } from 'src/hooks/api'
import { EntityHeader, HasSidebarLayout } from 'src/components'
import { ContractorTokensHistoryColumns } from 'src/components/columns/contractor'
import { useParams } from 'react-router-dom'

export type TokenHistoryItem = {
  id: number
  token: string
  action: string
  authorName: string
  authorId: number
  createdAt: string
}

const columns: GridColDef<TokenHistoryItem>[] = [
  ContractorTokensHistoryColumns.Token,
  ContractorTokensHistoryColumns.Action,
  ContractorTokensHistoryColumns.Author,
  ContractorTokensHistoryColumns.Datetime,
]

export const ContractorTokensHistory: FC = () => {
  const apiRef = useGridApiRef()
  const params = useParams()
  const id = parseInt((params as any).id, 10)
  const { data: contractor } = useContractorRequest({ id })
  const { data: tokensHistory, isInitialLoading } = useContractorTokensHistoryRequest({ id })
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['token'],
      },
    },
  })
  const preparedTokensHistory: TokenHistoryItem[] = useMemo(() => (
    (tokensHistory || []).map((item) => ({
      id: item.id,
      token: item.contractorToken?.obfuscatedToken || '',
      action: item.action,
      authorName: item.author?.name || '',
      authorId: item.author?.id || 0,
      createdAt: item.createdAt || '',
    }))
  ), [tokensHistory])
  
  return (
    <HasSidebarLayout>
      <Grid container flexDirection="column">
        <Grid item>
          <EntityHeader
            title={`Tokens history: ${contractor?.name || 'No contractor'}`}
          />
        </Grid>
        <Grid item>
          <Paper sx={{ flexGrow: 1, minHeight: '200px', height: "100%", width: "100%" }}>
            <DataGridPremium<TokenHistoryItem>
              disableRowSelectionOnClick
              rows={preparedTokensHistory}
              columns={columns}
              loading={isInitialLoading}
              apiRef={apiRef}
              initialState={initialState}
            />
          </Paper>
        </Grid>
      </Grid>
    </HasSidebarLayout>
  )
}

export default ContractorTokensHistory
