import { FC, useMemo } from "react"
import { AdminMobileSettings } from "src/api"
import * as Yup from "yup"
import {
  Button,
  Grid,
  Box,
  Typography,
} from "@mui/material"
import { useFormik, FormikProvider, Form } from 'formik'
import { TextField } from "src/components/ui"
import { useEditMobileSettingsRequest } from "src/hooks/api/useEditMobileSettings"
import { useAddPopupMessage, useDialog } from "src/hooks/ui"
import { SettingsItem, UpdateConfigurationSettingsModal } from "src/components/sections"
import { DialogNames } from "src/enums"

interface MobileSettingsProps {
    mobileSettings: AdminMobileSettings;
    description?: string;
    refetchMobileSettings: () => void;
}

const validationSchema = Yup.object({
  wagnerProbeSettings: Yup.object().shape({
    signalStrengthThreshold: Yup.number(),
    connectivitySignalLevel: Yup.number(),
    connectivityCheckPacketFreshnessThreshold: Yup.number(),
  }).nullable(),
})

export interface MobileSettingsEditState extends AdminMobileSettings { }

const defaultInitial: MobileSettingsEditState = {
  wagnerProbeSettings: {
    signalStrengthThreshold: 0,
    connectivitySignalLevel: 0,
    connectivityCheckPacketFreshnessThreshold: 0
  },
  backgroundSyncSettings: {
    chunkSize: 0,
  }
}

export const MobileSettingsEdit: FC<MobileSettingsProps> = ({ mobileSettings, description, refetchMobileSettings }) => {
  const request = useEditMobileSettingsRequest()
  const addPopupMessage = useAddPopupMessage()
  const {
    openDialog: openDialogConfigurationSettings,
    closeDialog: closeDialogConfigurationSettings,
  } = useDialog(DialogNames.ConfigurationSettings)

  const initialValues: MobileSettingsEditState = mobileSettings ? {
    wagnerProbeSettings: {
      signalStrengthThreshold: mobileSettings?.wagnerProbeSettings.signalStrengthThreshold,
      connectivitySignalLevel: mobileSettings?.wagnerProbeSettings.connectivitySignalLevel,
      connectivityCheckPacketFreshnessThreshold: mobileSettings?.wagnerProbeSettings.connectivityCheckPacketFreshnessThreshold,
    },
    backgroundSyncSettings: {
      chunkSize: mobileSettings?.backgroundSyncSettings?.chunkSize || 256,
    }
  } : defaultInitial

  const updateMobileSettings = (settings: AdminMobileSettings) => {
    request.mutate(
      settings,
      {
        onSuccess: async () => {
          addPopupMessage({ text: "The mobile settings was successfully updated", type: "success" })
          closeDialogConfigurationSettings()
          await refetchMobileSettings()
        },
        onError: ({ message: text }) => {
          addPopupMessage({ text, type: "error" })
        }
      }
    )
  }

  const formik = useFormik({
    initialValues: { ...defaultInitial, ...initialValues },
    onSubmit: (data) => {
      for (const rootValue of Object.values(data)) {
        if (typeof rootValue !== 'object') continue
        for (const [childKey, childValue] of Object.entries(rootValue)) {
          if (childValue === '') {
            Object.assign(rootValue, { [childKey]: null })
          };
        }
      }
      updateMobileSettings(data)
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  })
  const { values } = formik
  const settingsForModal: SettingsItem[] = useMemo(() => {
    const settings: SettingsItem[] = []
    settings.push({ name: "Signal strength threshold, dBm", newValue: String(values.wagnerProbeSettings.signalStrengthThreshold), oldValue: String(mobileSettings?.wagnerProbeSettings.signalStrengthThreshold) })
    settings.push({ name: "Connectivity signal level, dBm", newValue: String(values.wagnerProbeSettings.connectivitySignalLevel), oldValue: String(mobileSettings?.wagnerProbeSettings.connectivitySignalLevel) })
    settings.push({ name: "Connectivity check packet freshness threshold, sec", newValue: String(values.wagnerProbeSettings.connectivityCheckPacketFreshnessThreshold), oldValue: String(mobileSettings?.wagnerProbeSettings.connectivityCheckPacketFreshnessThreshold) })
    settings.push({ name: "Chunk size, KB", newValue: String(values?.backgroundSyncSettings?.chunkSize), oldValue: String(mobileSettings?.backgroundSyncSettings?.chunkSize) })
    return settings
  },[mobileSettings, values])

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={1}>
          <Grid item>
            <Typography variant="body2" sx={{ mb: 2 }}>{description}</Typography>
          </Grid>
          <Grid item container>
            <Typography variant="h5" sx={{ mb: 2 }}>Wagner Probe Settings</Typography>
            <Grid item xs={12}>
              <TextField name="wagnerProbeSettings.signalStrengthThreshold" label="Signal strength threshold, dBm" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="wagnerProbeSettings.connectivitySignalLevel" label="Connectivity signal level, dBm" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="wagnerProbeSettings.connectivityCheckPacketFreshnessThreshold" label="Connectivity check packet freshness threshold, sec" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
          </Grid>
          <Grid item container>
            <Typography variant="h5" sx={{ mb: 2 }}>Background sync</Typography>
            <Grid item xs={12}>
              <TextField name="backgroundSyncSettings.chunkSize" label="Chunk size, KB" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item>
              <Box pt={2}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={openDialogConfigurationSettings}
                >
                  Save
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box pt={2}>
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={() => formik.resetForm()}
                >
                  Reset
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <UpdateConfigurationSettingsModal
          onEditSettings={formik.handleSubmit}
          onResetSettings={() => {
            formik.resetForm()
            closeDialogConfigurationSettings()
          }}
          onCloseDialog={closeDialogConfigurationSettings}
          settings={settingsForModal}
          title="Update mobile settings"
          isLoading={request.isLoading}
        />
      </Form>
    </FormikProvider>
  )
}
